
/*
 *   File : manage-bank.js
 *   Author : https://evoqins.com
 *   Description : Popup component which sets the default bank if the customer has more than one account or remove the selected account. 
 *   Integrations : null
 *   Version : 1.0.0
*/

const ManageBankPopup = (props) => {

    const _handleDelete = (id) => {
        props.onDelete(id)
    }

    const _makePrimaryBank = (id) => {
        props.makePrimaryBank(id)
    }

    return (
        <div className={`position-absolute border-radius-12px border-width-2px border-solid border-bright-gray padding-10px-lr py-2 bg-white white-space-nowrap w-fit-content e-bank-options z-index-12 ${props.className}`}>
            {
                props.id !== props.primaryBank &&
                <p className="color-black e-poppins-regular e-font-14 margin-18px-bottom"
                    onClick={() => _makePrimaryBank(props.id)}>Make primary account</p>
            }
            <p className="color-black e-poppins-regular e-font-14 mb-0 "
                onClick={() => _handleDelete(props.id)}>Remove bank account</p>

        </div>
    )
}

export default ManageBankPopup