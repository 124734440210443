
/*
 *   File : ticket.js
 *   Author : https://evoqins.com
 *   Description : Common component which is being used in all tickets page.
 *   Integrations : null
 *   Version : 1.0.0
 */
import React from "react";
import styles from "../../Styles/Component/ticket.module.scss";



const TicketCard = React.memo((props) => {

    const _getFileName = (file) => {
        var file_name = file.split("?");
        file_name = file_name[0].split("/");
        file_name = file_name[file_name.length - 1];
        return file_name
    }

    return (
        <div className={`${styles.e_ticket} border-radius-8px border-all p-4 d-flex position-relative .align-items-flex-start border-columbia-blue`}>
            <div className="ms-1 w-100">
                <div className="d-flex justify-content-between align-items-center margin-12px-bottom mt-1">
                    <p className="color-gray e-poppins-medium e-font-16 my-0 line-height-22px">Ticket {props.data.ticket_id}</p>
                    <span className="py-2 px-3 border-radius-24px e-font-16 e-inter-regular line-height-16px bg-sea-green color-white">
                        {props.data.status}
                    </span>
                </div>
                <p className="color-black e-poppins-medium e-font-16 line-height-22px mb-0 mt-3 truncate-second-line">
                    {props.data.description}
                </p>
                <div className="d-lg-flex d-md-flex d-sm-flex justify-content-between align-items-center margin-12px-bottom mt-3 ">
                    {
                        props.data.image &&
                        <div className="bg-pale-baby-blue border-radius-8px w-60 d-flex justify-content-between align-items-center p-1">
                            <p className="p-2  color-jett-black e-poppins-regular  e-font-10 line-height-16px mb-0 cursor-pointer w-80 overflow-hidden text-overflow-ellipsis line-clamp-1 white-space-nowrap"
                                onClick={() => {
                                    window.open(props.data.image, "_blank")
                                }}>
                                {_getFileName(props.data.image)}
                            </p>
                            <img src={require("../../Assets/Images/tab/preview.svg").default}
                                alt="preview" className="ms-1" />
                        </div>
                    }

                    <p className="color-gray e-poppins-medium e-font-16 mb-0 line-height-22px mb-0 mt-3 mt-lg-0 mt-md-0 mt-sm-0">{props.data.created}</p>
                </div>
            </div>
        </div>
    )
})

export default TicketCard