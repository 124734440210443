

/*
 *   File : mandates.js
 *   Author URI : www.evoqins.com
 *   Description : Page to show all available and mandates created by Super Admin.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 17-01-2024
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

import { GradientButton } from "../../Component/CTA";
import { CustomLoader } from "../../Component/Other";
import Footer from "../../Component/Footer/footer";
import { CustomTab } from "../../Component/Tab";

import OrderAPIService from "../../Service/order-api";

const MANDATE_TYPE = ['E-mandate', 'Physical mandate'];

export default function Mandates() {

    var timer;
    const [apiLoader, setApiLoader] = useState(true);
    const [mandateList, setMandateList] = useState(null);
    const [showInvalidUserScreen, setShowInvalidUserScreen] = useState(false);
    const [invalidScreenDesc, setInvalidScreenDesc] = useState('');
    const [invalidScreenTitle, setInvalidScreenTitle] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [mandateType, setMandateType] = useState(1);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        _getMandates();
    }, [mandateType]);

    // redirect user based on login status
    function _redirectUser() {
        let login_status = Cookies.get('refreshToken');
        if (login_status) {
            window.location.href = '/';
        } else {
            window.location.href = process.env.REACT_APP_LANDING_PAGE_URL;
        }
    }

    function _openMandateUrl(url) {

        var new_window;

        new_window = window.open(url, '_blank', 'width=800,height=800');

        if (new_window !== null) {
            new_window.opener = null;
            timer = setInterval(function () {
                if (new_window.closed) {
                    clearInterval(timer);
                    _mandateCallback();
                }
            }, 1000);
        } else {
            setApiLoader(false);
            toast.dismiss();
            toast.error('Popups are disabled in your browser. Please allow popups to open the payment gateway.', {
                type: 'error'
            });
        }

    }

    // Call back mandate authentication
    function _mandateCallback() {
        let url = 'profile/mandates/callback';
        let payload = JSON.stringify({
            mandate_id: mandateList.mandate_id
        });
        OrderAPIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === true) {
                    toast.success('Mandate Approved successfully', {
                        type: 'success'
                    });
                } else {
                    toast.error('Mandate is pending', {
                        type: 'error'
                    });
                }

                _getMandates();
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        })
        setButtonLoader(false);

    }

    function _handleMandateType(value) {
        setMandateType(value);
    }

    // API - get mandates
    function _getMandates() {
        let url = `profile/mandates/pending-mandate?mandate_type=${mandateType}`;
        OrderAPIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setMandateList(response.data);
            } else {
                setShowInvalidUserScreen(true);
                setInvalidScreenTitle('No data found');
                setInvalidScreenDesc('No mandates found at the moment');
                setTimeout(() => {
                    _redirectUser();
                }, 3000);
            }
            setApiLoader(false);
        })
    };

    // API - authenticate mandate
    function _authMandate() {
        setButtonLoader(true);
        let url = 'profile/mandates/authenticate';
        let payload = JSON.stringify({
            mandate_id: mandateList.mandate_id
        });
        OrderAPIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _openMandateUrl(response.authentication_url);
            } else {
                if (response.status_code === 401) {
                    setTimeout(() => {
                        _redirectUser();
                    }, 3000);
                }
                toast.error(response.message, {
                    type: 'error'
                })
                setButtonLoader(false);
            }

        });
    }

    // inline component to show label value pair 
    function LabelValuePair({ label, value, post_fix, amount }) {
        return (
            <p className={"color-outer-space e-poppins-medium e-font-14"}>
                <label className="color-gray me-2">{label}: </label>
                {
                    amount === true ?
                        <span className="e-inter-medium">{`₹${value.toLocaleString('en-IN')}`}</span>
                        :
                        value
                }
                {
                    post_fix && (
                        <span className="color-outer-space e-poppins-regular e-font-12">({post_fix})</span>
                    )
                }
            </p>
        )
    }

    // Mandate card 
    function MandateCard({ data }) {
        return (
            <div className="border-bright-gray border-all border-radius-16px p-4">
                <div className="row border-bottom gx-0 pb-3">
                    <div className="col-md-6 col-12 d-flex">
                        <div className="bg-white border-bright-gray border-all border-radius-8px w-52px h-52px padding-6px-all me-3">
                            <img src={data.bank_icon}
                                alt={data.bank_name}
                                width={52}
                                height={52}
                                className="object-fit-contain" />
                        </div>
                        <div className="mt-2">
                            <h4 className="color-black e-poppins-medium e-font-16 mt-3">{data.bank_name}</h4>
                        </div>
                    </div>

                    <div className="col-md-6 text-md-end text-start">
                        <p className="color-light-black e-inter-medium e-font-24-sm-16">
                            <span className="color-gray e-poppins-medium e-font-14">Mandate limit: </span>
                            {`₹${data.mandate_limit.toLocaleString('en-IN')}`}
                        </p>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-4 col-md-6 col-12">
                        <LabelValuePair label="Mandate Id"
                            value={data.mandate_id} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <LabelValuePair label="Mandate status"
                            value={data.status} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-12">
                        <LabelValuePair label="Expiry date"
                            value={data.end_date} />
                    </div>

                    <div className="col-lg-4 col-md-6 col-12">
                        <LabelValuePair label="Created by"
                            value={data.created_by}
                            post_fix={data.admin_type} />
                    </div>
                    <div className="col-lg-8 col-md-6 col-12">
                        <LabelValuePair label="Created on"
                            value={data.created} />
                    </div>
                </div>
                <div className="row">
                    {
                        mandateType === 1 ?
                            <div className="col-lg-4 col-md-6 col-12 mt-2">
                                <GradientButton label="Verify & Approve mandate"
                                    loading={buttonLoader}
                                    className="padding-14px-tb padding-16px-lr"
                                    handleClick={_authMandate} />
                            </div>
                            :
                            <div className="col-lg-4 col-md-6 col-12 mt-2">
                                <GradientButton label="Download "
                                    loading={buttonLoader}
                                    className="padding-14px-tb padding-16px-lr"
                                    handleClick={_authMandate} />
                            </div>
                    }

                </div>

            </div>
        )
    }
    return (
        <>
            {
                apiLoader === true ? (
                    <div className="container h-100vh padding-64px-lr">
                        <CustomLoader />
                    </div>
                ) : (
                    <>
                        {
                            showInvalidUserScreen === false ? (
                                <>
                                    <div className="container h-100vh padding-64px-lr-md">
                                        {/* Header */}
                                        <div className="row mt-4">
                                            <div className="col-12 text-center">
                                                <img src={require('../../Assets/Images/master-trust-logo.svg').default}
                                                    alt="mt wealth - powered by Master Trust" />
                                            </div>
                                        </div>

                                        {/* Body  */}
                                        <div className="row">
                                            <div className="col-12 mb-3 mt-3">
                                                <h2 className="color-black e-poppins-medium e-font-20">
                                                    Pending mandate
                                                </h2>
                                            </div>

                                            <div className="col-12">
                                                <CustomTab data={MANDATE_TYPE}
                                                    wrapperClass={'gap-16px'}
                                                    selectedTab={mandateType}
                                                    className="px-0 padding-10px-tb e-order-tab mb-lg-4 mb-md-4 mb-3"
                                                    onSelectTab={_handleMandateType} />
                                            </div>

                                            {/* List mandate */}
                                            <div className="col-12">
                                                <MandateCard data={mandateList} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Footer */}
                                    <Footer />
                                </>

                            ) : (
                                <div className="container-fluid h-100vh">
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="e-card-401 w-400px border-radius-16px p-4 text-center">
                                            <img src={require('../../Assets/Images/no-transactions.jpg')}
                                                alt="404"
                                                width={200}
                                                height={200} />
                                            <h4>{invalidScreenTitle}</h4>
                                            <p>{invalidScreenDesc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </>
    );
};