
/*
 *   File : calender.js
 *   Author URI : https://evoqins.com
 *   Description : calender component
 *   Integrations : react-calendar
 *   Version : v1.1
 */

import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';

import Icon from '../Icon/icon';

import 'react-calendar/dist/Calendar.css';

// setting maximum date as three months from now
const MAX_DATE = new Date();
MAX_DATE.setMonth(MAX_DATE.getMonth() + 3);

const CustomCalendar = (props) => {
	const [value, setValue] = useState(new Date());
	
	useEffect(()=>{
		if (props.defaultDayAsTomorrow) {
			let next_day = new Date();
        	next_day.setDate(next_day.getDate() + 1);
			setValue(next_day);
		}
	},[props.defaultDayAsTomorrow])

	function onChange(e) {
		setValue(e);
		props.handleChange(e);
	};

	// check the day is week day 
	const isWeekend = (date) => {
		const day = date.getDay();
		return day === 0 || day === 6; // Sunday is 0, Saturday is 6
	};

	// format day according to the design
	function formatWeekDay(locale, date) {
		const day_format = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
		const day_index = date.getDay();
		const formatted_day = day_format[day_index];
		return formatted_day;
	}

	return (
		<div>
			<Calendar value={value}
				minDate={new Date()}
				maxDate={MAX_DATE}
				nextLabel={<Icon icon="calender-arrow-right" size={24} />}
				next2Label={null}
				prevLabel={<Icon icon="calender-arrow-left" size={24} />}
				prev2Label={null}
				showNeighboringMonth={false}
				navigationLabel={({ date, label, locale, view }) => { return label.split(' ')[0] }}
				tileClassName="color-black e-poppins-regular e-font-16 "
				tileDisabled={({ date }) => isWeekend(date)} // disabling date in week ends 
				formatShortWeekday={formatWeekDay}
				onChange={onChange} />
		</div>
	);
}

export default CustomCalendar;