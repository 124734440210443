/*
 *   File : data-table.js
 *   Author : https://evoqins.com
 *   Description : Data table component
 *   Integrations : react-data-table-component
 *   Version : 1.0.0
 */

/*import packages*/
import React from 'react';
import DataTable from 'react-data-table-component';

const SORT_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.5 4C1.5 3.72386 1.72386 3.5 2 3.5H14C14.2761 3.5 14.5 3.72386 14.5 4C14.5 4.27614 14.2761 4.5 14 4.5H2C1.72386 4.5 1.5 4.27614 1.5 4ZM1.5 6.66667C1.5 6.39052 1.72386 6.16667 2 6.16667H14C14.2761 6.16667 14.5 6.39052 14.5 6.66667C14.5 6.94281 14.2761 7.16667 14 7.16667H2C1.72386 7.16667 1.5 6.94281 1.5 6.66667ZM1.5 9.33333C1.5 9.05719 1.72386 8.83333 2 8.83333H7.33333C7.60948 8.83333 7.83333 9.05719 7.83333 9.33333C7.83333 9.60948 7.60948 9.83333 7.33333 9.83333H2C1.72386 9.83333 1.5 9.60948 1.5 9.33333ZM1.5 12C1.5 11.7239 1.72386 11.5 2 11.5H7.33333C7.60948 11.5 7.83333 11.7239 7.83333 12C7.83333 12.2761 7.60948 12.5 7.33333 12.5H2C1.72386 12.5 1.5 12.2761 1.5 12Z" fill="#84DCC8" />
        <path d="M11.3131 8.98043C11.5084 8.78517 11.825 8.78517 12.0202 8.98043L13.6869 10.6471C13.8821 10.8424 13.8821 11.1589 13.6869 11.3542C13.4916 11.5495 13.175 11.5495 12.9798 11.3542L12.1667 10.5411V13.334C12.1667 13.6101 11.9428 13.834 11.6667 13.834C11.3905 13.834 11.1667 13.6101 11.1667 13.334V10.5411L10.3536 11.3542C10.1583 11.5495 9.84171 11.5495 9.64645 11.3542C9.45118 11.1589 9.45118 10.8424 9.64645 10.6471L11.3131 8.98043Z" fill="#1177BD" />
    </svg>
);

export default function DataTableContainer(props) {


    return (
        <>
            <DataTable columns={props.columns}
                id={props.id}
                data={props.data}
                customStyles={props.customStyles}
                conditionalRowStyles={props.conditionalRowStyles} 
                paginationPerPage={10}
                paginationTotalRows={props.paginationTotalRows}
                pagination={props.pagination}
                selectableRows={props.selectableRows}
                paginationIconPrevious={props.paginationIconPrevious}
                onSelectedRowsChange={(row) => props.selectRows(row)}
                paginationIconNext={props.paginationIconNext}
                onRowClicked={(e) => props.rowClick(e)}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
                paginationComponentOptions={props.paginationComponentOptions}
                paginationIconLastPage={props.paginationIconLastPage}
                paginationIconFirstPage={props.paginationIconFirstPage}
                sortIcon={SORT_ICON}
                defaultSortAsc={props.defaultSortAsc}
                defaultSortFieldId={props.defaultSortFieldId}
                onRowMouseEnter={props.onRowMouseEnter}
                onRowMouseLeave={props.onRowMouseLeave} 
                fixedHeader={props.fixedHeader}
                fixedHeaderScrollHeight={props.fixedHeaderScrollHeight}/>
        </>
    );
}

DataTableContainer.defaultProps = {
    rowClick: () => { },
    // defaultSortAsc: false
}


