/*
 *   File : watchlist.js
 *   Author URI : www.evoqins.com
 *   Description : Container file to create the UI for items added for later from MF listing and detail
 *   Integrations : null
 *   Version : v1.0
 *   Created : 13-09-2023
 */

import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";

import { BreadCrumb } from "../BreadCrumb";
import { InvestmentHeader } from "../Header";
import Footer from "../Footer/footer";
import { WatchlistTable } from "../Table";
import { DeleteConfirmation, InvestGoalModal, PaymentStatusModal, MfInProcessStatus } from "../Modal";
import { CustomLoader, EmptyScreen } from "../Other";
import Icon from "../Icon/icon";

import { _getDayOfMonth } from "../../Helper";
import APIService from "../../Service/api-service";

import { _getMFInvestmentSummary, _addToWatchList } from "../../Helper/api";

import NoData from '../../Assets/Images/nodata/watchlist.svg';


const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Your watchlist', url: null },
];


const Watchlist = () => {
    const navigator = useNavigate();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [watchlistData, setWatchlistData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [lastItemReached, setLastItemStatus] = useState(false);
    const [loader, setLoader] = useState(true);
    const [selectedFund, setSelectedFund] = useState({});
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [removeFund, setRemoveFund] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [mountInvestModal, setMountInvestModal] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [isNextMonth, setIsNextMonth] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);

    useEffect(() => {

        function _handleScroll() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('scroll', _handleScroll);

        return () => {
            window.removeEventListener('scroll', _handleScroll);
        }
    }, []);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getWatchlistData();
    }, []);

    useEffect(() => {
        if (pageNumber > 1) {
            _getWatchlistData();
        }
    }, [pageNumber]);

    // listener open investment modal
    useEffect(() => {
        if (mountInvestModal === true) {
            _openInvestmentModal();
        }
    }, [mountInvestModal]);

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal])

    useEffect(() => {
        if (Object.keys(removeFund).length !== 0) {
            const modal = new Modal(document.getElementById("delete-confirmation"), {});
            modal.show();
        }
    }, [removeFund]);

    useEffect(()=>{
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    },[showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }

    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }

    const _removeItemFromWatchlist = (id) => {
        const updated_data = watchlistData.filter((item) => item.id !== id);
        if (updated_data.length < 10) {
            setLastItemStatus(true);
        }
        setWatchlistData(updated_data);
    }

    // handler for page change
    const _handlePageNumber = () => {
        setPageNumber(prevCount => prevCount + 1);
    }

    function _navigateToProfile() {
        navigator('/profile', {
            state: { tab: 5, type: 2 },
        });
    }

    // API - get watchlist data
    const _getWatchlistData = () => {
        let url = 'mf-data/list-watchlist';
        let payload = JSON.stringify({
            page_num: pageNumber,
            page_size: 10
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                if (pageNumber > 1) {
                    setWatchlistData([...watchlistData, ...response.data]);
                }
                else {
                    setWatchlistData(response.data)
                    setLastItemStatus(false);
                }
                if (response.data.length < 10) {
                    setLastItemStatus(true);
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setLastItemStatus(true);
            }
            setLoader(false);
        })

    }


    function _openInvestmentScreen(e) {
        if (USER_DATA.kyc_step < 11) {
            toast.dismiss();
            toast.error('Complete KYC to start investing', {
                type: 'error'
            });
            return;
        }
        setSelectedFund(e);
        const { date } = _getDayOfMonth();
        _getMFInvestmentSummary(e.id, true, date)
            .then((response) => {
                setMfInvestmentData(response);
                setMountInvestModal(true);
            })
            .catch((response) => {
                toast.error(response, {
                    type: "error",
                });
            });
    }

    async function _removeWatchList() {
        let status = false;
        _addToWatchList(removeFund.mf_id).then(() => {
            _removeItemFromWatchlist(removeFund.id);
            status = true;
            toast.dismiss();
            toast.success(`${removeFund['name']} removed from watchlist`, {
                type: "success",
            });
        })
        return status;
    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id, is_next_month, mode_of_payment) {
        if (id === null) {
            setOpenPaymentStatusModal(true);
            setIsNextMonth(is_next_month);
            setPaymentStatus(true);
            setPaymentStatusText('Success');
            return
        }
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                // making payment success modal status as processing in case of netbanking and made instant payment
                if (mode_of_payment === 2 && is_next_month !== true) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
            }
        })
    }

    return (
        <Fragment>
            {

                <div className="e-watchlist e-page-container margin-80px-bottom">
                    {/* Header */}
                    <InvestmentHeader />
                    {
                        loader === true ?
                            <div className="h-80vh">
                                <CustomLoader />
                            </div>
                            :
                            <>
                                <BreadCrumb data={PAGE_NAVIGATION} />

                                {/* For preload the image */}
                                <img src={NoData} className="d-none" />

                                {
                                    watchlistData.length === 0 ? (
                                        <div className="row">
                                            <div className="col-12 text-center h-80vh d-flex align-items-middle justify-content-center">
                                                <EmptyScreen image={NoData}
                                                    title="Empty watchlist, ready to fill it up!"
                                                    description="Craft your fund watchlist by pressing the button below."
                                                    buttonLabel="Create watchlist"
                                                    onPress={() => navigator('/explore')} />
                                            </div>
                                        </div>
                                    ) :
                                        <>
                                            <h5 className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px mb-3 margin-32px-top-sm-16">My watchlist</h5>
                                            {
                                                screenWidth > 576 &&
                                                <div className="col-12 text-end mt-2 e-table-scroll-indicator-watchlist">
                                                    <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                                        <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                                            width='24px'
                                                            height='24px'
                                                            className='e-rotate-gif' />
                                                        Scroll horizontally for view more  information</p>
                                                </div>
                                            }


                                            <WatchlistTable data={watchlistData}
                                                openInvestmentModal={_openInvestmentScreen}
                                                removeItem={(row) => setRemoveFund(row)} />

                                            {
                                                lastItemReached === false &&
                                                <div className="d-flex justify-content-center align-items-center w-100 mt-3"
                                                    onClick={() => _handlePageNumber()}>
                                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer">
                                                        View more
                                                    </p>
                                                    {
                                                        loader === false ?
                                                            <Icon icon="right-arrow"
                                                                size={24}
                                                                className="e-view-pending cursor-pointer" />
                                                            :
                                                            <img src={require("../../Assets/Images/loader.gif")}
                                                                alt="data loader"
                                                                width={50} />
                                                    }
                                                </div>
                                            }
                                        </>
                                }
                            </>
                    }





                </div>
            }
            <Footer />
            {
                mountInvestModal === true && (
                    <InvestGoalModal data={selectedFund}
                        mfInvestmentData={mfInvestmentData}
                        close={() => {
                            setMfInvestmentData({})
                            setSelectedFund({})
                            setMountInvestModal(false);
                        }}
                        navigateToProfile={_navigateToProfile}
                        showInProcessModal={_openMfInProcessModal}
                        getPaymentStatus={_getPaymentStatus} />
                )
            }
            {
                Object.keys(removeFund).length !== 0 && (
                    <DeleteConfirmation confirmationText="Are you sure you want to remove this fund from watchlist? This action is irreversible."
                        cancelButtonName="No, cancel"
                        submitButtonName="Yes, delete"
                        handleConfirmation={_removeWatchList}
                        close={() => setRemoveFund({})} />
                )
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        close={() => setOpenPaymentStatusModal(false)}
                        type={isNextMonth === true ? 5 : 1} />
                )
            }
             {
                showMfInProcessModal === true &&
                    <MfInProcessStatus message={inProcessMessage} 
                        close={()=> setShowMfInProcessModal(false)}/>
            }
        </Fragment>
    )
}

export default Watchlist