/*
 *   File : special-text.js
 *   Author URI : https://evoqins.com
 *   Description : Special text component for the investment platform
 *   Integrations : null
 *   Version : v1.1
 */

import styles from "../../Styles/Component/special-text.module.scss";


const SpecialText = (props) => {
    return (
        <h3 className={`${props.className} e-poppins-regular colour-black e-font-32 line-height-46px `}>
            <span className={`${props.titleClass} mb-1`}>{props.title}</span>
            <span className={`${styles.e_border_gradient} ${props.textClass} `}>
                <span className={`color-primary-color e-inter-medium `} >
                    {props.gradientTitle}
                </span>
            </span>
        </h3>
    )
}

SpecialText.defaultProps = {
    className: "",
    titleClass: "",
    title: "",
    textClass: "",
    gradientTitle: ""
}

export default SpecialText