
import { put, takeEvery } from "redux-saga/effects";

// manage profile data
function* _updateProfileData(action) {
    try {
        yield put({
            type: 'PROFILE_DATA', payload: action.payload, // Access payload from the action argument
        });
    }
    catch (error) {
        console.log(error);
    }
}

// manage search header data
function* _updateSearchSuggestion(action) {
    try {
        yield put({
            type: 'HEADER_DATA', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// manage search header data
function* _updateSearchSuggestionStatus(action) {
    try {
        yield put({
            type: 'SHOW_SEARCH_SUGGESTION', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// add to cart
function* _updateAddToCart(action) {
    try {
        yield put({
            type: 'ADD_CART', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// get investment styles
function* _getRiskCategories(action) {
    try {
        yield put({
            type: 'MF_RISK_CATEGORIES', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// get notification count
function* _getNotificationCount(action) {
    try {
        yield put({
            type: 'NOTIFICATION_COUNT', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// Get selected MF filter from Explore page
function* _getMFFilter(action) {
    try {
        yield put({
            type: 'SELECTED_MF_FILTERS', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// Get selected MF filter from Explore page
function* _getQueryTypes(action) {
    try {
        yield put({
            type: 'QUERY_TYPES', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// Get KYC general data from kyc page
function* _getKycTypes(action) {
    try {
        yield put({
            type: 'KYC_CATEGORIES', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// Get KYC progress
function* _getKYCProgress(action) {
    try {
        yield put({
            type: 'KYC_PROGRESS', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

// Get Tooltip data
function* _getToolToolData(action) {
    try {
        yield put ({
           type: 'TOOLTIP_DATA', payload: action.payload 
        });
    } catch (error) {
        console.log(error);
    }
}

// Get General data
function* _getGeneralData(action) {
    try {
        yield put({
            type: 'APP_GENERAL_DATA', payload: action.payload
        });
    }
    catch (error) {
        console.log(error);
    }
}

export function* updateProfile() {
    yield takeEvery('UPDATE_PROFILE_DATA', _updateProfileData);
}

export function* updateSearchSuggestions() {
    yield takeEvery('UPDATE_SEARCH_HEADER_DATA', _updateSearchSuggestion);
}

export function* updateSearchSuggestionStatus() {
    yield takeEvery('UPDATE_SEARCH_SUGGESTION_STATUS', _updateSearchSuggestionStatus);
}

export function* getInvestmentStyles() {
    yield takeEvery('MF_RISK_CATEGORIES', _getRiskCategories);
}

export function* getNotificationCount() {
    yield takeEvery('NOTIFICATION_COUNT', _getNotificationCount);
}

export function* getMFFilter() {
    yield takeEvery('SELECTED_MF_FILTERS', _getMFFilter);
}

export function* getQueryTypes() {
    yield takeEvery('QUERY_TYPES', _getQueryTypes);
}

export function* addToCart() {
    yield takeEvery('ADD_TO_CART', _updateAddToCart);
}

export function* getKycTypes() {
    yield takeEvery('KYC_CATEGORIES', _getKycTypes);
}

export function* getKYCProgress() {
    yield takeEvery('KYC_PROGRESS', _getKYCProgress);
}

export function* getToolTip() {
    yield takeEvery('TOOLTIP_DATA', _getToolToolData);
}

export function* getAppGeneralData() {
    yield takeEvery('APP_GENERAL_DATA', _getGeneralData);
}