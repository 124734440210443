/*
 *   File : bar.js
 *   Author URI : https://evoqins.com
 *   Description : Bar chart to show portfolio performance
 *   Integrations : chart.js
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';

const BarChart = (props) => {
 
    useEffect(() => {

        // Adding data and setting position of the tool tip
        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            if (tooltip.body) {
                const title = document.createElement('div'); //tooltip title
                const body = document.createElement('div'); // tooltip body
                // taking data from chart tooltip object and create text node
                const toolTipTitle = document.createTextNode(tooltip.title[0]);
                const toolTipBody = document.createTextNode(`₹ ${tooltip.body[0].lines[0]}`);
                // append the title to title div and style
                title.appendChild(toolTipTitle);
                title.style.color = Colors.gray;
                title.style.fontSize = '12px';
                title.style.fontFamily = 'Poppins-Regular';
                // append the body content to the div and style
                body.appendChild(toolTipBody);
                body.style.color = Colors.black;
                body.style.fontSize = '14px';
                body.style.fontFamily = 'Inter-Medium';
                tooltipEl.innerHTML = ''; // Clear previous content
                // appending tooltip to the tooltip element
                tooltipEl.appendChild(title);
                tooltipEl.appendChild(body);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';

            // Apply styles to the tooltip
            tooltipEl.style.background = Colors.white;
            tooltipEl.style.borderRadius = '6px';
            tooltipEl.style.boxShadow = '4px 4px 12px 0px rgba(175, 175, 175, 0.58)';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, -90%)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.padding = '5px 10px';
        };

        // Chart configuration
        var newChart = document.getElementById(`${props.chartId}`).getContext('2d');
        newChart = new Chart(newChart, {
            type: 'bar',
            data: {
                labels: props.data.labels,
                datasets: [{
                    data: props.data.data,
                    backgroundColor: props.data.color,
                }]
            },
            plugins: [htmlLegendBarChart],
            options: {
                plugins: {
                    htmlLegend: { // custom legend
                        // ID of the container to put the legend in
                        containerID: 'bar-legend-container',
                    },
                    legend: { // hide default legend
                        display: false,
                        position: 'top'
                    },
                    tooltip: { //tooltip
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler,
                        callbacks: {
                            label: function(tooltipItem, data) {
                              return tooltipItem.parsed.y.toLocaleString('en-IN');
                            }
                        }
                    },
                },
                scales: {
                    x: {
                        afterFit(scale) {
                            const margin = 1; // 1 is the full with of a bar
                            scale._startValue -= margin;
                            scale._valueRange += 2 * margin;
                        },
                        display: false,
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            display: false,
                            lineWidth: false,
                            drawTicks: false,
                        },
                        border: {
                            display: false,
                            dash: [8, 4]
                        },
                        grid: {
                            color: function (context) {
                                return Colors.pale_blue;

                            },
                        },
                    },
                },
                categoryPercentage: 0.9,
                barPercentage: 0.9,
            },

        });
        return () => {
            newChart.destroy()
        }
    }, [props.data, props.chartId]);

    // custom legend  
    const htmlLegendBarChart = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            // function call for create custom legend 
            const ul = _getOrCreateLegendList(chart, options.containerID);
            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }
            const items = props.data.labels;
            // create and style legend label and color indicator
            let i = 0;
            items.forEach(item => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginTop = '10px';
                li.style.marginLeft = '10px';
                li.style.marginRight = '30px';
                // Color box
                const boxSpan = document.createElement('span');
                boxSpan.style.background = props.data.color[i];
                boxSpan.style.borderRadius = '4px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.flexShrink = 0;
                boxSpan.style.height = '16px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '16px';

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = Colors.gray;
                textContainer.style.fontSize = '12px';
                textContainer.style.fontFamily = 'Poppins-regular';
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const text = document.createTextNode(item);
                textContainer.appendChild(text);
                li.setAttribute('data-content', `₹ ${props.data.data[i].toLocaleString('en-IN')}`);
                li.classList.add('bar-legend-item');

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
                i++;
            });
        }
    };


    // Append custom legend to div
    function _getOrCreateLegendList(chart, id) {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'row';
            listContainer.style.margin = 0;
            listContainer.style.padding = 0;

            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    // Creating custom tool tip
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = 'tooltip'; // Add a class for styling
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };




    return (
        <div className="e-chart-container position-relative">
            <div className="e-bar-legend mb-3" id="bar-legend-container">
            </div>
            <canvas id={props.chartId} width="400" height="220"  ></canvas>

        </div>
    )
}

export default BarChart;
