/*
 *   File : bank-account.js
 *   Author URI : https://evoqins.com
 *   Description : Bank account page which lists all bank accounts linked or added against the investor account.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState } from "react"
import { Modal } from "bootstrap"
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

import Store from "../../Store";
import { InvestmentHeader } from "../../Component/Header"
import { BreadCrumb } from "../../Component/BreadCrumb"
import { DataTable } from "../../Component/Form-elements"
import { useClickOutside } from "../../Helper"
import { MandateListModal, AddBankModal, DeleteConfirmation } from "../../Component/Modal"
import { GradientButton, LinkText } from "../../Component/CTA"
import Footer from "../../Component/Footer/footer"
import { ManageBankPopup } from "../../Component/Popups"
import { CustomLoader, EmptyScreen } from "../../Component/Other"
import APIService from "../../Service/api-service"

import Color from "../../Styles/color.module.scss";

// Bread crumbs data
const BANK_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage bank account', url: null },
]

const BankAccounts = () => {

    const bankRef = useRef();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [bankData, setBankData] = useState([]);
    const [primaryBank, setPrimaryBank] = useState(null);
    const [viewMandates, setViewMandates] = useState({});
    const [openAddBankModal, setOpenAddBankModal] = useState(false);
    const [loader, setLoader] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});
    const [tabLoader, setTabLoader] = useState(false);
    // custom hook called when clicked outside the popup to close the pop up
    // useClickOutside(bankRef, () => {
    //     setSelectedBank({});
    // });

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getBanks();
    }, []);

    // listener for open add bank modal
    useEffect(() => {
        if (openAddBankModal === true) {
            const modal = new Modal(document.getElementById("add-bank"), {});
            modal.show();
        }
    }, [openAddBankModal]);

    useEffect(() => {
        if (showDeleteModal === true) {
            const modal = new Modal(document.getElementById("delete-confirmation"), {});
            modal.toggle();
        }
    }, [showDeleteModal]);


    // listener for opening delete modal
    useEffect(() => {
        if (Object.keys(viewMandates).length !== 0) {
            _openMandateModal();
        }
    }, [viewMandates]);

    useEffect(() => {
        console.log('USER_DATA', USER_DATA);
    }, [USER_DATA])
    // Bank data table
    const BANK = [
        {
            name: 'Linked bank',
            selector: row => row['bank_name'],
            sortable: false,
            // width: "360px",
            cell: row =>
                <div className="d-flex align-items-center gap-16px">
                    <div className="padding-12px-tb px-2 border-radius-8px border-all border-bright-gray e-bank-image">
                        <img src={row['bank_image']}
                            alt={row['bank_name']}
                            width={64}
                            height={28}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-8px">
                        <div className="d-flex gap-16px flex-wrap ">
                            <p className="color-black e-poppins-semi-bold e-font-16 line-height-32px mb-0">{row['bank_name']}</p>
                            {
                                row.is_default === true &&
                                <p className="border-radius-24px bg-alice-blue color-primary-color e-poppins-regular e-font-12 line-height-16px mb-0 p-2">Primary bank</p>
                            }
                        </div>
                        <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{row['masked_account_number']}</p>
                    </div>

                </div>
        },
        {
            name: 'Account type',
            selector: row => row['account_type_label'],
            sortable: false,
            width: "120px",
        },
        {
            name: 'IFSC code',
            selector: row => row['ifsc_code'],
            sortable: false,
            width: "110px",
        },
        {
            name: 'Mandate mapped',
            selector: row => row['mandates_available'],
            sortable: false,
            width: "150px",
            cell: row => <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['mandates_available'] === true ? "Yes" : "No"}</p>
        },
        {
            name: '',
            sortable: false,
            width: "130px",
            cell: row =>
                <div className={`gap-24px ${row.mandates_available === true ? "d-flex justify-content-between align-items-center" : "w-100 text-end"}`}>
                    {row.mandates_available === true &&
                        <LinkText title="View mandate"
                            onClick={() => _viewMandates(row)} />
                    }
                    {
                        bankData.length > 1 &&
                        <>
                            <img src={require('../../Assets/Images/profile/bank-menu-dots.png')}
                                alt="View more"
                                width={24}
                                height={24}
                                onClick={() => setSelectedBank(row)} />
                            {
                                selectedBank.bank_id === row.bank_id && (
                                    <>
                                        <div ref={bankRef}
                                            className="position-relative">
                                            <ManageBankPopup id={selectedBank.bank_id}
                                                primaryBank={primaryBank}
                                                makePrimaryBank={_makeDefaultBank}
                                                onDelete={_handleDeleteBank} 
                                                className="top-0 right-5px"/>
                                        </div>
                                        <div className="position-fixed w-100 z-index-10 h-100 left-0 top-0 cursor-default"
                                        onClick={() => setSelectedBank({})}></div>
                                    </>
                                )

                            }
                        </>
                    }
                </div>

        }

    ];

    // bank table style
    const bank_styles = {
        table: {
            style: {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        },
        headRow: {
            style: {
                padding: "0 1.5rem 1rem 1.5rem",
                gap: "3.5rem",
                justifyContent: "flex-start",
            }
        },
        headCells: {
            style: {
                color: Color.outer_space,
                fontSize: "1rem",
                fontFamily: "Poppins-regular",
                fontWeight: "400",
                lineHeight: "1rem",
            }
        },
        rows: {
            style: {
                borderRadius: "1.5rem",
                gap: "3.5rem",
                padding: "1.5rem",
                marginBottom: "2rem",
                "&:last-child": {
                    marginBottom: "0"
                }
            },
        },
        cells: {
            style: {
                color: Color.black,
                fontFamily: "Poppins-Medium",
                fontSize: "1rem",
                lineHeight: "1.25rem",
                justifyContent: "flex-start",
            }
        }
    };

    // function called to show options when clicked on the dots icon
    const _showBankOptions = (id) => {
        setSelectedBank(id);
    }


    // handler to delete the bank account
    const _handleDeleteBank = (id) => {
        setShowDeleteModal(true);
    }

    // handler to show mandate modal
    const _viewMandates = (data) => {
        setViewMandates(data)
    }

    // handler to manage mandates from mandate modal
    const _manageMandate = () => {
        setViewMandates({})
    }

    //to open mandate modal
    const _openMandateModal = () => {
        const modal = new Modal(document.getElementById("available-mandates"), {});
        modal.show();
    };

    const _handleCloseAddBank = () => {
        setLoader(true);
        setOpenAddBankModal(false)
        _getBanks();

    }
    // API - Get all banks
    function _getBanks() {
        let url = 'profile/banks/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBankData(response.data);
                const default_bank = response.data.find((item) => item.is_default === true)
                setPrimaryBank(default_bank.bank_id);
                _updateBankInStore(response.data);

            } else {
                setBankData([]);
                setPrimaryBank(null);
            }
            setLoader(false);
        });

    }

    // API : Set primary account
    function _makeDefaultBank() {
        let url = 'profile/banks/set-primary';
        let payload = JSON.stringify({
            bank_id: selectedBank.bank_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Successfully set ${selectedBank.bank_name} as primary account`, {
                    type: "success"
                });
                _getBanks();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setTimeout(() => {
                setSelectedBank(0);
            }, 1000);

        })
    };

    // API : Remove bank account 
    async function _removeBankAccount() {
        setTabLoader(true);
        let status = true;
        let url = 'profile/banks/delete';
        let payload = JSON.stringify({
            bank_id: selectedBank.bank_id
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`${selectedBank.bank_name} removed successfully`, {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }
            setTimeout(() => {
                setSelectedBank(0);
                setTabLoader(false);
            }, 1000);
        });
        return status;
    }

    const _updateBankInStore = (data) => {
        console.log('user data', USER_DATA);
        let profile = USER_DATA;
        profile.banks = data;
        Store.dispatch({
            type: 'UPDATE_PROFILE_DATA',
            payload: profile
        });
    };


    return (
        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <div className="e-bank-account e-page-container margin-72px-bottom">
                    <InvestmentHeader />
                    <BreadCrumb data={BANK_NAVIGATION} />
                    <p className="color-black e-poppins-medium e-font-20 line-height-32px mt-4 mb-2">Bank account details</p>

                    {
                        bankData.length != 0 ?
                            <>
                                <div className="e-bank-data-table">
                                    <DataTable columns={BANK}
                                        data={bankData}
                                        pagination={false}
                                        customStyles={bank_styles} />
                                </div>
                                <GradientButton label="Add new bank"
                                    className="py-2 px-3 e-font-16 margin-40px-top"
                                    disabled={USER_DATA.is_kyc_done === false ? true : false}
                                    handleClick={() => setOpenAddBankModal(true)} />
                            </>
                            :
                            <div className="col-12 text-center">
                                <EmptyScreen image={require('../../Assets/Images/nodata/bank.svg').default}
                                    title="Link your bank account to start investing."
                                    description="Initiate your investment journey seamlessly by linking your bank account"
                                    buttonLabel={USER_DATA.is_kyc_done == true ? "Add new bank" : null}
                                    disabled={USER_DATA.is_kyc_done === false ? true : false}
                                    onPress={() => setOpenAddBankModal(true)}
                                />
                            </div>
                    }
                </div>

                {/* to show mandate modal */}
                {
                    Object.keys(viewMandates).length !== 0 ?
                        <MandateListModal manageMandate={viewMandates.mandates}
                            handleClick={() => setViewMandates({})}
                            close={() => setViewMandates({})} />
                        :
                        null
                }

                {/* add bank modal */}
                {
                    openAddBankModal === true &&
                    <AddBankModal numberOfBank={bankData.length}
                        close={_handleCloseAddBank} />
                }

                {
                    showDeleteModal === true && (
                        <DeleteConfirmation confirmationText="Are you sure you want to delete this bank account? This action is irreversible."
                            cancelButtonName="No, cancel"
                            submitButtonName="Yes, delete"
                            loading={tabLoader}
                            updateList={_getBanks}
                            handleConfirmation={_removeBankAccount}
                            close={() => { setShowDeleteModal(false); }} />
                    )
                }

                <Footer />
            </Fragment>

    )
}

export default BankAccounts