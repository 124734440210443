// format for action
import { UPDATE_CART, UPDATE_NAVIGATION } from "../types";

export function updateNavigation(navigationPath) {
    return {
        type: UPDATE_NAVIGATION,
        payload: navigationPath
    };
};

export function updateCart(item) {
    return {
        type: UPDATE_CART,
        payload: item
    }
};