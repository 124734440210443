// store.js

import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './Reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Saga';

const sagaMiddleware = createSagaMiddleware();
const Store = configureStore(
    {
        reducer: rootReducer,
        middleware: () => [sagaMiddleware]
    });

sagaMiddleware.run(rootSaga)

export default Store;
