/*
 *   File :  news-listing.js
 *   Author URI : https://evoqins.com
 *   Description : Listing page for listing news in UI.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { format } from "date-fns";

import { InvestmentHeader } from "../../Component/Header";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { CustomLoader } from "../../Component/Other";
import { NewsModal } from "../../Component/Modal";
import { DateRangeFilterPopup } from "../../Component/Popups";
import { EmptyScreen } from "../../Component/Other";
import { NewsCard } from "../../Component/Card";
import Icon from "../../Component/Icon/icon";
import Footer from "../../Component/Footer/footer";

import APIService from "../../Service/api-service";

const NEWS_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'News', url: null },
]

const NewsListing = () => {

    const [initialLoader, setInitialLoader] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [newsData, setNewsData] = useState([]);
    const [selectedNews, setSelectedNews] = useState({});
    const [openNewsDetail, setOpenNewsDetail] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);
    const [showDateFilter, setShowDateFilter] = useState(false);
    const [selectedRange, setSelectedRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [startDate, setStartDate] = useState("Start date");
    const [endDate, setEndDate] = useState("End date");
    const [paginationLoader, setPaginationLoader] = useState(false);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (paginationLoader === true || apiLoader === true) {
            _getNews();
        }
    }, [paginationLoader, apiLoader]);

    useEffect(() => {
        if (openNewsDetail === true) {
            const modal = new Modal(document.getElementById("news-modal"), {});
            modal.show();
        }
    }, [openNewsDetail]);

    function _openNewsDetailModal(data) {
        setOpenNewsDetail(true);
        setSelectedNews(data);
    }

    function _unMountNewsDetailModal() {
        setOpenNewsDetail(false);
        setSelectedNews({});
    }

    const _handleShowDateFilter = (clear) => {
        setShowDateFilter(!showDateFilter);
        if (clear) {
            setStartDate("Start date");
            setEndDate("End date");
            setSelectedRange({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            })
        }
    }

    const _handleDateRangeChange = (newRange) => {
        setPageNumber(1);
        setShowDateFilter(false);
        setStartDate(format(newRange.startDate, "dd MMM yy"));
        setEndDate(format(newRange.endDate, "dd MMM yy"));
        setSelectedRange(newRange);
        setApiLoader(true);
    };

    // API - get news
    const _getNews = () => {
        let start_date = format(selectedRange.startDate, "dd-MM-yyyy")
        let end_date = format(selectedRange.endDate, "dd-MM-yyyy")
        let url = 'news/list';
        let payload = JSON.stringify({
            from_date: startDate !== "Start date" ? start_date : null,
            to_date: endDate !== "End date" ? end_date : null,
            page_num: pageNumber,
            page_size: 20,
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (newsData.length !== 0) {
                    console.log("news", [...newsData, response.data])
                    setNewsData([...newsData, ...response.data])
                }
                else {
                    setNewsData(response.data);
                }
            } else {
                setNewsData([]);
            }
            setInitialLoader(false);
            setApiLoader(false);
            setPaginationLoader(false);
        })
    }

    const _handleNavigateNews = () => {
        setPaginationLoader(true);
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
    };

    return (
        initialLoader === true ?
            <CustomLoader />
            :
            <Fragment>
                <div className="e-news e-page-container margin-72px-bottom">
                    <InvestmentHeader />
                    <BreadCrumb data={NEWS_NAVIGATION} />
                    <div className="row">
                        <div className="d-flex  justify-content-between">
                            <p className="color-black e-poppins-medium e-font-20 line-height-32px mt-4 mb-3 margin-block-end-0">News</p>
                            <div className="position-relative e-margin-left-auto mt-md-2 mt-3 ps-ms-0 ps-5">
                                <p className={`border-radius-6px border-all bg-white p-2 mb-0 color-outer-space e-poppins-regular e-font-14 line-height-24px cursor-pointer ${startDate !== "Start date" && endDate !== "End date" ? 'border-sea-green' : 'border-bright-gray'}`}
                                    onClick={() => {
                                        _handleShowDateFilter(false);
                                    }}>
                                    {startDate} - {endDate}
                                </p>
                                {
                                    showDateFilter === true &&
                                    <DateRangeFilterPopup
                                        maxDate={new Date()}
                                        initialRange={selectedRange}
                                        cancel={() => {
                                            _handleShowDateFilter(true)
                                        }}
                                        onUpdate={_handleDateRangeChange} />
                                }
                            </div>
                        </div>
                    </div>
                    {
                        apiLoader === true ? (
                            <div className="row">
                                <CustomLoader />
                            </div>
                        ) : (
                            <>
                                {
                                    newsData.length > 0 ?
                                        <div className="row">
                                            {
                                                newsData.map((item, key) =>
                                                    <div className="col-12 mb-2"
                                                        key={key}>
                                                        <NewsCard data={item}
                                                            viewDetail={_openNewsDetailModal} />
                                                    </div>
                                                )
                                            }
                                            <div className="d-flex align-items-center justify-content-center mt-3"
                                                onClick={_handleNavigateNews}>

                                                <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer">
                                                    View more
                                                </p>
                                                {
                                                    paginationLoader === false ?
                                                        <Icon icon="right-arrow"
                                                            size={24}
                                                            className="e-view-pending cursor-pointer" />
                                                        :
                                                        <img src={require("../../Assets/Images/loader.gif")}
                                                            alt="data loader"
                                                            width={50} />
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="row mt-5">
                                            <div className="col-12 text-center">
                                                <EmptyScreen title="No news found"
                                                    description=" " />
                                            </div>
                                        </div>
                                }
                            </>
                        )
                    }


                </div>
                <Footer />
                {
                    openNewsDetail === true && (
                        <NewsModal data={selectedNews}
                            close={_unMountNewsDetailModal} />
                    )
                }
            </Fragment>
    )
}

export default NewsListing;