
/*
 *   File : notification.js
 *   Author : https://evoqins.com
 *   Description : Common component which is being used in all notifications page.
 *   Integrations : null
 *   Version : 1.0.0
 */

import Icon from "../Icon/icon"
import style from "../../Styles/Component/notification.module.scss"

const NotificationCard = (props) => {
    return (
        <div className={`border-radius-8px border-all p-sm-4 p-3 d-flex position-relative cursor-pointer .align-items-flex-start ${props.data.is_read === false ? "border-pearl-aqua bg-light-pastel-blue-gray cursor-pointer" : "border-bright-gray"}`}
            onClick={() => props.notificationClick()}>
            <img alt={props.data.title}
                src={
                    props.data.transactional_notification === true ?
                        require("../../Assets/Images/notifications/payment-icon.png")
                        :
                        require("../../Assets/Images/notifications/non-payment-icon.png")
                }
                width={24}
                height={24}
                draggable={false}
            />
            <div className="ms-1 w-100 mb-2">
                <div className="d-flex justify-content-between align-items-center margin-12px-bottom mt-sm-1 mt-0">
                    <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px-sm-24px mb-0">{props.data.title}</p>
                    <p className="color-gray e-poppins-medium e-font-10 mb-0 opacity-6">{props.data.created}</p>
                </div>
                <p className={`color-gray e-inter-medium e-font-14 mb-2 ${window.innerWidth > 575 ? 'lininfo-circlee-height-14px' : 'line-height-24px'}`}>
                    {props.data.message}
                </p>
                {
                    props.data.order_status && <div className="d-flex">
                        <p className="color-outer-space e-poppins-medium e-font-14 mb-0">Order status:&nbsp;</p>
                        <div className="d-flex align-items-center ">
                            <p className={`me-1 e-poppins-medium e-font-14 mb-0 ${props.data.order_status === 'Failed' ? 'color-lust' : props.data.order_status === 'Success' ? 'color-sea-green' : props.data.order_status === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.data.order_status}
                            </p>
                            {
                                props.data.order_status === 'Failed' ?
                                    <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                        width={24}
                                        height={24}
                                        draggable={false} />
                                    :
                                    props.data.order_status === 'Success' ?
                                        <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                            width={24}
                                            height={24}
                                            draggable={false} />
                                        :
                                        props.data.order_status === "Payment pending" ?
                                            <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                                width={24}
                                                height={24}
                                                draggable={false} />
                                            : <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                                width={24}
                                                height={24}
                                                draggable={false} />
                            }
                        </div>
                    </div>
                }
            </div>
            <div className={`position-absolute right-0 p-2 d-flex align-items-center ${style.e_notification} ${props.data.is_transactional === true ? "bg-pastel-turquoise" : "bg-pastel-sky-blue"}`}>
                <p className={"color-jett-black e-poppins-regular e-font-10 line-height-12px mb-0 me-1"}>{props.data.is_transactional === true ? "Transactional" : "Non-transactional"}</p>
                <img src={
                    props.data.is_transactional === true ?
                        require("../../Assets/Images/notifications/transactional-icon.png")
                        :
                        require("../../Assets/Images/notifications/non-transactional-icon.png")
                } alt={props.data.title}
                    width={12}
                    height={12}
                    draggable={false} />
            </div>
        </div>
    )
}

export default NotificationCard