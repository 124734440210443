/*
 *   File : support.js
 *   Author URI : www.evoqins.com
 *   Description : Support page to raise a ticket for user
 *   Integrations : null
 *   Version : v1.0
 *   Created : 21-11-2023
 */

import { Fragment, useEffect, useState } from "react";
import { InvestmentHeader } from "../../Component/Header";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { CustomTab } from "../../Component/Tab";
import { CustomFilePicker, CustomSearchSelectBox, CustomTextarea, FilePreview } from "../../Component/Form-elements";
import { _uploadDocument } from "../../Helper/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GradientButton } from "../../Component/CTA";
import { TicketCard } from "../../Component/Card";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import Icon from "../../Component/Icon/icon";

import APIService from "../../Service/api-service";
import { _uploadDocumentToS3 } from "../../Service/aws-service";
import { _isFileTypeAllowed } from "../../Helper";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Help and support', url: null },
]

const TAB_DATA = [{
    title: "Rise a ticket",
    id: 1,
    icon: require("../../Assets/Images/tab/ticket-star.svg")
}, {
    title: "My tickets",
    id: 2,
    icon: require("../../Assets/Images/tab/money-ticket.svg")
}];


const Support = () => {

    const TICKET_TYPES = useSelector(state => state.generalData.SUPPORT_QUERIES);
    const [tabIndex, setTabIndex] = useState(1);
    const [queryType, setQueryType] = useState("");
    const [queryTypeError, setQueryTypeError] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [file, setFile] = useState("");
    const [fileError, setFileError] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [submitLoader, setSubmitLoader] = useState(false);
    const [fileLoader, setFileLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);
    const [ticketList, setTicketList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [lastItemReached, setLastItemStatus] = useState(false);
    const [loader, setLoader] = useState(true);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (tabIndex === 2) {
            _listTickets();
        }
    }, [tabIndex, pageNumber]);

    // tab set value
    const _handleTabSelect = (id) => {
        setTabIndex(id);
    }

    // handler for page change
    const _handlePageNumber = () => {
        setPageNumber(prevCount => prevCount + 1);
    }

    // field change set value
    const _handleFields = (value, type, file) => {
        switch (type) {
            case 1:
                setQueryType(value);
                setQueryTypeError("");
                break;
            case 2:
                setDescription(value);
                setDescriptionError("");
                break;
            case 3:
                //eslint-disable-next-line
                var file_formatted = value.replace(/^.*[\\\/]/, '').split('/');
                //eslint-disable-next-line
                file_formatted = file_formatted[0].replace(/[\(\)\s]/g, '');
                const parts = file_formatted.split('.');
                if (parts.length > 1) {
                    const extension = '.' + parts.pop();
                    if (_isFileTypeAllowed(extension)) {
                        // checking if file size is greater than 5mb
                        const file_size = 5 * 1024 * 1024
                        if (file.size < file_size) {
                            _fileUpload(file_formatted, file);
                        } else {
                            setFileError("File size should be less than 5mb");
                        }
                    }
                    else {
                        setFileError("File type should be .pdf, .jpeg, .jpg, .png");
                    }
                }
                break;

            default:
                break;
        }
    }

    // validation
    const _validateForm = () => {
        let valid = true;
        if (queryType.length === 0) {
            setQueryTypeError('Select query type');
            valid = false;
        }
        if (description.length < 3) {
            setDescriptionError('Add description');
            valid = false;
        }
        // if (fileUrl.length === 0) {
        //     setFileError("File is required");
        //     valid = false;
        // }

        if (valid === true) {
            _createTicket()
        }
    }

    // clear data
    const _clearFields = () => {
        setDescription("");
        setQueryType("");
        setFile("");
        setFileUrl("");
    }

    //API :: file upload api
    const _fileUpload = (key, file) => {
        setFileError("");
        setFileLoader(true);
        let url = "general/file-upload";
        let body = {
            "key": `support/${key}`
        }

        APIService(true, url, body).then((response) => {
            if (response.status_code === 200) {
                setFileLoader(true);

                // s3 helper
                _uploadDocumentToS3(response.data, key, file).then((result) => {
                    setFileUrl(response.data.view_info); // file got uploaded successfully
                    setFileLoader(false);
                    setFile(key);
                }).catch(error => {
                    toast.dismiss();
                    toast.error(error, {
                        type: "error",
                    });
                    setFileLoader(false);
                });

            } else {
                setFileLoader(false);
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            }
        })
    }

    //API :: create ticket api
    const _createTicket = () => {
        setSubmitLoader(true);
        let url = "support/new-ticket";
        let body = {
            "query_type": queryType.value,
            "description": description,
            "image_url": fileUrl == "" ? null : fileUrl
        }

        APIService(true, url, body).then((response) => {
            if (response.status_code === 200) {
                setSubmitLoader(false);
                _clearFields();
                toast.dismiss();
                toast.success('Ticket created successfully', {
                    type: "success",
                });
            } else {
                setSubmitLoader(false);
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            }
        })
    }

    // API :: fetch tickets api
    const _listTickets = (page_status = false) => {
        let url = "support/list-tickets";
        let data = {
            page_num: pageNumber
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.data.length !== 0) {
                    setTicketList([...ticketList, ...response.data]);
                    if (response.data.length < 20) {
                        setLastItemStatus(true);
                    }else{
                        setLastItemStatus(false);
                    }
                    
                } else {
                    setLastItemStatus(true);
                }
            } else {
                setLastItemStatus(true);
                if (pageNumber === 1) {
                    setTicketList([]); /* ---- empty data if api fails only if page number is 1---------*/
                }
            }
            setApiLoader(false);
            setLoader(false);

        });
    }


    return (
        <Fragment>
            <div className="e-page-container margin-80px-bottom e-order">
                {/* Heading */}
                <InvestmentHeader />

                {/* bread crumb */}
                <BreadCrumb data={PAGE_NAVIGATION} />

                <h5 className="color-black e-poppins-medium e-font-20 line-height-32px mb-4 margin-32px-top">
                    Help and support
                </h5>

                {/* Tab select */}
                <CustomTab data={TAB_DATA}
                    type={2}
                    wrapperClass={'gap-16px'}
                    className="px-1 padding-10px-tb e-order-tab"
                    onSelectTab={_handleTabSelect} />

                {
                    tabIndex === 1 ?
                        // create ticket form
                        <Fragment>
                            {/* select query type */}
                            <div className="row padding-32px-top">
                                <div className="col-lg-6 col-md-6 col-sm-7">
                                    <CustomSearchSelectBox options={TICKET_TYPES}
                                        value={queryType}
                                        background='transparent'
                                        label="Select your query"
                                        error={queryTypeError}
                                        hide_error_text={true}
                                        placeholder=""
                                        radius="8px"
                                        labelColor="#ffffff"
                                        postfix="*"
                                        padding="12px 12px 12px 12px"
                                        onSelectChange={(select_value) => {
                                            _handleFields(select_value, 1)
                                        }} />
                                </div>
                            </div>

                            {/* query description */}
                            <div className="row pt-3">
                                <div className="col-lg-6 col-md-6 col-sm-7">
                                    <CustomTextarea value={description}
                                        label="Description"
                                        error={descriptionError}
                                        postfix="*"
                                        hide_error_text={true}
                                        handleChange={(input_value) => {
                                            _handleFields(input_value, 2)
                                        }} />
                                </div>
                            </div>

                            {/* attachments */}
                            <div className="row pt-2 padding-40px-bottom">
                                <div className="col-lg-4 col-md-6 col-sm-7 col-12" onClick={() => setFileError("")}>
                                    <CustomFilePicker
                                        accept=".png, .pdf, .jpeg "
                                        className="e-select-file"
                                        error={fileError}
                                        loading={fileLoader}
                                        onSelectFile={(path, file) => {
                                            _handleFields(path, 3, file);
                                        }} />

                                    {
                                        file.length === 0 ?
                                            null
                                            :
                                            <div className="col-12 pt-3">
                                                <FilePreview fileName={file}
                                                    removeFile={() => {
                                                        setFile("");
                                                    }} />
                                            </div>
                                    }
                                </div>
                            </div>

                            {/* create ticket */}
                            <div className="row">
                                <div className="col-3">
                                    <GradientButton label="Submit"
                                        loading={submitLoader}
                                        className="py-2 padding-72px-lr e-font-16 line-height-24px"
                                        handleClick={_validateForm} />
                                </div>
                            </div>
                        </Fragment>
                        :

                        // tickets list
                        <Fragment>
                            {
                                apiLoader ?
                                    <div className='h-70vh w-100vw'>
                                        <CustomLoader />
                                    </div>
                                    :
                                    ticketList.length > 0 ?
                                        <div className="row padding-32px-top">
                                            {
                                                ticketList.map((item, index) => {
                                                    return (
                                                        <div key={index}
                                                            className="col-lg-8 mb-3">
                                                            <TicketCard data={item} />
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="col-lg-8 mb-3">
                                                {
                                                    lastItemReached === false &&
                                                    <div className="d-flex justify-content-center align-items-center w-100 mt-3"
                                                        onClick={() => _handlePageNumber()}>
                                                        <p className="color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer">
                                                            View more
                                                        </p>
                                                        {
                                                            loader === false ?
                                                                <Icon icon="right-arrow"
                                                                    size={24}
                                                                    className="e-view-pending cursor-pointer" />
                                                                :
                                                                <img src={require("../../Assets/Images/loader.gif")}
                                                                    alt="data loader"
                                                                    width={50} />
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12 text-center">
                                            <EmptyScreen image={require('../../Assets/Images/nodata/general.svg').default}
                                                title="You haven't created a support ticket yet."
                                                description="Your support tickets will list here." />
                                        </div>
                            }
                        </Fragment>
                }
            </div >
        </Fragment>
    )
}

export default Support;