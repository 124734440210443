
/*
 *   File : dashboard.js
 *   Author URI : https://evoqins.com
 *   Description : Dashboard for family
 *   Integrations : null
 *   Version : v1.1
 */

// import packages
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// import components
import { InvestmentHeader } from "../../Component/Header";
import Footer from "../../Component/Footer/footer";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { CustomTab } from "../../Component/Tab";
import { FamilyRequestCard } from "../../Component/Card";
import { InviteMemberModal, FamilyJoiningRequest, DeleteConfirmation } from "../../Component/Modal";
import { Icon } from "../../Component/Icon";

// import service
import APIService from "../../Service/api-service";


const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage family', url: null }
];

const TAB_DATA = ['Family members', 'Manage request'];



export default function FamilyDashboard(props) {
    const [apiLoader, setApiLoader] = useState(true);
    const [tabIndex, setTabIndex] = useState(1);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState(false);
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [summaryData, setSummaryData] = useState({});
    const [requestedList, setRequestedList] = useState([]);
    const [tabLoader, setTabLoader] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        if (openConfirmModal === true) {
            const modal = new Modal(document.getElementById("delete-confirmation"), {});
            modal.show();
        }
    }, [openConfirmModal]);

    useEffect(() => {
        _getFamilySummary();
    }, []);

    useEffect(() => {
        if (tabIndex === 1) {
            _getFamilyMembers();
        } else {
            _getRequest();
        }
    }, [tabIndex]);

    useEffect(() => {
        if (openInviteModal === true) {
            _openInviteModal();
        }
    }, [openInviteModal]);

    useEffect(() => {
        if (openRequestModal === true) {
            _openRequestModal();
        }
    }, [openRequestModal]);

    // open invite modal
    function _openInviteModal() {
        const modal = new Modal(document.getElementById("invite-modal"), {});
        modal.show();
    }

    function _openRequestModal() {
        const modal = new Modal(document.getElementById("family-request-status"), {});
        modal.show();
    }

    function _handleRequestCallBack(status) {
        setRequestStatus(status);
        setOpenRequestModal(true);
    }

    // handle tab change
    function _handleTabSelect(value) {
        setTabIndex(value);
    }

    // handle button click
    function _handleInviteButton() {
        setOpenInviteModal(true);
    }

    // handle modal close callback
    function _closeInviteModal() {
        setOpenInviteModal(false);
    }

    function _handleRequestModal() {
        setOpenRequestModal(false);
        _getRequest();
    }

    function _openConfirmModal(id) {
        setSelectedMember(id);
        setOpenConfirmModal(true);
    }

    function _closeConfirmModal() {
        setSelectedMember(null);
        setOpenConfirmModal(false);
    }
    // handle invite 
    function _handleInvite() {
        setOpenInviteModal(false);
        _getRequest();
        setTabIndex(2);
    }

    // API - get family summary
    function _getFamilySummary() {
        let url = 'family/summary';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
            }
        })
    }

    // API - get family members
    function _getFamilyMembers() {
        setTabLoader(true);
        let url = 'family/get';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setFamilyMembers(response.data);
            }else {
                setFamilyMembers([]);
            }
            setApiLoader(false);
            setTabLoader(false);
            setOpenConfirmModal(false);
        })
    }

    // API - get Family requests
    function _getRequest() {
        setTabLoader(true);
        let url = 'family/list-request';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setRequestedList(response.data);
            } else {
                setRequestedList([]);
            }
            setTabLoader(false);
        })
    }

    // API - remove family member
    async function _removeMember() {
        setRemoving(true);
        let status = false;
        let url = 'family/remove';
        let payload = JSON.stringify({
            family_detail_id: selectedMember
        });
        APIService(true,url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('Removed member from family');
                _getFamilyMembers();
                status = true;
            }else{
                toast.dismiss();
                toast.error(response.message);
            }
            setRemoving(false);
        })
        return status;
    }

    const FamilyInfo = (props) => {
        return (
            <div className="bg-light-powder-blue border-radius-16px padding-24px-all">
                <h4 className="e-poppins-medium e-font-14 line-height-20px color-outer-space">{props.heading}</h4>
                <p className={`${props.descriptionColor} e-poppins-bold e-font-24 mb-0`}>{props.rupee && <span className="e-inter-bold">₹</span>} {props.description}</p>
            </div>
        )
    }

    return (
        <>
            <div className="e-page-container margin-118px-bottom pb-5">

                {/* Header */}
                <InvestmentHeader />
                {
                    apiLoader === true ? (
                        <div className='h-80vh '>
                            <CustomLoader />
                        </div>
                    ) : (
                        <>
                            {/* Page navigation */}
                            <BreadCrumb data={PAGE_NAVIGATION} />
                            <div className="row">
                                <div className="col-12">
                                    <p className="color-black e-poppins-semi-bold e-font-16 line-height-24px mt-4 mb-3 me-3 margin-block-end-0">
                                        Family investment summary
                                    </p>
                                </div>

                                {
                                    Object.keys(summaryData).length !== 0 &&
                                    <>
                                        <div className="col-md-4 col-sm-6">
                                            <FamilyInfo heading="Total invested amount"
                                                descriptionColor="color-black"
                                                rupee={true}
                                                description={summaryData.invested_amount?.toLocaleString('en-IN')} />
                                        </div>
                                        <div className="col-md-4 col-sm-6 mt-sm-0 mt-4">
                                            <FamilyInfo heading="Current value"
                                                descriptionColor="color-black"
                                                rupee={true}
                                                description={summaryData.current_amount?.toLocaleString('en-IN')} />
                                        </div>
                                        <div className="col-md-4 col-sm-6 mt-md-0 mt-4">
                                            <FamilyInfo heading="P&L"
                                                descriptionColor={summaryData.returns_amount < 0 ? "color-red" : "color-sea-green"}
                                                rupee={false}
                                                description={`${summaryData.returns_amount?.toLocaleString('en-IN')}(${summaryData.returns_percentage}%)`} />
                                        </div>
                                        {/* <div className="col-md-3 col-sm-6 mt-md-0 mt-4">
                                            <FamilyInfo heading="XIRR"
                                                descriptionColor="color-sea-green"
                                                rupee={false}
                                                description='50.190%' />
                                        </div> */}
                                    </>
                                }
                            </div>

                            {/* Tabs */}
                            <div className="row mt-4">
                                <div className="col-sm-8 ">
                                    <CustomTab data={TAB_DATA}
                                        wrapperClass={'gap-16px'}
                                        selectedTab={tabIndex}
                                        className="px-0 padding-10px-tb e-order-tab mb-4"
                                        onSelectTab={_handleTabSelect} />
                                </div>
                                <div className="col-sm-4 text-sm-end">
                                    <button className="border-radius-8px padding-8px-tb border-all border-primary-color outline-none  e-poppins-medium e-font-14 color-primary-color bg-white align-items-center"
                                        onClick={_handleInviteButton}>
                                        <img src={require('../../Assets/Images/family/invite-family.png')}
                                            className="w-24px h-24px me-2" />
                                        Invite family member
                                    </button>
                                </div>
                            </div>

                            {/* Tab data */}
                            {
                                tabLoader === true ? (
                                    <div className='h-80vh '>
                                        <CustomLoader />
                                    </div>
                                ) :
                                    <>
                                        {
                                            tabIndex === 1 ?
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="color-jett-black e-poppins-semi-bold e-font-16 line-height-24px mt-4 mb-3 me-3 margin-block-end-0">
                                                            Family investment summary
                                                        </p>
                                                    </div>
                                                    {
                                                        familyMembers.length > 0 ?
                                                            <>
                                                                {
                                                                    familyMembers.map((item, key) => {
                                                                        return (
                                                                            <div className="col-12 mt-3" key={key}>
                                                                                <FamilyRequestCard data={item} type={1}
                                                                                    openDeleteModal={_openConfirmModal} />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            :
                                                            <EmptyScreen title="No family members added" />
                                                    }

                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="color-jett-black e-poppins-semi-bold e-font-16 line-height-24px mt-4 mb-3 me-3 margin-block-end-0">
                                                            New request
                                                        </p>
                                                    </div>
                                                    {
                                                        requestedList.length > 0 ?
                                                            requestedList.map((item, key) => {
                                                                return (
                                                                    <div className="col-12 mt-3" key={key}>
                                                                        <FamilyRequestCard data={item}
                                                                            type={2}
                                                                            handleJoiningRequestCallBack={_handleRequestCallBack} />
                                                                    </div>
                                                                )
                                                            })
                                                            :
                                                            <EmptyScreen title="No request found" />
                                                    }

                                                </div>
                                        }
                                    </>
                            }
                        </>
                    )
                }
            </div>
            <Footer />
            {
                openInviteModal === true &&
                <InviteMemberModal sendInvite={_handleInvite}
                    close={_closeInviteModal}
                />
            }
            {
                openRequestModal === true &&
                <FamilyJoiningRequest status={requestStatus}
                    close={_handleRequestModal} />
            }
            {
                openConfirmModal === true &&
                <DeleteConfirmation confirmationText="Are you sure you want to remove this member from family?"
                    cancelButtonName="No, cancel"
                    submitButtonName="Yes, delete"
                    loading={removing}
                    updateList={_getFamilyMembers}
                    handleConfirmation={_removeMember}
                    close={_closeConfirmModal} />
            }
        </>
    )
}