

/*
 *   File : ipv.js
 *   Author URI : www.evoqins.com
 *   Description : Page to complete In-Person video verification using DIGIO from admin initiated KYC
 *   Integrations : null
 *   Version : v1.0
 *   Created : 03-10-2024
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';

import { GradientButton } from "../../Component/CTA";
import { CustomLoader } from "../../Component/Other";
import Footer from "../../Component/Footer/footer";
import Icon from '../../Component/Icon/icon';
import { IPVSuccess } from "../../Component/Modal";

import OrderAPIService from "../../Service/order-api";

export default function InitiateIPV(props) {

    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);
    const navigator = useNavigate();
    const [selfieImage, setSelfieImage] = useState("");
    const [digioAccessToken, setDigioAccessToken] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (showSuccess === true) {
            const { Modal } = require("bootstrap");
            const modal = new Modal(document.getElementById("ipv-status"), {});
            modal.show();
        }
    }, [showSuccess]);


    // redirect user based on login status
    function _redirectUser() {
        let login_status = Cookies.get('refreshToken');
        if (login_status) {
            window.location.href = '/';
        } else {
            window.location.href = process.env.REACT_APP_LANDING_PAGE_URL;
        }
    }

    const _closeModal = () => {
        setShowSuccess(false);
        _redirectUser();
    }

    const _handleProceed = () => {
        // props.updateProgress(9);
        setApiLoader(true);
        let url = 'kyc/ipv/initiate';
        OrderAPIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _initiateDigiLocker(response.data.id, response.data.customer_identifier, response.data.access_token.id);
                Cookies.set('digio_token', response.data.access_token.id);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    };

    async function _initiateDigiLocker(digiLockerId, mail, token_id) {
        var Digio = await window.Digio;
        var options = {
            // "environment" : "production", 
            "environment": process.env.REACT_APP_DIGILOCKER_ENVIRONMENT,
            "callback": _digiLockerCallback,
            "logo": process.env.REACT_APP_LOGO
        };

        var digio = new Digio(options);

        try {
            digio.init();
            digio.submit(digiLockerId, mail, token_id);
        } catch (err) {
            // console.log(err)
            this.notify("Allow pop-ups and reload the page to fetch from digilocker. ")
        }

    }

    function _digiLockerCallback(e) {
        setApiLoader(true);
        let url = 'kyc/ipv/callback';
        OrderAPIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _generatePdf();
                setShowSuccess(true);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
           setApiLoader(false);
        })
    }

    function _generatePdf() {
        let url = 'kyc/generate-pdf';
        return OrderAPIService(false, url).then((response) => {
            return response.status;
        });
    }

    const _nextStep = async () => {
        navigator('/');
    }


    return (
        <div className="container padding-64px-lr-md align-items-center d-flex justify-content-center">
            {
                selfieImage.length !== 0
                    ?
                    <div className='pb-sm-0 pb-4 mt-5'>
                        <p className='color-black e-popggpins-bold e-font-24-sm-18 mb-2 mt-5'>Capture your selfie</p>
                        <p className='color-outer-space e-poppins-regular line-height-24px margin-32px-bottom e-font-sm-16-14  '>Your selfie has been successfully captured.</p>
                        <img src={selfieImage} alt="Selfie"
                            width={226}
                            draggable={false}
                            height={208}
                            className='object-fit-contain mb-4' />
                        <div className='d-flex align-items-center gap-8px margin-40px-bottom cursor-pointer w-max-content'
                            onClick={_handleProceed}>
                            <Icon icon="re-capture"
                                size={24} />
                            <p className='color-primary-color e-poppins-regular e-font-sm-16-14 line-height-24px mb-0'>Re-capture</p>
                        </div>
                        <GradientButton label="Save & proceed"
                            loading={apiLoader}
                            className="padding-12px-tb px-4"
                            handleClick={_nextStep} />

                    </div>
                    :
                    <div className="row pb-sm-0 pb-4 mt-5 pt-5">
                        <div className="col-sm-7 pe-5 order-sm-1 order-2 mt-sm-0 mt-3 mb-sm-0 mb-3">
                            <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-2 position-relative w-max-content">
                                Capture your selfie
                                {
                                    Object.keys(TOOL_TIP).length !== 0 && (
                                        <>
                                            <Icon icon="info"
                                                size={24}
                                                className="ms-2"
                                                data-tooltip-id="ipv-tooltip" />
                                            <Tooltip id="ipv-tooltip"
                                                arrowColor='transparent'
                                                effect="float"
                                                place="bottom"
                                                className='e-tooltip-general'>
                                                {parse(TOOL_TIP.kyc_ipv_step)}
                                            </Tooltip>
                                        </>
                                    )
                                }

                            </p>
                            <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0 pe-5">
                                For clear selfie, follow the below instructions.
                            </p>

                            {/* <p className="color-black e-poppins-bold e-font-16 line-height-24px mb-0 px-4 py-3 margin-40px-bottom w-fit-content e-dashed-otp mt-3">
                    2406
                </p> */}

                            <div className="border-radius-16px bg-beige p-3 margin-40px-top pe-4">
                                <p className="color-black e-poppins-medium e-font-14-sm-12 line-height-14px mb-2">*Note</p>
                                <ol className="ps-3">
                                    <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                        Make sure the place where you are taking a selfie is well lit and not dark.
                                    </li>
                                    <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                        Do not wear eyeglasses.
                                    </li>
                                    <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                        Ensure your face is within the frame.
                                    </li>
                                    <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                        Position your face in the frame and move your head in a circle.
                                    </li>
                                </ol>

                            </div>
                            <GradientButton label="Proceed"
                                className="margin-40px-top padding-12px-tb px-4 e-font-16"
                                loading={apiLoader}
                                handleClick={_handleProceed} />
                        </div>
                        <div className="col-sm-5 order-sm-2 order-1">
                            {/* <p className="color-black e-poppins-bold e-font-16 mb-3">Example</p> */}
                            <img src={require("../../Assets/Images/kyc/ipv.png")}
                                alt="IPV"
                                draggable={false}
                                height={312}
                                className="object-fit-contain" />
                        </div>

                    </div>
            }
            {
                showSuccess === true &&
                <IPVSuccess close={_closeModal}/>
            }
        </div>
    )
}