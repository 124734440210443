
/*
 *   File : date-range-filter.js
 *   Author : https://evoqins.com
 *   Description : Date range picker component
 *   Integrations : null
 *   Version : 1.0.0
*/

import React, { Fragment, useState } from "react";
import { DateRangePicker } from "react-date-range";
import {
    endOfDay,
    startOfDay,
    isSameDay,
    subDays,
    subMonths,
} from "date-fns";

import Icon from "../Icon/icon";
import { GradientButton, OutlineButton } from "../CTA";

function DateRangeFilterPopup({ initialRange, onUpdate, cancel, minDate, maxDate, leftAlign }) {
    console.log('initialRange',initialRange)
    const [selectionRange, setSelectionRange] = useState(initialRange);

    const _handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
        // if (onChange) {
        //     onChange(ranges.selection);
        // }
    };

    const _handleCancel = () => {
        cancel();
    }

    const _handleUpdate = () => {
        onUpdate(selectionRange);
    }

    // Modify the labels of the defaultStaticRanges
    const customStaticRanges = [
        {
            label: "Last 1 Week",
            range: () => ({
                startDate: startOfDay(subDays(new Date(), 6)), // 6 days ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },
        {
            label: "Last 1 Month",
            range: () => ({
                startDate: startOfDay(subMonths(new Date(), 1)), // 1 month ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        }, {
            label: "Last 3 Month",
            range: () => ({
                startDate: startOfDay(subMonths(new Date(), 3)), // 3 month ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },
        {
            label: "Last 6 Month",
            range: () => ({
                startDate: startOfDay(subMonths(new Date(), 6)), // 6 month ago
                endDate: endOfDay(new Date()), // Today
            }),
            isSelected(range) {
                const definedRange = this.range();
                return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                );
            },
        },
    ]

    const customNavigatorRenderer = (focusedDate, changeShownDate, props) => {
        const { showMonthArrow } = props;

        return (
            <div className="w-100 position-absolute e-custom-date-navigator">
                {showMonthArrow ? (
                    <button
                        type="button"
                        className="e-left-icon"
                        onClick={() => changeShownDate(-1, 'monthOffset')}
                    >
                        <i>
                            <Icon icon="calender-arrow-left"
                                size={16} />
                        </i>
                    </button>
                ) : null}
                {/* Add your custom month and year picker here */}
                {showMonthArrow ? (
                    <button
                        type="button"
                        className="e-right-icon"
                        onClick={() => changeShownDate(1, 'monthOffset')}
                    >
                        <i>
                            <Icon icon="calender-arrow-right"
                                size={16} />
                        </i>
                    </button>
                ) : null}
            </div>
        );
    };


    return (
        <Fragment>
            <div className={`position-absolute bg-white border-radius-8px e-filter-date-picker z-index-10 w-fit-content ${leftAlign === true && 'left-0'}`}>
                <DateRangePicker showSelectionPreview={false}
                    showDateDisplay={false}
                    moveRangeOnFirstSelection={false}
                    showMonthAndYearPickers={false}
                    months={2}
                    weekdayDisplayFormat="EEEEEE"
                    monthDisplayFormat="MMMM yyyy"
                    inputRanges={[]}
                    ranges={[selectionRange]}
                    direction="horizontal"
                    staticRanges={customStaticRanges}
                    minDate={minDate}
                    maxDate={maxDate}
                    navigatorRenderer={customNavigatorRenderer}
                    onChange={_handleSelect} />

                <div className="d-flex gap-16px justify-content-end p-3 border-top-1px border-bright-gray">
                    <OutlineButton label="Clear"
                        className=" e-poppins-regular e-font-14 py-2 px-3"
                        handleClick={_handleCancel} />
                    <GradientButton label="Update"
                        className="px-3 py-2 border-radius-8px"
                        handleClick={_handleUpdate} />
                </div>
            </div>
            <div className="position-fixed w-100 z-index-9 h-100 left-0 top-0 cursor-default"
                onClick={cancel}></div>
        </Fragment>

    );
}

DateRangeFilterPopup.defaultProps = {
    leftAlign: false
}

export default DateRangeFilterPopup;
