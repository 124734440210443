/*
 *   File : opening-fees-slider.js
 *   Author URI : www.evoqins.com
 *   Description : Slider
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import packages */
import { Fragment } from "react";
import Slider from "react-slick";

/* import components */

const AccountFeesSlider = (props) => {

    function _redirect(url) {
        if (url !== null) {
            // console.log('pdata',props.data.redirection_url);
            window.open(url, '_blank');
        }
    }

    const AccountCard = (props) => {
        return (
            <Fragment>
                <img src={props.data.image_url}
                    alt="Account opening fees"
                    draggable={false}
                    className={`${props.data.is_clickable === true && 'cursor-pointer'} w-100`}
                    onClick={()=>_redirect(props.data.redirection_url)} />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <Slider infinite={true}
                autoplay={true}
                autoplaySpeed={props.timer*1000}
                slidesToScroll={1}
                slidesToShow={1}
                dots={true}>
                {
                    props.data.map((item, index) => {
                        return (
                            <div key={index}
                                data-aos="fade-in"
                                data-aos-easing="ease-in-sine"
                                data-aos-duration="300"
                                data-aos-delay={`${300}*${index}`}>
                                <AccountCard data={item} />
                            </div>
                        )
                    })
                }
            </Slider>
        </Fragment>
    )
}

export default AccountFeesSlider;