/*
 *   File : doughnut.js
 *   Author URI : https://evoqins.com
 *   Description : Doughnut chart to show portfolio break down
 *   Integrations : chart.js
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';

// Legend tooltip component
const LegendTooltip = ({ content, index, data }) => {
    if (!content) {
        return null;
    }
    let top = 0;
    if (index === 0) {
        top = 40;
    } else {
        top = 80;
    }

    return (
        <div
            style={{
                background: Colors.white,
                borderRadius: "6px",
                boxShadow: "4px 4px 12px 0px rgba(175, 175, 175, 0.58)",
                pointerEvents: "none",
                position: "absolute",
                transform: "translate(-50%, -90%)",
                transition: "all .1s ease",
                padding: "5px 10px",
                top: top+"px",
                left: "50px",
            }}
        >
            {data[index] + "%"}
        </div>
    );
};

const DoughnutChart = (props) => {
    const [legendTooltip, setLegendTooltip] = useState({ content: "", index: null });

    useEffect(() => {
        const chart_id = props.type === 1 ? 'portfolio-breakdown' : 'sector-allocation';

        // custom legend  
        const htmlLegendPlugin = {
            id: 'htmlLegend',
            afterUpdate(chart, args, options) {
                // function call for create custom legend 
                const ul = _getOrCreateLegendList(chart, options.containerID);
                // Remove old legend items
                while (ul.firstChild) {
                    ul.firstChild.remove();
                }
                // Getting legend from chart
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                // create and style legend label and color indicator
                items.forEach(item => {
                    const li = document.createElement('li');
                    li.style.alignItems = 'center';
                    li.style.cursor = 'pointer';
                    li.style.display = 'flex';
                    li.style.flexDirection = 'row';
                    li.style.marginLeft = '10px';
                    li.style.marginRight = '30px';
                    li.style.position = 'relative';
                    li.style.marginTop = '10px'
                    // Color box
                    const boxSpan = document.createElement('span');
                    boxSpan.style.background = item.fillStyle;
                    boxSpan.style.borderColor = item.strokeStyle;
                    boxSpan.style.borderWidth = item.lineWidth + 'px';
                    boxSpan.style.borderRadius = '4px';
                    boxSpan.style.display = 'inline-block';
                    boxSpan.style.flexShrink = 0;
                    boxSpan.style.height = '23px';
                    boxSpan.style.marginRight = '10px';
                    boxSpan.style.width = '23px';

                    // Text
                    const textContainer = document.createElement('p');
                    textContainer.style.color = Colors.black;
                    textContainer.style.fontSize = '14px';
                    textContainer.style.fontFamily = 'Poppins-Medium';
                    textContainer.style.margin = 0;
                    textContainer.style.padding = 0;
                    textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                    const text =  document.createTextNode(`${item.text}(${props.data.data[item.index]}%)`);
                    textContainer.appendChild(text);
                    li.onmouseenter = () => {
                        _showLegendTooltip(item.text, item.index);
                    }
                    li.onmouseleave = () => { _removeLegendTooltip() }
                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    ul.appendChild(li);
                });
            }
        };

        // Adding data and setting position of the tool tip
        const externalTooltipHandler = (context) => {
            console.log('tooltip.body :: ',context)
            // Tooltip Element
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }
            let prefix = '';
            let sufix = "%";
            if (tooltip.body) {
                const title = document.createElement('div'); //tooltip title
                const body = document.createElement('div'); // tooltip body
                // taking data from chart tooltip object and create text node
                const toolTipTitle = document.createTextNode(tooltip.title[0]);
                const toolTipBody = document.createTextNode(`${prefix} ${tooltip.body[0].lines[0]} ${sufix}`);
                // append the title to title div and style
                title.appendChild(toolTipTitle);
                title.style.color = Colors.gray;
                title.style.fontSize = '12px';
                title.style.fontFamily = 'Poppins-Regular';
                // append the body content to the div and style
                body.appendChild(toolTipBody);
                body.style.color = Colors.black;
                body.style.fontSize = '14px';
                body.style.fontFamily = 'Inter-Medium';
                tooltipEl.innerHTML = ''; // Clear previous content
                // appending tooltip to the tooltip element
                tooltipEl.appendChild(title);
                tooltipEl.appendChild(body);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + tooltip.caretY + 'px';

            // Apply styles to the tooltip
            tooltipEl.style.background = Colors.white;
            tooltipEl.style.borderRadius = '6px';
            tooltipEl.style.boxShadow = '4px 4px 12px 0px rgba(175, 175, 175, 0.58)';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, -90%)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.padding = '5px 10px';
            tooltipEl.style.minWidth = '80px';
        };

        // Adding Text in center of the doughnut (Sector allocation)
        const centerText = {
            beforeDraw(chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                ctx.font = '16px Inter-SemiBold';
                ctx.fillStyle = Colors.outer_space;
                ctx.textBaseline = "middle";
                var text = chart.config.options.elements.center.text,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        };

        // Chart configuration
        var newChart = document.getElementById(chart_id).getContext('2d');
        if (props.type === 1) {
            // Draw chart 
            newChart = new Chart(newChart, {
                type: 'doughnut',
                data: {
                    labels: props.data.labels,
                    datasets: [
                        {
                            data: props.data.data,
                            backgroundColor: props.data.color,
                        }
                    ]
                },
                plugins: [htmlLegendPlugin], // custom plugin for legend
                options: {
                    plugins: {
                        htmlLegend: { // custom legend
                            // ID of the container to put the legend in
                            containerID: 'doughnut-legend-container',
                        },
                        legend: { // hide default legend
                            display: false,
                            position: 'bottom',
                        },
                        datalabels: {
                            align: function (context) {
                                var index = context.dataIndex;
                                var value = context.dataset.data[index];
                                return value < 1 ? 'start' : 'end'
                            },
                            anchor: 'end',
                            backgroundColor: null,
                            borderColor: null,
                            borderRadius: 4,
                            borderWidth: 1,
                            color: Colors.black,
                            font: {
                                family: 'Poppins-Regular',
                                size: 14
                            },
                            offset: 20,
                            padding: 0,
                            formatter: (value) => {
                                return value + '%';
                            },
                        },
                        tooltip: { //tooltip
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler
                        },
                    },
                    cutout: 70, //doughnut slice width
                    radius: 80, // radius of the chart
                    borderWidth: 0,
                    responsive: true,
                    maintainAspectRatio: false
                },
            });
        } else {
            // Draw chart 
            newChart = new Chart(newChart, {
                type: 'doughnut',
                data: {
                    // labels: props.data.label,
                    labels: props.type === 3 ? props.data.labels : props.data.name,
                    datasets: [
                        {
                            data: props.type === 3 ? props.data.data : props.data.percentage,
                            backgroundColor: props.data.color,
                        }
                    ]
                },
                plugins: props.type === 2 ? [centerText] : null,
                options: {
                    elements: {
                        center: {
                            text: `${props.aum}`
                        },
                    },
                    plugins: {
                        htmlLegend: { // custom legend
                            // ID of the container to put the legend in
                            containerID: 'doughnut-legend-container',
                        },
                        legend: { // hide default legend
                            display: false,
                            position: 'bottom'
                        },
                        tooltip: { //tooltip
                            enabled: false,
                            position: 'nearest',
                            external: externalTooltipHandler
                        },
                    },
                    cutout: 84, //doughnut slice width
                    radius: 140, // radius of the chart
                    borderWidth: 0,
                    responsive: true,
                    maintainAspectRatio: false,

                },
            });
        }
        return () => {
            newChart.destroy();
        }
    }, []);

    // Adding Text in center of the doughnut (Sector allocation)
    const centerText = {
        beforeDraw(chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            var fontSize = (height / 114).toFixed(2);
            ctx.font = '16px Inter-SemiBold';
            ctx.fillStyle = Colors.outer_space;
            ctx.textBaseline = "middle";
            var text = chart.config.options.elements.center.text,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    };


    // custom legend  
    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            // function call for create custom legend 
            const ul = _getOrCreateLegendList(chart, options.containerID);
            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }
            // Getting legend from chart
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            // create and style legend label and color indicator
            items.forEach(item => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';
                li.style.marginRight = '30px';
                li.style.position = 'relative';
                // Color box
                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.fillStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.borderRadius = '4px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.flexShrink = 0;
                boxSpan.style.height = '23px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '23px';

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.color = Colors.black;
                textContainer.style.fontSize = '14px';
                textContainer.style.fontFamily = 'Poppins-Medium';
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);
                li.onmouseenter = () => {
                    _showLegendTooltip(item.text, item.index);
                }
                li.onmouseleave = () => { _removeLegendTooltip() }
                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        }
    };

    // Append custom legend to div
    function _getOrCreateLegendList(chart, id) {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'block';
            listContainer.style.flexDirection = 'row';
            listContainer.style.margin = '40px 0 0 0';
            listContainer.style.padding = 0;

            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    // hover function for legend hovering passing label and array index;
    const _showLegendTooltip = (text, index) => {
        setLegendTooltip({ content: text, index });
    };

    // mouse leave function for legend resetting the state;
    const _removeLegendTooltip = () => {
        setLegendTooltip({ content: "", index: null });
    }

    // Creating custom tool tip
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = 'tooltip'; // Add a class for styling
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    // Adding data and setting position of the tool tip
    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        if (tooltip.body) {
            const title = document.createElement('div'); //tooltip title
            const body = document.createElement('div'); // tooltip body
            // taking data from chart tooltip object and create text node
            const toolTipTitle = document.createTextNode(tooltip.title[0]);
            const toolTipBody = document.createTextNode(`₹ ${tooltip.body[0].lines[0]}`);
            // append the title to title div and style
            title.appendChild(toolTipTitle);
            title.style.color = Colors.gray;
            title.style.fontSize = '12px';
            title.style.fontFamily = 'Poppins-Regular';
            // append the body content to the div and style
            body.appendChild(toolTipBody);
            body.style.color = Colors.black;
            body.style.fontSize = '14px';
            body.style.fontFamily = 'Inter-Medium';
            tooltipEl.innerHTML = ''; // Clear previous content
            // appending tooltip to the tooltip element
            tooltipEl.appendChild(title);
            tooltipEl.appendChild(body);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';

        // Apply styles to the tooltip
        tooltipEl.style.background = Colors.white;
        tooltipEl.style.borderRadius = '6px';
        tooltipEl.style.boxShadow = '4px 4px 12px 0px rgba(175, 175, 175, 0.58)';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, -90%)';
        tooltipEl.style.transition = 'all .1s ease';
        tooltipEl.style.padding = '5px 10px';
    };

    return (
        <>
            {
                props.type === 1 ?
                    <div className="e-doughnut-chart-container position-relative">
                        <canvas id={"portfolio-breakdown"} 
                            className="e-doughnut-canvas-dashboard"
                             height="180"></canvas>

                        <div className="position-absolute e-doughnut-legend" 
                            id="doughnut-legend-container">
                            <LegendTooltip content={legendTooltip.content} 
                                index={legendTooltip.index} 
                                data={props.data.data} />
                        </div>
                    </div>
                :
                    <div className={`e-doughnut-chart-container ${props.className}`}>
                        <canvas id={'sector-allocation'} 
                            className="e-doughnut-canvas" 
                                height="308"></canvas>
                        <div className="position-absolute e-doughnut-legend" 
                            id="doughnut-legend-container"></div>
                    </div>
            }
        </>

    )
}

export default DoughnutChart;
