/*
 *   File : external-fund-status.js
 *   Author URI : https://evoqins.com
 *   Description : UI component which allows a customer to reload the external funds from MF Central. This popup will appear only for customers who already imported external funds.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";

import Icon from "../Icon/icon";
import { GradientButton, OutlineButton } from "../CTA";

import style from "../../Styles/Component/external-fund-status.module.scss"

const ExternalFundStatusModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("external-funds-status-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const _handleUpdate = () => {
        props.updateNow();
    }

    return (
        <div className={`modal fade ${style.e_external_fund_status_modal}`}
            id="external-funds-status-modal"
            tabIndex="-1"
            aria-labelledby="external-funds-status-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content text-center d-flex align-items-center border-radius-32px padding-28px-lr padding-32px-top padding-28px-bottom ${style.e_content} `}>
                    <img src={require("../../Assets/Images/portfolio/import-external-funds.png")}
                        width={72}
                        height={72}
                        draggable={false} />
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Import external fund</p>
                    {
                        props.isUpdateAvailable === true ?
                            <p className="color-jett-black e-poppins-regular e-font-16 mb-2">
                                {`Your external portfolio was last updated on ${props.lastCASImport}. Click the “update now” button below to keep your portfolio current.`}
                            </p>
                            :
                            <p className="color-jett-black e-poppins-regular e-font-16 mb-4">
                                {`Your external portfolio was last updated on ${props.lastCASImport}. You can refresh external portfolios after 30 days from last update.`}
                            </p>
                    }
                    {
                        props.isUpdateAvailable === true &&
                        <div className="d-flex align-items-center gap-8px border-radius-8px bg-antique-white py-2 padding-6px-lr mb-3">
                            <Icon icon="info"
                                size={24} />
                            <p className="color-jett-black e-poppins-regular e-font-14 mb-0">
                                External portfolio can be uploaded again after 30 days from the date of the first upload.
                            </p>
                        </div>
                    }

                    <div className="d-flex gap-8px ">
                        <OutlineButton label={ props.isUpdateAvailable === false ? "Close" : "Cancel"}
                            cancel="modal"
                            className="e-font-16 px-4 padding-12px-tb" />
                        {
                            props.isUpdateAvailable === true &&
                            <GradientButton label="Update now"
                                cancel="modal"
                                className="e-font-16 padding-12px-tb padding-64px-lr"
                                handleClick={_handleUpdate} />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExternalFundStatusModal