
import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, useNavigate } from 'react-router-dom';
import { Modal } from "bootstrap";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import OneSignal from 'react-onesignal';

import { ForgotPINModal, Unlock } from './Component/Modal';
import { Toast } from './Component/Message';
import { NavigationItem } from './Container/Navigation';
import { Dashboard, NewsListing, NFOListing } from './Container/Dashboard';
import { Explore, FundDetail, PublicDetail } from './Container/Fund';
import { InvestInGoals, Retirement, House, Education, Car, Vacation, CustomGoal } from './Container/Goals';
import { BankAccounts, Cart, FamilyAccounts, RelatedAccount, RiskAssessment, Settings, ManageMandate, ManageNominee } from './Container/Profile';

import { Support } from './Container/Support';
import { WatchList } from './Component/Watchlist';
import { Orders, FundOrderSummary, GoalOrderSummary } from './Container/Order';
import { Listing, SIPDetail, GoalSIPDetail } from './Component/SIP';
import { CustomLoader, FAQ, Notifications } from './Component/Other';
import { PageNotFound, EmptyScreen } from './Component/Other';
import { KYC, Profile } from './Container/KYC';
import { FundPortfolioDetail, GoalPortfolioDetail, Portfolio } from './Container/Portfolio';
import { Report } from './Container/Reports';
import { Payment, Mandates, InitiateIPV, HandShake } from './Container/General';
import { TransactionListing, TransactionSummary } from './Container/MF-Transactions';
import { UpdateNFT } from './Container/UpdateNFT';
import { CompareFunds } from './Container/Compare';
import { FamilyDashboard, FamilyMemberDetail  } from './Container/Family';
import { _getHeaderSearch, _getProfile, _mfGetCart, _getUerName, _getInvestmentStyles, _getQueryTypes, _getForgotPINOTP, _getKycProfile } from './Helper/api';
import { logOut } from './Helper';

import Store from './Store'
import APIService from './Service/api-service';

function App() {

    const [tokenStatus, setTokenStatus] = useState(false);
    const [openPinModal, setOpenPinModal] = useState(false);
    const [kycCompleted, setKycCompleted] = useState(true);
    const [openForgotPin, setOpenForgotPin] = useState(false);
    const [userName, setUserName] = useState('');
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [primaryCredential, setPrimaryCredential] = useState(null);
    const [browserZoom, setBrowserZoom] = useState(100);
    const [publicUrl, setPublicUrl] = useState(false);
    const [userRelation, setUserRelation] = useState(null);

    // Listener internet connection
    useEffect(() => {
        const handleOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', handleOnlineStatus);
        window.addEventListener('offline', handleOnlineStatus);

        return () => {
            window.removeEventListener('online', handleOnlineStatus);
            window.removeEventListener('offline', handleOnlineStatus);
        };
    }, []);

    useEffect(() => {
        Cookies.set('refreshToken', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0eXBlIjo0LCJpZCI6MTE3NDcsImNyZWF0ZWQiOiIyMDI0LTExLTE5IDA0OjMwOjMzLjA4NzMwNSIsImV4cCI6MTczNDU4MjYzM30.WpYMzgSFOOg_1ayPxwV7gSxq7PLXpmj226WB3ZWx2U4', { domain: '192.168.0.180', path: '/' });
    }, []);

    useEffect(() => {
        _initializeOneSignal();
        const refresh_token = Cookies.get('refreshToken');
        const path = window.location.pathname;
        const url_params = new URLSearchParams(window.location.search);
        const ref = url_params.get('ref');
        const landing_page_url = process.env.REACT_APP_LANDING_PAGE_URL;

        // Redirecting to landing page if admin initiated account creation
        if (ref !== null) {
            Cookies.remove('refreshToken', { path: '/', domain: process.env.REACT_APP_DOMAIN });
            Cookies.remove('risk_score_shown');
            Cookies.remove('is_pin_configured', { path: '/', domain: process.env.REACT_APP_DOMAIN });
            sessionStorage.clear();
            localStorage.clear();
            window.location.replace(`${landing_page_url}?_ref=${ref}`);
            window.history.replaceState({}, document.title, window.location.href);
            return;
        }
        // checking for admin initiated payment | mandate
        if (["/payment", "/mandate", "/ipv", "/oauth-redirect"].includes(path)) {
            setTokenStatus(true);
            setPublicUrl(true);
            return;
        }
        if (refresh_token === null || refresh_token === undefined) {
            const paths = ["/bank-account", "/manage-nominee", "/profile", "/kyc"];
            // checking for admin initiated edit and passing the router to landing
            // after successful login redirected to desired page from landing page
            if (path.startsWith("/explore/") || paths.includes(path)) {
                window.location.href = `${landing_page_url}?_next=${path}`;
                return;
            }
            logOut();
            window.location.href = landing_page_url;

        }
        // API :: for get user name
        const _fetchUserName = async () => {
            try {
                const response_data = await _getUerName();
                setUserName(response_data.name);
            } catch (error) {
                toast.dismiss();
                toast.error(error, {
                    type: "error"
                });
            }
        };
        // check if user has finished verifying pin or not
        const _checkSessionStorage = () => {
            const refresh_token = Cookies.get('refreshToken');
            console.log('REFRESH_TOKEN', refresh_token)
            if (sessionStorage.getItem('access_token') === null && refresh_token !== undefined) {
                console.log('REFRESH_TOKEN', refresh_token);
                setOpenPinModal(true);
            }
        };

        // zoom fixes
        const _handleResize = () => {
            document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + (1 / window.devicePixelRatio));
        };

        _fetchUserName();
        _checkSessionStorage();

    }, []);

    // close modal on browser back
    useEffect(() => {
        const _handleBrowserBack = () => {
            // This function will be called on navigation changes
            const modal_element = document.querySelector('.modal-backdrop');
            if (modal_element) {
                modal_element.remove()
                document.body.removeAttribute("style");
                document.body.removeAttribute("class");
            }
        };

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', _handleBrowserBack);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', _handleBrowserBack);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("resize", getSizes);

        return () => {
            window.removeEventListener("resize", getSizes);
        };
    }, []);

    // if app is not unlocked show unlock modal
    useEffect(() => {
        if (openPinModal === true) {
            _openPinModal();
        }
    }, [openPinModal]);

    useEffect(() => {
        if (openForgotPin === true) {
            const modal = new Modal(document.getElementById("forgot-pin"), {});
            modal.show();
        }
    }, [openForgotPin])

    // // get profile data and store in redux
    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            _getSearchData();
            _getKycProfile();
            _fetchProfile();
            _getCartData();
            _getRiskCategories();
            _getSupportQueries();
            _getToolTipData();
            _getGeneralData();
            setTokenStatus(true);
        }
    }, [sessionStorage]);


    const getSizes = () => {
        const new_zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
        const scale = window.devicePixelRatio;
        if (window.innerWidth < 575) {
            return;
        }
        if (scale >= 1.5) {
            setBrowserZoom(Math.ceil(new_zoom));
        }
    };

    const _initializeOneSignal = async () => {
        // if (process.env.REACT_APP_ENV == 'PROD') {
        await OneSignal.init({
            appId: process.env.REACT_APP_ONESIGNAL_ID,
            allowLocalhostAsSecureOrigin: true
        });
        // }
    }

    //to open unlock modal
    const _openPinModal = () => {
        const modal = new Modal(document.getElementById("unlock-modal"), {});
        modal.show();
    };

    // APIs to be called after successful verification
    const _handleClosePinModal = () => {
        setTokenStatus(true);
        setOpenPinModal(false);
        _getSearchData();
        _getKycProfile();
        _fetchProfile();
        _getCartData();
        _getRiskCategories();
        _getSupportQueries();
        _getGeneralData();
        _getToolTipData();
    }

    const _handleOpenUnlockModal = () => {
        setOpenForgotPin(false);
        setOpenPinModal(true);
    }


    // API - Get profile details 
    const _fetchProfile = async () => {
        try {
            const profile_data = await _getProfile();
            Store.dispatch({
                type: 'UPDATE_PROFILE_DATA',
                payload: profile_data
            });
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    };

    // API - Get profile details 
    const _getSearchData = async () => {
        try {
            const header_data = await _getHeaderSearch();
            Store.dispatch({
                type: 'UPDATE_SEARCH_HEADER_DATA',
                payload: header_data
            });
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    };

    // API - Get cart data 
    const _getCartData = () => {
        _mfGetCart()
            .then((response) => {
                Store.dispatch({
                    type: 'ADD_TO_CART',
                    payload: response
                });
            })
            .catch((response) => {

            });
    };

    // API - Investment styles
    const _getRiskCategories = () => {
        _getInvestmentStyles()
            .then((response) => {
                Store.dispatch({
                    type: 'MF_RISK_CATEGORIES',
                    payload: response
                });
            })
            .catch((response) => {

            });
    };

    // API - Get support query types
    const _getSupportQueries = () => {
        let query_options = [];
        _getQueryTypes()
            .then((response) => {
                response.map((item) => (
                    query_options.push({
                        label: item.name,
                        value: item.id
                    })
                ))
                Store.dispatch({
                    type: 'QUERY_TYPES',
                    payload: query_options
                });
            })
            .catch((response) => {
                Store.dispatch({
                    type: 'QUERY_TYPES',
                    payload: query_options
                });
            });
    };

    // API - forgot pin
    function _handleForgotPin() {
        _getForgotPINOTP().then((response) => {
            setPrimaryCredential(response.username);
            setUserRelation(response.relation);
            setOpenForgotPin(true);
            setOpenPinModal(false);
            toast.dismiss();
            toast.success('OTP has been sent to your registered email and phone number', {
                type: "success"
            });
        }).catch((response) => {
            toast.dismiss();
            toast.error(response, {
                type: "error"
            });
        });
    }

    // API - getTooltip data
    async function _getToolTipData() {
        let url = 'general/get-tooltip';
        let response = await APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'TOOLTIP_DATA',
                    payload: response.data
                });
            }
        });
    }
    // API - Get general data (to get relation and guardian options)
    function _getGeneralData() {
        let url = 'general/get-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'APP_GENERAL_DATA',
                    payload: response.data
                });
            }
        })
    }

    //commented to fix bundle warning (defined but never used)
    const WatchListRouter = () => {
        return (
            <Outlet />
        );
    }

    const InvestRouter = () => {
        return (
            <Outlet />
        )
    }
    // eslint-disable-next-line 
    const OrderRouter = () => {
        return (
            <Outlet />
        )
    }

    const ProfileInnerRouting = () => {
        return (
            <Outlet />
        )
    }

    const SIPRouter = () => {
        return (
            <Outlet />
        )
    }

    const ExploreRouter = () => {
        return (
            <Outlet />
        )
    }

    const PortfolioRouter = () => {
        return (
            <Outlet />
        )
    }

    const STPSWPRouter = () => {
        return (
            <Outlet />
        )
    }

    const FamilyRouter = () => {
        return (
            <Outlet />
        )
    }

    const ProfileRouter = () => {
        const navigate = useNavigate();

        useEffect(() => {
            if (kycCompleted !== true) {
                navigate('/kyc');
            }
        }, [kycCompleted, navigate]);

        return <Outlet />;
    };

    return (
        <div className="">
            <Router>
                {
                    tokenStatus === true ?
                        <Fragment>
                            <div className={`container-fluid ${browserZoom === 110 ? 'e-app-zoom-level-9' : browserZoom === 125 ? 'e-app-zoom-level-8' : browserZoom >= 150 ? 'e-app-zoom-level-7' : null}`}>
                                <div className="row">
                                    {
                                        publicUrl === false && (
                                            <div className="col-xl-2 col-lg-2 e-navigation-hide px-0 ">
                                                <NavigationItem />
                                            </div>
                                        )
                                    }

                                    <div className={publicUrl === false ? "col-xl-10 col-lg-10 px-0 e-zoom-out-wrapper" : "col-12 px-0 e-zoom-out-wrapper"}>
                                        {
                                            isOnline === true ? (
                                                <Routes>
                                                    <Route path="/"
                                                        element={<Dashboard />} >
                                                    </Route>
                                                    <Route path="/portfolio" element={<PortfolioRouter />} >
                                                        <Route index element={<Portfolio />} />
                                                        <Route path="mutual-fund-detail"
                                                            element={<FundPortfolioDetail />} >
                                                        </Route>
                                                        <Route path="goal-fund-detail"
                                                            element={<GoalPortfolioDetail />} >
                                                        </Route>
                                                    </Route>
                                                    <Route path="/goal" element={<InvestRouter />}>
                                                        <Route index element={<InvestInGoals />} />
                                                        <Route path="retirement" element={<Retirement />} />
                                                        <Route path="house" element={<House />} />
                                                        <Route path="education" element={<Education />} />
                                                        <Route path="car" element={<Car />} />
                                                        <Route path="vacation" element={<Vacation />} />
                                                        <Route path="custom-goal" element={<CustomGoal />} />
                                                    </Route>

                                                    <Route path="/explore" element={<ExploreRouter />} >
                                                        <Route index element={<Explore />} />
                                                        <Route path="fund" element={<FundDetail />} />
                                                        <Route path=":id" element={<PublicDetail/>} />
                                                    </Route>

                                                    <Route path="/risk-assessment"
                                                        element={<RiskAssessment />} >
                                                    </Route>

                                                    <Route path="/orders" element={<OrderRouter />}>
                                                        <Route index element={<Orders />} />
                                                        <Route path="fund-order-summary" element={<FundOrderSummary />} />
                                                        <Route path="goal-order-summary" element={<GoalOrderSummary />} />
                                                    </Route>
                                                    <Route path="/sip" element={<SIPRouter />}>
                                                        <Route index element={<Listing />} />
                                                        <Route path='sip-summary' element={<SIPDetail />}></Route>
                                                        <Route path='sip-goal-summary' element={<GoalSIPDetail />}></Route>

                                                    </Route>
                                                    <Route path="/" element={<ProfileRouter />}>
                                                        <Route path="kyc" element={<KYC />} />
                                                        <Route path="/profile" element={<ProfileInnerRouting />}>
                                                            <Route index element={<Profile />} />
                                                            <Route path="risk-assessment" element={<RiskAssessment />} />
                                                        </Route>

                                                    </Route>

                                                    <Route path="/watchlist" element={<WatchListRouter />}>
                                                        <Route index element={<WatchList />} />
                                                    </Route>

                                                    <Route path="/cart"
                                                        element={<Cart />} >
                                                    </Route>
                                                    <Route path="/settings"
                                                        element={<Settings />} >
                                                    </Route>
                                                    <Route path="/notifications"
                                                        element={<Notifications />} >
                                                    </Route>
                                                    <Route path="/faq"
                                                        element={<FAQ />} >
                                                    </Route>
                                                    <Route path="/support"
                                                        element={<Support />} >
                                                    </Route>
                                                    <Route path="/family-investment-detail"
                                                        element={<FamilyAccounts />} >
                                                    </Route>
                                                    <Route path="/related-account"
                                                        element={<RelatedAccount />} >
                                                    </Route>
                                                    <Route path="/bank-account"
                                                        element={<BankAccounts />} >
                                                    </Route>
                                                    <Route path='/manage-mandate'
                                                        element={<ManageMandate />} />
                                                    <Route path="/reports"
                                                        element={<Report />} >
                                                    </Route>
                                                    <Route path="/manage-nominee"
                                                        element={<ManageNominee />} >
                                                    </Route>
                                                    <Route path="/news"
                                                        element={<NewsListing />} >
                                                    </Route>
                                                    <Route path="/nfo-list"
                                                        element={<NFOListing />}>
                                                    </Route>
                                                    <Route path="/payment"
                                                        element={<Payment />} >
                                                    </Route>
                                                    <Route path="/mandate"
                                                        element={<Mandates />} >
                                                    </Route>
                                                    <Route path="/ipv"
                                                        element={<InitiateIPV />} >
                                                    </Route>

                                                    <Route path="/oauth-redirect"
                                                        element={<HandShake />}>
                                                    </Route>

                                                    <Route path="/manage-stp-swp" element={<STPSWPRouter />}>
                                                        <Route index element={<TransactionListing />} />
                                                        <Route path='summary' element={<TransactionSummary />}></Route>
                                                    </Route>
                                                    <Route path="/compare-funds"
                                                        element={<CompareFunds />}>
                                                    </Route>
                                                    <Route path="/update-nft"
                                                        element={<UpdateNFT />}>
                                                    </Route>
                                                    <Route path="/manage-family" element={<FamilyRouter />}>
                                                        <Route index element={<FamilyDashboard/>} />
                                                        <Route path="detail" element={<FamilyMemberDetail/>} />
                                                    </Route>
                                                    <Route path="*" element={<PageNotFound />} />
                                                </Routes>
                                            ) : (
                                                <div className='h-100vh d-flex align-items-middle justify-content-center'>
                                                    <EmptyScreen image={require('./Assets/Images/nodata/no-network.svg').default}
                                                        title="Lost your internet connection"
                                                        description="please connect to proper internet connection and try again." />
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>

                        </Fragment>
                        :
                        <div className='h-100vh w-100vw'>
                            <CustomLoader />
                        </div>
                }
            </Router>
            {
                openPinModal === true ?
                    <Unlock userName={userName}
                        forgotPin={_handleForgotPin}
                        close={_handleClosePinModal} />
                    :
                    null
            }

            {
                openForgotPin === true ?
                    <ForgotPINModal userName={primaryCredential}
                        relation={userRelation}
                        close={_handleOpenUnlockModal} />
                    :
                    null
            }

            <Toast />
        </div >
    );
}

export default App;