/*
 *   File : custom-textbox.js
 *   Author : https://evoqins.com
 *   Description : Input component
 *   Integrations : null
 *   Version : 1.0.0
*/
import React, { useState, useCallback, useRef, useEffect } from 'react';

import style from '../../Styles/Component/custom-textbox.module.scss';
import Icon from '../Icon/icon';

const CustomTextInput = React.memo((props) => {
    const inputRef = useRef(null);
    const [isInputFocused, setInputFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    // const [value, setValue] = useState("");


    const _handleChange = useCallback((event) => {
        const input_value = event.target.value;
        if (props.maxLength) {
            if (input_value.length <= props.maxLength) {
                if (props.pan) {
                    props.handleChange(input_value.toUpperCase());
                    return;
                }
                props.handleChange(input_value);
            }
            return;
        }
        // Check if it's a name and allow only space and dot characters
        if (props.personName) {
            const validNameRegex = /^[A-Za-z. ]*$/;
            if (validNameRegex.test(input_value)) {
                props.handleChange(input_value);
            }
        } else {
            props.handleChange(input_value);
        }
    }, [props.handleChange, props.maxLength, props.pan, props.personName]);

    // Preventing non numeric characters if type is number 
    // Validating because Firefox and Safari don't support type number 
    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code)) {
            e.preventDefault();
        }
    };

    const _onKeyDown = (e) => {
        if (props.type == 'number') {
            _validateNumber(e);
            // Disable arrow up and down keys
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        }
    }


    const _handleFocus = () => {
        setInputFocus(true);
    };

    const _handleBlur = () => {
        setInputFocus(false);
    };

    function _handleWheel(event) {
        document.activeElement.blur();
    }

    // focus input
    function _focusInputAtEnd() {
        if (inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.setSelectionRange(
                    inputRef.current.value.length,
                    inputRef.current.value.length
                );
            }, 0);
        }
    }

    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}
            data-disabled={`${props.disabled}`}>
            {/* Input box */}
            <input 
            // ref={inputRef}
                ref={props.inputRef}
                value={props.value}
                name={props.name}
                id={props.id ? props.id : props.label}
                autoComplete={props.autoComplete}
                placeholder={props.placeholder}
                type={props.type ? props.type : 'text'}
                className={`${style.e_input} ${props.inputClass} color-charleston-green e-poppins-regular e-font-16 w-100 no-outline`}
                onKeyDown={_onKeyDown}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                onWheel={_handleWheel}
                required
            />
            {/* label */}
            <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                htmlFor={props.id ? props.id : props.label}>
                {props.label}
                <span className='color-red'>
                    {props.postfix}
                </span>
            </label>
            {/* Error text */}
            {
                props.description !== "" && !props.error &&
                <div className='position-absolute d-flex align-items-center mt-1 '>
                    <p className='color-steel-gray e-font-12 '>{props.description}</p>
                </div>
            }
            {props.error &&
                <div className='position-absolute d-flex align-items-center mt-1 '>
                    <Icon icon="info-circle"
                        size={16}
                        className="me-1" />
                    <p className='color-red margin-block-end-0 e-font-12 '>
                        {props.error}
                    </p>
                </div>}
            {
                props.password_icon ?
                    <span className={style.e_password_icon}>
                        <Icon icon={showPassword ? "preview" : 'eye-slash'}
                            size={24}
                            className='cursor-pointer'
                            onClick={() => {
                                props.showPassword();
                                setShowPassword(!showPassword)
                                _focusInputAtEnd()
                            }} />
                    </span>
                    :
                    null
            }
        </div>
    )
})

CustomTextInput.defaultProps = {
    value: null,
    handleChange: () => { },
    className: '',
    error: null,
    inputRef: null,
    autoComplete: "off",
    labelClass: 'bg-white',
    id: null,
    description: ""
};

export default CustomTextInput;