/*
 *   File : custom-textbox.js
 *   Author : https://evoqins.com
 *   Description : Input component
 *   Integrations : null
 *   Version : 1.0.0
*/
import React, { useState } from 'react';

import Icon from '../Icon/icon';

import style from '../../Styles/Component/custom-textbox.module.scss';


const CustomPhoneInput = React.memo((props) => {
    const [isInputFocused, setInputFocus] = useState(false);

    const _handleChange = (event) => {
        event.preventDefault();
        const input_value = event.target.value;
        if (input_value.length <= 10) {
            props.handleChange(input_value);
        }
    }

    const _handleFocus = (e) => {
        e.stopPropagation();
        setInputFocus(true);
    };

    const _handleBlur = () => {
        setInputFocus(false);
    };

    // handle wheel event
    function _handleOnWheel() {
        document.activeElement.blur()
    }

    function _handleClick(e) {
        // alert('ewietyo');
        e.preventDefault();
        e.stopPropagation();
    }
 
    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code)) {
            e.preventDefault();
        }
    };

    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}>
            {/* Input box */}
            {/* Phone number prefix */}
            {
                (isInputFocused === true || props.value.length !== 0) &&
                <span className={`position-absolute color-charleston-green e-poppins-regular e-font-16 no-outline ${style.e_phone_number} ${props.isMobileInput === true && style.e_mobile_input}`}>
                    +91
                </span>
            }
            <input
                value={props.value}
                id={props.label}
                type={props.type ? props.type : 'text'}
                className={`${style.e_phone_input} ${props.inputClass} color-charleston-green e-poppins-regular e-font-16 w-100 no-outline padding-54px-left`}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                onWheel={_handleOnWheel}
                onKeyDown={_validateNumber}
                required
                onClick={_handleClick}
            />
            {/* label */}
            <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-red'>
                    {props.postfix}
                </span>
            </label>
            {/* Error text */}
            <p className='color-deep-red e-inter-regular margin-block-end-0 e-font-12 position-absolute mt-1'>
                {props.error &&
                    <Icon icon="info-circle"
                        className="vertical-sub"
                        size={16} />
                }
                {props.error}
            </p>
        </div>
    )
})

CustomPhoneInput.defaultProps = {
    value: '',
    handleChange: () => { },
    className: '',
    error: '',
    labelClass: 'bg-white'
};

export default CustomPhoneInput;