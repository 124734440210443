/*
 *   File : area.js
 *   Author URI : https://evoqins.com
 *   Description : Area chart to show portfolio returns
 *   Integrations : chart.js
 *   Version : v1.1
 */

import { useEffect } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';

const SegmentChart = (props) => {

    useEffect(() => {
        // Adding data and setting position of the tool tip
        const 
        externalTooltipHandler = (context) => {
            // Tooltip Element
            const { chart, tooltip } = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            if (tooltip.body) {
                const title = document.createElement('div'); //tooltip title
                const body = document.createElement('div'); // tooltip body
                // taking data from chart tooltip object and create text node
                console.log('tooltip.body[0]',tooltip.title);
                const tootTipTitle = document.createTextNode(tooltip.title);
                const toolTipBody =  document.createTextNode(`₹${tooltip.body[0].lines[0]}`);

                // append the body content to the div and style
                title.appendChild(tootTipTitle);
                title.style.color = Colors.gray;
                title.style.fontSize = '12px';
                title.style.fontFamily = 'Poppins-Regular';
                body.appendChild(toolTipBody);
                body.style.color = Colors.black;
                body.style.fontSize = '14px';
                body.style.fontFamily = 'Inter-Medium';
                tooltipEl.innerHTML = ''; // Clear previous content
                // appending tooltip to the tooltip element
                tooltipEl.appendChild(title);
                tooltipEl.appendChild(body);
            }

            const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = positionX + tooltip.caretX + 'px';
            tooltipEl.style.top = positionY + (tooltip.caretY - 17) + 'px';

            // Apply styles to the tooltip
            tooltipEl.style.background = Colors.white;
            tooltipEl.style.borderRadius = '6px';
            tooltipEl.style.boxShadow = '4px 4px 12px 0px rgba(175, 175, 175, 0.58)';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, -90%)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.padding = '6px 10px 5px 10px';
        };

        // Chart configuration
        const chart_id = props.type === 1 ? "segmentChart" : "segementChartTwo"
        var newChart = document.getElementById(chart_id).getContext('2d');
        newChart = new Chart(newChart, {
            type: 'line',
            data: { //chart area configuration
                labels: props.data && props.data.label.length > 0 ? props.data.label : [],
                datasets: [
                    // Chart data configuration
                    {
                        data: props.data && props.data.data.length > 0 ? props.data.data : [],

                        borderColor: [
                            Colors.deep_blue
                        ],
                        borderWidth: 2,
                        tension: 0.5,
                        fill: 0,
                        pointRadius: 0,
                        pointHoverRadius: 8,
                        pointBorderWidth: 0,
                        pointHoverBorderWidth: 2,
                        pointHoverBorderColor: Colors.deep_blue,
                        pointBorderColor: Colors.white,
                        pointBackgroundColor: '#e9ede7f0',
                        // Adding dashed border for NaN values
                        segment: {
                            borderColor: ctx => skipped(ctx, `${Colors.deep_blue}`) || skipped(ctx, `${Colors.deep_blue}`),
                            borderDash: ctx => skipped(ctx, [2.5, 3]),
                        },
                        spanGaps: true,
                    },
                    // Configuration for active data part
                    {
                        label: '',
                        data: props.data.activeSegments,
                        fill: 0,
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        pointBorderWidth: 0,
                        backgroundColor: function (context) {
                            const chart = context.chart;
                            const { ctx, chartArea } = chart;
                            const gradient_color = { start: 'rgba(42, 100, 217, 0.6)', end: 'rgba(42, 100, 217, 0)' };
                            return _getGradient(ctx, chartArea, gradient_color);
                        },
                    },

                ]
            },
            options: { //chart  options
                hover: {
                    mode: 'index',
                    intersect: false,
                },
                scales: { // grid lines
                    x: {
                        offset: false,
                        beginAtZero: true,
                        border: {
                            display: false,
                        },
                        ticks: {
                            color: Colors.outer_space,
                            display: true,
                            autoSkip: true,
                            maxTicksLimit: 3,
                            labelOffset: 10,
                            font: {
                                size: 12,
                                weight: 400,
                                family: 'Poppins-Regular'
                            },
                            callback: function (value, index, values) {
                                // const date = new Date(props.data.label[index]);
                                // const options = { month: 'long' };
                                // return date.toLocaleDateString(undefined, options);
                            },

                        },
                        grid: {
                            drawTicks: false,
                            drawBorder: false,
                            display: false
                        },
                    },
                    y: {
                        beginAtZero: false,
                        ticks: {
                            display: false,
                        },
                        border: {
                            display: false,
                            dash: [8, 4],
                        },
                        grid: {
                            color: function (context) {
                                if (context.index % 2 !== 0) {
                                    return Colors.pale_blue;
                                }

                            },
                            drawTicks: false,
                            drawBorder: false,
                        },
                    },
                },
                plugins: {
                    legend: { // remove legend
                        display: false,
                    },
                    tooltip: { //tooltip
                        mode: 'index',
                        intersect: false,   
                        enabled: false,
                        // position: 'average',
                        external: externalTooltipHandler,
                        callbacks: {
                            label: function(tooltipItem, data) {
                              return tooltipItem.parsed.y.toLocaleString('en-IN');
                            }
                        }
                    },
                }
            }

        });
        return () => {
            newChart.destroy()
        }
    }, [props.data]);

    // function to set draw dashed border
    const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;

    // Chart area gradient color
    const _getGradient = (ctx, chartArea, gradientColor) => {
        if (chartArea) {
            let gradient;
            gradient = ctx.createLinearGradient(0, 250, 10, 0);
            gradient.addColorStop(1, gradientColor.start);
            gradient.addColorStop(0, gradientColor.end);

            return gradient;
        }
    };

    // Creating custom tool tip
    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div.tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.className = 'tooltip'; // Add a class for styling
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    return (
        <div className="e-segment-chart position-relative">
            <canvas id={props.type === 1 ? "segmentChart" : "segementChartTwo"} width="100" height="60" ></canvas>
            <div className="d-flex justify-content-between">
                <p className="color-black e-poppins-regular e-font-12">{props.data.label[0]}</p>
                <p className="color-black e-poppins-regular e-font-12">{props.data.label[props.data.label.length - 1]}</p>
            </div>
        </div>
    )
}

export default SegmentChart;