/*
 *   File : payment-status.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for showing payment status. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GradientButton, OutlineButton } from "../CTA";
import Icon from "../Icon/icon";

import style from "../../Styles/Component/cancel-sip.module.scss";

const MfInProcessStatus = (props) => {

    const navigator = useNavigate();
    const [paymentInfo, setPaymentInfo] = useState({title: "", message: ""});
    const [paymentStatus, setPaymentStatus] = useState(1);

    useEffect(() => {
        if (props.message) {
            setPaymentInfo(props.message);
        }
    }, [props.message]);

    useEffect(() => {
        var my_modal = document.getElementById("mf-inprocess-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const _handleNavigate = (type) => {
        navigator("/orders", { state: { order_status: 1 } });
    }

    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="mf-inprocess-status"
            tabIndex="-1"
            aria-labelledby="payment-status"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-lr pb-3 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="text-center">
                        <img src={require('../../Assets/Images/orders/payment-process.svg').default}
                            alt="Pause SIP"
                            width={72}
                            height={72} />
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5 className="color-eerie-black e-poppins-medium e-font-18 mt-5">
                                Order is in process
                            </h5>
                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0  text-center">
                                {props.message}
                            </p>
                        </div>
                        <div className="mt-4 pt-3 d-sm-flex d-block justify-content-center gap-8px mb-3">

                            <GradientButton label="View orders"
                                cancel="modal"
                                className="e-font-16 padding-70px-lr padding-12px-tb w-mobile-100 mt-sm-0 mt-3" 
                                handleClick={_handleNavigate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MfInProcessStatus.defaultProps = {
    close: ()=>{},
}

export default MfInProcessStatus