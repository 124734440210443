/*
 *   File : mandates.js
 *   Author URI : https://evoqins.com
 *   Description : Modal screen to show available mandates created by selected bank account
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect } from "react";
import { GradientButton } from "../CTA";
import Icon from "../Icon/icon";

import style from "../../Styles/Component/mandates.module.scss"

const MandateListModal = ({ close, manageMandate, ...props }) => {

    useEffect(() => {
        var my_modal = document.getElementById("available-mandates");
        my_modal.addEventListener("hidden.bs.modal", () => {
            close();
        });
    }, [close]);

    const _handleManageMandate = () => {
        props.handleClick();
    }

    return (
        <div className={`modal fade ${style.e_mandates}`}
            id="available-mandates"
            tabIndex="-1"
            aria-labelledby="available-mandates"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content border-radius-24px pt-3 px-4 pb-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px mb-0">Mapped mandates</p>
                        <Icon icon="close"
                            className="cursor-pointer e-modal-close-btn"
                            data-bs-dismiss="modal"
                            size={24} />
                    </div>
                    <div className="row ">
                        <div className="col-4">
                            <p className="color-outer-space e-poppins-regular e-font-16 line-height-16px mb-0">Mandate id</p>
                        </div>
                        <div className="col-4">
                            <p className="color-outer-space e-poppins-regular e-font-16 line-height-16px mb-0">Mandate limit</p>
                        </div>
                        {/* <div className="col-4">
                            <p className="color-outer-space e-poppins-regular e-font-16 line-height-16px mb-0">Expire in</p>
                        </div> */}
                    </div>
                    <div className={`${style.e_body}`}>
                    {
                        manageMandate.map((item) =>
                            <Fragment>
                                <div className="row pb-3 mt-3">
                                    <div className="col-4">
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{item.mandate_id}</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{item.mandate_limit.toLocaleString('en-IN')}</p>
                                    </div>
                                    {/* <div className="col-4">
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">11 Sep 2033</p>
                                    </div> */}
                                </div>
                                <div className={`bg-bright-gray mx-2 ${style.e_horizontal_line}`}>
                                </div>
                            </Fragment>
                        )
                    }
                    </div>

                    {/* <div className="row pb-3 mt-3">
                        <div className="col-4">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">0908973</p>
                        </div>
                        <div className="col-4">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0"><span className="e-inter-medium">₹</span>1,00,000</p>
                        </div>
                        <div className="col-4">
                            <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">11 Sep 2033</p>
                        </div>
                    </div>
                    <div className={`bg-bright-gray mx-2 ${style.e_horizontal_line}`}>

                    </div> */}
                    <div className="text-center">
                        <GradientButton label="Manage mandates"
                            className="px-3 py-2 e-font-16 mt-4"
                            cancel="modal"
                            handleClick={_handleManageMandate} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MandateListModal