
/*
 *   File : search-input.js
 *   Author URI : www.evoqins.com
 *   Description : Search input component
 *   Integrations : NA
 *   Version : v1.1
 */

import { useState, useRef, useEffect, Fragment, memo } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

import Icon from "../Icon/icon";
import Store from "../../Store";

import Colors from '../../Styles/color.module.scss';
import APIService from "../../Service/api-service";

export default memo(SearchInput);
function SearchInput(props) {

    const { topSearches, placeHolder } = props;

    const navigate = useNavigate();
    const location = useLocation();

    const menuRef = useRef();
    const inputRef = useRef();
    const TOGGLE_FILTER = useSelector((state) => state.headerFilerToggle.SHOW_SEARCH_SUGGESTION);

    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [search, setSearch] = useState("");
    const [amc, setAmc] = useState([]);
    const [selectedAmc, setSelectedAmc] = useState([null]);
    const [rating, setRating] = useState([]);
    const [selectedRating, setSelectedRating] = useState(null);
    const [fundCategory, setFundCategory] = useState([]);
    const [selectedFundCategory, setSelectedFundCategory] = useState([null]);
    const [fundType, setFundType] = useState([]);
    const [selectedFundType, setSelectedFundType] = useState([null]);
    const [minimumInvestment, setMinimumInvestment] = useState([]);
    const [selectedMinimumInvestment, setSelectedMinimumInvestment] = useState(null);
    const [showSuggestions, setShowSuggestions] = useState(TOGGLE_FILTER);
    const [navigateToExplore, setNavigateToExplore] = useState(false);
    const [amcHover, setAmcHover] = useState(null);
    const [rankHover, setRankHover] = useState(null);
    const [fundCategoryHover, setFundCategoryHover] = useState(null);
    const [fundTypeHover, setFundTypeHover] = useState(null);
    const [minimumInvestmentHover, setMinimumInvestmentHover] = useState(null);
    const [searchSuggestion, setSearchSuggestion] = useState([]);

    useEffect(() => {
        if (props.amcData) {
            setAmc(props.amcData);
        }
        
    }, [props.amcData]);

    useEffect(() => {
        if (props.rating) {
            setRating(props.rating);
        }
    }, [props.rating]);

    useEffect(() => {
        if (props.category) {
            setFundCategory(props.category);
        }
    }, [props.category]);

    useEffect(() => {
        if (props.fundType) {
            setFundType(props.fundType);
        }
    }, [props.fundType]);

    useEffect(() => {
        if (props.minimumInvestment) {
            setMinimumInvestment(props.minimumInvestment);
        }
        
    }, [props.minimumInvestment]);

    useEffect(() => {
        // if (props.searchText.length === 0) {
        setSearch(props.searchText);
        // }
    }, [props.searchText])

 

    useEffect(() => {
        if (navigateToExplore === true) {
            _handleFilterNavigate();
        }
    }, [navigateToExplore])

    useEffect(() => {
        if (location.state !== null && location.state.search_text) {
            setSearch(location.state.search_text);
        }
    }, [location])

    useEffect(() => {
        if (props.updateStatus == true) {
            _closePopUp();
        }
    }, [props.updateStatus]);

    const _handleFilterNavigate = (search_text = search) => {
        navigate("/explore", {
            replace: (location.pathname === '/explore') ? true : false,
            state: {
                amc: selectedAmc,
                rating: selectedRating,
                fund_category: selectedFundCategory,
                fund_type: selectedFundType,
                theme: [null],
                minimum_investment: selectedMinimumInvestment,
                search_text,
                filter_by: null,
                category_name: "",
                filter_by_tag: "",
                current: location.pathname
            }
        });

        setSearch("");
        setSelectedAmc([]);
        setSelectedRating(null);
        setSelectedFundCategory([]);
        setSelectedFundType([]);
        setSelectedMinimumInvestment(null);
        setNavigateToExplore(false);
        _forceCloseSuggestions();

    }

    const _forceCloseSuggestions = () => {
        setShowSuggestions(false);
        Store.dispatch({
            type: 'UPDATE_SEARCH_SUGGESTION_STATUS',
            payload: false
        });
        document.body.style.overflow = 'auto';
        document.removeEventListener("mouseup", _closeSearchDropDown);
    }

    // handler for when pressed Enter key
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            inputRef.current.blur();
            _handleFilterNavigate();
        }
    };

    // handler for all chips
    const _handleChipSelected = (type, selected_item) => {
        switch (type) {
            case 1:
                _manageCheckBox(selectedAmc, selected_item, type);
                break;
            case 2:
                setSelectedRating(selected_item);
                setNavigateToExplore(true);
                // _manageCheckBox(selectedRating, selected_item, type);
                break;
            case 3:
                _manageCheckBox(selectedFundCategory, selected_item, type);
                break;
            case 4:
                _manageCheckBox(selectedFundType, selected_item, type);
                break;
            case 5:
                setSelectedMinimumInvestment(selected_item);
                setNavigateToExplore(true)
                break;
            default:
                break;
        }
    }

    // helper function for managing the filtered data
    const _manageCheckBox = (array_to_be_modified, selected_filter_type, type) => {
        let filter_type = [...array_to_be_modified];
        if (filter_type.includes(selected_filter_type) === true) {
            let selected_type_index = filter_type.findIndex(item => item === selected_filter_type);
            if (selected_type_index != -1) {
                filter_type.splice(selected_type_index, 1);
            }
        } else {
            filter_type.push(selected_filter_type);
        }
        filter_type = filter_type.filter(item => item !== null);
        switch (type) {
            case 1:
                setSelectedAmc([...filter_type]);
                setNavigateToExplore(true)
                break;
            case 2:
                setSelectedRating(filter_type)
                setNavigateToExplore(true)
                break;
            case 3:
                setSelectedFundCategory([...filter_type]);
                setNavigateToExplore(true)
                break;
            case 4:
                setSelectedFundType([...filter_type]);
                setNavigateToExplore(true)
                break;
            default:
                break;
        }
    }

    // handler for showing dropdown
    const _showSuggestion = () => {
        // document.addEventListener("mouseup", _closeSearchDropDown);
        // document.body.style.overflow = 'hidden';

        // forcefully updating the state to avoid delay in Redux 
        // if (
        //     // search.length === 0 &&
        //     selectedAmc.length === 0 &&
        //     selectedRating.length === 0 &&
        //     selectedFundCategory.length === 0 &&
        //     selectedFundType.length === 0 &&
        //     selectedMinimumInvestment === null
        // ) {
        setShowSuggestions(true);
        Store.dispatch({
            type: 'UPDATE_SEARCH_SUGGESTION_STATUS',
            payload: true
        });
        // }
    }

    // listener for click outside the view detail div
    const _closeSearchDropDown = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setSearch("");
            setSelectedAmc([]);
            setSelectedRating(null);
            setSelectedFundCategory([]);
            setSelectedFundType([]);
            setSelectedMinimumInvestment(null);
            _forceCloseSuggestions();
        }
    };

    const _closePopUp = (navigate = false) => {
        setSearch("");
        setSelectedAmc([]);
        setSelectedRating(null);
        setSelectedFundCategory([]);
        setSelectedFundType([]);
        setSelectedMinimumInvestment(null);
        setShowSuggestions(false);
        if (navigate === true ) {
            _handleFilterNavigate("");
        }
        Store.dispatch({
            type: 'UPDATE_SEARCH_SUGGESTION_STATUS',
            payload: false
        });
        document.body.style.overflow = 'auto';
        document.removeEventListener("mouseup", _closeSearchDropDown);
    }

    // handler for showing advanced search
    const _handleAdvancedSearch = () => {
        setShowAdvancedSearch(!showAdvancedSearch)
    }

    // handler to store search value
    const _handleSearch = (e) => {
        _getSearchSuggestion(e.target.value);
        if (showSuggestions === true) {
            if (e.target.value.length !== 0) {
                setSearch(e.target.value);
            }
            else {
                setSearch("");
            }
        }
    }

    const _handleNavigate = (selected_fund) => {
        navigate("/explore/fund", {
            replace: true,
            state: {
                fund_id: selected_fund.id
            }
        });
        setShowSuggestions(false);
        document.body.style.overflow = 'auto';



        setShowSuggestions(false);
        Store.dispatch({
            type: 'UPDATE_SEARCH_SUGGESTION_STATUS',
            payload: false
        });
        document.body.style.overflow = 'auto';
        document.removeEventListener("mouseup", _closeSearchDropDown);

    }

    const _handleSearchArrow = () => {
        setNavigateToExplore(true);
    }

    // API :: search suggestion
    async function _getSearchSuggestion(value) {
        if (value == "") {
            setSearchSuggestion([]);
            return null;
        }
        let url = 'mf-data/search';
        let payload = JSON.stringify({
            query: value
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSearchSuggestion(response.data);
            } else {
                setSearchSuggestion([]);
            }
        })
    }
    return (
        <div className='position-relative'
            ref={menuRef}>

            <input type="text"
                ref={inputRef}
                value={search}
                placeholder={placeHolder}
                className='e-investment-search z-index-10 position-relative padding-12px-all border-radius-8px border-all border-may-green bg-white w-100 color-black e-poppins-regular e-font-16 line-height-24px'
                onClick={_showSuggestion}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                aria-autocomplete="none"
                spellCheck="false"
                autoCorrect="off" 
                onChange={(e) => _handleSearch(e)} />

            {
                search.length !== 0 ?
                    <div className="bg-ocean-boat-blue z-index-12 position-absolute right-0 top-0 h-100 w-50px cursor-pointer e-investment-search-button"
                        onClick={_handleSearchArrow}>
                        <Icon icon="arrow-right1"
                            size={24}
                            color={Colors.white}
                            className='position-absolute e-investment-search-icon e-transition-right-search' />
                    </div>
                    :
                    <img src={require('../../Assets/Images/dashboard/search.png')}
                        alt="Search"
                        width={24}
                        height={24}
                        draggable={false}
                        className='position-absolute z-index-12 e-investment-search-icon' />
            }

            {/* search suggestion list */}
            {
                showSuggestions == true &&
                <Fragment>
                    <div className='position-absolute padding-24px-all e-drop-down bg-white border-radius-8px border-all border-anti-flash-white'>
                        <div className='position-relative h-100'>
                            <div className='position-absolute e-search-separator bg-light-silver d-md-block d-sm-none d-none'></div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 ">
                                    <p className='color-outer-space e-poppins-medium e-font-14 line-height-24px mb-3'>Top funds</p>
                                    {
                                        searchSuggestion.length !== 0 ? (
                                            <div className='d-flex flex-column gap-8px h-400px overflowY-auto e-top-searches-scroll'>
                                                {
                                                    searchSuggestion.map((item, key) =>
                                                        <div className='d-flex align-items-center py-2 padding-14px-lr gap-14px cursor-pointer e-top-searches' key={key}
                                                            onClick={() => _handleNavigate(item)}>
                                                            <img src={require('../../Assets/Images/investment-header/search-line.svg').default}
                                                                alt="Top searches"
                                                                draggable={false} 
                                                                className="d-sm-block d-none"/>
                                                            <p className='color-black e-poppins-regular e-font-14 mb-0'>{item.name}</p>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        ) : (
                                            <div className='d-flex flex-column gap-8px'>
                                                {
                                                   
                                                    topSearches && topSearches.map((item, key) =>
                                                        <div className='d-flex align-items-center py-2 padding-14px-lr gap-14px cursor-pointer e-top-searches' key={key}
                                                            onClick={() => _handleNavigate(item)}>
                                                            <img src={require('../../Assets/Images/investment-header/search-line.svg').default}
                                                                alt="Top searches"
                                                                draggable={false} 
                                                                className="d-sm-block d-none"/>
                                                            <p className='color-black e-poppins-regular e-font-14 mb-0'>{item.name}</p>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        )
                                    }



                                </div>
                                <div className="col-12 bg-light-silver d-md-none  d-block w-100 mt-2 mb-3" style={{ height: "1px" }}></div>
                                <div className="col-lg-6 col-md-6  ps-lg-3 ps-md-3 position-relative">
                                    <p className='color-outer-space padding-32px-left e-poppins-medium e-font-14 line-height-24px mb-3'>Quick search</p>

                                    <div className='padding-32px-left pb-md-0 pb-4'>
                                        <p className='color-black e-poppins-regular e-font-14 mb-2'>Search by AMCs</p>
                                        <div className='d-flex gap-8px flex-wrap'>
                                            {
                                                amc.length !== 0 && amc.slice(0, 4).map((item, key) =>
                                                    <div className={`border-radius-4px border-all bg-white text-center e-amc-img cursor-pointer 
                                                    ${selectedAmc.includes(item.id) ? "border-primary-color" : "border-bright-gray"}
                                                    ${amcHover === item.id && "e-chip-hover"}`}
                                                        key={key}
                                                        onMouseEnter={() => setAmcHover(item.id)}
                                                        onMouseLeave={() => setAmcHover(null)}
                                                        onClick={() => _handleChipSelected(1, item.id)}>
                                                        <img src={item.image} alt="AMC"
                                                            draggable={false}
                                                            className="border-radius-4px h-100 object-fit-contain" />
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <p className='color-black e-poppins-regular e-font-14 mt-3 mb-1'>Rank</p>
                                        <div className='d-flex gap-8px flex-wrap'>
                                            {
                                                rating.length !== 0 && rating.map((item, key) =>
                                                    <div key={key}
                                                        className={`border-all border-radius-16px px-2 py-1 d-flex align-items-center cursor-pointer 
                                                    ${selectedRating === item.id ? "border-primary-color" : "border-bright-gray"}
                                                    ${rankHover === item.id && "e-chip-hover"}`}
                                                        onMouseEnter={() => setRankHover(item.id)}
                                                        onMouseLeave={() => setRankHover(null)}
                                                        onClick={() => _handleChipSelected(2, item.id)}>
                                                        <p className='color-charleston-green e-inter-medium e-font-12 line-height-18px e-2px mb-0'>{item.name}</p>
                                                        <img src={require('../../Assets/Images/dashboard/Star.png')}
                                                            alt="Star"
                                                            width={16}
                                                            height={16}
                                                            draggable={false} />

                                                    </div>
                                                )
                                            }
                                        </div>

                                        <label className='d-flex mt-3' >
                                            <p className='color-sea-green e-poppins-medium e-font-14 line-height-24px mb-0 me-1 cursor-pointer'
                                                onClick={_handleAdvancedSearch}>Advanced search</p>
                                            <Icon icon="right-arrow"
                                                size={24}
                                                className={showAdvancedSearch === true ? "e-rotate-left-90 cursor-pointer" : "e-rotate-right-90 cursor-pointer"}
                                                onClick={_handleAdvancedSearch} />
                                        </label>


                                        <div className={`d-flex flex-column gap-16px margin-12px-top e-header-advanced-search-dropdown ${showAdvancedSearch === true ? "show" : ""}`}>
                                            <div>
                                                <p className='color-black e-poppins-regular e-font-14  mb-2'>Fund category</p>
                                                <div className='d-flex gap-8px flex-wrap'>
                                                    {
                                                        fundCategory.length !== 0 && fundCategory.map((item, key) =>
                                                            <p className={`color-outer-space e-poppins-regular e-font-14 mb-0 e-advanced-type border-radius-4px border-all cursor-pointer 
                                                            ${selectedFundCategory.includes(item.id) ? "border-primary-color" : "border-gray"}
                                                            ${fundCategoryHover === item.id && "e-chip-hover"}`}
                                                                onMouseEnter={() => setFundCategoryHover(item.id)}
                                                                onMouseLeave={() => setFundCategoryHover(null)}
                                                                key={key}
                                                                onClick={() => _handleChipSelected(3, item.id)}>
                                                                {item.name}
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>


                                            <div>
                                                <p className='color-black e-poppins-regular e-font-14  mb-2'>Fund type</p>
                                                <div className='d-flex gap-8px flex-wrap'>
                                                    {
                                                        fundType.length !== 0 && fundType.map((item, key) =>
                                                            <p className={`color-outer-space e-poppins-regular e-font-14 mb-0 e-advanced-type border-radius-4px border-all cursor-pointer 
                                                            ${selectedFundType.includes(item.id) ? "border-primary-color" : "border-gray"}
                                                            ${fundTypeHover === item.id && "e-chip-hover"}`}
                                                                key={key}
                                                                onMouseEnter={() => setFundTypeHover(item.id)}
                                                                onMouseLeave={() => setFundTypeHover(null)}
                                                                onClick={() => _handleChipSelected(4, item.id)}>
                                                                {item.name}
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                <p className='color-black e-poppins-regular e-font-14  mb-2'>Minimum investment</p>
                                                <div className='d-flex gap-8px flex-wrap'>
                                                    {
                                                        minimumInvestment.length !== 0 && minimumInvestment.map((item, key) =>
                                                            <p key={key}
                                                                className={`color-outer-space e-poppins-regular e-font-14 mb-0 e-advanced-type border-radius-4px border-all cursor-pointer 
                                                                                    ${item.name.includes('₹') && 'e-inter-regular'}
                                                                                    ${selectedMinimumInvestment === item.id ? "border-primary-color" : "border-gray"}
                                                                                    ${minimumInvestmentHover === item.id && "e-chip-hover"}`}
                                                                onMouseEnter={() => setMinimumInvestmentHover(item.id)}
                                                                onMouseLeave={() => setMinimumInvestmentHover(null)}
                                                                onClick={() => _handleChipSelected(5, item.id)}>
                                                                {item.name}
                                                            </p>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-fixed w-100 z-index-8 h-100 left-0 top-0 cursor-default"
                        onClick={()=>_closePopUp(true)}></div>
                </Fragment>
            }
        </div>
    )
}

// Define default props for the component
SearchInput.defaultProps = {
    searchText: "",
};