
/*
 *   File : tab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 25-01-2022
*/

/*import package*/
import { useEffect, useState } from 'react';

/*import styles*/
import styles from '../../Styles/Component/tab.module.scss';


/* ------------------

type: 2 style tab with icon,

type :1 style tab without icon default


---------------------*/

const CustomTab = (props) => {
    const [tabIndex, setTabIndex] = useState(1);

    useEffect(() => {
        if (props.selectedTab) {
            setTabIndex(props.selectedTab)
        }
    }, [props.selectedTab])

    //Onclick event for tab change
    const _handleChangeTab = (index) => {
        let tab = document.getElementById(`tab-${index}`);
        const container = document.querySelector('.e-tap-wrapper'); 
        if (tab && container) {
            // Scroll the container so the selected tab is aligned with the left side
            container.scrollLeft = tab.offsetLeft-10;
        }
           
        setTabIndex(index);
        props.onSelectTab(index);
    }

    return (
        <div className={`d-flex ${styles.e_tab_wrapper} e-tab-wrapper-responsive ${props.wrapperClass}`}>
            {
                props.type === 2 ?
                    props.data.map((item, key) => {
                        return (
                            <span className={
                                item.id === tabIndex ? `border-bottom-2px  border-may-green text-center  e-font-sm-16-14 cursor-pointer padding-24px-lr padding-6px-tb e-poppins-semi-bold color-black ${props.className}`
                                    : ` text-center  e-font-16 cursor-pointer padding-24px-lr padding-6px-tb e-poppins-medium color-outer-space ${props.className}`
                            }
                                key={key}
                                id={`tab-${item.id}`}
                                onClick={() => _handleChangeTab(item.id)}>
                                <img src={(item.icon).default} alt="tab-icon" className='me-1'
                                    width={"24px"}
                                    height={"24px"} />  {item.title}
                            </span>

                        )
                    })
                    :
                    props.data.map((item, key) => {
                        if (props.indexToHide !== key) {
                            return (
                                <span className={
                                    (key + 1) === tabIndex ? `border-bottom-2px  border-may-green text-center  e-font-sm-16-14 cursor-pointer padding-24px-lr padding-6px-tb e-poppins-semi-bold color-black ${props.className}`
                                        : ` text-center  e-font-sm-16-14 cursor-pointer padding-24px-lr padding-6px-tb e-poppins-medium color-outer-space ${props.className}`
                                }
                                    key={key}
                                    id={`tab-${key+1}`}
                                    onClick={() => _handleChangeTab(key + 1)}>
                                    {item}
                                </span>
    
                            )
                        }
                        
                    })
            }

        </div>
    )
}

export default CustomTab;

CustomTab.defaultProps = {
    type: 1,
    className: '',
    selectedTab: 1,
}

