/*
 *   File : pending-goal.js
 *   Author URI : https://evoqins.com
 *   Description : Pending goals card
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useState } from "react";

import { GradientButton } from "../CTA"
import Icon from "../Icon/icon";
import style from "../../Styles/Component/pending-goal.module.scss"

const PendingGoalCard = React.memo((props) => {
    const [hoveredItem, setHoveredItem] = useState(null);

    const _handleContinue = () => {
        props.continue(props.index);
    }

    const _handleDelete = () => {
        props.delete(props.goalId);
    }
    return (
        <>
            <div className="border-radius-16px d-sm-block d-none  border-all border-bright-gray py-3 ps-sm-4 ps-3 pe-3 d-flex align-items-center"
                style={{ backgroundColor: props.data.background_color }}>
                <div className="d-flex gap-16px flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap w-100">
                    <img src={props.data.icon}
                        alt="Goals"
                        width={64}
                        height={64} />


                    <div className="d-flex justify-content-between align-items-center flex-wrap gap-16px w-100">
                        {/* goal name, last edited date and description */}
                        <div className="d-flex flex-column gap-6px">
                            <p className="color-black e-poppins-regular e-font-12 line-height-12px mb-0">Last edit on {props.data.last_edited_date}</p>
                            <p className="color-black e-poppins-semi-bold e-font-24 line-height-24px mb-0 mt-sm-0 mt-2">{props.data.name}</p>
                            <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 e-p-line-height mt-sm-0 mt-2">{props.data.description}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <GradientButton label="Continue"
                                className="py-2 px-4 e-font-16 margin-32px-right"
                                handleClick={_handleContinue} />
                            <Icon icon={hoveredItem === props.goalId ? "delete-red" : "delete"}
                                size={24}
                                onMouseEnter={() => setHoveredItem(props.goalId)}
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={_handleDelete}
                                className='cursor-pointer' />
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-radius-16px d-sm-none d-block  border-all border-bright-gray py-3 ps-sm-4 ps-3 pe-3 "
                style={{ backgroundColor: props.data.background_color }}>
                <div className="d-flex justify-content-between flex-wrap w-100">
                    <div className="d-flex gap-16px align-items-center">
                        <img src={props.data.icon}
                            alt="Goals"
                            width={64}
                            height={64} />

                        <div>
                            <p className="color-black e-poppins-regular e-font-12 line-height-12px mb-0">Last edit on {props.data.last_edited_date}</p>
                            <p className="color-black e-poppins-semi-bold e-font-18 line-height-24px mb-0 mt-sm-0 mt-2">{props.data.name}</p>
                        </div>
                    </div>
                    <Icon icon={hoveredItem === props.goalId ? "delete-red" : "delete"}
                        size={24}
                        onMouseEnter={() => setHoveredItem(props.goalId)}
                        onMouseLeave={() => setHoveredItem(null)}
                        onClick={_handleDelete}
                        className='cursor-pointer' />
                </div>
                <div className="d-flex flex-column gap-6px ">
                    <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 e-p-line-height mt-sm-0 mt-2">{props.data.description}</p>
                </div>
                <div className="d-flex align-items-center mt-3">
                    <GradientButton label="Continue"
                        className="py-2 px-4 e-font-16 margin-32px-right"
                        handleClick={_handleContinue} />

                </div>
            </div>
        </>
    )
})
export default PendingGoalCard