import * as type from '../types';

const initialState = {
    SHOW_SEARCH_SUGGESTION: false
};

export default function toggleHeaderFilterReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_SEARCH_SUGGESTION_STATUS':
            return {
                ...state,
                SHOW_SEARCH_SUGGESTION: action.payload,
            };
        default: return { state }
    }
}