/*
 *   File : mandates.js
 *   Author URI : https://evoqins.com
 *   Description : Modal screen to show available mandates created by selected bank account
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { parse, differenceInYears, format, subYears } from 'date-fns';

import Icon from "../Icon/icon";
import { CustomSelectBox, CustomTextInput, DatePicker, OTPInput, RadioGroup } from "../Form-elements";
import { CustomTab } from "../Tab";
import { GradientButton, OutlineButton } from "../CTA";
import { CustomLoader } from "../Other";
import APIService from "../../Service/api-service";
import { _consent2FA } from "../../Helper/api";

import style from "../../Styles/Component/mange-nominee.module.scss";
import Colors from "../../Styles/color.module.scss";
import { useSelector } from "react-redux";

const OptOptions = [{
    id: 1,
    label: "Opt-in"
},
{
    id: 2,
    label: "Opt-out"
}]

const NOMINEE = {
    nominee_id: null,
    name: "",
    relation: null,
    allocation: "",
    date_of_birth: null,
    guardian_name: "",
    guardian_relationship: null,
    guardian_pan: "",
    ageCheck: true,
};

const NOMINEE_ERROR = {
    name: "",
    date_of_birth: "",
    relation: "",
    allocation: "",
    guardian_name: "",
    guardian_relationship: "",
    guardian_pan: "",
}

const ManageNomineeModal = (props) => {

    const navigator = useNavigate();
    const location = useLocation();
    
    const GENERAL_DATA = useSelector(state => state.generalData.GENERAL_DATA)
    const nomineeErrorRef = useRef([NOMINEE_ERROR]);
    const [selectedOptOptions, setSelectedOptOptions] = useState(OptOptions[0].id);
    const [nomineeDetail, setNomineeDetail] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [tabData, setTabData] = useState([]);
    const [tabIndex, setTabIndex] = useState(1);
    const [deleteHover, setDeleteHover] = useState(false);
    const [loader, setLoader] = useState(true);
    const [tabLoader, setTabLoader] = useState(true);
    const [relationOptions, setRelationOptions] = useState([]);
    const [guardianOptions, setGuardianOptions] = useState([]);
    const [proceed, setProceed] = useState(false);
    const [pinString, setPinString] = useState("");
    const [pinError, setPinError] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [showSuccess, setShowSuccess] = useState(false);

    // max date that can be selected for dob (current date - 18 years)
    const maxDate = new Date();
    // maxDate.setFullYear(maxDate.getFullYear() - 18);

    useEffect(() => {
        var my_modal = document.getElementById("manage-nominee");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);

    useEffect(() => {
        if (proceed === true) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, proceed]);

    useEffect(() => {
        const _generateTabData = (length) =>
            Array.from({ length }, (_, index) => `Nominee ${index + 1}`);

        const tab_data = _generateTabData(nomineeDetail.length === 0 ? 1 : nomineeDetail.length);
        setTabData(tab_data);
        setTabLoader(false);
    }, [nomineeDetail]);

    useEffect(() => {
        if (props.data.length !== 0) {
            const updated_data = props.data.map(({ relationship_id, relationship, guardian_relationship_id, guardian_relationship, date_of_birth, allocation, guardian_name, guardian_pan, ...rest }) => {
                const formatted_date = format(parse(date_of_birth, 'dd-MM-yyyy', new Date()), 'dd/MM/yyyy');
                return {
                    ...rest,
                    allocation: parseFloat(allocation),
                    ageCheck: _is18YearsOld(formatted_date),
                    date_of_birth: formatted_date,
                    guardian_name: guardian_name === "-" ? null : guardian_name,
                    guardian_pan: guardian_pan === "-" ? null : guardian_pan,
                    guardian_relationship: guardian_relationship_id === "-" ? null : {
                        value: guardian_relationship_id,
                        label: guardian_relationship
                    },
                    relation: {
                        value: relationship_id,
                        label: relationship
                    }
                }
            });

            for (let i = updated_data.length; i > 1; i--) {
                nomineeErrorRef.current.push(NOMINEE_ERROR);
            }
            console.log("Updated nominee", updated_data)

            setNomineeDetail(updated_data);
        } else {
            setNomineeDetail([NOMINEE]);
        }
        _getGeneralData();
    }, [props.data]);

    const _handleClose = () => {
        props.close();
    }

    const _handleRadioInput = (e) => {
        setSelectedOptOptions(e);
        if (e === 2) {
            for (const key of Object.keys(NOMINEE_ERROR)) {
                nomineeErrorRef.current[tabIndex - 1][key] = '';
            }
            toast.dismiss();
            toast.success("You have decided not to add any nominee to your investment account.", {
                type: "success",
            });

        }
    }

    // handler for tab change
    const _handleTabSelect = (index) => {
        setTabIndex(index);
    }

    const _is18YearsOld = (dobString) => {
        // Parse the date of birth string into a Date object
        const dob = parse(dobString, 'dd/MM/yyyy', new Date());

        // Calculate the age difference
        const age_difference = differenceInYears(new Date(), dob);

        return age_difference >= 18;
    }

    const _handleInput = (type, value, key) => {
        let nominee = [...nomineeDetail];

        switch (type) {
            case 1:
                // Create a new copy of the array
                nominee[key] = { ...nominee[key], name: value }; // Update the specific element
                nomineeErrorRef.current[key].name = "";
                setNomineeDetail(nominee);
                break;
            case 2:
                nominee[key] = { ...nominee[key], date_of_birth: value, ageCheck: _is18YearsOld(value) }; // Update the specific element
                if (_is18YearsOld(value) === true) {
                    nominee[key] = { ...nominee[key], guardian_name: "" }
                    nominee[key] = { ...nominee[key], guardian_relationship: null }
                    nominee[key] = { ...nominee[key], guardian_pan: "" }
                    nomineeErrorRef.current[key].guardian_name = "";
                    nomineeErrorRef.current[key].guardian_relationship = "";
                    nomineeErrorRef.current[key].guardian_pan = "";
                }
                nomineeErrorRef.current[key].date_of_birth = "";
                setNomineeDetail(nominee);
                break;
            case 3:
                nominee[key] = { ...nominee[key], relation: value }; // Update the specific element
                nomineeErrorRef.current[key].relation = "";
                setNomineeDetail(nominee);
                break;
            case 4:
                nominee[key] = { ...nominee[key], allocation: value }; // Update the specific element
                nomineeErrorRef.current[key].allocation = "";
                setNomineeDetail(nominee);
                break;
            case 5:
                nominee[key] = { ...nominee[key], guardian_name: value }; // Update the specific element
                nomineeErrorRef.current[key].guardian_name = "";
                setNomineeDetail(nominee);
                break;
            case 6:
                nominee[key] = { ...nominee[key], guardian_relationship: value }; // Update the specific element
                nomineeErrorRef.current[key].guardian_relationship = "";
                setNomineeDetail(nominee);
                break;
            case 7:
                nominee[key] = { ...nominee[key], guardian_pan: value }; // Update the specific element
                nomineeErrorRef.current[key].guardian_pan = "";
                setNomineeDetail(nominee);
                break;
        }
    }

    const _validateForm = (type) => {
        let valid = true;
        let nominee = [...nomineeDetail];
        for (let index = 0; index < nomineeErrorRef.current.length; index++) {
            const element = nomineeErrorRef.current[index];
            const is_empty = Object.values(element).every(value => value === "");
            if (is_empty === true) {
                setTabIndex(index + 1);
            }
        }


        nominee.map((item, key) => {

            if (item.name.length === 0) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], name: "Name is required" };
                valid = false;
            }

            if (item.date_of_birth === null) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], date_of_birth: "Date of Birth is required" };
                valid = false;
            }



            if (item.relation === null) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], relation: "Relation is required" };
                valid = false;
            }


            if (item.allocation.length === 0) {
                nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], allocation: "Allocation is required" };
                valid = false;
            }
            if (item.date_of_birth !== null && _is18YearsOld(item.date_of_birth) === false) {
                if (item.guardian_name.length === 0) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_name: "Guardian name is required" };
                    valid = false;
                }

                if (item.guardian_relationship === null) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_relationship: "Guardian relation is required" };
                    valid = false;
                }
                if (item.guardian_pan.length === 0) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_pan: "Guardian pan is required" };
                    valid = false;
                } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(item.guardian_pan)) {
                    nomineeErrorRef.current[key] = { ...nomineeErrorRef.current[key], guardian_pan: "Invalid Guardian pan " };
                    valid = false;
                }
            }

            if (valid == false) {
                return false;
            }
        });
        console.log('Form validation :: ', valid)
        if (valid === true) {
            if (type === 1) {
                const total_allocation = nominee.reduce((acc, curr) => acc + parseInt(curr.allocation), 0);
                if (total_allocation !== 100) {
                    valid = false;
                    toast.dismiss();
                    toast.error("Sum of allocations must be 100%", {
                        type: "error"
                    });
                } else {
                    let proceed_nominees = _processNomineeData();
                    _validateNominee(proceed_nominees);

                    console.log('Processed nominees :: ', proceed_nominees)
                }
            } else {
                _handleMore();
            }
        }
        setForceUpdate(!forceUpdate);
    }

    const _handleMore = () => {
        let nominee = [...nomineeDetail]
        nominee.push(NOMINEE)
        nomineeErrorRef.current.push(NOMINEE_ERROR);
        setNomineeDetail(nominee);
        setTabIndex(prevState => prevState + 1);
    }

    const _removeNominee = () => {
        let nominee_detail = [...nomineeDetail];
        nominee_detail.splice(tabIndex - 1, 1);
        nomineeErrorRef.current.splice(tabIndex - 1, 1);
        if (nominee_detail.length === 0) {
            nominee_detail = [NOMINEE];
        }
        setTabIndex(1);
        setTabLoader(true);
        setDeleteHover(false);
        setNomineeDetail([...nominee_detail]);
    }

    const _handleGoback = () => {
        setPinString("");
        setPinError("");
        setProceed(false);
    }

    const _handlePin = (value) => {
        setPinString(value);
        setPinError("");
    }

    function _navigateToProfile () {
        navigator('/profile', { state: { tab: location.state.tabIndex } })
    }

    // validate pin
    const _validatePin = async () => {
        let valid = true;
        if (pinString.length === 0) {
            setPinError("Enter OTP");
            valid = false;
        }
        if (valid === true) {
            let process_nominees = [];
            if (selectedOptOptions === 1) {
                process_nominees = await _processNomineeData();
            }
            _addNominee(process_nominees);
        }
    }

    function _processNomineeData() {
        const updated_data = nomineeDetail.map(({ relation, guardian_name, guardian_relationship, date_of_birth, ageCheck, date_of_birth_str, guardian_pan, ...rest }) => {
            // Parse the date_of_birth and format it as 'dd-MM-yyyy'

            let formatted_date = '';
            if (date_of_birth) {
                formatted_date = format(parse(date_of_birth, 'dd/MM/yyyy', new Date()), 'dd-MM-yyyy');
            }


            return {
                ...rest,
                relation_id: relation?.value,
                guardian_name: ageCheck === true ? null : guardian_name,
                guardian_pan: ageCheck === true ? null : guardian_pan,
                guardian_relationship_id: guardian_relationship !== null ? guardian_relationship.value : null,
                date_of_birth: formatted_date
            };
        });
        return updated_data;
    }


    // API - Get general data (to get relation and guardian options)
    function _getGeneralData() {
        let relation_data = GENERAL_DATA.nominee_relation.map((item) => {
            return ({ value: item.id, label: item.name })
        });
        let guardian_data = GENERAL_DATA.nominee_guardian_relation.map((item) => {
            return ({ value: item.id, label: item.name })
        });
        setGuardianOptions(guardian_data);
        setRelationOptions(relation_data);
        setLoader(false);
    }

    // API - add nominee
    function _addNominee(nomineeData) {
        setApiLoader(true);
        let url = 'profile/nominee/upsert';
        if (props.nft === true) {
            url = 'mf-services/nft/verify-nominee-otp';
        }

        let payload = JSON.stringify({
            is_nominee_available: selectedOptOptions === 1 ? true : false,
            otp: pinString,
            nominee: selectedOptOptions === 1 ? nomineeData : [],
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                if (props.nft === true) {
                    setShowSuccess(true);
                } else {
                    document.getElementById("close-modal").dispatchEvent(new Event("click"));
                    if (selectedOptOptions === 1) {
                        toast.dismiss();
                        toast.success("Nominee details updated sucessfully", {
                            type: "success",
                        });
                    } else {
                        toast.dismiss();
                        toast.success("Nominee opted-out successfully", {
                            type: "success",
                        });
                    }

                    props.update();
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - validate nominee
    function _validateNominee(nomineeData, resend = false) {
        if (resend === false) {
            setApiLoader(true);
        }
        
        let url = 'profile/nominee/confirm-update';
        let payload = JSON.stringify({
            is_nominee_available: selectedOptOptions === 1 ? true : false,
            nominee: selectedOptOptions === 1 ? nomineeData : [],
        });
        if (props.nft === true) {
            url = 'mf-services/nft/change-nominee';
            payload = JSON.stringify({
                folio_numbers: [props.folio],
                is_nominee_available: selectedOptOptions === 1 ? true : false,
                nominee: selectedOptOptions === 1 ? nomineeData : [],
            });
        }


        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                
                setProceed(true);

                // _updateCountdown();


            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    const _handleResendOTP = () => {
        if (props.nft === true) {
            let proceed_nominees = _processNomineeData();
            _validateNominee(proceed_nominees, true);
            setSeconds(30);
            setPinString("");
            return;
        }
        _consent2FA("NOMINEE")
            .then((response) => {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setSeconds(30);
                setPinString("");
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    return (
        <div className={`modal fade ${style.e_nominee_modal}`}
            id="manage-nominee"
            tabIndex="-1"
            aria-labelledby="manage-nominee"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content border-radius-24px border-all border-anti-flash-white padding-32px-all ${style.e_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        proceed === false && showSuccess === false ?
                            <Fragment>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">Nominee details</p>
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className="cursor-pointer e-modal-close-btn"
                                        onClick={_handleClose} />
                                </div>
                                <div className={style.e_nominee_scroll}>

                                    <p className="color-outer-space e-poppins-regular e-font-14 mb-3">Add nominee to your investment account.</p>
                                    <RadioGroup data={OptOptions}
                                        className={`gap-16px `}
                                        radioItem={"w-unset"}
                                        spanClass="e-poppins-regular"
                                        iconClassName="me-2"
                                        size={20}
                                        type={1}
                                        id={selectedOptOptions}
                                        radioHandler={_handleRadioInput} />
                                    {
                                        selectedOptOptions === 1 ?
                                            <div className="mt-3 d-flex justify-content-between align-items-center margin-28px-bottom">
                                                <CustomTab data={tabData}
                                                    selectedTab={tabIndex}
                                                    wrapperClass={'gap-16px'}
                                                    className="px-0 padding-10px-tb e-order-tab"
                                                    onSelectTab={_handleTabSelect} />

                                                <div className="d-flex align-items-center gap-10px">
                                                    {
                                                        nomineeDetail.length > 1 && (
                                                            <div className="position-relative">
                                                                <Icon icon={deleteHover === true ? "delete-red" : "delete"}
                                                                    size={24}
                                                                    className={`${nomineeDetail.length === 1 && "no-events"} cursor-pointer`}
                                                                    onMouseEnter={() => setDeleteHover(true)}
                                                                    onMouseLeave={() => setDeleteHover(false)}
                                                                    onClick={_removeNominee} />
                                                                {
                                                                    deleteHover === true &&
                                                                    <p className={`border-radius-4px color-outer-space e-poppins-regular e-font-12 line-height-12 mb-0 p-2 bg-white position-absolute white-space-nowrap z-index-1 ${style.e_delete_icon}`}>
                                                                        Delete nominee
                                                                    </p>
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        nomineeDetail.length < 3 &&
                                                        <OutlineButton label="Add new"
                                                            className="px-3 e-font-sm-16-14 padding-12px-tb-sm-8"
                                                            handleClick={() => _validateForm(2)} />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <h5 className='e-poppins-semi-bold e-font-16  color-outer-space d-block mt-3 mt-5'>
                                                    You have selected not to add nominee to your investments
                                                </h5>

                                                <h5 className='e-poppins-semi-regular e-font-14 line-height-26px color-outer-space d-block'>
                                                    You can always update nominee by choosing Opt-in anytime
                                                </h5>
                                            </>
                                    }

                                    {
                                        loader === true || tabLoader === true ?
                                            <CustomLoader />
                                            :
                                            <Fragment>
                                                {
                                                    selectedOptOptions === 1 && (
                                                        <div className="row mt-4"
                                                        // style={{ overflowY: nomineeDetail[tabIndex - 1].ageCheck === false ? "scroll" : "visible" }}
                                                        >
                                                            <div className="col-12">
                                                                <CustomTextInput label="Name"
                                                                    postfix="*"
                                                                    value={nomineeDetail[tabIndex - 1].name}
                                                                    className="margin-32px-bottom"
                                                                    error={nomineeErrorRef.current[tabIndex - 1]['name']}
                                                                    handleChange={(data) => _handleInput(1, data, tabIndex - 1)} />
                                                            </div>
                                                            <div className="col-12">
                                                                <CustomSelectBox value={nomineeDetail[tabIndex - 1].relation}
                                                                    label="Relation"
                                                                    error={nomineeErrorRef.current[tabIndex - 1]['relation']}
                                                                    postfix="*"
                                                                    className="margin-32px-bottom "
                                                                    options={relationOptions}
                                                                    onSelectChange={(value) => _handleInput(3, value, tabIndex - 1)} />
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-12">
                                                                <DatePicker label="Date of Birth"
                                                                    error={nomineeErrorRef.current[tabIndex - 1]['date_of_birth']}
                                                                    value={nomineeDetail[tabIndex - 1].date_of_birth}
                                                                    isAdult={true}
                                                                    maxDate={maxDate}
                                                                    postFix="*"
                                                                    dob={(value) => _handleInput(2, value, tabIndex - 1)} />
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-4">
                                                                <CustomTextInput label="Allocation percentage"
                                                                    postfix="*"
                                                                    type="number"
                                                                    maxLength={3}
                                                                    value={nomineeDetail[tabIndex - 1].allocation}
                                                                    className="margin-32px-bottom "
                                                                    error={nomineeErrorRef.current[tabIndex - 1]['allocation']}
                                                                    handleChange={(value) => _handleInput(4, value, tabIndex - 1)} />
                                                            </div>
                                                            {
                                                                nomineeDetail[tabIndex - 1].ageCheck === false &&
                                                                <Fragment>
                                                                    <div className="col-12">
                                                                        <CustomTextInput label="Guardian name"
                                                                            postfix="*"
                                                                            value={nomineeDetail[tabIndex - 1].guardian_name}
                                                                            className="margin-32px-bottom"
                                                                            error={nomineeErrorRef.current[tabIndex - 1]['guardian_name']}
                                                                            handleChange={(data) => _handleInput(5, data, tabIndex - 1)} />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <CustomSelectBox value={nomineeDetail[tabIndex - 1].guardian_relationship}
                                                                            label="Guardian relation"
                                                                            error={nomineeErrorRef.current[tabIndex - 1]['guardian_relationship']}
                                                                            postfix="*"
                                                                            className="margin-32px-bottom "
                                                                            options={guardianOptions}
                                                                            onSelectChange={(value) => _handleInput(6, value, tabIndex - 1)} />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <CustomTextInput label="Guardian PAN number"
                                                                            postfix="*"
                                                                            pan={true}
                                                                            inputClass="text-uppercase"
                                                                            value={nomineeDetail[tabIndex - 1].guardian_pan}
                                                                            className="margin-32px-bottom"
                                                                            error={nomineeErrorRef.current[tabIndex - 1]['guardian_pan']}
                                                                            handleChange={(data) => _handleInput(7, data, tabIndex - 1)} />
                                                                    </div>
                                                                </Fragment>

                                                            }

                                                        </div>
                                                    )
                                                }
                                            </Fragment>
                                    }
                                </div>

                                <div className={selectedOptOptions === 1 ? "mx-5 " : "mx-5 mt-4"}>
                                    <GradientButton label="Verify & Save"
                                        className="e-font-16 padding-12px-tb w-100"
                                        loading={apiLoader}
                                        handleClick={() => {
                                            if (selectedOptOptions === 1) {
                                                _validateForm(1);
                                            } else {
                                                _validateNominee([]);
                                            }
                                        }} />
                                </div>
                            </Fragment>
                            : proceed === true && showSuccess === false ?
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        id="close-modal"
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_proceed_close}`} />
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={Colors.black}
                                        className={`position-absolute cursor-pointer ${style.e_back} ${apiLoader === true && "no-events"}`}
                                        onClick={_handleGoback} />

                                    <div className="text-center mt-4">
                                        <img src={require("../../Assets/Images/portfolio/redeem-confirm.png")}
                                            alt="Confirm payment"
                                            width={72}
                                            height={72} />
                                        <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                        <p className="color-jett-black e-poppins-regular e-font-16 mb-3">Submit OTP to update nominee details.</p>
                                        
                                        <div className="d-flex justify-content-center w-100">
                                            <OTPInput pinString={pinString}
                                                id="invest"
                                                type={props.nft === true ? 2 : 1}
                                                autoFocus={true}
                                                pinError={pinError}
                                                className={props.nft === true &&'e_width_386'}
                                                disabled={apiLoader}
                                                handleChange={_handlePin} />
                                        </div>

                                    </div>
                                    <div className="margin-22px-top mx-5 px-5">
                                        <GradientButton label="Confirm OTP"
                                            className=" e-font-16 padding-12px-tb w-100"
                                            loading={apiLoader}
                                            handleClick={_validatePin} />
                                    </div>
                                    <div className="d-flex justify-content-center mt-4">
                                        {
                                            seconds === 0 ?
                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                    onClick={_handleResendOTP}
                                                >Resend OTP</p>
                                                :
                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                    Resend OTP in <span className="color-black">{seconds}s</span>
                                                </p>
                                        }

                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_proceed_close}`} />
                                    <div className="text-center">
                                        <img src={require('../../Assets/Images/orders/order-success.svg').default}
                                            alt="Pause SIP"
                                            width={72}
                                            height={72} />
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h5 className="color-eerie-black e-poppins-medium e-font-18 mt-5">
                                                Data added successfully.
                                            </h5>
                                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0  text-center">
                                                Your request is successfully submitted.
                                            </p>
                                        </div>
                                        <div className="mt-4 pt-3 d-sm-flex d-block justify-content-center gap-8px mb-3">

                                            <GradientButton label="Back to home"
                                                cancel="modal"
                                                className="e-font-16 padding-70px-lr padding-12px-tb w-mobile-100 mt-sm-0 mt-3"
                                                handleClick={_navigateToProfile} />
                                        </div>
                                    </div>
                                </Fragment>
                    }


                </div>
            </div>

        </div>
    )
}


ManageNomineeModal.defaultProps = {
    data: [NOMINEE],
    nft: false,
}
export default ManageNomineeModal