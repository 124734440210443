
/*
 *   File : goal-redeem.js
 *   Author URI : https://evoqins.com
 *   Description : create redeem screen from goal info.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import APIService from "../../Service/api-service";
import { toast } from "react-toastify";
import Icon from "../Icon/icon";
import { GradientButton } from "../CTA";
import { CustomAmountInput, DataTable, OTPInput } from "../Form-elements";

import style from "../../Styles/Component/goal-redeem.module.scss"
import Colors from "../../Styles/color.module.scss"

const GoalRedeemModal = (props) => {

    const PROFILE_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [amount, setAmount] = useState("")
    const [amountError, setAmountError] = useState("");
    const [withdrawAll, setWithdrawAll] = useState(false);
    const [showBank, setShowBank] = useState(false);
    const [selectedBank, setSelectedBank] = useState({});
    const [proceed, setProceed] = useState(false);
    const [pinString, setPinString] = useState("");
    const [pinError, setPinError] = useState("");
    const [apiLoader, setApiLoader] = useState(false);

    const FUND_COLUMN = [
        {
            name: 'Fund name',
            selector: row => row['name'],
            sortable: false,
            // width: "546px",
            cell: row =>
                <p className="color-primary-color e-poppins-regular e-font-12 line-height-18px mb-0">{row['name']}</p>
        },
        {
            name: "Total amount",
            width: "130px",
            selector: row => row['current_amount'],
            sortable: false,
            cell: row =>
                <p className="color-outer-space e-poppins-medium e-font-14 mb-0"><span className="e-inter-medium">₹</span>{row['current_amount'].toLocaleString("en-IN")}</p>

        },
        {
            name: 'Available for withdraw',
            selector: row => row['withdrwable_amount'],
            sortable: false,
            width: "150px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 mb-0 ">
                    {
                        (amount === '' || amount > props.data.current_amount) ? '-'
                            :
                            <>
                                <span className="e-inter-medium">₹</span>
                                {row['allocation'] && (amount * (row['allocation'] / 100)).toLocaleString("en-IN")}
                            </>
                    }
                </p>
        }
    ];

    useEffect(() => {
        if (withdrawAll == true) {
            setAmount(props.data.current_amount);
        }
    }, [withdrawAll]);

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("goal-redeem");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        const default_bank_index = props.data.banks.findIndex(bank => bank.is_default === true);
        if (default_bank_index != -1) {
            let default_bank = props.data.banks[default_bank_index];
            setSelectedBank(default_bank);
        }
    }, [props.data])

    const _handleInput = (value) => {
        if (value > props.data.current_amount) {
            toast.dismiss();
            toast.error("Insufficient balance to withdraw from this goal", {
                type: "error",
            });
        }
        setAmount(value);
        setAmountError("");
    }

    const _handleWithdrawAll = () => {
        setWithdrawAll(!withdrawAll);
    }

    const _handleShowBank = () => {
        setShowBank(!showBank);
    }

    const _handleSelectedBank = (data) => {
        setSelectedBank(data);
    }

    const _handleGoback = () => {
        setPinString("");
        setPinError("");
        setProceed(false);
    }

    const _handlePin = (value) => {
        setPinString(value);
        setPinError("");
    }

    // validate pin
    const _validatePin = () => {
        let valid = true;
        if (pinString.length === 0) {
            setPinError("OTP Required");
            valid = false;
        }
        if (valid === true) {
            _confirmPayment();
        }
    }


    const _validateProceed = () => {
        let valid = true;
        if (amount.length === 0) {
            setAmountError("Enter amount to withdraw");
            valid = false;
        }
        if (amount > props.data.current_amount) {
            toast.dismiss();
            toast.error("Insufficient balance to withdraw from this goal", {
                type: "error",
            });
            valid = false;
        }

        if (valid === true) {
            _confirmProceed();
        }
    }

    // API - confirm proceed to get otp
    function _confirmProceed() {
        setApiLoader(true);
        let url = 'goal/confirm-redeem';
        let payload = JSON.stringify({
            goal_id: props.data.goal_data.id,
            amount: amount,
            redeem_all: withdrawAll,
            bank_id: selectedBank.bank_id,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setProceed(true);
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - confirm redeem
    function _confirmPayment() {
        setApiLoader(true);
        let url = 'goal/redeem';
        let payload = JSON.stringify({
            goal_id: props.data.goal_data.id,
            amount: amount,
            redeem_all: withdrawAll,
            bank_id: selectedBank.bank_id,
            otp: pinString,
        });

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                // document.getElementById("goal-redeem").dispatchEvent(new Event("click"));
                props.redeemStatus(1);
            }
            else {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.redeemStatus(2);
            }
            setApiLoader(false);
        });
    }

    const SelectBank = ({ item }) => {

        return (
            <div className={`d-flex justify-content-between align-items-center cursor-pointer gap-8px h-100
                ${item.bank_id === selectedBank.bank_id ?
                    `border-radius-8px border-all padding-12px-all border-primary-color`
                    :
                    `border-radius-8px border-all padding-12px-all border-bright-gray`
                }`}

                onClick={() => _handleSelectedBank(item)}>
                <div className="d-flex align-items-center">
                    <img src={item.image} alt={item.name}
                        width={35}
                        height={35}
                        className="border-radius-100 me-1" />
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <span className="color-black e-poppins-semi-bold e-font-14 line-height-14px me-1 ">
                                {item.name}
                            </span>
                            <span className="color-outer-space e-poppins-medium e-font-14 line-height-14px ">{item.account_number}</span>
                        </div>
                        {
                            item.is_default === true &&
                            <p className="color-primary-color e-poppins-medium e-font-12  line-height-12px mb-0 mt-1">Primary bank</p>
                        }
                    </div>
                </div>
                <Icon icon={
                    selectedBank.bank_id === item.bank_id ?
                        'radio-selected' :
                        'radio-unselected'
                }
                    size={20} />
            </div>
        )
    }

    const customStyles = {
        table: {
            style: {
                backgroundColor: Colors.light_sky_blue,
                borderRadius: "16px",
                padding: "16px"
            }
        },
        headRow: {
            style: {
                fontSize: "12px",
                padding: "0px !important",
                paddingBottom: "8px",
                backgroundColor: "transparent",
                borderBottom: "none"
            }
        },
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
                '&:nth-child(3)': {
                    justifyContent: 'flex-end',
                },
            },
        },
        rows: {
            style: {
                padding: "0px",
                paddingTop: "8px",
                minHeight: "fit-content",
                marginBottom: "0px",
                border: "none",
                borderRadius: "unset",
                '&:not(:last-child)': {
                    borderBottom: `1px solid ${Colors.pastel_shade_blue} !important`,
                    paddingBottom: "8px",
                },
                backgroundColor: "transparent",
            },
        },

        cells: {
            style: {
                '&:first-child': {
                    marginRight: "40px"
                },
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            }
        }
    };

    return (
        <div className={`modal fade ${style.e_goal_redeem}`}
            id="goal-redeem"
            tabIndex="-1"
            aria-labelledby="goal-redeem"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content border-radius-24px padding-20px-top ps-4 padding-32px-right pb-4 position-relative ${style.e_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    {
                        proceed === false ?
                            <Fragment>
                                <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px mb-3">Withdraw details</p>
                                <Icon icon="close"
                                    size={24}
                                    data-bs-dismiss="modal"
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                                <div className={style.e_redeem}>
                                    <div className={style.e_body}>
                                        <div className="border-radius-16px bg-light-sky-blue pt-3 pb-4 px-4 margin-34px-bottom ">
                                            <div className="d-flex justify-content-between border-bottom-1px border-shade-light-blue pb-3 mb-3 gap-32px">
                                                <div className="d-flex gap-16px">
                                                    <div className="p-2 bg-white border-all border-bright-gray border-radius-4px">
                                                        <img src={props.data.goal_data.image}
                                                            alt={props.data.goal_data.name}
                                                            width={40}
                                                            height={40}
                                                            className="object-fit-contain" />
                                                    </div>
                                                    <p className="color-black e-poppins-medium e-font-14 line-height-20px mb-2">
                                                        {props.data.goal_data.name}
                                                    </p>

                                                </div>
                                                {/* investment style comes here waitng from api */}
                                                <div className="d-flex">
                                                    <img src={props.data.goal_data.investment_style.image}
                                                        alt={props.data.goal_data.investment_style.name}
                                                        width={30}
                                                        height={16} />
                                                    <p className="color-primary-color e-poppins-medium e-font-14 line-height-20px ms-1 mb-0">{props.data.goal_data.investment_style.name}</p>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-24px">
                                                <p className="color-gray e-poppins-medium e-font-14 mb-0">Invested: <span className="color-outer-space">{props.data.invested_amount.toLocaleString("en-IN")}</span></p>
                                                <p className="color-gray e-poppins-medium e-font-14 mb-0">Current: <span className="e-inter-medium color-outer-space">₹</span><span className="color-outer-space">{props.data.current_amount.toLocaleString("en-IN")}</span></p>
                                                <p className="color-gray e-poppins-medium e-font-14 mb-0">Redemption time: <span className="color-outer-space">{props.data.redemption_time}</span></p>

                                            </div>
                                        </div>
                                        <div data-disabled={PROFILE_DATA.demat === true ? 'true' : 'false'}>
                                            <CustomAmountInput value={amount}
                                                label="Amount"
                                                error={amountError}
                                                rupeeStyle="pt-1"
                                                inputClass="py-3 bg-transparent e-poppins-semi-bold padding-28px-left border-radius-12px"
                                                labelClass="bg-white"
                                                disabled={withdrawAll}
                                                handleChange={_handleInput} />
                                        </div>

                                        <div className="margin-32px-top d-flex align-items-center gap-8px cursor-pointer"
                                            data-disabled={PROFILE_DATA.demat === true ? 'true' : 'false'}
                                            onClick={_handleWithdrawAll}>
                                            <Icon icon={withdrawAll === true ? "checked" : "unchecked"}
                                                size={24} />
                                            <p className="color-outer-space e-poppins-medium e-font-14 mb-0">Withdraw all units</p>
                                        </div>
                                        {
                                            PROFILE_DATA.demat === false &&
                                            <div className="cursor-pointer d-flex align-items-center gap-8px justify-content-end mt-3"
                                                onClick={_handleShowBank}>
                                                <p className="color-sea-green e-poppins-medium e-font-12 mb-0">Select bank</p>
                                                <Icon icon="right-arrow"
                                                    size={24}
                                                    className={showBank === true ? style.e_rotate_left_90 : style.e_rotate_right_90} />
                                            </div>
                                        }

                                        {
                                            showBank === true &&
                                            <div className="border-radius-8px border-all border-bright-gray p-2 mt-3">
                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-1">Select bank</p>
                                                <div className="d-flex gap-8px flex-wrap">
                                                    {
                                                        props.data.banks.map((item, key) =>
                                                            <div className={style.select_bank_radio} key={key}>
                                                                <SelectBank item={item} />
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="e-goal-redeem-table mt-3">
                                            <DataTable columns={FUND_COLUMN}
                                                data={props.data.goal_data.investment_details}
                                                pagination={false}
                                                customStyles={customStyles}
                                                defaultSortAsc={false} />
                                        </div>
                                        {
                                            PROFILE_DATA.demat === false &&
                                            <div className="mt-4 p-2 bg-pale-yellow d-flex align-items-center border-radius-8px gap-12px">
                                                <Icon icon="info"
                                                    size={24} />
                                                <p className="color-jett-black e-poppins-regular e-font-10 line-height-16px mb-0">Order will be processed as per the cut-off time for each funds.</p>
                                            </div>
                                        }

                                    </div>
                                    {
                                        PROFILE_DATA.demat === false ?
                                            <div className="mx-5 px-5 mt-4">
                                                <GradientButton label="Request OTP & redeem"
                                                    loading={apiLoader}
                                                    className="e-font-16 padding-12px-tb w-100"
                                                    handleClick={_validateProceed} />
                                            </div>
                                            :
                                            <div className="color-red e-poppins-regular e-font-14 bg-light-red border-red border-all mt-4 p-3 border-radius-16px">
                                                Currently DEMAT redemption is not allowed for DEMAT holdings. Contact  
                                                <a href="tel:8069991111" className="color-primary-color d-block text-decoration-underline">080-69991111</a>
                                            </div>
                                    }



                                </div>
                            </Fragment>
                            :
                            <Fragment>
                                <Icon icon="close"
                                    size={24}
                                    id="close-modal"
                                    data-bs-dismiss="modal"
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_proceed_close}`} />
                                <Icon icon="arrow-right1"
                                    size={24}
                                    color={Colors.black}
                                    className={`position-absolute cursor-pointer ${style.e_back} ${apiLoader === true && "no-events"}`}
                                    onClick={_handleGoback} />
                                <div className="text-center mt-4">
                                    <img src={require("../../Assets/Images/portfolio/redeem-confirm.png")}
                                        alt="Confirm payment"
                                        width={72}
                                        height={72} />
                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                    {/* <p className="color-jett-black e-poppins-regular e-font-16 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                    <OTPInput pinString={pinString}
                                        id="redeem-input"
                                        autoFocus={true}
                                        disabled={apiLoader}
                                        pinError={pinError}
                                        handleChange={_handlePin} />

                                </div>
                                <div className="margin-22px-top mx-5 px-5">
                                    <GradientButton label="Confirm withdrawal"
                                        className=" e-font-16 padding-12px-tb w-100"
                                        loading={apiLoader}
                                        handleClick={_validatePin} />
                                </div>
                            </Fragment>
                    }
                </div>
            </div>
        </div>

    )
}

export default GoalRedeemModal