/*
 *   File :  related-account.js
 *   Author URI : https://evoqins.com
 *   Description : Detail page to show the investment and details of a family-linked account.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react"

import { BreadCrumb } from "../../Component/BreadCrumb"
import { InvestmentHeader } from "../../Component/Header"
import Icon from "../../Component/Icon/icon";
import { CustomTab } from "../../Component/Tab";
import { DataTable } from "../../Component/Form-elements";

import Color from "../../Styles/color.module.scss";

const FAMILY_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Linked account', url: '/family-investment-detail' },
    { page: 'Linked account details', url: null }
]

const TAB_DATA = ['Mutual funds', 'Life goals'];

const INVESTMENT_BREAKDOWN = [{
    heading: "SIP breakdown",
    active_sip: 4,
    invested: 5000,
    current: 7000,
    return: 200000,
    returns: 9.29,
    xirr: 10.209
},
{
    heading: "Lumpsum breakdown",
    invested: 5000,
    current: 7000,
    return: 200000,
    returns: 9.29,
    xirr: 10.209
}]

const MF_GOALS = [{
    id: 1,
    title: "HSBC Small Cap Direct Growth",
    icon: require("../../Assets/Images/dashboard/hsbc.svg").default,
    category: ["Equity- Small Cap"],
    current_amount: 275000,
    invested_amount: 200000,
    total_units: 50.210,
    p_l: "24.16(+9.20)",
},
{
    id: 2,
    title: "Quant Small Cap Fund Direct Plan Growth",
    icon: require("../../Assets/Images/dashboard/escorts.svg").default,
    category: ["Equity- Small Cap"],
    current_amount: 275000,
    invested_amount: 5000,
    total_units: 50.210,
    p_l: "24.16(+9.20)",
},
{
    id: 3,
    title: "HSBC Small Cap Direct Growth",
    icon: require("../../Assets/Images/dashboard/hsbc.svg").default,
    category: ["Equity- Small Cap"],
    current_amount: 275000,
    invested_amount: 1000,
    total_units: 50.210,
    p_l: "24.16(+9.20)",
},
{
    id: 4,
    title: "Kotak Small Cap Fund Direct Growth",
    icon: require("../../Assets/Images/dashboard/kotak.svg").default,
    category: ["Equity- Small Cap"],
    current_amount: 275000,
    invested_amount: 500,
    total_units: 50.210,
    p_l: "24.16(+9.20)",
}]

const LIFE_GOALS = [{
    id: 1,
    title: "Dream house",
    icon: require("../../Assets/Images/dashboard/second-card-icon.png"),
    type: "Lumpsum",
    amount: 100000,
    status: "Processing",
    status_id: 3,
    order_date: "09 Sep 2023"
},
{
    id: 2,
    title: "Dream car",
    icon: require("../../Assets/Images/invest-in-goals/dream-car.png"),
    type: "Lumpsum",
    amount: 100000,
    status: "Success",
    status_id: 2,
    order_date: "09 Sep 2023"
},
{
    id: 3,
    title: "Dream vacation trip",
    icon: require("../../Assets/Images/invest-in-goals/vacation.png"),
    type: "Lumpsum",
    amount: 100000,
    status: "Failed",
    status_id: 4,
    order_date: "09 Sep 2023"
}]

const RelatedAccount = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [tabIndex, setTabIndex] = useState(1);
    const [showArrow, setShowArrow] = useState(0);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const MF_COLUMNS = [
        {
            name: 'Funds',
            selector: row => row['title'],
            sortable: true,
            width: "400px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name">
                    <img src={row['icon']} alt={row['title']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray" />
                    <div>
                        <div className="d-flex gap-8px mb-2">
                            {
                                row['category'].map((item, key) =>
                                    <span key={key}
                                        className="border-radius-16px border-all border-bright-gray py-1 padding-6px-lr color-black e-poppins-medium e-font-10 line-height-18px">{item}</span>
                                )
                            }
                        </div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0 bg-white">{row['title']}</p>
                    </div>

                </div>
        },
        {
            name: 'Invested amount',
            selector: row => row['invested_amount'],
            sortable: true,
            // width: "150px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                >
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current amount',
            selector: row => row['current_amount'],
            sortable: true,
            // width: "150px",
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0"
                >
                    <span className="e-inter-medium ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Total units',
            selector: row => row['total_units'],
            sortable: true,
            // width: "150px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0">{row['total_units']}</p>

        },
        {
            name: 'P&L',
            selector: row => row['p_l'],
            sortable: true,
            // width: "110px",
            cell: row =>
                <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0">{row['p_l']}</p>

        },
        {
            name: '',
            width: "24px",
            cell: row =>

                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Color.black} />
                </div>

        }

    ];

    const LIFE_GOALS_COLUMN = [
        {
            name: 'Life goals',
            selector: row => row['title'],
            sortable: false,
            left: true,
            // width: "576px",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name">
                    <img src={row['icon']} alt={row['title']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray" />
                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{row['title']}</p>
                </div>
        },
        {
            name: 'Type',
            selector: row => row['type'],
            sortable: false,
            left: true,
            width: "90px",
            style: {
                color: Color.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: "16px",
                lineHeight: "18px",
            }
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0">
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Status',
            selector: row => row['status'],
            sortable: false,
            left: true,
            width: "110px",
            cell: row =>
                row['status'] === "Processing" ?
                    <div className="d-flex align-items-center justify-content-center">
                        <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                            {row['status']}
                        </p>
                        <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                            width={24}
                            height={24}
                            draggable={false} />
                    </div>
                    :

                    row['status'] === "Success" ?
                        <div className="d-flex align-items-center justify-content-center ">
                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                {row['status']}
                            </p>
                            <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                width={24}
                                height={24}
                                draggable={false} />
                        </div>
                        :
                        <div className="d-flex align-items-center justify-content-center">
                            <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                {row['status']}
                            </p>
                            <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                width={24}
                                height={24}
                                draggable={false} />
                        </div>
        },
        {
            name: 'Order date',
            selector: row => row['order_date'],
            sortable: false,
            left: true,
            width: "100px",
            center: true,
            style: {
                color: Color.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }

        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "24px",
            cell: row =>

                <div className={`e-fund-order-hover text-start ${showArrow === row.id ? 'show' : ''}`}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Color.black} />
                </div>

        }

    ];

    function _handleAccordionClick(index) {
        setActiveIndex(index);
    }

    // handler for tab change
    const _handleTabSelect = (index) => {
        console.log("Index", index)
        setTabIndex(index);
    }

    const _handleRowHover = (row) => {
        console.log("rpw", row)
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const mf_customs_styles = {
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        rows: {
            style: {
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
            }
        }
    };

    const lf_custom_styles = {
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
            }
        }
    };


    const BannerInfo = (props) => {
        return (
            <div className="d-flex align-items-center">
                <p className="color-gray e-poppins-medium e-font-14 line-height-16px mb-0 me-1">{props.heading}: </p>
                <p className="color-black e-poppins-medium e-font-14 line-height-14px mb-0">{props.description}</p>
            </div>
        )
    }
    return (
        <div className="e-family-accounts e-page-container margin-72px-bottom">
            <InvestmentHeader />
            <BreadCrumb data={FAMILY_NAVIGATION} />
            <p className="color-black e-poppins-medium e-font-20 line-height-32px my-4">Family details</p>
            <div className="border-radius-24px border-all border-bright-gray bg-light-pastel-blue-gray p-4 d-flex align-items-center gap-28px">
                <img src={require("../../Assets/Images/profile/profile-pic.png")}
                    alt="Profile pic"
                    width={62}
                    height={62} />
                <div>
                    <p className="color-black e-poppins-semi-bold e-font-16 line-height-16px mb-3">Helen Wick</p>
                    <div className="d-flex gap-16px flex-wrap">
                        <BannerInfo heading="Client ID" description="98908324" />
                        <BannerInfo heading="Email" description="wickhelen@gmail.com" />
                        <BannerInfo heading="Phone" description="XXXXXX7890" />
                        <BannerInfo heading="Added on" description="24 Jun 2022" />
                    </div>
                </div>

            </div>
            <div className="row mt-4">
                <div className="col-xl-7">
                    <div className="border-radius-24px bg-light-sky-blue p-4 h-100">
                        <p className="color-black e-poppins-semi-bold e-font-16 mb-4">Investment summary</p>
                        <div className="row">
                            <div className="col-6">
                                <div className="border-radius-16px bg-white position-relative p-3">
                                    <p className="color-black e-poppins-regular e-font-14 mb-3">Invested amount</p>
                                    <Icon icon="invested-icon"
                                        size={24}
                                        className="position-absolute e-icon" />
                                    <p className="color-black e-poppins-semi-bold e-font-18 line-height-20px mb-0"><span className="e-inter-semi-bold">₹</span>50,00,000.00</p>

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="border-radius-16px bg-white position-relative p-3">
                                    <p className="color-black e-poppins-regular e-font-14 mb-3">Current value</p>
                                    <Icon icon="invested-icon"
                                        size={24}
                                        className="position-absolute e-icon" />
                                    <p className="color-black e-poppins-semi-bold e-font-18 line-height-20px mb-0"><span className="e-inter-semi-bold">₹</span>50,00,000.00</p>

                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="border-radius-16px bg-white p-3 ">
                                    <div className="row">
                                        <div className="col-6 border-right-1px">
                                            <div className="position-relative">
                                                <p className="color-black e-poppins-regular e-font-14 mb-3">Returns </p>
                                                <img src={require("../../Assets/Images/profile/total-returns.svg").default}
                                                    alt="Total returns"
                                                    width={24}
                                                    height={24}
                                                    className="position-absolute e-returns " />
                                                <div className="d-flex">
                                                    <p className="color-black e-poppins-semi-bold e-font-18 line-height-20px mb-0 me-1">
                                                        <span className="e-inter-semi-bold">₹</span>
                                                        50,00,000.00
                                                    </p>
                                                    <div className="d-flex align-items-center">
                                                        <Icon icon="green-up-arrow"
                                                            width={15.5}
                                                            height={7.5} />
                                                        <p className="color-sea-green e-poppins-regular e-font-14 mb-0">35%</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-6 ps-4">
                                            <div className="position-relative">
                                                <p className="color-black e-poppins-regular e-font-14 mb-3">XIRR </p>
                                                <img src={require("../../Assets/Images/profile/xirr.svg").default}
                                                    alt="XIRR"
                                                    width={24}
                                                    height={24}
                                                    className="position-absolute e-returns" />
                                                <p className={`${18.24 < 0 ? "color-red" : "color-sea-green"} e-poppins-semi-bold e-font-18 line-height-20px mb-0`}>18.24%</p>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-5">
                    <div className="border-radius-16px bg-pale-cream py-3 px-4">
                        <p className="color-black e-poppins-semi-bold e-font-16 line-height-20px mb-3">Investment breakdown</p>
                        <div className="border-radius-24px bg-white py-3 px-2">
                            <div class="accordion accordion-flush" id="investment-breakdown">
                                {
                                    INVESTMENT_BREAKDOWN.map((item, key) =>
                                        <div class={`accordion-item e-investment-breakdown border-0`} key={key}>
                                            <h2 class="border-radius-8px bg-off-white-color d-flex justify-content-between align-items-center p-2 mb-1" id={`heading-${key}`}
                                            >
                                                <button class={`accordion-button collapsed e-poppins-medium e-font-14 line-height-16px p-0 e-heading`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse-${key}`}
                                                    aria-expanded={activeIndex === key ? true : false}
                                                    aria-controls={`collapse-${key}`}
                                                    onClick={() => _handleAccordionClick(key)}>
                                                    {item.heading}
                                                </button>
                                                <Icon icon="calender-arrow-right"
                                                    size={16}
                                                    color={activeIndex === key ? Color.primary : Color.black}
                                                    className={activeIndex === key ? "e-rotate-left-90" : "e-rotate-right-90"}
                                                />
                                            </h2>
                                            <div id={`collapse-${key}`} class={`accordion-collapse collapse ${activeIndex === key && "show"}`} aria-labelledby={`heading-${key}`} data-bs-parent="#investment-breakdown">

                                                <div className="row">
                                                    {
                                                        item.hasOwnProperty('active_sip') &&
                                                        <div className="col-4 mt-2">
                                                            <div className="p-2">
                                                                <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Active SIP</p>
                                                                <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{item.active_sip}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-4 mt-2">
                                                        <div className="p-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Invested</p>
                                                            <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{item.invested.toLocaleString("en-IN")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 mt-2">
                                                        <div className="p-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Current</p>
                                                            <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{item.current.toLocaleString("en-IN")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 mt-2">
                                                        <div className="p-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Returns</p>
                                                            <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0"><span className="e-inter-medium">₹</span>{item.return.toLocaleString("en-IN")}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 mt-2">
                                                        <div className="p-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">Return %</p>
                                                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-14px mb-0">{item.returns}%</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 mt-2">
                                                        <div className="p-2">
                                                            <p className="color-outer-space e-poppins-medium e-font-14 line-height-14px mb-2">XIRR</p>
                                                            <p className={`${item.xirr < 0 ? "color-red" : "color-sea-green"} e-poppins-medium e-font-14 line-height-14px mb-0`}>{item.xirr}%</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <p className="color-jett-black e-poppins-medium e-font-16 line-height-24px mb-3 margin-32px-top">
                Your Investments
            </p>
            <CustomTab data={TAB_DATA}
                wrapperClass={'gap-16px'}
                className="px-1 padding-10px-tb e-order-tab mb-4"
                onSelectTab={_handleTabSelect} />
            {
                tabIndex === 1 ?
                    <DataTable columns={MF_COLUMNS}
                        data={MF_GOALS}
                        key="Mutual funds"
                        pagination={false}
                        customStyles={mf_customs_styles}
                        onRowMouseEnter={_handleRowHover}
                        onRowMouseLeave={_handleRowRemoveHover} />
                    :
                    <DataTable columns={LIFE_GOALS_COLUMN}
                        data={LIFE_GOALS}
                        key="Life goals"
                        pagination={false}
                        customStyles={lf_custom_styles}
                        onRowMouseEnter={_handleRowHover}
                        onRowMouseLeave={_handleRowRemoveHover} />
            }
        </div>
    )
}

export default RelatedAccount