/*
 *   File :  create-bank.js
 *   Author URI : https://evoqins.com
 *   Description : Page to add bank details
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';

import Icon from '../../../Component/Icon/icon';
import { GradientButton } from '../../../Component/CTA';
import { AddBankModal } from '../../../Component/Modal';
import APIService from '../../../Service/api-service';
import { _getKycProfile } from '../../../Helper/api';
import { useSelector } from 'react-redux';

const CreateBank = (props) => {

    const BANK_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA);
    const [verifyUsingUPI, setVerifyUsingUPI] = useState(true); // decider for UPI or bank verification
    const [openAddBankModal, setOpenAddBankModal] = useState(false);
    const [upiResponse, setUpiResponse] = useState({});
    const [apiLoader, setApiLoader] = useState(false);

    // listener for open add bank modal
    useEffect(() => {
        if (openAddBankModal === true) {
            const modal = new Modal(document.getElementById("add-bank"), {});
            modal.show();
        }
    }, [openAddBankModal]);

    // API - add bank using upi
    function _addBankUsingUpi() {
        let url = 'kyc/add-bank';
        let payload = JSON.stringify({
            is_default: true,
            is_manual: !verifyUsingUPI,
            primary: true
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setUpiResponse(response.data);
                setOpenAddBankModal(true)
            }else{
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    }

    const _handleButtonClick = () => {
        setApiLoader(true)
        switch (verifyUsingUPI) {
            case true:
                _addBankUsingUpi();
                break;
            case false:
                setOpenAddBankModal(true);
                break;
        }
    }

    // API :: get kyc progress
    const _getKYCProgress = async () => {
        try {
            const kyc_data = await _getKycProfile();
            props.updateProgress(7)
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    };

    // component for Account linking type card
    function LinkBankTypeCard(props) {

        const { title, desc, upi, recommended } = props;
        return (
            <div className={`${props.className}`}
                onClick={() => props.handleClick()}>

                {/* Check icon */}
                <Icon icon={props.icon}
                    size={22}
                    className={`position-absolute cursor-pointer right-10px`} />

                <div className="row">
                    <div className="col-12 d-flex">
                        {/* Card title */}
                        <h4 className="color-black e-poppins-semi-bold e-font-16 margin-block-end-0 line-height-normal me-2">
                            {title}
                        </h4>
                        {/* UPI icon */}
                        <img src={props.image}
                            alt=""
                            draggable={false}
                            className={`me-2`} />
                        {/* Recommended badge */}
                        {
                            recommended === true && (
                                <span className={`border-radius-8px bg-bubble-light color-primary-color e-poppins-regular e-font-10 outline-1px-solid h-max-content padding-2px-tb padding-4px-lr`}>
                                    Recommended
                                </span>
                            )
                        }
                    </div>
                    {/* description */}
                    <div className="col-12 text-start">
                        <p className="e-inter-regular e-font-14 color-outer-space margin-block-end-0 mt-2">{desc}</p>
                    </div>
                </div>
            </div>
        )

    };

    const LabelAnswer = (props) => {
        return (
            <Fragment>
                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-16px mb-2">{props.label}</p>
                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px mb-0">{props.answer}</p>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-sm-2 mb-0">Add your bank account</p>

            <div className="row mt-3">
                <div className="col-lg-7 col-md-7 col-12">
                    <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">
                        Verify and register your account with mt wealth. As per regulatory requirements you can invest through a registered bank account only.
                    </p>
                </div>
                {
                    BANK_DATA.kyc_step >= 5 &&
                    <Fragment>
                        <div className="col-lg-7 col-md-7 col-12 mt-sm-4 mt-0">
                            {/* Bank details*/}
                            <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-3">
                                <p className='color-jett-black e-poppins-medium e-font-sm-16-14 mb-3'>Bank details</p>
                                <div className="row">
                                <div className="col-6">
                                        <LabelAnswer label="Account holder name"
                                            answer={BANK_DATA.bank_details.account_holder_name} />
                                    </div>
                                    <div className="col-6">
                                        <LabelAnswer label="Bank name"
                                            answer={BANK_DATA.bank_details.bank_name} />
                                    </div>
                                    <div className="col-6 mt-3">
                                        <LabelAnswer label="Account number"
                                            answer={BANK_DATA.bank_details.account_number} />
                                    </div>
                                    <div className="col-6 mt-3">
                                        <LabelAnswer label="Account type"
                                            answer={BANK_DATA.bank_details.account_type_text} />
                                    </div>
                                    <div className="col-6 mt-3">
                                        <LabelAnswer label="IFSC code"
                                            answer={BANK_DATA.bank_details.ifsc_code} />
                                    </div>
                                </div>

                            </div>
                            <p className='color-black e-poppins-semi-bold e-font-sm-16-14 mb-0 mt-3'>Would you like to update your banking information?</p>
                        </div>
                    </Fragment>
                }
                {/* Verify using UPI card */}
                <div className="col-lg-7 col-md-7 col-12 mt-sm-4 mt-2">
                    <LinkBankTypeCard title="Verify with"
                        desc="We’ll debit ₹1 from your account to verify the details. we’ll refund this once the verification is complete."
                        upi={true}
                        recommended={true}
                        className={`${verifyUsingUPI === true ? 'border-pearl-aqua bg-frosty-cyan' : 'border-bright-gray'} p-3 border-all border-radius-16px position-relative cursor-pointer `}
                        icon={verifyUsingUPI === true ? "radio-selected" : "radio-unselected"}
                        image={require('../../../Assets/Images/profile/upi_logo.svg').default}
                        handleClick={() => setVerifyUsingUPI(true)} />
                </div>

                {/* Manual bank verification card */}
                <div className="col-lg-7 col-md-7 col-12">
                    <LinkBankTypeCard title="Add details manually"
                        desc="Add your account number, IFSC code manually."
                        upi={false}
                        recommended={false}
                        className={`${verifyUsingUPI === false ? 'border-pearl-aqua bg-frosty-cyan' : 'border-bright-gray'} p-3 border-all border-radius-16px position-relative cursor-pointer mt-3`}
                        icon={verifyUsingUPI === false ? "radio-selected" : "radio-unselected"}
                        handleClick={() => setVerifyUsingUPI(false)} />
                </div>
            </div>
            <GradientButton label={verifyUsingUPI === true ? "Continue securely with UPI" : "Continue"}
                loading={apiLoader}
                className="px-4 padding-12px-tb margin-40px-top mob-w-100 mb-sm-0 mb-3"
                handleClick={_handleButtonClick} />

            {
                openAddBankModal === true && (
                    <AddBankModal kycFlow={true}
                        verifyUsingUPI={verifyUsingUPI}
                        upiResponse={upiResponse}
                        numberOfBank={0} // Making toggle primary bank account as disable 
                        updateBank={_getKYCProgress}
                        close={() => {
                            setApiLoader(false)
                            setOpenAddBankModal(false)
                        }} />
                )
            }
        </Fragment>
    )
}

export default CreateBank