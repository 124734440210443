
/*
 *   File : create-account-banner.js
 *   Author URI : https://evoqins.com
 *   Description : Banner for account creation
 *   Integrations : null
 *   Version : v1.1
 */
import { useNavigate } from "react-router-dom";
import { GradientButton } from "../CTA";

import Color from "../../Styles/color.module.scss";
import { useSelector } from "react-redux";

export default function CreateAccountBanner(props) {

    const KYC_DONE = useSelector(state => state.generalData.PROFILE_DATA);
    const KYC_STEP = useSelector(state => state.generalData.KYC_PROGRESS_DATA);
    const navigator = useNavigate();

    function _handleNavigator() {
        navigator('/kyc');
    }


    const KycBanner = (props) => {
        return (
            <div className='padding-40px-tb padding-40px-left border-radius-20px position-relative padding-40px-right-mob'
                style={{ backgroundColor: props.backgroundColor }}>
                <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-7 col-12">
                        <p className='color-black e-inter-semi-bold e-font-32 margin-12px-bottom e-kyc-title'>{props.heading}</p>
                        <p className='color-black e-poppins-medium e-font-16 line-height-22px mb-4'>{props.description}</p>
                        <GradientButton label={props.label}
                            className="padding-12px-tb px-3 e-invest-btn-w-100"
                            handleClick={_handleNavigator} />
                    </div>
                </div>

                <img src={props.image}
                    alt={props.label}
                    height={224}
                    draggable={false}
                    className='object-fit-contain position-absolute e-kyc-decision-banner' />

            </div>
        )
    }

    return (

        KYC_DONE.is_kyc_done === true ?
            null :
            KYC_STEP.kyc_step === 0 ?
                <div className="bg-lemon-chiffon p-4 border-radius-16px">
                    <div className="row">
                        <div className="col-8">
                            <h6 className="color-black e-font-20 e-inter-semi-bold">
                                It seems like you don't have an investment account yet!
                            </h6>
                            <p className="color-outer-space e-font-14 e-poppins-medium ">
                                Open one now to start your investment journey.
                            </p>
                            <GradientButton label="Open an account"
                                className='padding-14px-tb padding-24px-lr'
                                handleClick={_handleNavigator} />
                        </div>
                        <div className="col-4">
                            <img src={require('../../Assets/Images/dashboard/create-ac-banner.png')}
                                alt=""
                                width={132}
                                height={122}
                                className="w-100 object-fit-contain" />
                        </div>
                    </div>
                </div>
                :
                KYC_STEP.kyc_step < 9 ?
                    <KycBanner heading={KYC_STEP.kyc_step === 0 ? "Start your KYC documentation today" : "Complete your KYC documentation today"}
                        description={KYC_STEP.kyc_step === 0 ? "Begin your KYC creation with a PAN number check." : "You are 1 step away from completing your KYC details. Verify your identity for a safer experience."}
                        label={KYC_STEP.kyc_step === 0 ? "Create an account" : "Resume now"}
                        backgroundColor={Color.pastel_blue}
                        image={require("../../Assets/Images/kyc/complete-kyc.png")} />
                    : KYC_STEP.kyc_step === 9 ?
                        <KycBanner heading="Your KYC under verification"
                            description="Currently undergoing KYC verification. Stay tuned for updates on the status."
                            label="View KYC"
                            backgroundColor={Color.pastel_blue}
                            image={require("../../Assets/Images/kyc/complete-kyc.png")} />
                        :
                        <KycBanner heading="KYC updation failed"
                            description="Due to some technical errors your KYC couldn't complete please update your KYC for better investing experience. "
                            label="Update KYC"
                            backgroundColor={Color.light_cream}
                            image={require("../../Assets/Images/kyc/update-kyc.png")} />
    )
}