/*
 *   File : secondary.js
 *   Author URI : https://evoqins.com
 *   Description : Secondary button for site
 *   Integrations : null
 *   Version : v1.1
 */


import style from "../../Styles/Component/secondary-btn.module.scss";

const SecondaryButton = (props) => {

    return (
        // Secondary button
        <button className={props.disabled === false ?
            `${style.e_active_btn} ${props.className} line-height-24px border-all border-primary-color e-primary-font-medium padding-12px-tb padding-24px-lr bg-white color-black text-center border-radius-8px no-border cursor-pointer`
            : `${style.e_inactive_btn} ${props.className} line-height-24px border-all border-primary-color e-primary-font-medium padding-12px-tb padding-24px-lr bg-white color-black text-center border-radius-8px no-border`}
            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.handleClick}>
            <span>{props.label ? props.label : "Submit"}</span>
        </button>
    );
};

SecondaryButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    handleClick: () => { }
}

export default SecondaryButton;
