
/*
 *   File : explore-filter.js
 *   Author : https://evoqins.com
 *   Description : UI element which is used to do the fund filter.
 *   Integrations : null
 *   Version : 1.0.0
*/

import { useState, useRef, useEffect, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckBoxGroup, RadioGroup } from "../Form-elements";
import Icon from "../Icon/icon"
import { GradientButton, OutlineButton } from "../CTA";
import Store from "../../Store";


export default memo(ExploreFilterPopup);
function ExploreFilterPopup(props) {

    const location = useLocation();
    const navigator = useNavigate();
    // state variables to store data
    const filterRef = useRef(null);
    const [miaData, setMiaData] = useState([]);
    const [selectedMiaTypes, setSelectedMiaTypes] = useState(props.investmentAmount);
    const [fundCategory, setFundCategory] = useState([]);
    const [selectedFundCategory, setSelectedFundCategory] = useState(props.fundCategory);
    const [fundType, setFundType] = useState([]);
    const [selectedFundType, setSelectedFundType] = useState(props.fundType);
    const [amc, setAmc] = useState([]);
    const [selectedAmc, setSelectedAmc] = useState(props.amc);
    const [theme, setTheme] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(props.theme);
    const [rating, setRating] = useState([]);
    const [selectedRating, setSelectedRating] = useState(props.rating);
    const [filterBy, setFilterBy] = useState([""]);
    const [selectedFilterBy, setSelectedFilterBy] = useState(props.filterBy);
    const [amcSearch, setAmcSearch] = useState("");
    const [themeSearch, setThemeSearch] = useState("");
    const [amcMatchingItems, setAmcMatchingItems] = useState([]);
    const [themeMatchingItems, setThemeMatchingItems] = useState([]);
    const [clearFilter, setClearFilter] = useState(false);

    console.log('props.', props.amc);
    // listener for outside click
    useEffect(() => {
        document.addEventListener("mousedown", closeDropdownOnClickOutside);

        return () => {
            document.removeEventListener("mousedown", closeDropdownOnClickOutside);
        };
    }, []);

    // updating data from props

    useEffect(() => {
        const minimum_investment = [{ id: null, label: "All" }, ...props.data.min_investment.map(item => ({
            id: item.id,
            label: item.name
        }))];

        const fund_category = [{ id: null, name: "All" }, ...props.data.category.map(item => ({
            id: item.id,
            name: item.name
        }))];

        const fund_type = [{ id: null, name: "All" }, ...props.data.type.map(item => ({
            id: item.id,
            name: item.name
        }))];

        const amc = [{ id: null, name: "All" }, ...props.data.amc.map(item => ({
            id: item.id,
            name: item.name
        }))];

        const theme_option = [{ id: null, name: "All" }, ...props.data.theme.map(item => ({
            id: item.id,
            name: item.name
        }))];

        const rating = [{ id: null, name: "All" }, ...props.data.rank.map(item => ({
            id: item.id,
            name: item.name
        }))];

        const filter_by = [{ id: null, label: "All" }, ...props.data.filter_by.map(item => ({
            id: item.id,
            label: item.name
        }))];

        setMiaData(minimum_investment);
        setFundCategory(fund_category);
        setFundType(fund_type);
        setRating(rating);
        setAmc(amc);
        setTheme(theme_option);
        setFilterBy(filter_by);


        if (props.fundCategory.includes(null)) {
            setSelectedFundCategory(fund_category.map(item => item.id))
        }
        if (props.fundType.includes(null)) {
            setSelectedFundType(fund_type.map(item => item.id))
        }
        if (props.amc.includes(null)) {
            setSelectedAmc(amc.map(item => item.id))
        }
        if (props.theme.includes(null)) {
            setSelectedTheme(theme_option.map(item => item.id))
        }
        // if (props.rating.includes(null)) {
        // setSelectedRating(rating)
        // }

    }, []);



    const closeDropdownOnClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            props.close();
        }
    };

    // handler for all checkboxes
    const _handlerCheckBoxIcon = (type, selected_item) => {
        console.log('location', location.state);
        switch (type) {
            case 1:
                setSelectedMiaTypes(selected_item);
                break;
            case 2:
                _manageCheckBox(selectedFundCategory, selected_item, type, fundCategory);
                break;
            case 3:
                _manageCheckBox(selectedFundType, selected_item, type, fundType);
                break;
            case 4:
                _manageCheckBox(selectedAmc, selected_item, type, amc);
                setAmcSearch('');
                break;
            case 5:
                _manageCheckBox(selectedTheme, selected_item, type, theme);
                setThemeSearch('');
                break;
            case 6:
                setSelectedRating(selected_item);
                // _manageCheckBox(selectedRating, selected_item, type);
                break;
            case 7:
                setSelectedFilterBy(selected_item);
                let state = location.state;
                if (state !== null && state.filter_by !== null) {
                    state.filter_by = selected_item;
                    state.filter_by_tag = "";
                    location.state = { ...state };
                }
                break;
        }
    }

    // helper function for managing the filtered data
    const _manageCheckBox = (array_to_be_modified, selected_filter_type, type, filterData) => {
        let filter_type = [...array_to_be_modified];

        console.log('location', location.state);

        if (selected_filter_type === null) {
            if (filter_type.length === filterData.length) {
                filter_type = [];
            } else {
                filter_type = filterData.map(item => item.id);
            }
        } else {
            if (filter_type.includes(selected_filter_type)) {
                let selected_type_index = filter_type.findIndex(item => item === selected_filter_type);
                if (selected_type_index !== -1) {
                    filter_type.splice(selected_type_index, 1);
                    filter_type = filter_type.filter(item => item !== null);
                }
            } else {
                if (filter_type.length === filterData.length - 2 && !filter_type.includes(null)) {

                    filter_type = filterData.map(item => item.id);
                } else {
                    filter_type.push(selected_filter_type);
                }

            }

        }
        switch (type) {
            case 2: setSelectedFundCategory(filter_type);
                let state = location.state;
                if (state !== null) {
                    state.fund_category = filter_type;
                    state.category_name = "";
                    location.state = { ...state };
                }
                break;
            case 3: setSelectedFundType(filter_type);
                break;
            case 4: setSelectedAmc(filter_type);
                break;
            case 5: setSelectedTheme(filter_type);
                break;
            default:
                break;
        }

    };




    const _handleClose = (status) => {
        if (clearFilter === true) {
            Store.dispatch({
                type: "SELECTED_MF_FILTERS",
                payload: { 
                    filter_enabled: false,
                    funds: [],
                }
            });
        } 
        props.applyFilter(selectedMiaTypes, selectedFundCategory, selectedFundType, selectedAmc, selectedTheme, selectedRating, selectedFilterBy, status);
    }

    // search functions for amc and theme
    const _searchItems = (arrayToMatchFrom, value) => {
        const lower_case = value.toLowerCase();
        const matching_items = arrayToMatchFrom.filter(item => {
            return item.name.toLowerCase().includes(lower_case);
        });
        return matching_items;
    }

    // search handler for amc and theme
    const _handleSearch = (type, e) => {
        switch (type) {
            case 1:
                setAmcSearch(e.target.value);
                if (e.target.value.length !== 0) {
                    setAmcMatchingItems(_searchItems(amc, e.target.value));
                }
                else {
                    setAmcMatchingItems([]);
                }
                break;
            case 2:
                setThemeSearch(e.target.value);
                if (e.target.value.length !== 0) {
                    setThemeMatchingItems(_searchItems(theme, e.target.value));
                }
                else {
                    setThemeMatchingItems([]);
                }
                break;
        }

    }

    // clearing all filter
    const _handleClear = () => {
        setSelectedMiaTypes(null);
        setSelectedFundCategory(fundCategory.map(item => item.id));
        setSelectedFundType(fundType.map(item => item.id));
        setSelectedAmc(amc.map(item => item.id));
        setSelectedTheme(theme.map(item => item.id));
        setSelectedRating(null);
        setSelectedFilterBy(null);
        let state = location.state;
        state = {
            amc: [null],
            rating: null,
            fund_category: [null],
            fund_type: [null],
            theme: [null],
            minimum_investment: null,
            search_text: "",
            filter_by: null,
            filter_by_tag: "",
            category_name: "",
            current: location.pathname
        }
        location.state = { ...state };
        setClearFilter(true);
    }


    return (
        <div className="border-radius-16px z-index-12 border-all border-bright-gray bg-white position-absolute right-0 e-explore-filter-popup "
            ref={filterRef}>

            <div className="position-relative">
                {/* close icon */}
                <Icon icon="close"
                    size={24}
                    className="position-absolute e-close cursor-pointer e-modal-close-btn"
                    onClick={() => _handleClose(false)} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-12 padding-24px-all">
                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Investment amounts</p>
                            <div className="row">
                                <div className="col-9">

                                    {/* Investment amount data */}
                                    <RadioGroup data={miaData}
                                        className="gap-12px pb-3 border-bottom-1px border-bright-gray e-investment-amount"
                                        type={1}
                                        containesRupee={true}
                                        id={selectedMiaTypes}
                                        radioHandler={(id) => _handlerCheckBoxIcon(1, id)} />

                                </div>
                            </div>
                            {/* Top amc's data */}
                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2 mt-3">Top AMCs</p>
                            <div className="row">
                                <div className="col-9">
                                    {/* search field for amc */}
                                    <div className="position-relative mb-3">
                                        <input type="search"
                                            value={amcSearch}
                                            placeholder='Search AMC'
                                            className='e-investment-search p-1 ps-2 border-radius-8px border-all border-may-green bg-white w-100 color-outer-space e-poppins-regular e-font-16 line-height-16px'
                                            onChange={(e) => _handleSearch(1, e)} />
                                        <img src={require('../../Assets/Images/dashboard/search.png')}
                                            alt="Search"
                                            width={16}
                                            height={16}
                                            draggable={false}
                                            className='position-absolute e-investment-search-icon' />
                                    </div>
                                </div>
                            </div>

                            {/* amc list */}
                            <div className="d-flex flex-column gap-12px e-top-amc">
                                {/* items matching the amc search fields */}
                                {
                                    amcSearch.length !== 0 ?

                                        amcMatchingItems.length !== 0 ?
                                            <div className="row">
                                                <div className="col-10">
                                                    <div className="border-bottom-1px border-bright-gray d-flex flex-column gap-12px pb-2">
                                                        {
                                                            amcMatchingItems.map((item, key) =>
                                                                <CheckBoxGroup selectedType={selectedAmc}
                                                                    item={item}
                                                                    iconSize={22}
                                                                    key={key}
                                                                    onHandleClick={(id) => _handlerCheckBoxIcon(4, id)} />
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :

                                            // if no items matching the search field
                                            <div className="row">
                                                <div className="col-10">
                                                    <p className="color-gray e-poppins-regular e-font-14 line-height-24px text-center border-bottom-1px border-bright-gray pb-2 mb-0" >No result found</p>
                                                </div>
                                            </div>
                                        :
                                        // showing all data when search field is empty
                                        amc.map((item, key) =>
                                            <CheckBoxGroup selectedType={selectedAmc}
                                                item={item}
                                                iconSize={22}
                                                key={key}
                                                onHandleClick={(id) => _handlerCheckBoxIcon(4, id)} />
                                        )

                                }
                            </div>
                        </div>

                        <div className="col padding-24px-all bg-very-light-gray-md">
                            <div className="bg-transparent">

                                {/* Fund category data */}
                                <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Fund categories</p>
                                <div className="d-flex flex-column gap-12px   e-fund-category">
                                    {
                                        fundCategory.map((item, key) =>
                                            <CheckBoxGroup selectedType={selectedFundCategory}
                                                item={item}
                                                iconSize={22}
                                                key={key}
                                                onHandleClick={(id) => _handlerCheckBoxIcon(2, id)} />
                                        )
                                    }
                                </div>

                                {/* Theme data */}
                                <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2 mt-3 pt-3 border-top-1px border-bright-gray">Theme</p>

                                {/* search fields for theme */}
                                <div className="position-relative mb-3">
                                    <input type="search"
                                        value={themeSearch}
                                        placeholder='Search Theme'
                                        className='e-investment-search p-1 ps-2 border-radius-8px border-all border-may-green bg-white w-100 color-outer-space e-poppins-regular e-font-16 line-height-16px'
                                        onChange={(e) => _handleSearch(2, e)} />
                                    <img src={require('../../Assets/Images/dashboard/search.png')}
                                        alt="Search"
                                        width={16}
                                        height={16}
                                        draggable={false}
                                        className='position-absolute e-investment-search-icon' />

                                </div>
                                <div className="d-flex flex-column gap-12px e-theme">
                                    {/* items matching theme search field */}
                                    {
                                        themeSearch.length !== 0 ?

                                            themeMatchingItems.length !== 0 ?
                                                <div className="row">
                                                    <div className="col-10">
                                                        <div className="border-bottom-1px border-bright-gray d-flex flex-column gap-12px pb-2">
                                                            {
                                                                themeMatchingItems.map((item, key) =>
                                                                    <CheckBoxGroup selectedType={selectedTheme}
                                                                        item={item}
                                                                        iconSize={22}
                                                                        key={key}
                                                                        onHandleClick={(id) => _handlerCheckBoxIcon(5, id)} />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                // if no items matching the search field
                                                <div className="row">
                                                    <div className="col-10">
                                                        <p className="color-gray e-poppins-regular e-font-14 line-height-24px text-center border-bottom-1px border-bright-gray pb-2 mb-0" >No result found</p>
                                                    </div>
                                                </div>

                                            :
                                            // showing all data when search field is empty
                                            theme.map((item, key) =>
                                                <CheckBoxGroup selectedType={selectedTheme}
                                                    item={item}
                                                    iconSize={22}
                                                    key={key}
                                                    onHandleClick={(id) => _handlerCheckBoxIcon(5, id)} />
                                            )

                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col padding-24px-all">
                            {/* fund type data */}
                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Fund type</p>
                            <div className="row">
                                <div className="col-10">

                                    <div className="d-flex flex-column gap-12px pb-3 border-bottom-1px border-bright-gray">
                                        {
                                            fundType.map((item, key) =>
                                                <CheckBoxGroup selectedType={selectedFundType}
                                                    item={item}
                                                    iconSize={22}
                                                    key={key}
                                                    onHandleClick={(id) => _handlerCheckBoxIcon(3, id)} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* rating data */}
                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2 mt-3">Rank(CRISIL)</p>
                            <div className="d-flex flex-wrap gap-8px border-bottom-1px border-bright-gray pb-3 mb-3">
                                {
                                    rating.map((item, key) =>
                                        <div key={key}
                                            className={`d-flex align-items-center py-1 px-2 border-radius-16px border-all border-bright-gray cursor-pointer ${selectedRating === item.id ? 'bg-pearl-aqua border-pearl-aqua' : 'border-bright-gray bg-white'}`}
                                            onClick={() => _handlerCheckBoxIcon(6, item.id)}>
                                            <p className="e-poppins-black e-poppins-medium e-font-12 line-height-18px mb-0">{item.name}&nbsp;</p>
                                            {
                                                item.id !== null &&
                                                <img src={require("../../Assets/Images/dashboard/Star.png")}
                                                    alt="Rating"
                                                    width={16}
                                                    height={16} />
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            {/* Filter by */}
                            <p className="color-charleston-green e-poppins-semi-bold e-font-14 line-height-24px mb-2">Filter by</p>
                            <div className="row">
                                <div className="col-12">
                                    {/* Investment amount data */}
                                    <RadioGroup data={filterBy}
                                        className="gap-12px pb-3 e-investment-amount e-filter-by"
                                        type={1}
                                        id={selectedFilterBy}
                                        radioHandler={(id) => _handlerCheckBoxIcon(7, id)} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* CTA buttons */}
            <div className="d-flex gap-16px justify-content-end m-4 mt-0 pt-4 border-top-1px border-bright-gray">
                <OutlineButton label="Clear filters"
                    className="e-font-16"
                    handleClick={_handleClear} />
                <GradientButton label="Apply filters"
                    className="e-font-16 padding-12px-tb px-3"
                    handleClick={() => _handleClose(true)} />
            </div>

        </div>

    )
}