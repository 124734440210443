/*
 *   File : footer.js
 *   Author URI : https://evoqins.com
 *   Description : A common component to create a platform footer.
 *   Integrations : null
 *   Version : v1.1
 */

import React, { Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Colors from '../../Styles/color.module.scss';

import Icon from '../Icon/icon'

const Footer = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();

    function _redirectToFAQ() {
        let url = process.env.REACT_APP_LANDING_PAGE_URL + '/faqs';
        window.open(url, "_blank", "noreferrer");
    }

    function _redirectToClosure() {
        window.open('https://www.mastertrust.co.in/disclosure', "_blank", "noreferrer")
    }

    function _redirectToCalculator() {
        window.open('https://www.mastertrust.co.in/calculator', "_blank", "noreferrer")
    }
    function _scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Fragment>
            <div className="container-fluid ps-0 d-lg-block d-md-block d-sm-block d-none">
                <div className="row ">
                    <div className="col-12 e-footer bg-ghost-white">
                        <div className="row">
                            <div className='col-12 text-center d-sm-block d-none'>
                                <div className='color-black e-poppins-medium e-font-16 d-flex align-items-center justify-content-center gap-22px'>
                                    <div className='mb-0 border-right-1px-light-silver padding-22px-right '>
                                        <a className='color-black e-poppins-regular e-contact-link e-font-16 line-height-20px mb-0  d-flex align-items-center' href="mailto:helpdesk@mastertrust.co.in">
                                            <Icon icon="mail"
                                                size={18}
                                                className="me-1" /> <span className='pt-1'>helpdesk@mastertrust.co.in</span></a>
                                    </div>
                                    <div>
                                        <a className='color-black e-poppins-regular e-contact-link e-font-16 line-height-20px mb-0  d-flex align-items-center' href="tel:080-69991111">
                                            <Icon icon="phone"
                                                size={18}
                                                className="me-1" />080-69991111</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 px-0 padding-24px-tb">
                                <div className='color-black e-poppins-medium e-font-16 d-flex align-items-center justify-content-center gap-22px'>
                                    <p className='mb-0 border-right-1px-light-silver padding-22px-right cursor-pointer'>
                                        <Link to="/" className='color-black e-poppins-medium e-footer-link' onClick={_scrollToTop}>Home</Link>
                                    </p>
                                    <p className='mb-0 border-right-1px-light-silver padding-22px-right cursor-pointer'>
                                        <Link to="/support" className='color-black e-poppins-medium e-footer-link' >Support</Link>
                                    </p>
                                    <p className='mb-0 cursor-pointer border-right-1px-light-silver padding-22px-right'>
                                        <span onClick={_redirectToFAQ} className='color-black e-poppins-medium e-footer-link'>FAQs</span>
                                    </p>
                                    <p className='mb-0 cursor-pointer border-right-1px-light-silver padding-22px-right'>
                                        <span onClick={_redirectToCalculator} className='color-black e-poppins-medium e-footer-link'>Calculators</span>
                                    </p>
                                    <p className='mb-0 cursor-pointer'>
                                        <span onClick={_redirectToClosure} className='color-black e-poppins-medium e-footer-link'>Disclosure</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 text-center'>
                            <p className='color-silver-sand e-poppins-medium e-font-12'>
                                AMFI Registered Mutual Fund Distributor |  ARN-58711 | Date of initial Registration: 18/03/2008 | Current validity of ARN - 17/03/2025
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="padding-18px-tb padding-12px-lr bg-white w-100 z-index-10 position-fixed bottom-0 d-flex justify-content-around d-lg-none d-md-none d-sm-none">
                <div className="e-mobile-footer text-center cursor-pointer"
                    onClick={() => navigate('/')}>
                    <Icon icon="home"
                        color={location.pathname === '/' ? Colors.primary : Colors.dark_silver}
                        size={24} />
                    <p className={location.pathname === '/' ? "color-primary-color e-poppins-medium e-font-12 line-height-16px mb-0 " : "color-dark-silver e-poppins-medium e-font-12 line-height-16px mb-0 "}>Home</p>
                </div>
                <div className="e-mobile-footer text-center cursor-pointer"
                    onClick={() => navigate('/explore')}>
                    <Icon icon="explore"
                        color={location.pathname.includes('/explore') ? Colors.primary : Colors.dark_silver}
                        size={24} />
                    <p className={location.pathname.includes('/explore') ? "color-primary-color e-poppins-medium e-font-12 line-height-16px mb-0 " : "color-dark-silver e-poppins-medium e-font-12 line-height-16px mb-0 "}>Explore</p>
                </div>
                <div className="e-mobile-footer text-center"
                    onClick={() => navigate('/goal')}>
                    <Icon icon="life-goals"
                        color={location.pathname.includes('/goal') ? Colors.primary : Colors.dark_silver}
                        size={24} />
                    <p className={location.pathname.includes('/goal') ? "color-primary-color e-poppins-medium e-font-12 line-height-16px mb-0 " : "color-dark-silver e-poppins-medium e-font-12 line-height-16px mb-0 "}>Life goals</p>
                </div>
                <div className="e-mobile-footer text-center"
                    onClick={() => navigate('/portfolio')}>
                    <Icon icon="portfolio"
                        color={location.pathname.includes('/portfolio') ? Colors.primary : Colors.dark_silver}
                        size={24} />
                    <p className={location.pathname.includes('/portfolio') ? "color-primary-color e-poppins-medium e-font-12 line-height-16px mb-0 " : "color-dark-silver e-poppins-medium e-font-12 line-height-16px mb-0 "}>Portfolio</p>
                </div>

            </div>


        </Fragment>
    )
})

export default Footer