/*
 *   File :  family-account.js
 *   Author URI : https://evoqins.com
 *   Description : The linked family account and its summary are listed on this page. Customers can send invitations with Master Trust ID. 
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react"
import { Modal } from "bootstrap"

import { GradientButton } from "../../Component/CTA"
import { InviteMemberModal } from "../../Component/Modal"
import { BreadCrumb } from "../../Component/BreadCrumb"
import { InvestmentHeader } from "../../Component/Header"
import { useNavigate } from "react-router-dom"

// Bread crumbs data
const FAMILY_NAVIGATION = [
    {page: 'Home', url: '/'},
    {page: 'Linked accounts', url: null},
]

// linked family account detail
const LINKED_FAMILY = [{
    id: 1,
    image: require("../..//Assets/Images/profile/profile-pic.png"),
    name: "Helen Wick",
    client_id: 98908324,
    active_sip: 3,
    total_invested: 6525000,
    current_value: 6525000,
    return: 6525000,
    returns: 100.03,
    xirr: 90.190
},
{
    id: 2,
    image: require("../..//Assets/Images/profile/profile-pic.png"),
    name: "Helen Wick",
    client_id: 98908324,
    active_sip: 3,
    total_invested: 6525000,
    current_value: 6525000,
    return: 6525000,
    returns: 100.03,
    xirr: 90.190
}]


const FamilyAccounts = () => {

    const navigate = useNavigate();
    const [showInviteModal, setShowInviteModal] = useState(false);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    // listener for opening invite modal
    useEffect(() => {
        if (showInviteModal === true) {
            _openInviteModal();
        }
    }, [showInviteModal]);

    //to open invite modal
    const _openInviteModal = () => {
        const modal = new Modal(document.getElementById("invite-modal"), {});
        modal.show();
    };

    // to show invite modal
    const _showInviteModal = () => {
        setShowInviteModal(true);
    }

    // to close invite modal
    const _handleInvite = (id, pan_number) => {
        setShowInviteModal(false);
        console.log(id, pan_number);
    }

    const _handleNavigate = () => {
        navigate("/related-account")
    }

    // common inline component for  family info
    const FamilyInfo = (props) => {
        return (
            <div className={`p-2 e-family-info ${props.border === true && "border-right-1px"}`}>
                <p className={`color-outer-space margin-10px-bottom e-poppins-medium e-font-14 ${props.type === 1 ? "line-height-16px" : " line-height-14px"}`}>{props.heading}</p>
                <p className={`${props.descriptionColor} ${props.type === 1 ? "e-poppins-semi-bold e-font-16" : "e-poppins-medium e-font-14"}  line-height-16px mb-0`}>
                    {props.rupee === true && <span className="e-inter-medium">₹</span>}
                    {props.description.toLocaleString('en-IN')}
                    {props.percent === true && <span className="e-inter-medium">%</span>}
                </p>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="e-family-accounts e-page-container margin-72px-bottom">
                <InvestmentHeader />
                <BreadCrumb data={FAMILY_NAVIGATION} />
                <p className="color-black e-poppins-medium e-font-20 line-height-32px my-4">Family details</p>

                {/* banner info */}
                <div className="p-3 border-radius-24px border-all border-bright-gray bg-soft-green position-relative">
                    <div className="border-radius-8px d-flex align-items-center gap-6px border-all border-primary-color p-2 position-absolute e-invite-family cursor-pointer"
                        onClick={_showInviteModal}>
                        <img src={require("../../Assets/Images/profile/invite-family.png")}
                            alt="Invite family"
                            width={24}
                            height={24} />
                        <p className="color-primary-color e-poppins-medium e-font-12 mb-0">Invite family member</p>
                    </div>
                    <div className="text-center">
                        <img src={require("../../Assets/Images/profile/family-investment.png")}
                            alt="Family investment"
                            width={72}
                            height={72} />
                        <p className="color-jett-black e-poppins-medium e-font-14 mb-2">Family investments</p>
                    </div>
                    <div className="d-flex gap-36px flex-wrap justify-content-center">
                        <FamilyInfo heading="Members"
                            descriptionColor="color-black"
                            border={true}
                            type={1}
                            description={7} />
                        <FamilyInfo heading="Total invested"
                            descriptionColor="color-black"
                            rupee={true}
                            border={true}
                            type={1}
                            description={6525000} />
                        <FamilyInfo heading="Current value"
                            descriptionColor="color-black"
                            rupee={true}
                            border={true}
                            type={1}
                            description={6525000} />
                        <FamilyInfo heading="Returns"
                            descriptionColor="color-black"
                            rupee={true}
                            border={true}
                            type={1}
                            description={6525000} />
                        <FamilyInfo heading="Returns %"
                            descriptionColor={100.03 < 0 ? "color-red" : "color-sea-green"}
                            percent={true}
                            border={true}
                            type={1}
                            description={100.03} />
                        <FamilyInfo heading="XIRR"
                            descriptionColor={90.190 < 0 ? "color-red" : "color-sea-green"}
                            percent={true}
                            type={1}
                            description={90.190} />
                    </div>
                </div>
                <p className="color-jett-black e-poppins-medium e-font-16 my-4">Linked family accounts</p>
                <div className="d-flex flex-column gap-16px">
                    {/* to show list of accounts which are linked */}
                    {
                        LINKED_FAMILY.map((item, key) =>
                            <div className="border-radius-24px border-all border-bright-gray bg-pure-white p-3" key={key}>
                                <div className="d-flex align-items-center gap-8px">
                                    <img src={item.image}
                                        alt="Profile pic"
                                        width={40}
                                        height={40} />
                                    <div className="d-flex gap-8px">
                                        <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">{item.name}</p>
                                        <p className="color-gray e-poppins-medium e-font-14 line-height-16px mb-0">Client ID: <span className="color-black"></span>{item.client_id}</p>
                                    </div>
                                </div>
                                <div className="d-flex gap-16px flex-wrap align-items-center mt-3">
                                    <FamilyInfo heading="Active SIP"
                                        descriptionColor="color-black"
                                        border={true}
                                        description={item.active_sip} />
                                    <FamilyInfo heading="Total invested"
                                        descriptionColor="color-black"
                                        rupee={true}
                                        border={true}
                                        description={item.total_invested} />
                                    <FamilyInfo heading="Current value"
                                        descriptionColor="color-black"
                                        rupee={true}
                                        border={true}
                                        description={item.current_value} />
                                    <FamilyInfo heading="Returns"
                                        descriptionColor="color-black"
                                        rupee={true}
                                        border={true}
                                        description={item.return} />
                                    <FamilyInfo heading="Returns %"
                                        descriptionColor="color-sea-green"
                                        percent={true}
                                        border={true}
                                        description={item.returns} />
                                    <FamilyInfo heading="XIRR"
                                        descriptionColor={item.xirr < 0 ? "color-red" : "color-sea-green"}
                                        percent={true}
                                        description={item.xirr} />
                                    <GradientButton label="View details"
                                        className="px-3 py-2"
                                        handleClick={_handleNavigate} />
                                </div>


                            </div>
                        )
                    }
                </div>
            </div>
            {/* to show invite modal */}
            {
                showInviteModal === true ?
                    <InviteMemberModal sendInvite={_handleInvite}
                        close={() => setShowInviteModal(false)} />
                    :
                    null
            }
        </Fragment>
    )
}

export default FamilyAccounts