
/*
 *   File : cart.js
 *   Author URI : https://evoqins.com
 *   Description : Button for adding item to cart 
 *   Integrations : null
 *   Version : v1.1
 */
import { Modal } from "bootstrap";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../Store/Action/action";
import { UpdateMfLumpusm } from "../Modal";
import Store from "../../Store";

const CartButton = (props) => {

    const dispatch = useDispatch();
    const CART_DATA = useSelector(state => state.generalData.CART);
    const [showTooltip, setShowTooltip] = useState(null);
    const [fundData, setFundData] = useState(null);

    useEffect(() => {
        if (fundData !== null) {
            var modal = new Modal(document.getElementById("lumpsum-amount"), {})
            modal.show();
        }
    }, [fundData]);


    // updating the price and adding to cart
    function _handleAddToCard(data) {
        // props.data.price = amount;
        // Check if data is already in the cartItems
        // if (!CART_DATA.some(item => item.id === props.data.id && props.data)) {
        //     dispatch(addToCart(props.data));
        // }
        Store.dispatch({
            type: 'ADD_TO_CART',
            payload: data
        });
    }

    function _handleCartButtonClick() {
        setFundData(props.data);
    }


    return (
        <>
            {
                Object.keys(CART_DATA).length !== 0 && CART_DATA.data.some(item => item.mf_id === props.data.id) ?
                    <img src={require("../../Assets/Images/explore/carted.svg").default}
                        alt="Cart"
                        width={40}
                        height={40} />
                    :
                    <div className={`p-2 bg-bubbles border-radius-100 w-unset h-100 cursor-pointer position-relative ${Object.keys(CART_DATA).length !== 0 && CART_DATA.data.some(item => item.mf_id === props.data.id) && 'no-events cursor-default'}`}
                        onClick={_handleCartButtonClick}>
                        <img src={require("../../Assets/Images/explore/cart.png")}
                            alt="Cart"
                            width={24}
                            height={24}
                            onMouseEnter={() => setShowTooltip(props.data.id)}
                            onMouseLeave={() => setShowTooltip(null)} />

                        {
                            showTooltip === props.data.id &&
                            <p className="color-jett-black e-poppins-regular e-font-14 mb-0 border-all border-bright-gray position-absolute p-1 white-space-nowrap bg-white border-radius-8px e-add-to-basket">
                                Add to basket
                            </p>
                        }

                    </div>


            }

            {
                fundData !== null && (
                    <UpdateMfLumpusm data={fundData}
                        updateFund={false}
                        explore={props.explore}
                        handleUpdateAmount={_handleAddToCard}
                        close={() => setFundData(null)} />
                )
            }

        </>
    )
};

export default CartButton;
