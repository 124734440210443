import * as type from '../types';

const initialState = {
    path: '/',
    CART: {},
    PROFILE_DATA: {},
    HEADER_DATA: {}, // Search suggestion and filter data for explore,
    INVESTMENT_STYLES: [],
    NOTIFICATION_COUNT: 0,
    SUPPORT_QUERIES: [],
    MF_FILTERS: {
        filter_enabled: false,
        funds: [],
        page_num: 1
    },
    KYC_GENERAL_DATA: {},
    KYC_PROGRESS: {},
    TOOL_TIP_DATA: {},
    KYC_PROGRESS_DATA: {},
    GENERAL_DATA: {}
};

export default function generalDataReducer(state = initialState, action) {
    switch (action.type) {
        case type.UPDATE_NAVIGATION:
            return {
                ...state,
                path: action.payload
            };
        case 'ADD_TO_CART':
            return {
                ...state,
                CART: action.payload
            }
        case type.UPDATE_CART:
            return {
                ...state,
                CART: action.payload,
            }
        case 'UPDATE_PROFILE_DATA':
            return {
                ...state,
                PROFILE_DATA: action.payload
            }
        case 'UPDATE_SEARCH_HEADER_DATA':
            return {
                ...state,
                HEADER_DATA: action.payload
            }
        case 'MF_RISK_CATEGORIES':
            return {
                ...state,
                INVESTMENT_STYLES: action.payload
            }
        case 'NOTIFICATION_COUNT':
            return {
                ...state,
                NOTIFICATION_COUNT: action.payload
            }
        case 'SELECTED_MF_FILTERS':
            return {
                ...state,
                MF_FILTERS: action.payload
            }
        case 'QUERY_TYPES':
            return {
                ...state,
                SUPPORT_QUERIES: action.payload
            }
        case 'KYC_CATEGORIES':
            return {
                ...state,
                KYC_GENERAL_DATA: action.payload
            }
        case 'KYC_PROGRESS':
            return {
                ...state,
                KYC_PROGRESS_DATA: action.payload
            }
        case 'TOOLTIP_DATA' : 
            return {
                ...state,
                TOOL_TIP_DATA: action.payload
            }
        case 'APP_GENERAL_DATA':
            return {
                ...state,
                GENERAL_DATA: action.payload
            }
        default:
            return state
    }
}