/*
 *   File : payment-status.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for showing payment status. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GradientButton, OutlineButton } from "../CTA";
import Icon from "../Icon/icon";

import style from "../../Styles/Component/cancel-sip.module.scss";

const SUCCESS = {
    title: 'Order successful',
    message: 'Your order has been successfully placed'
};

const FAIL = {
    title: 'Order failed',
    message: 'We regret to inform you that your recent order has failed.'
};

const PAYMENT_PENDING = {
    title : 'Payment pending',
    message: 'Payment is pending. Please approve payment to place order.'
}

const PROCESSING = {
    title: 'Processing',
    message: 'Payment completed. Your order status will reflect after some time.'
}

const MANDATE_SUCCESS = {
    title: 'Mandate creation under process',
    message: 'Please check the status after some time'
}

const MANDATE_FAILED =  {
    title: 'Mandate failed',
    message: 'We regret to inform you that your recent mandate creation has failed.'
}

const SIP_SUCCESS = {
    title: 'SIP created',
    message: 'Your SIP has been successfully created'
}

const SIP_FAILED = {
    title: 'SIP failed',
    message: 'We regret to inform you that your recent SIP has failed'
}

const PaymentStatusModal = (props) => {

    const navigator = useNavigate();
    const [paymentInfo, setPaymentInfo] = useState({title: "", message: ""});
    const [paymentStatus, setPaymentStatus] = useState(1);

    useEffect(() => {
        if (props.message) {
            setPaymentInfo(props.message);
        }
    }, [props.message]);

    useEffect(()=>{
        if (props.type === 1 || props.type ===2) {
            if (props.paymentStatus !== '' && props.status === false) {
                if (props.paymentStatus === 'Payment Pending') {
                    setPaymentInfo(PAYMENT_PENDING);
                    setPaymentStatus(2);
                } else if (props.paymentStatus === 'Processing') {
                    setPaymentInfo(PROCESSING);
                    setPaymentStatus(2);
                } else{
                    setPaymentStatus(3);
                    setPaymentInfo(FAIL);
                }
            }else{
                setPaymentInfo(props.status === true ? SUCCESS :  FAIL);
                setPaymentStatus(props.status === true ? 1 : 3);
            }
        }else if(props.type === 3){
            if (props.status === true) {
                setPaymentInfo(MANDATE_SUCCESS);
                setPaymentStatus(1);
            }else{
                setPaymentInfo(MANDATE_FAILED);
                setPaymentStatus(3);
            }
        }else {
            if (props.status === true) {
                setPaymentInfo(SIP_SUCCESS);
                setPaymentStatus(1);
            }else{
                setPaymentInfo(SIP_FAILED);
                setPaymentStatus(3);
            }
        }
        
    },[props.paymentStatus, props.status]);

    useEffect(() => {
        var my_modal = document.getElementById("payment-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const _handleNavigate = (type) => {
        switch (type) {
            case 1: navigator('/portfolio');
                break;
            case 2: navigator('/orders', { state: { order_type: props.type, filter_type: props.transactionType !== null ? props.transactionType : null } });
                break;
            case 4 : navigator('/sip');
            default: return;
        }
    }

    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="payment-status"
            tabIndex="-1"
            aria-labelledby="payment-status"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-lr pb-3 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="text-center">
                        <img src={paymentStatus === 1 ? props.type === 3 ? require('../../Assets/Images/orders/payment-process.svg').default : require('../../Assets/Images/orders/order-success.svg').default : paymentStatus === 2 ? require('../../Assets/Images/orders/payment-process.svg').default : require('../../Assets/Images/orders/order-failed.svg').default}
                            alt="Pause SIP"
                            width={72}
                            height={72} />
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5 className="color-eerie-black e-poppins-medium e-font-18 mt-5">
                                {paymentInfo?.title}
                            </h5>
                            <p className="color-outer-space e-poppins-regular e-font-14 mb-0  text-center">
                                {paymentInfo?.message}
                            </p>
                        </div>
                        <div className="mt-4 pt-3 d-sm-flex d-block justify-content-center gap-8px mb-3">
                            <OutlineButton label="View portfolio"
                                cancel="modal"
                                className="e-font-16 e-poppins-regular color-black px-4 padding-12px-tb w-mobile-100"
                                handleClick={() => _handleNavigate(1)} />

                            <GradientButton label={props.type === 3 ? "View mandate" : props.type === 5 ? "View SIP" : "View orders"}
                                cancel="modal"
                                className="e-font-16 padding-70px-lr padding-12px-tb w-mobile-100 mt-sm-0 mt-3" 
                                handleClick={() => { props.type === 3 ? _handleNavigate(3) : props.type === 5 ?_handleNavigate(4) :  _handleNavigate(2) }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

PaymentStatusModal.defaultProps = {
    type: 1,
    close: ()=>{},
    paymentStatus : "",
    transactionType: null
}

export default PaymentStatusModal