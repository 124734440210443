/*
 *   File : forgot-pin.js
 *   Author URI : https://evoqins.com
 *   Description : Screen which allows the customer to update PIN from the "Investment platform
 *   Integrations : react-toastify
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { toast } from 'react-toastify';

import { useKeyHandler } from "../../Helper";
import Icon from "../Icon/icon";
import { GradientButton } from "../CTA";
import APIService from "../../Service/api-service";
import { OTPInput } from "../Form-elements";
import { _getForgotPINOTP } from '../../Helper/api';
import style from "../../Styles/Component/forgot-pin.module.scss"
import Color from "../../Styles/color.module.scss"


const ForgotPINModal = (props) => {

    const [otpString, setOtpString] = useState("");
    const [otpError, setOtpError] = useState("");
    const [loader, setLoader] = useState(false);
    const [otpScreen, setOtpScreen] = useState(true);
    const [newPinScreen, setNewPinScreen] = useState(null);
    const [seconds, setSeconds] = useState(30);
    const [newPin, setNewPin] = useState("");
    const [confirmPinString, setConfirmPinString] = useState("");
    const [validationError, setValidationError] = useState("");
    const [tempToken, setTempToken] = useState("");
    const [pinSuccessScreen, setPinSuccessScreen] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("forgot-pin");
        my_modal.addEventListener("hidden.bs.modal", () => {
            document.body.style.overflow = 'auto';
        });
    }, []);


    useEffect(() => {
        if (otpScreen === true) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, otpScreen]);

    useKeyHandler('forgot-pin', 'a', (event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault();
        }
    });

    const _handleBackToUnlockModal = () => {
        props.close();
    }

    const _handleChange = (type, updated_pin) => {
        switch (type) {
            case 1:
                setOtpString(updated_pin);
                setOtpError("");
                break;
            case 2:
                setNewPin(updated_pin);
                setValidationError("");
                break;
            case 3:
                setConfirmPinString(updated_pin);
                setValidationError("");
                break;
        }
    };

    function _validatePIN() {
        if (newPin.length != 4) {
            setValidationError('4 digit PIN required');
        } else if (confirmPinString.length != 4) {
            setValidationError('4 digit Confirm PIN required');
        } else if (newPin === confirmPinString) {
            _pinHandler();
            setLoader(true);
        } else {
            setValidationError('Both PIN must be identical');
        }
    }

    const _validateOTP = () => {
        let valid = true;
        if (otpString.length < 4) {
            setOtpError("Enter OTP");
            valid = false;
        }
        if (valid === true) {
            _verifyOtp()
            setLoader(true);
        }
    }

    const _handleGoBack = (type) => {
        setOtpString("");
        setOtpError("");
        setNewPin("");
        setConfirmPinString("");
        setValidationError("");
        switch (type) {
            case 1:
                setOtpScreen(true);
                setNewPinScreen(false);
                break;
            case 2:
                setPinSuccessScreen(false);
                setNewPinScreen(true);
                break
        }
    }

    // API - Verify otp
    const _verifyOtp = () => {
        let url = 'auth/verify-otp';
        let data = {
            "otp": otpString,
            "username": props.userName,
            "relation": props.relation,
            "type": "RESET_PIN"
        }
        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setOtpString("");
                setTempToken(response.data.temp_token);
                setNewPinScreen(true);
                setOtpScreen(false);
                toast.dismiss();
                toast.dismiss();
                toast.success('Account verified successfully. Update your login PIN', {
                    type: "success"
                });
            } else {
                toast.dismiss();
                toast.success(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
    }

    // API - Confirm reset pin
    const _pinHandler = () => {
        let url = 'auth/reset-pin';
        let payload = JSON.stringify({
            pin: newPin,
            temp_token: tempToken
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                console.log("Success")
                setPinSuccessScreen(true);
                setNewPinScreen(false);
            } else {
                toast.success(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        })
    }

    // API - Resent OTP in forgot password flow
    const _handleResendOtp = () => {
        _getForgotPINOTP().then(() => {
            toast.dismiss();
            toast.success('OTP has been re-sent to your registered email and phone number', {
                type: "success"
            });
            setSeconds(30);
            setOtpString("");
        }).catch((response) => {
            toast.error(response, {
                type: "error"
            });
        });
    }

    const _handleSubmit = (event) => {
        event.preventDefault();
    }


    return (
        <div id="forgot-pin"
            data-bs-backdrop="false"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="forgot-pin"
            aria-hidden="true"
            className={`modal ${style.e_forgot_pin_modal}`}>
            <div className={`modal-dialog modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white border-radius-32px text-center ${style.e_content}`}>
                    {
                        otpScreen === true ?
                            // OTP screen
                            <Fragment>
                                <Icon icon="close"
                                    size={24}
                                    data-bs-dismiss="modal"
                                    className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                    onClick={_handleBackToUnlockModal} />
                                <div className="mb-4">
                                    <img src={require("../../Assets/Images/forgot-pin/otp-recevied.png")}
                                        alt="OTP recevied"
                                        width={72}
                                        height={72} />
                                </div>
                                <p className="color-eerie-black e-poppins-medium e-font-18 mb-2">Reset Login PIN</p>
                                <p className="e-jett-black e-poppins-regular e-font-16 mb-3 mx-3">Enter OTP received on Email and Phone number to reset PIN</p>
                                <form onSubmit={_handleSubmit}>
                                    <OTPInput pinString={otpString}
                                        id="OTP"
                                        autoFocus={true}
                                        pinError={otpError}
                                        handleChange={(value) => _handleChange(1, value)} />

                                    <GradientButton label="Verify OTP"
                                        loading={loader}
                                        className="px-3 padding-12px-tb mt-4 mb-2"
                                        handleClick={_validateOTP} />
                                </form>
                                {
                                    seconds === 0 ?
                                        <div className="d-flex justify-content-center">
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                onClick={_handleResendOtp}>Resend OTP</p>
                                        </div>
                                        :
                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0'>Resend OTP in <span className="color-black">{seconds}s</span></p>
                                }

                            </Fragment>
                            :
                            newPinScreen === true ?
                                // PIN screen
                                <Fragment>
                                    <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                                        onClick={_handleBackToUnlockModal} />

                                    <div className="mb-4">
                                        <img src={require("../../Assets/Images/forgot-pin/new-pin.png")}
                                            alt="New pin"
                                            width={72}
                                            height={72} />
                                    </div>
                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2">Set your new PIN</p>
                                    <p className="e-jett-black e-poppins-regular e-font-16 mb-3 mx-5">We value security. Your investments are more secure and secured with PIN.</p>

                                    <form onSubmit={_handleSubmit}>
                                        <OTPInput title="New PIN*"
                                            id="Pin"
                                            autoFocus={true}
                                            pinString={newPin}
                                            pinError={validationError}
                                            handleChange={(value) => _handleChange(2, value)} />

                                        <OTPInput title="Confirm PIN*"
                                            id="Confirm-Pin"
                                            autoFocus={false}
                                            className="mt-3 margin-32px-bottom"
                                            pinString={confirmPinString}
                                            pinError={validationError}
                                            handleChange={(value) => _handleChange(3, value)} />

                                        <div className="mx-5 px-5">
                                            <GradientButton label="Set PIN"
                                                className="w-100 e-font-16 py-3 margin-32px-top"
                                                loading={loader}
                                                handleClick={_validatePIN} />
                                        </div>
                                    </form>
                                </Fragment>
                                :
                                // Pin success screen
                                pinSuccessScreen === true &&
                                <Fragment>
                                    {/* <Icon icon="close"
                                        size={24}
                                        data-bs-dismiss="modal"
                                        className={`position-absolute cursor-pointer ${style.e_close}`}
                                        onClick={_handleBackToUnlockModal} /> */}
                                    {/* <Icon icon="arrow-right1"
                                        size={24}
                                        color={Color.black}
                                        className={`position-absolute cursor-pointer ${style.e_back}`}
                                        onClick={() => _handleGoBack(2)} />
                                     */}
                                    <div className="mt-5 mb-4">
                                        <img src={require("../../Assets/Images/forgot-pin/otp-recevied.png")}
                                            alt="Success"
                                            width={72}
                                            height={72} />
                                    </div>
                                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2">Login PIN updated successfully! </p>
                                    <p className="e-jett-black e-poppins-regular e-font-16 mx-5">Your PIN has been successfully reset, click below
                                        to access your dashboard.</p>

                                    <div className="mb-5">
                                        <GradientButton label="Continue"
                                            loading={loader}
                                            cancel="modal"
                                            className="px-3 padding-12px-tb mt-4"
                                            handleClick={_handleBackToUnlockModal} />
                                    </div>
                                </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default ForgotPINModal