
/*
 *   File :  mandate.js
 *   Author URI : https://evoqins.com
 *   Description : Listing page for available mandate
 *   Integrations : null
 *   Version : v1.1
 */
import { Fragment, useState, useEffect } from "react";
import { InvestmentHeader } from "../../Component/Header";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { CustomTab } from "../../Component/Tab";
import { MandateCard } from "../../Component/Card";
import { GradientButton } from "../../Component/CTA";
import { CustomLoader } from "../../Component/Other";

import APIService from "../../Service/api-service";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage mandate', url: null }
];

const TAB_DATA = ['Active', 'Cancelled'];

export default function ManageMandate(props) {

    const [tabIndex, setTabIndex] = useState(1);
    const [mandateList, setMandateList] = useState([]);
    const [apiLoader, setApiLoader] = useState(true);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (tabIndex === 1) {
            _getActiveList();
        } else {
            _getCancelledList();
        }
    }, [tabIndex]);

    function _getActiveList() {
        setApiLoader(true);
        let url = 'profile/mandates/list';
        let payload = JSON.stringify({
            bank_id: null
        })
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMandateList(response.data);
            } else {
                setMandateList([]);
            }
            setApiLoader(false);
        })
    }

    function _getCancelledList() {
        setApiLoader(true);
        let url = 'profile/mandates/cancelled-list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setMandateList(response.data);
            } else {
                setMandateList([]);
            }
            setApiLoader(false);
        })
    }

    function _handleTabSelect(index) {
        setTabIndex(index);
    }

    return (
        <Fragment>
            <div className="e-page-container margin-80px-bottom e-explore">
                {/* Header */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />

                <h5 className="color-black e-poppins-medium e-font-20 line-height-32px mb-4 margin-32px-top">Manage mandate</h5>

                <CustomTab data={TAB_DATA}
                    wrapperClass={'gap-16px'}
                    className="px-1 padding-10px-tb e-order-tab"
                    onSelectTab={_handleTabSelect} />
                {
                    apiLoader === true ? (
                        <div className="row h-90">
                            <div className="col-12 text-center">
                                <CustomLoader />
                            </div>
                        </div>
                    ) : (
                        <div className="row mt-4">
                            {
                                mandateList.length === 0 ? (
                                    <div className="col-12 text-center h-100">
                                        <p className="color-red e-font-16 e-poppins-medium">
                                            No mandate found
                                        </p>
                                    </div>
                                ) : (
                                    <div className={tabIndex === 1 ? 'col-9' : 'col-8'}>
                                        {
                                            mandateList.map((item, key) => {
                                                return (
                                                    <div className=" mt-3" key={key}>
                                                        <MandateCard data={item}
                                                            type={tabIndex} />
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            tabIndex === 1 && (
                                                <div className="col-12 text-center pt-2">
                                                    <GradientButton label="Create new mandate"
                                                        className="padding-14px-all mt-4" />
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </Fragment>
    )
} 