import axios from "axios";

export default async function OrderAPIService(is_post, url, data, valid_user) {
    
    const url_param = new URL (window.location.href);
    let token = url_param.searchParams.get('token');
    if (valid_user === true ) {
        token = sessionStorage.getItem('access_token');
    }
    
    let API_URL = process.env.REACT_APP_API_URL + url;

    if (is_post === true) {
        return await axios
            .post(API_URL, data, {
                headers: {
                    method: "post",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {

                    return error.response.data;
                } else if (error.request) {
                    console.log("error.request", error.request);
                }
            });

    } else {
        return await axios
            .get(API_URL, {
                headers: {
                    method: "get",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {

                    return error.response.data;
                }
            });
    }
}
