/*
 *   File : listing.js
 *   Author URI : https://evoqins.com
 *   Description : SIP transactions done in the platform
 *   Integrations : react-toastify
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

import { BreadCrumb } from "../BreadCrumb"
import { InvestmentHeader } from "../Header"
import { CustomTab } from "../Tab";
import { Chip, CustomLoader, EmptyScreen } from "../Other";
import Footer from "../Footer/footer";
import { SIPTable } from "../Table";
import { SIPCard } from "../Card";
import { ActivateSIPModal } from "../Modal";
import { _consent2FA } from "../../Helper/api";

import APIService from "../../Service/api-service";

import NoData from '../../Assets/Images/nodata/sip.svg';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage SIPs', url: null },
];

const TAB_DATA = ['Active SIPs', 'Paused SIPs', 'Cancelled SIPs'];

const CHIP_DATA = ['All', 'Mutual funds', 'Life goals'];


const Listing = () => {

    const navigator = useNavigate();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(1);
    const [chipFilter, setChipFilter] = useState(1);
    const [selectedRow, setSelectedRowId] = useState(null);
    const [pageNavigation, setPageNavigation] = useState(PAGE_NAVIGATION);
    const [apiLoader, setApiLoader] = useState(false);
    const [pausedSIP, setPausedSIP] = useState([]);
    const [cancelledSIP, setCancelledSIP] = useState([]);
    const [selectedSIP, setSelectedSIP] = useState(null);
    const [sipActionLoader, setSIPActionLoader] = useState(false);
    const [loader, setLoader] = useState(true);
    const [initialLoader, setInitialLoader] = useState(true);
    const [sipList, setSipList] = useState({});
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {

        function _handleScroll() {
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener('scroll', _handleScroll);

        return () => {
            window.removeEventListener('scroll', _handleScroll);
        }
    }, []);
    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            switch (location.state.selectedTab) {
                case 2: _getPausedSIP();
                    break;
                case 3: _getCancelledSIP();
                    break;
            }
            setTabIndex(location.state.selectedTab)
        }
    }, [location])

    useEffect(() => {
        if (selectedSIP !== null) {
            const modal = new Modal(document.getElementById("active-sip"), {});
            modal.show();
        }
    }, [selectedSIP])

    useEffect(() => {
        _getSipList();;
    }, [chipFilter]);

    // handler for tab change
    const _handleTabSelect = (index) => {
        setTabIndex(index);
        switch (index) {
            case 2: _getPausedSIP();
                break;
            case 3: _getCancelledSIP();
                break;
            default: return;
        }
    }

    // handler for chip filter
    function _handleChipFilter(selected_status) {
        if (selected_status != chipFilter) {
            setLoader(true);
            setChipFilter(selected_status);
        }
    };

    // API : Get paused SIPs
    function _getPausedSIP() {
        setApiLoader(true);
        let url = 'sip/paused-list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setPausedSIP(response.data);
            }
            else {
                setPausedSIP([]);
            }
            setApiLoader(false);
        })
    }

    // API : Get cancelled SIP list
    function _getCancelledSIP() {
        setApiLoader(true);
        let url = 'sip/cancelled-list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setCancelledSIP(response.data);
            } else {
                setCancelledSIP([]);
            }
            setApiLoader(false);
        })
    }

    // API : Resume a paused SIP
    async function _resumeSIP(pin) {
        setSIPActionLoader(true);
        let status = true;
        let url = 'sip/resume';
        let payload = JSON.stringify({
            sip_id: selectedSIP,
            otp: pin
        });
        await APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('SIP resumed successfully', {
                    type: 'success'
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }
            setSIPActionLoader(false);
        });
        return status;
    }

    async function _activateSIP() {

    }

    function _getSIP() {

    }

    // API - Sip listing 
    // function _getSipList() {
    //     setChipFilter(1);
    //     let url = 'sip/active-list';
    //     let payload = JSON.stringify({
    //         is_goal: chipFilter === 1 ? null : chipFilter === 2 ? false : true
    //     });
    //     APIService(true, url, payload).then((response) => {
    //         if (response.status_code === 200) {
    //             setSipList(response.data)
    //             setLoader(false);
    //             setInitialLoader(false);
    //         } else {
    //             setLoader(false);
    //             setInitialLoader(false);
    //             setSipList([]);
    //         }
    //     });
    // }


    async function _sendOTP(id, type) {
        _consent2FA(type)
            .then((response) => {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setSelectedSIP(id);
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    const HeadingCard = (props) => {
        return (
            <div className="border-radius-10px border-all border-primary-color padding-22px-lr py-2 text-center">
                <img src={props.image}
                    alt={props.title}
                    width={24}
                    height={24} />
                <p className="color-gray e-poppins-medium e-font-14 line-height-sm-14px-20px mb-2 mt-1">{props.title}</p>
                <p className="color-black e-poppins-semi-bold e-font-14 line-height-16px mb-0">
                    <span className="e-inter-semi-bold">{props.rupee === true && "₹"}
                    </span>
                    {props.value}
                </p>
            </div>

        )
    }

    const _handleSipDetail = (data) => {
        setSelectedRowId(data);
        // setPageNavigation([...pageNavigation, "SIP detail"])
    }

    // API - sip lisiting
    function _getSipList() {
        let url = 'sip/active-list';
        let payload = JSON.stringify({
            is_goal: chipFilter === 1 ? null : chipFilter === 2 ? false : true
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSipList(response.data)
                setLoader(false);
                setInitialLoader(false);
            }
        });
    }

    return (

        <Fragment>
            <div className="e-page-container e-manage-sip margin-80px-bottom">

                {/* Header */}
                <InvestmentHeader />
                {
                    initialLoader === true ?
                        <div className="h-80vh">
                            <CustomLoader />
                        </div>
                        :
                        <>
                            <BreadCrumb data={pageNavigation} />

                            {/* NO data image preload */}
                            <img src={NoData} className="d-none" />
                            {
                                selectedRow !== null ?
                                    <p className="color-black e-poppins-medium e-font-20 line-height-32px mt-sm-4 mt-2 mb-sm-4 mb-0">My SIP</p>
                                    :
                                    <p className="color-black e-poppins-medium e-font-20 line-height-32px mt-sm-4 mt-2 mb-sm-4 mb-0">
                                        {
                                            tabIndex === 1 ?
                                                `Active SIPs(${sipList.fund_data.length})`
                                                : tabIndex === 2 ?
                                                    `Paused SIPs(${pausedSIP.length})`
                                                    :
                                                    `Cancelled SIPs(${cancelledSIP.length})`
                                        }
                                    </p>
                            }

                            {/* Tab select */}
                            <CustomTab data={TAB_DATA}
                                selectedTab={tabIndex}
                                indexToHide={1}
                                wrapperClass={'gap-16px'}
                                className="px-1 padding-10px-tb e-order-tab"
                                onSelectTab={_handleTabSelect} />
                            <>
                                {
                                    apiLoader === true ? (
                                        <div className="row">
                                            <div className="col-12 text-center pt-5 mt-5">
                                                <CustomLoader />
                                            </div>
                                        </div>
                                    ) :
                                        tabIndex === 1 ?
                                            <Fragment>

                                                <div className="mt-4 margin-32px-bottom d-flex  gap-16px">
                                                    <HeadingCard image={require("../../Assets/Images/Sidebar/manage-sips-color.png")}
                                                        title="Active SIPs"
                                                        value={sipList.total_active_sip}
                                                        rupee={false} />
                                                    <HeadingCard image={require("../../Assets/Images/manage-sip/total-amount.png")}
                                                        title="Total amount"
                                                        value={sipList.amount_active_sip.toLocaleString('En-IN')}
                                                        rupee={true} />
                                                    <HeadingCard image={require("../../Assets/Images/manage-sip/amc-invested.png")}
                                                        title="AMC invested"
                                                        value={sipList.amc_counts}
                                                        rupee={false} />
                                                </div>

                                                <div className="d-flex gap-8px flex-wrap mb-4"
                                                    data-disabled={`${loader}`}>
                                                    {
                                                        CHIP_DATA.map((tag, index) => {
                                                            return (
                                                                <Chip data={tag}
                                                                    key={index}
                                                                    type={2}
                                                                    chipIndex={index + 1}
                                                                    activeChipIndex={chipFilter}
                                                                    onPress={_handleChipFilter} />
                                                            )
                                                        })
                                                    }
                                                </div>

                                                {
                                                    loader === true ?
                                                        <CustomLoader />
                                                        :
                                                        <div className="row">
                                                            {
                                                                sipList.fund_data && sipList.fund_data.length !== 0 ? (
                                                                    <div className="col-12">
                                                                        {
                                                                            screenWidth > 576 &&
                                                                            <div className="col-12 text-end mt-2 e-table-scroll-indicator-sip">
                                                                                <p className="color-outer-space e-poppins-regular e-font-12 mb-0">
                                                                                    <img draggable={false} src={require('../../../src/Assets/Images/explore/scroll-gif.gif')} alt="scroll"
                                                                                        width='24px'
                                                                                        height='24px'
                                                                                        className='e-rotate-gif' />
                                                                                    Scroll horizontally for view more  information</p>
                                                                            </div>
                                                                        }
                                                                        <SIPTable data={sipList.fund_data}
                                                                            showSipDetail={_handleSipDetail} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="col-12 text-center mt-5">
                                                                        <EmptyScreen image={NoData}
                                                                            title="Not yet initiated a SIP?"
                                                                            description=" Using SIPs to invest is a wise strategy to reduce market volatility and creating wealth in long term."
                                                                            buttonLabel="Explore fund"
                                                                            onPress={() => navigator('/explore')} />
                                                                    </div>
                                                                )
                                                            }

                                                        </div>

                                                }
                                            </Fragment>

                                            :
                                            tabIndex === 2 ?
                                                <div className="row margin-32px-top">
                                                    {
                                                        pausedSIP.length !== 0 ? (
                                                            <div className="col-xl-9">
                                                                <div className="d-flex flex-column gap-16px">
                                                                    {
                                                                        pausedSIP.map((item, key) =>
                                                                            <SIPCard type={1}
                                                                                data={item}
                                                                                key={key}
                                                                                resumeSIP={_sendOTP} />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="col-12 text-center mt-5">
                                                                <EmptyScreen image={NoData}
                                                                    title="There are no SIPs currently on hold"
                                                                    description=" "
                                                                    buttonLabel="Explore fund"
                                                                    onPress={() => navigator('/explore')} />
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                                :
                                                <div className="row margin-32px-top">
                                                    {
                                                        cancelledSIP.length !== 0 ? (
                                                            <div className="col-xl-9 ">
                                                                <div className="d-flex flex-column gap-16px">
                                                                    {
                                                                        cancelledSIP.map((item, key) =>
                                                                            <SIPCard type={2}
                                                                                data={item}
                                                                                key={key} />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="col-12 text-center mt-5">
                                                                <EmptyScreen image={NoData}
                                                                    title="There are no SIPs currently on cancelled"
                                                                    description=" "
                                                                    buttonLabel="Explore fund"
                                                                    onPress={() => navigator('/explore')} />
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                }
                            </>
                        </>
                }

            </div >
            <Footer />
            {
                selectedSIP !== null && (
                    <ActivateSIPModal type={tabIndex}
                        loader={sipActionLoader}
                        handleOTPSubmission={tabIndex === 1 ? _activateSIP : _resumeSIP}
                        updateList={tabIndex === 1 ? _getSipList : _getPausedSIP}
                        close={() => setSelectedSIP(null)} />
                )
            }

        </Fragment >
    )
}

export default Listing