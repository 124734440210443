/*
 *   File : section-title.js
 *   Author URI : https://evoqins.com
 *   Description : Section title component for the investment platform
 *   Integrations : null
 *   Version : v1.1
 */

import React from "react";


const SectionTitle = React.memo((props) => {
    return (
        <h4 className={`${props.className} color-black e-inter-bold e-font-22-sm-18 margin-24px-bottom-sm-16`}>{props.title}</h4>
    )
})

export default SectionTitle;