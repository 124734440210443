/*
 *   File : investment.js
 *   Author URI : https://evoqins.com
 *   Description : Header for investment platform
 *   Integrations : react-toastify
 *   Version : v1.1
 */

import { Fragment, useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { updateNavigation } from '../../Store/Action/action';
import { SearchInput } from '../Form-elements';
import { GradientButton } from '../CTA';
import { _getNotificationCount } from '../../Helper/api';
import { MenuModal } from '../Modal';
import Store from '../../Store';
import Icon from '../Icon/icon';
import { logOut } from '../../Helper';

import Color from "../../Styles/color.module.scss"
import { KYC } from '../../Container/KYC';


const PROFILE_NAV_LINKS = [
    {
        id: 1,
        label: "Raise a ticket"
    },
];

const PROFILE_NAV_LINKS_MOBILE = [
    {
        id: 1,
        label: "Raise a ticket"
    },
    {
        id: 2,
        label: "FAQs"
    },
    {
        id: 3,
        label: "Calculators"
    },
    {
        id: 4,
        label: "Disclosure"
    },
];

export default memo(InvestmentHeader);
function InvestmentHeader(props) {

    const dispatch = useDispatch();
    const navigator = useNavigate();
    const location = useLocation();

    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA); // profile data 
    const KYC_STEP = useSelector(state => state.generalData.KYC_PROGRESS_DATA);
    const CART = useSelector((state) => state.generalData.CART); // cart items
    const HEADER_DATA = useSelector((state) => state.generalData.HEADER_DATA); // header data for search filter
    const NOTIFICATION_COUNT = useSelector(state => state.generalData.NOTIFICATION_COUNT); // notifications

    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [query, setQuery] = useState(props.searchText);
    const [closeSuggestion, setSuggestion] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [browserZoom, setBrowserZoom] = useState(100);
    const [kycStep, setKycStep] = useState(useSelector(state => state.generalData.KYC_PROGRESS_DATA.kyc_step));
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const _handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);


    useEffect(() => {
        // console.log('user data', USER_DATA);
        // setKycStep(USER_DATA.kyc_step);
    }, [USER_DATA]);


    useEffect(() => {
        _notificationCount();
    }, []);

    useEffect(() => {
        setQuery(props.searchText);
    }, [props.searchText]);



    const _navigateToCart = () => {
        setSuggestion(true);
        setTimeout(() => {
            setSuggestion(false);
        }, 100);
        navigator("/cart");
        dispatch(updateNavigation("/cart"));
    }

    const _navigateToNotification = () => {
        setSuggestion(true);
        setTimeout(() => {
            setSuggestion(false);
        }, 100);
        navigator("/notifications");
        dispatch(updateNavigation("/notifications"));
    }

    const _navigateSettings = () => {
        setSuggestion(true);
        setTimeout(() => {
            setSuggestion(false);
        }, 100);
        navigator("/settings");
        dispatch(updateNavigation("/settings"));
    }

    const _navigateToProfile = (id) => {
        switch (id) {
            case 1:
                navigator("/support")
                break;
            case 2:
                let url = process.env.REACT_APP_LANDING_PAGE_URL + '/faqs';
                window.open(url, "_blank", "noreferrer");
                break;
            case 3:
                window.open(" https://www.mastertrust.co.in/calculator", "_blank")
                break;
            case 4:
                window.open("https://www.mastertrust.co.in/disclosure", "_blank")
                break;
        }
        setShowProfileMenu(false);
    }

    function _handleChangeSearch(search_query) {
        setQuery(search_query);
        props.onSearchChange(search_query);
    }

    const _showProfileMenu = () => {
        if (showProfileMenu === false) {
            setShowProfileMenu(!showProfileMenu);
        }
    }

    const _showProfileHover = () => {
        setSuggestion(true);
        setTimeout(() => {
            setSuggestion(false);
        }, 100);
        setShowProfileMenu(true);
    }

    const _hideProfileMenu = () => {
        setShowProfileMenu(false);
    }

    function _redirectToKYC() {
        setSuggestion(true);
        setTimeout(() => {
            setSuggestion(false);
        }, 100);
        navigator('/kyc');
        dispatch(updateNavigation("/kyc"));
    }

    // logout function
    function _logout() {
        logOut();
    }

    // API - Get un-read notification count 
    const _notificationCount = () => {
        _getNotificationCount()
            .then((response) => {
                Store.dispatch({
                    type: 'NOTIFICATION_COUNT',
                    payload: response.notification_count
                });
            })
            .catch((response) => {
                // re-setting notification count to "0" if getting any error
                Store.dispatch({
                    type: 'NOTIFICATION_COUNT',
                    payload: 0
                });
            });
    };


    // to open sidebar for mobile
    const openModal = () => {
        setIsModalOpen(true);
        // Add a class to the body to prevent scrolling
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setIsModalOpen(false);
        // Remove the class to allow scrolling when the modal is closed
        document.body.style.overflow = 'auto';
    };

    useEffect(() => {
        // Clean up the style when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);


    return (
        <Fragment>
            {/* large screen view */}
            <header>
                <div className='d-lg-block d-none position-fixed bg-white e-header top-0 pe-2'>
                    <div className={`row padding-22px-tb position-relative pb-3 `}>
                        {/* search widget */}
                        <div className="col-lg-5 col-md-5">
                            <SearchInput topSearches={HEADER_DATA.top_searches}
                                amcData={HEADER_DATA.amc}
                                rating={HEADER_DATA.rank}
                                category={HEADER_DATA.category}
                                minimumInvestment={HEADER_DATA.min_investment}
                                fundType={HEADER_DATA.type}
                                searchText={query}
                                placeHolder="Search with fund name"
                                searchType={1}
                                updateStatus={closeSuggestion} // force close by sending props to component
                                onSearchChange={_handleChangeSearch} />
                        </div>

                        {/* cart */}
                        <div className="col-lg-7 col-md-7 d-flex justify-content-end align-items-center gap-16px pe-5">
                            {
                                USER_DATA.is_kyc_done === false && KYC_STEP.kyc_step < 10 &&
                                <GradientButton className="padding-12px-tb padding-32px-lr e-header-btn"
                                    label={
                                        KYC_STEP.kyc_step === 0 ? "Create an account" :
                                            KYC_STEP.kyc_step <= 8 ? "Resume KYC" :
                                                KYC_STEP.kyc_step === 9 ? "View KYC" : "Update KYC"
                                    }
                                    handleClick={_redirectToKYC} />
                            }


                            <div className={`p-3 border-radius-10px e-heading-icons position-relative cursor-pointer ${location.pathname === "/cart" && "outline-1px-solid"}`}
                                onClick={_navigateToCart}>
                                <img src={require('../../Assets/Images/dashboard/cart.png')} alt="Settings"
                                    width={24}
                                    height={24}
                                    draggable={false} />

                                {
                                    Object.keys(CART).length !== 0 && CART.data.length !== 0 &&
                                    <p className='color-white  e-poppins-bold e-font-10 line-height-12px e-badge position-absolute mb-0 bg-sea-green d-flex justify-content-center align-items-center'>
                                        {CART.data.length > 99 ? "99+" : CART.data.length}
                                    </p>
                                }
                            </div>

                            {/* settings */}
                            <div className={`p-3 border-radius-10px e-heading-icons position-relative cursor-pointer ${location.pathname === "/settings" && "outline-1px-solid"}`}
                                onClick={_navigateSettings}>
                                <img src={require('../../Assets/Images/dashboard/setting-img.png')} alt="Settings"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                            </div>

                            {/* notifications */}
                            <div className={`p-3 border-radius-10px e-heading-icons position-relative cursor-pointer ${location.pathname === "/notifications" && "outline-1px-solid"}`}
                                onClick={_navigateToNotification}>
                                <img src={require('../../Assets/Images/dashboard/notification-img.png')} alt="Notification"
                                    width={24}
                                    height={24}
                                    draggable={false} />

                                {
                                    NOTIFICATION_COUNT > 0 &&
                                    <p className='color-white e-poppins-bold e-font-10 line-height-12px e-badge position-absolute mb-0 bg-sea-green d-flex justify-content-center align-items-center'>
                                        {NOTIFICATION_COUNT < 100 ? NOTIFICATION_COUNT : '99+'}
                                    </p>}

                            </div>
                            <div className="position-relative"
                                onMouseEnter={_showProfileHover}
                                onMouseLeave={_hideProfileMenu}
                                onClick={_showProfileMenu}>
                                <p className='bg-light-cyan border-radius-10px color-ocean-blue e-poppins-semi-bold e-font-22 cursor-pointer padding-12px-tb text-center min-width-60 mb-0'>{USER_DATA.short_name}</p>

                                {
                                    showProfileMenu === true &&
                                    <div className='border-radius-12px border-all border-very-light-purple position-absolute top-60px right-0 p-4 white-space-nowrap bg-white e-profile-more z-index-1'
                                        onMouseEnter={_showProfileHover}
                                        onMouseLeave={_hideProfileMenu}>
                                        <div className='d-flex align-items-center gap-16px pb-4 mb-3 border-bottom-1px border-very-light-purple '>
                                            {/* <p className="bg-light-cyan border-radius-10px color-ocean-blue e-poppins-semi-bold e-font-22 padding-12px-tb text-center min-width-60 mb-0">
                                            {USER_DATA.short_name}
                                        </p> */}
                                            <div>
                                                <p className='color-gray e-poppins-medium e-font-14 line-height-24px mb-2'>MT Wealth ID: <span className='color-black'>{USER_DATA.mastertrust_id}</span></p>
                                                <p className='color-gray e-poppins-medium e-font-14 line-height-24px mb-2'>Email id: <span className='color-black'>{USER_DATA.email}</span></p>
                                                <p className='color-gray e-poppins-medium e-font-14 line-height-24px mb-2'>Phone: <span className='color-black'>{USER_DATA.mobile}</span></p>
                                                {
                                                    USER_DATA.demat === true &&
                                                    <p className='color-gray e-poppins-medium e-font-14 line-height-24px mb-0'>Demat account number: <span className='color-black'>{USER_DATA.demat_account_number}</span></p>
                                                }
                                            </div>
                                        </div>
                                        {
                                            PROFILE_NAV_LINKS.map((item, key) =>
                                                <div className='d-flex justify-content-between align-items-center cursor-pointer'
                                                    key={key}
                                                    onClick={() => _navigateToProfile(item.id)}>
                                                    <p className='color-onyx e-poppins-regular e-font-16 line-height-20px mb-0 py-2'>{item.label}</p>
                                                    <Icon icon="calender-arrow-right"
                                                        size={16}
                                                        color={Color.old_lavender} />
                                                </div>)
                                        }
                                        <p className='color-red e-poppins-regular e-font-16 line-height-20px mb-0 mt-3 py-2 cursor-pointer'
                                            onClick={_logout}>Logout</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* mobile view */}
                <div className='d-lg-none d-block'>
                    <div className='d-flex justify-content-between align-items-center mt-3 gap-8px'>
                        <div className='d-flex gap-16px w-100'>
                            <img src={require('../../Assets/Images/investment-header/drawer.svg').default}
                                alt="Menu"
                                className='cursor-pointer'
                                draggable={false}
                                onClick={() => openModal()} />
                            <div className='e-search-width-100'>
                                <SearchInput topSearches={HEADER_DATA.top_searches}
                                    amcData={HEADER_DATA.amc}
                                    rating={HEADER_DATA.rank}
                                    category={HEADER_DATA.category}
                                    minimumInvestment={HEADER_DATA.min_investment}
                                    fundType={HEADER_DATA.type}
                                    searchText={query}
                                    placeHolder="Search with fund name"
                                    searchType={1}
                                    updateStatus={closeSuggestion} // force close by sending props to component
                                    onSearchChange={_handleChangeSearch} />
                            </div>

                        </div>

                        <div className="position-relative "
                            onMouseEnter={_showProfileHover}
                            onMouseLeave={_hideProfileMenu}
                            onClick={_showProfileMenu}>
                            <p className='bg-light-cyan border-radius-10px color-ocean-blue e-poppins-semi-bold e-font-22 cursor-pointer padding-12px-tb text-center min-width-60 mb-0'>{USER_DATA.short_name}</p>
                            {
                                showProfileMenu === true &&
                                <div className="position-fixed w-100 z-index-1 h-100 left-0 top-0 cursor-default"
                                    onClick={() => setShowProfileMenu(false)}></div>
                            }

                            {
                                showProfileMenu === true &&
                                <div className='border-radius-12px border-all border-very-light-purple position-absolute top-64px right-0 p-4 white-space-nowrap bg-white e-profile-more z-index-12'
                                    onMouseEnter={_showProfileHover}
                                    onMouseLeave={_hideProfileMenu}>
                                    <div className='d-flex align-items-center gap-16px pb-4 mb-3 border-bottom-1px border-very-light-purple '>
                                        <p className="bg-light-cyan border-radius-10px color-ocean-blue e-poppins-semi-bold e-font-22 padding-12px-tb text-center min-width-60 mb-0">
                                            {USER_DATA.short_name}
                                        </p>
                                        <div>
                                            <p className='color-black e-poppins-semi-bold e-font-16 line-height-24px mb-1'>{USER_DATA.name}</p>
                                            <p className='color-gray e-poppins-medium e-font-14 line-height-24px mb-0'>MT Wealth ID: <span className='color-black'>{USER_DATA.mastertrust_id}</span></p>
                                        </div>
                                    </div>
                                    {
                                        PROFILE_NAV_LINKS_MOBILE.map((item, key) => {
                                            if (screenWidth > 576 && key === 0) {
                                                return (
                                                    <div className='d-flex justify-content-between align-items-center cursor-pointer'
                                                        key={key}
                                                        onClick={() => _navigateToProfile(item.id)}>
                                                        <p className='color-onyx e-poppins-regular e-font-16 line-height-20px mb-0 py-2'>{item.label}</p>
                                                        <Icon icon="calender-arrow-right"
                                                            size={16}
                                                            color={Color.old_lavender} />
                                                    </div>
                                                );
                                            } else if (screenWidth <= 576) {
                                                return (
                                                    <div className='d-flex justify-content-between align-items-center cursor-pointer'
                                                        key={key}
                                                        onClick={() => _navigateToProfile(item.id)}>
                                                        <p className='color-onyx e-poppins-regular e-font-16 line-height-20px mb-0 py-2'>{item.label}</p>
                                                        <Icon icon="calender-arrow-right"
                                                            size={16}
                                                            color={Color.old_lavender} />
                                                    </div>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                    {
                                        screenWidth <= 576 &&
                                        <>

                                            <a className='color-primary-color e-poppins-regular e-font-14 line-height-20px mb-0 py-2 d-flex align-items-flex-start' href="mailto:helpdesk@mastertrust.co.in">
                                                <Icon icon="mail"
                                                    size={18}
                                                    color={Color.primary}
                                                    className="me-1" />helpdesk@mastertrust.co.in</a>
                                            <a className='color-primary-color e-poppins-regular e-font-14 line-height-20px mb-0 py-2 d-flex align-items-center' href="tel:080-69991111">
                                                <Icon icon="phone"
                                                    size={18}
                                                    color={Color.primary}
                                                    className="me-1" />080-69991111</a>
                                        </>
                                    }

                                    <p className='color-red e-poppins-regular e-font-16 line-height-20px mb-0 mt-3 py-2 cursor-pointer'
                                        onClick={_logout}>Logout</p>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </header>

            {/* responsive sidebar item modal */}
            {
                isModalOpen && (
                    <MenuModal onClose={closeModal}
                        showOverlay={isModalOpen} />
                )
            }
        </Fragment>

    )
}