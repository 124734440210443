
/*
 *   File : delete-confirmation.js
 *   Author URI : https://evoqins.com
 *   Description : Confirmation screen to be displayed before removing any items in app
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { Modal } from "bootstrap";

import { GradientButton, OutlineButton } from "../CTA";

import style from "../../Styles/Component/delete-cart.module.scss"


export default function DeleteConfirmation(props) {

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("delete-confirmation");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setButtonLoader(false);
        });
    }, [props.close]);

    // listener api loader
    useEffect(()=> {
        setButtonLoader(props.loading);
    },[props.loading]);

    // submit button will call props.handleConfirmation, 
    // and return api status based on that toggle modal 
    async function _handleDelete() {
        try {
            const result = await props.handleConfirmation();
            // closing modal after api call;
            const close_button = document.getElementById("close-modal");
            close_button.click();

            // call listing API if needed after delete api return status 
            if (result === true) {
                props.updateList(); 
            }
        } catch (error) {
        
        } 
    }

    return (
        <div className={`modal fade ${style.e_delete_cart}`}
            id="delete-confirmation"
            tabIndex="-1"
            aria-labelledby="delete-confirm"
            aria-hidden="true"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white text-center d-flex align-items-center padding-32px-lr ${style.e_content} `}>
                    <img src={require("../../Assets/Images/invest-in-goals/delete-modal-icon.png")}
                        alt="Delete account"
                        width={72}
                        height={72}
                        className="margin-32px-top" />
                            <p className="color-eerie-black e-poppins-medium e-font-18 mt-4 mb-0">
                                {props.confirmationText}
                            </p>
                        
                    <div className="d-flex gap-8px margin-34px-top margin-32px-bottom  w-100 px-5">
                        <OutlineButton label={props.cancelButtonName}
                            id="close-modal"
                            cancel="modal"
                            className="padding-12px-tb px-3 white-space-nowrap  e-font-16 e-poppins-regular "/>
                        <GradientButton label={ buttonLoader ? 'Loading' : props.submitButtonName}
                            className="padding-12px-tb e-font-16 w-100 "
                            loading={buttonLoader}
                            handleClick={_handleDelete} />
                    </div>
                </div>
            </div>
        </div>
    );
}

DeleteConfirmation.defaultProps = {
    handleConfirmation: () => {},
    close: ()=> {},
    updateList: () => {}
};

