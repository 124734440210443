
/*
 *   File : complete-riskscore.js
 *   Author URI : https://evoqins.com
 *   Description : Banner for complete risk score indication
 *   Integrations : null
 *   Version : v1.1
 */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientButton } from '../CTA';

export default function CompleteRiskScore(props) {

    const navigator = useNavigate();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    function _handleNavigation() {
        navigator('/profile/risk-assessment');
    }
    return (
        <div className="bg-celadon ps-lg-4 ps-md-4 ps-3  pb-4 border-radius-24px pe-sm-0 pe-3">
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12 mb-2 pt-3">
                    <h2 className="color-black e-poppins-semi-bold e-font-22-sm-18  mt-4">
                        It seems like you haven't taken the risk assessment yet!
                    </h2>
                    <p className={`color-outer-space e-poppins-medium e-font-14-sm-12 line-height-22px ${screenWidth > 576 && 'padding-100px-right'}`}>
                        To proceed with your life goal investment, take your assessment quiz now.
                    </p>
                    <div className='text-sm-start text-center col-12'>
                        <GradientButton label='Calculate my risk appetite'
                            className='padding-12px-all me-sm-3 me-0'
                            handleClick={_handleNavigation} />
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12 d-lg-block d-md-block d-none">
                    <img src={require('../../Assets/Images/dashboard/calculate-risk.svg').default}
                        alt=''
                        height={162} />
                </div>
            </div>
        </div>
    )
}
