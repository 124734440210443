/*
 *   File : sip-detail.js
 *   Author URI : https://evoqins.com
 *   Description :  SIP transaction details of a selected transition
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react"
import { Modal } from "bootstrap"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify";

import { GradientButton, LinkText } from "../CTA";
import Icon from "../Icon/icon";
import { updateNavigation } from "../../Store/Action/action";
import { CancelSIPModal, PauseSIPModal, InvestGoalModal } from "../Modal";
import { InvestmentHeader } from "../Header";
import { BreadCrumb } from "../BreadCrumb";
import APIService from "../../Service/api-service";

import style from "../../Styles/Component/sip-detail.module.scss";
import { CustomLoader } from "../Other";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage SIPs', url: '/sip' },
    { page: 'SIP detail', url: null }
];


const GoalSIPDetail = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const sipId = location.state && location.state.sip_id;
    const [cancelSipModal, setCancelSipModal] = useState(false);
    const [pauseSipModal, setPauseSipModal] = useState(false);
    const [showInvestmentModal, setShowInvestModal] = useState(false);
    const [sipDetailData, setSipDetailData] = useState({});
    const [loader, setLoader] = useState(true);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (sipId) {
            _getSIPDetail();
        } else {
            navigate(-1);
        }
    }, [sipId])
    // listener for opening cancel modal
    useEffect(() => {
        if (cancelSipModal === true) {
            _openCancelModal();
        }
    }, [cancelSipModal]);

    // listener for opening pause modal
    useEffect(() => {
        if (pauseSipModal === true) {
            _openPauseModal();
        }
    }, [pauseSipModal]);

    // listener for opening invest modal
    useEffect(() => {
        if (showInvestmentModal === true) {
            _openInvestmentModal();
        }
    }, [showInvestmentModal]);

    const _handleNavigate = (order_id) => {
        navigate("/orders/goal-order-summary", { state: { order_id: order_id } });
        dispatch(updateNavigation("/orders"));
    }

    //to open cancel modal
    const _openCancelModal = () => {
        const modal = new Modal(document.getElementById("cancel-sip"), {});
        modal.show();
    };

    // Investment modal
    const _openInvestmentModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    }

    //to open cancel modal
    const _openPauseModal = () => {
        const modal = new Modal(document.getElementById("pause-sip"), {});
        modal.show();
    };

    const _handleCancelSip = () => {
        setCancelSipModal(true);
    }

    const _openPauseSipModal = () => {
        setPauseSipModal(true);
    }

    // API - cancel sip
    function _handleCancel() {

        let url = 'sip/cancel';
        let payload = JSON.stringify({
            sip_id: sipId
        });
        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                toast.success(`SIP cancelled`, {
                    type: "success",
                });
                navigate("/sip", { state: { selectedTab: 3 } });
            }
            else {
                toast.error(`Failed to cancel SIP`, {
                    type: "error",
                });
            }
        });
    }

    // API - get sip detail
    function _getSIPDetail() {
        let url = "sip/detail"
        let payload = JSON.stringify({
            sip_id: sipId
        });

        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                setSipDetailData(response.data);
                setLoader(false);
            }
            else {
                // navigate(-1);
                navigate("/sip");
            }
        });
    }

    // API - pause SIP
    function _handlePauseSip() {
        let url = "sip/pause"
        let payload = JSON.stringify({
            sip_id: sipId
        });
        APIService(true, url, payload).then((response) => {
            console.log('response', response);
            if (response.status_code === 200) {
                toast.success(`SIP paused`, {
                    type: "success",
                });
                setPauseSipModal(false);
                navigate("/sip", { state: { selectedTab: 2 } });
            }
            else {
                toast.error(`Failed to pause SIP`, {
                    type: "error",
                });
            }
        });
    }

    const Heading = (props) => {
        return (
            <div className={`d-flex ${props.className}`}>
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">{props.heading}&nbsp;</p>
                <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{props.answer}</p>
            </div>

        )
    }

    const PaymentAndOtherActivities = (props) => {
        return (
            <div className="mt-3">
                <p className="color-gray e-poppins-medium e-font-16 line-height-16px margin-10px-bottom e-payment-other-activities">
                    {props.heading}
                    <span className="d-lg-none d-inline">:</span>
                </p>
                <p className="color-black e-poppins-semi-bold e-font-18 line-height-16px mb-0">{props.answer}</p>
            </div>
        )
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="e-page-container e-manage-sip margin-80px-bottom">
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />
                <p className="color-black e-poppins-medium e-font-20 line-height-32px my-4">My SIP</p>


                <div className="row">
                    <div className="col-lg-10 col-12">
                        <div className="border-radius-24px border-all border-bright-gray p-4">
                            <div className="border-bottom-1px border-bright-gray pb-3 d-flex justify-content-between align-items-center flex-wrap">
                                <div className="d-flex align-items-center gap-12px">
                                    <div className="border-all border-bright-gray border-radius-8px p-2">
                                        <img src={sipDetailData.image}
                                            width={36}
                                            height={36}
                                            className="object-fit-contain" />
                                    </div>

                                    <p className="color-black e-poppins-medium e-font-16 line-height-20px mb-0">{sipDetailData.name}</p>
                                </div>
                                <div>
                                    <p className="color-outer-space e-poppins-semi-bold e-font-20 line-height-20px mb-0 mt-lg-0 mt-md-0 mt-sm-0 mt-3"><span className="e-inter-semi-bold">₹</span>{(sipDetailData.amount).toLocaleString("en-IN")}
                                    </p>
                                    <p className="color-gray e-poppins-regular e-font-12 line-height-18px mb-0">SIP amount</p>
                                </div>
                            </div>


                            <div className="mt-3 d-flex flex-wrap justify-content-between align-items-center">
                                <Heading heading="Last installment:"
                                    answer={sipDetailData.last_installment} />
                                <div className="d-flex align-items-center border-all border-primary-color border-radius-30px bg-pale-steel-blue px-3 py-2 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                    <p className="color-gray e-poppins-medium e-font-16 line-height-16px mb-0">Next installment &nbsp;</p>
                                    <p className="color-primary-color e-poppins-semi-bold e-font-16 mb-0">{sipDetailData.next_sip_date}</p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between mt-2 flex-wrap">
                                <Heading heading="SIP created on:"
                                    answer={sipDetailData.sip_created_on}
                                    className="mt-lg-0 mt-md-0 mt-sm-0 mt-3" />
                                <Heading heading="First purchase:"
                                    answer="Yes"
                                    className="mt-lg-0 mt-md-0 mt-sm-0 mt-4" />
                            </div>

                            <div className="border-radius-16px bg-light-grayish-white p-3 my-4">
                                <div className="d-flex justify-content-between flex-wrap">
                                    <Heading heading="SIP mandate:"
                                        answer={sipDetailData.mandate_id} />
                                    <Heading heading="Start date:"
                                        answer={sipDetailData.start_date}
                                        className="mt-lg-0 mt-md-0 mt-sm-0 mt-3" />
                                </div>
                                <div className="d-flex justify-content-between mt-3 flex-wrap">
                                    <Heading heading="Bank:"
                                        answer={`${sipDetailData.bank_name} (${sipDetailData.account_number})`} />
                                    <Heading heading="End date:"
                                        answer={sipDetailData.end_date}
                                        className="mt-lg-0 mt-md-0 mt-sm-0 mt-3" />
                                </div>
                            </div>

                            <div className="border-top-1px border-bright-gray pt-3 d-flex justify-content-between algin-items-center flex-wrap">
                                <div className="d-flex align-items-center gap-16px">
                                    <div className="border-all border-radius-6px border-bright-gray p-3 d-flex align-items-center gap-8px cursor-pointer"
                                        onClick={_openPauseSipModal}>
                                        <Icon icon="pause"
                                            size={16} />
                                        <p className="color-black e-poppins-medium e-font-14 mb-0">Pause SIP</p>
                                    </div>
                                    <p className="color-primary-color e-poppins-regular e-font-16 mb-0 cursor-pointer"
                                        onClick={_handleCancelSip}>Cancel SIP</p>
                                </div>
                                {/* <GradientButton label="Invest more"
                                            className="e-font-16 px-3 padding-12px-tb mt-lg-0 mt-md-0 mt-sm-0 mt-4 e-sip-detail-invest-btn"
                                            handleClick={() => setShowInvestModal(true)} /> */}
                            </div>

                        </div>

                        <p className="color-jett-black e-poppins-medium e-font-16 my-3">Payments and other activities</p>
                        <div className="border-all border-bright-gray pb-3 px-4 d-flex flex-wrap gap-24px border-radius-16px">
                            <PaymentAndOtherActivities heading="Total no. of installments"
                                answer={sipDetailData.total_number_of_installments} />
                            <PaymentAndOtherActivities heading="Missed installments"
                                answer={sipDetailData.missed_of_installments} />
                            <PaymentAndOtherActivities heading="Rejected installments"
                                answer={sipDetailData.rejected_of_installments} />
                            <PaymentAndOtherActivities heading="Remaining installments"
                                answer={sipDetailData.remaining_installments} />
                        </div>

                        <div className="border-radius-24px border-all border-bright-gray p-4 mt-3">
                            {
                                sipDetailData.installments.map((installment, key) => {

                                    return (
                                        <>
                                            <div key={key}
                                                // className={`d-flex gap-8px  cursor-pointer ${sipDetailData.installments.length - 1 !== key && "border-bottom-1px border-bright-gray mb-3 pb-3"} ${(installment.status === "Upcoming" && 'e-pointer-events-none')}`}
                                                // >
                                                className={`d-flex gap-8px position-relative  ${sipDetailData.installments.length - 1 !== key && "border-bottom-1px border-bright-gray mb-3 pb-3"} ${(installment.status === "Upcoming" && 'e-pointer-events-none')}`}
                                                onClick={() => _handleNavigate(installment.order_id)}
                                            >
                                                <Icon icon={installment.status === "Upcoming" ? "upcoming" : installment.status === "Success" ? "check-green" : "cancelled"}
                                                    size={24} />
                                                {/* <div className="w-100"> */}
                                                {/* <div className="d-flex justify-content-between align-items-center ">
                                                        <div>
                                                            <div className="d-flex gap-16px align-items-center mb-2">
                                                                <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{installment.sl_no} installment: {installment.status === "Upcoming" ? "Not paid yet" : installment.status === "Success" ? "Paid" : "Failed"}</p>
                                                                {
                                                                    installment.status !== "Upcoming" &&
                                                                    <p className={`position-relative e-font-14 e-poppins-regular mb-0 ${style.e_sip}`}>SIP</p>
                                                                }
                                                            </div>
                                                      
                                                            <p className="color-outer-space e-poppins-regular e-font-14 line-height-16px mb-0">{installment.transaction_date}</p>
                                                                {
                                                                    installment.status === "Upcoming" ?
                                                                        <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 position-absolute right-0 top-0">Upcoming</p>
                                                                        :
                                                                        installment.status === "Success" ?
                                                                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 position-absolute right-0 top-0">Success</p>
                                                                            :
                                                                            <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0">Failed</p>
                                                                }
                                                        </div>
                                                        
                                                        {
                                                            installment.status === 'Success' && (
                                                                <LinkText title="View details"
                                                                    icon="arrow-right"
                                                                    className="mt-4"
                                                                    onClick={() =>  _handleNavigate(installment.order_id)} />
                                                            )
                                                        }
                                                    </div> */}
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between align-items-center ">
                                                        <div>
                                                            <div className="d-flex gap-16px align-items-center mb-2">
                                                                <p className="color-outer-space e-poppins-medium e-font-16 line-height-16px mb-0">{installment.sl_no} installment: {installment.status === "Upcoming" ? "Not paid yet" : installment.status === "Success" ? "Paid" : "Failed"}</p>
                                                                {
                                                                    installment.status !== "Upcoming" &&
                                                                    <p className={`position-relative e-font-14 e-poppins-regular mb-0 ${style.e_sip}`}>SIP</p>
                                                                }
                                                            </div>
                                                            <p className="color-outer-space e-poppins-regular e-font-14 line-height-16px mb-0">{installment.transaction_date}</p>
                                                        </div>
                                                        {
                                                            installment.status === "Upcoming" || installment.status === 'Pending' ?
                                                                <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0">{installment.status}</p>
                                                                :
                                                                installment.status === "Success" ?
                                                                    <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0">Success</p>
                                                                    :

                                                                    <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0">Failed</p>
                                                        }
                                                    </div>
                                                    {
                                                        installment.failure_reason &&
                                                        <div className="row mt-2">
                                                            <div className="col-lg-10 col-md-10">
                                                                <div className="border-radius-8px  bg-antique-white py-3 px-2 d-flex align-items-center">
                                                                    {/* <Icon icon="info"
                                                                            style={{ color :'red'}}
                                                                            className="color-lust"
                                                                            size={24} /> */}

                                                                    <p className="color-outer-space e-poppins-regular e-font-14 line-height-22px mb-0">{installment.failure_reason}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div>
                                                {
                                                    installment.is_complete === true && installment.funds.map((fund, key) => {
                                                        return (
                                                            <div className="d-flex align-items-center gap-12px e-fund-name mt-3 ms-4">
                                                                <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                                                                    <img src={fund.image}
                                                                        width={24}
                                                                        height={24} />
                                                                </div>

                                                                <div>
                                                                    <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0 bg-white">{fund.name}</p>
                                                                    <p className="color-black e-inter-medium e-font-12 line-height-16px mb-0 bg-white"><span className="e-poppins-medium">Amount: </span>₹ {fund.amount.toLocaleString('en-IN')}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                {
                    cancelSipModal === true ?
                        <CancelSIPModal cancel={_handleCancel}
                            close={() => setCancelSipModal(false)} />
                        :
                        null
                }
                {
                    pauseSipModal === true ?
                        <PauseSIPModal pauseSip={_handlePauseSip}
                            close={() => setPauseSipModal(false)} />
                        :
                        null
                }
                {
                    showInvestmentModal === true && (
                        <InvestGoalModal
                            close={() => setShowInvestModal(false)} />
                    )
                }

            </div>
    )
}

export default GoalSIPDetail