/*
 *   File : notification-card-slider.js
 *   Author URI : www.evoqins.com
 *   Description : Slider
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import packages */
import { useRef } from "react";
import Slider from "react-slick";
import { NotificationBannerCard } from "../Card";
import Icon from "../Icon/icon";

import Color from "../../Styles/color.module.scss"

/* import components */

const NOTIFICATION_DATA = [{
    image: require("../../Assets/Images/dashboard/sip-bg.svg").default,
    icon: require("../../Assets/Images/dashboard/sip.png"),
    heading: "1 Upcoming SIP",
    description: "You have a pending SIP"
},
{
    image: require("../../Assets/Images/dashboard/sip-bg.svg").default,
    icon: require("../../Assets/Images/dashboard/sip.png"),
    heading: "1 Upcoming SIP",
    description: "You have a pending SIP"
},
{
    image: require("../../Assets/Images/dashboard/sip-bg.svg").default,
    icon: require("../../Assets/Images/dashboard/sip.png"),
    heading: "1 Upcoming SIP",
    description: "You have a pending SIP"
}]

const NotificationCardSlider = (props) => {
    const sliderRef = useRef(null);
    const NextArrow = ({ className, currentSlide, slideCount }) => {
        const isLastSlide = currentSlide === slideCount - 1;
        return (
            <div
                className={`${className} ${isLastSlide ? 'disabled' : ''}`}
            >
                <Icon
                    icon="arrow-right1"
                    alt="Next"
                    color={isLastSlide ? `${Color.anti_flash_white}` : Color.charleston_green} // Reduced opacity for the icon
                    width={24}
                    height={24}
                    onClick={() => sliderRef.current.slickNext()}
                    style={{ pointerEvents: isLastSlide ? 'none' : 'auto' }} // Disable pointer events
                />
            </div>
        );
    };

    const PrevArrow = ({ className, currentSlide }) => {
        // Disable the Prev arrow when on the first slide
        if (currentSlide === 0) {
            return (
                <div className={`${className} disabled`} onClick={null}>
                    <Icon
                        icon="arrow-right1"
                        alt="Prev"
                        color={`${Color.anti_flash_white}`} // Reduced opacity for the icon
                        width={24}
                        height={24}
                        style={{ pointerEvents: 'none' }} // Disable pointer events
                    />
                </div>
            );
        }

        return (
            <div className={className} 
                onClick={() =>{ sliderRef.current.slickPrev()}}>
                <Icon
                    icon="arrow-right1"
                    alt="Prev"
                    color={Color.charleston_green}
                    width={24}
                    height={24}
                />
            </div>
        );
    };

    return (
        <div className="e-notification-slider">
            <Slider infinite={true}
                ref={sliderRef}
                autoplay={true}
                autoplaySpeed={2000}
                slidesToScroll={1}
                slidesToShow={1}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                dots={false}>
                {
                    props.data.map((item, index) => {
                        return (
                            <div className="col-12">
                                <NotificationBannerCard data={item} key={index} />
                            </div>
                            
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default NotificationCardSlider;