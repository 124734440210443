/*
 *   File : import-external-fund.js
 *   Author URI : https://evoqins.com
 *   Description : import external fund component
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Icon from "../Icon/icon";
import { CustomTextInput, OTPInput } from "../Form-elements";
import { GradientButton } from "../CTA";
import APIService from "../../Service/api-service";

import style from "../../Styles/Component/import-external-fund.module.scss";
import Color from "../../Styles/color.module.scss";

const FamilyImportFund = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const USER_NAME = useSelector(state => state.generalData.PROFILE_DATA);
    const [aipLoader, setApiLoader] = useState(false);
    const [panScreen, setPanScreen] = useState(true);
    const [otpScreen, setOtpScreen] = useState(false);
    const [consentData, setConsentData] = useState({ family_detail_id: location.state.id })
    const [pinString, setPinString] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [panNumber, setPanNumber] = useState("");
    const [maskedMobile, setMaskedMobile] = useState("");
    const [panError, setPanError] = useState("");
    const [seconds, setSeconds] = useState(30);
    const [resendLoader, setResendLoader] = useState(false);

    useEffect(() => {
        const my_modal = document.getElementById("family-initiate-external-funds-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, [props.close]);

    useEffect(() => {
        if (pinString.length !== 0) {
            setConsentData({ ...consentData, otp: `${pinString}`, });
        }
    }, [pinString]);

    useEffect(() => {
        if (panScreen === false) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, panScreen]);

    useEffect(() => {
        _maskMobile();
    }, [USER_NAME]);

    function _maskMobile() {
        if (props.mobile !== '-') {
            const masked_number = props.mobile.substring(0, 3) + "*****" + props.mobile.substring(8);
            setMaskedMobile(masked_number);
        }
    }


    // handle function pin input
    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    const _handleNavigate = () => {
        props.callBackExternalFund();
    }

    const _handlePanInputChange = (value) => {
        setPanNumber(value.toUpperCase());
        setPanError("");
    }

    const _validate = () => {
        let valid = true;
        if (pinString.length < 6) {
            valid = false;
            setErrorMessage("Please enter OTP");
        }

        if (valid === true) {
            _verifyConsent();
        }
    }

    function _validatePAN() {
        console.log('panNumber', panNumber);
        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
            setPanError("Enter valid pan number");
            return;
        }
        _initiateExternalFunds();
    }

    // API - initiate import fund
    const _initiateExternalFunds = (otpLoader= false) => {
        if (otpLoader === false) {
            setApiLoader(true);
        }   
        setResendLoader(otpLoader);
        let url = 'family/cas/initiate';
        let payload = JSON.stringify({
            family_detail_id: location.state.id,
            pan: panNumber
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                // Use regular expression to extract numbers
                setConsentData(prevData => ({ ...prevData, ...response.data }));
                setPanScreen(false);
                setOtpScreen(true);
                if (otpLoader) {
                    setSeconds(29);
                }

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setResendLoader(false);
            setApiLoader(false);
        })
    }

    // API - verify import fund
    const _verifyConsent = () => {
        setApiLoader(true);
        let url = 'family/cas/consent';
        let payload = JSON.stringify(consentData);

        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                _importFunds();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                setApiLoader(false);
            }

        })
    }

    // API - import funds
    const _importFunds = () => {
        let url = 'family/cas/get-statement';
        let payload = JSON.stringify({
            family_detail_id: location.state.id,
            req_id: consentData.req_id,
            client_ref_no: consentData.client_ref_no
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setPanScreen(false);
                setOtpScreen(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }

    const _handleGoback = () => {
        // setPinString("");
        // setPinError("");
        setPanScreen(true);
    }

    const Header = (props) => {
        return (
            <div className="text-center">
                <img src={props.image}
                    width={72}
                    height={72}
                    alt={props.heading} />
                <p className={`color-eerie-black e-poppins-medium e-font-18 mb-2 mt-4 ${otpScreen === true && window.innerWidth > 575 && "white-space-nowrap"}`}>{props.heading}</p>
                <p className="color-jett-black e-poppins-regular e-font-16 margin-26px-bottom">{props.description}</p>
            </div>
        )
    }


    return (
        <div className={`modal fade ${style.e_import_external_fund}`}
            id="family-initiate-external-funds-modal"
            tabIndex="-1"
            aria-labelledby="family-initiate-external-funds-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-all position-relative ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                        data-bs-dismiss="modal" />
                    {
                        panScreen === true ?
                            <Fragment>
                                <Header image={require("../../Assets/Images/import-external-funds/pan.png")}
                                    heading="Import CAS report using PAN."
                                    description="Enter your PAN and complete OTP verification to the mobile number linked to your PAN. External holding imports are allowed only once every 30 days." />
                                <CustomTextInput value={panNumber}
                                    label="Enter PAN number"
                                    postfix="*"
                                    inputClass="text-uppercase"
                                    pan={true}
                                    error={panError}
                                    handleChange={_handlePanInputChange} />
                                <div className="text-center ">
                                    <p className="mt-3">
                                        For successful CAS fetching, your registered mobile number with master capital services ltd. should be same as registered in mutual fund folio.
                                    </p>
                                    <GradientButton label="Verify PAN details"
                                        loading={aipLoader}
                                        className="px-3 padding-12px-tb "
                                        handleClick={_validatePAN} />
                                </div>
                            </Fragment>
                            :
                            otpScreen === true ?
                                <Fragment>
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={Color.black}
                                        className={`position-absolute cursor-pointer ${style.e_back} ${aipLoader === true && "no-events"}`}
                                        onClick={_handleGoback} />
                                    <Header image={require("../../Assets/Images/forgot-pin/otp-recevied.png")}
                                        heading="Please enter the OTP received in your registered mobile number "
                                        description={`Use the 6-digit one-time password (OTP) sent on ${maskedMobile}. The OTP is valid for only 15 minutes. Do not share your OTP with anyone.`} />

                                    <OTPInput pinString={pinString}
                                        id="external-fund-otp"
                                        type={2}
                                        autoFocus={true}
                                        pinError={errorMessage}
                                        className={style.e_width_386}
                                        disabled={aipLoader}
                                        handleChange={_handlePin} />

                                    <div className="d-flex justify-content-center mt-lg-4 mt-md-4 mt-0">
                                        {
                                            seconds === 0 ?
                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                    onClick={()=>_initiateExternalFunds(true)}>{resendLoader === true ? 'Resending..' : 'Resend OTP'}</p>
                                                :
                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                    Resend OTP in <span className="color-black">{seconds}s</span>
                                                </p>
                                        }

                                    </div>
                                    <div className="text-center margin-32px-top">
                                        <GradientButton label="Confirm OTP"
                                            loading={aipLoader}
                                            className="px-3 padding-12px-tb "
                                            handleClick={_validate} />
                                    </div>
                                </Fragment>
                                :
                                <Fragment>
                                    <Header image={require("../../Assets/Images/import-external-funds/import-funds.png")}
                                        heading="Your funds are up-to-date, You can refresh after 30 days"
                                        description="Your funds are updated successfully. You can update them again after 30 days. If you want to get more details, click on the “view import details” button." />

                                    <div className="text-center mt-4">
                                        <GradientButton label="View import details"
                                            loading={aipLoader}
                                            cancel="modal"
                                            className="px-3 padding-12px-tb "
                                            handleClick={_handleNavigate} />
                                    </div>
                                </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default FamilyImportFund