/*
 *   File : kyc.js
 *   Author URI : https://evoqins.com
 *   Description : Navigation screen which holds all four steps in the KYC registration flow.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";
import Store from "../../Store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BreadCrumb } from "../../Component/BreadCrumb"
import { InvestmentHeader } from "../../Component/Header"
import Icon from "../../Component/Icon/icon";
import Footer from "../../Component/Footer/footer";
import LandingPage from "./helper/landing";
import KYCStatusPage from "./helper/kyc-status";
import PANDetailsPage from "./helper/pan-details";
import PersonalDetailsPage from "./helper/personal-details";
import DeclarationsPage from "./helper/declaration";
import IPVPage from "./helper/ipv";
import KYCStatus from "./helper/status";
import NomineePage from "./helper/nominee";
import PreviewPage from "./helper/preview";
import CreateBank from "./helper/create-bank";
import { CustomLoader } from "../../Component/Other";

import APIService from "../../Service/api-service";
import { _getKycProfile } from "../../Helper/api";

import Colors from '../../Styles/color.module.scss';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Account opening', url: null },
];


const KYC = () => {

    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [progress, setprogress] = useState(1);
    const [backNavigation, setBackNavigation] = useState(false);
    const [loader, setLoader] = useState(true);
    const [digioToken, setDigioToken] = useState('');

    useEffect(() => {
        _getKycGeneralData();
        _getKYCProgress();
    }, [])

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [progress]);

    const _updateProgress = (value, digio_token = null) => {
        if (digio_token !== null) {
            setDigioToken(digio_token);
        }
        setprogress(value);
        setBackNavigation(false);
    }

    function _navigateBack() {
        setprogress(progress - 1);
        setBackNavigation(true);
    }

    // API - get kyc general data
    function _getKycGeneralData() {
        let url = 'kyc/general-data';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                Store.dispatch({
                    type: 'KYC_CATEGORIES',
                    payload: response.data
                });
            }
        })
    }

    // API :: get kyc progress
    const _getKYCProgress = async () => {
        try {
            const kyc_data = await _getKycProfile();
            if (kyc_data.kyc_step === 0) {
                if (Object.keys(kyc_data).length !== 0 && kyc_data.email !== null && kyc_data.mobile !== null) {
                    console.log("Why here")
                    setprogress(2);
                }
                else {
                    console.log("in here")
                    setprogress(kyc_data.kyc_step + 1)
                }
            }
            else
                if (kyc_data.kyc_step < 9) {
                    console.log("not 0", kyc_data.kyc_step + 2)
                    setprogress(kyc_data.kyc_step + 2)
                }
                else {
                    setprogress(kyc_data.kyc_step + 1)
                }
            setLoader(false);
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    };


    function KYCProgress() {
        return (
            <div className={ progress > 9 && "mt-5"}>
                <p className="color-outer-space e-poppins-regular e-font-16 margin-block-end-0 mb-2">{`Step ${progress <= 10 ? progress : 10} of 10`}</p>
                <div className="d-flex mb-4">
                    {Array.from({ length: 10 }, (_, key) => (
                        <div key={key} className="bg-sliver-gray border-radius-4px position-relative e-kyc-progress-track me-1">
                            <div
                                className="bg-ocean-boat-blue border-radius-4px position-absolute e-kyc-progress-bar top-0 left-0"
                                data-completed-step={progress >= key + 1 ? true : false} // Completed step 
                                data-completing-step={progress === key + 1 && backNavigation === false ? true : false} // next step progress animation
                                data-prev-step={backNavigation && progress === key} // prev step progress animationś
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }


    return (
        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <div className="e-kyc e-page-container margin-72px-bottom">
                    {/* Header */}
                    <InvestmentHeader />

                    {/* Breadcrumb */}
                    <BreadCrumb data={PAGE_NAVIGATION}
                        className={`${progress === 2 && "mb-3"} mt-lg-0 mt-md-0 mt-4`} />
                    {/* <p className="color-black e-poppins-medium e-font-20 line-height-32px my-4">Account opening</p> */}
                    {
                        progress === 1 ?
                            <LandingPage updateProgress={_updateProgress} />
                            :
                            <div className="row">
                                <div className="col-12">

                                    {
                                       ( progress > 2 && progress < 10 )&&
                                        <button className="color-black e-poppins-medium e-font-sm-16-14 e-kcy-back-button px-0 bg-transparent no-border no-outline my-sm-4 my-2 py-2 "
                                            onClick={_navigateBack}>
                                            <Icon icon="back-arrow"
                                                size={24}
                                                className="me-2 e-arrow"
                                                color={Colors.black} />
                                            Back to previous step
                                        </button>
                                    }

                                    <KYCProgress />

                                    {
                                        progress === 2 ?
                                            <PANDetailsPage updateProgress={_updateProgress} />
                                            : progress === 3 ?
                                                <KYCStatusPage updateProgress={_updateProgress} />
                                                : progress === 4 ?
                                                    <PersonalDetailsPage updateProgress={_updateProgress} />
                                                    : progress === 5 ?
                                                        <DeclarationsPage updateProgress={_updateProgress} />
                                                        : progress === 6 ?
                                                            <CreateBank updateProgress={_updateProgress} />
                                                            :
                                                            progress === 7 ?
                                                                <NomineePage updateProgress={_updateProgress} />
                                                                :
                                                                progress === 8 ?
                                                                    <IPVPage updateProgress={_updateProgress} />
                                                                    : progress === 9 ?
                                                                        <PreviewPage updateProgress={_updateProgress} 
                                                                            digioToken={digioToken}/>
                                                                        : <KYCStatus updateProgress={_updateProgress}/>

                                    }
                                </div>
                            </div>
                    }
                </div>
                <Footer />
            </Fragment>
    )
}

export default KYC