
/*
 *   File : goal-summary.js
 *   Author URI : https://evoqins.com
 *   Description : Component to suggest fund to invest for a selected goal
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useState, useRef, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { CustomLoader, CustomSlider, RepresentationLabel } from "../Other";
import { CustomAmountInput, RadioGroup } from "../Form-elements";
import { GradientButton, OutlineButton } from "../CTA";
import { InvestmentStylePopup } from "../Popups";
import { FundSuggestionTable } from "../Table";
import { _setGoal, _getGoalInvestmentSummary } from "../../Helper/api";
import { AppointmentModal, InvestGoalModal, PaymentStatusModal, MfInProcessStatus } from "../Modal";
import { CreateAccountBanner, CompleteRiskScore } from "../Card";
import { _getColorForInvestmentStyle } from "../../Helper";

import { _getDayOfMonth } from "../../Helper";
import APIService from "../../Service/api-service";

import Color from "../../Styles/color.module.scss";

const ARROW = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" id="arrow-right">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5303 5.46967C10.8232 5.76256 10.8232 6.23744 10.5303 6.53033L5.81066 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H5.81066L10.5303 17.4697C10.8232 17.7626 10.8232 18.2374 10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303L3.46967 12.5303C3.17678 12.2374 3.17678 11.7626 3.46967 11.4697L9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967Z" fill="#1C274C" />
    </svg>
);

const EDIT_FUND_DISTRIBUTION = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4426 1.25L14 1.25C14.4142 1.25 14.75 1.58579 14.75 2C14.75 2.41421 14.4142 2.75 14 2.75H12.5C10.1218 2.75 8.41356 2.75159 7.11358 2.92637C5.83517 3.09825 5.06445 3.42514 4.4948 3.9948C3.92514 4.56445 3.59825 5.33517 3.42637 6.61358C3.25159 7.91356 3.25 9.62177 3.25 12C3.25 14.3782 3.25159 16.0864 3.42637 17.3864C3.59825 18.6648 3.92514 19.4355 4.4948 20.0052C5.06445 20.5749 5.83517 20.9018 7.11358 21.0736C8.41356 21.2484 10.1218 21.25 12.5 21.25C14.8782 21.25 16.5864 21.2484 17.8864 21.0736C19.1648 20.9018 19.9355 20.5749 20.5052 20.0052C21.0749 19.4355 21.4018 18.6648 21.5736 17.3864C21.7484 16.0864 21.75 14.3782 21.75 12V10.5C21.75 10.0858 22.0858 9.75 22.5 9.75C22.9142 9.75 23.25 10.0858 23.25 10.5V12.0574C23.25 14.3658 23.25 16.1748 23.0603 17.5863C22.866 19.031 22.4607 20.1711 21.5659 21.0659C20.6711 21.9607 19.531 22.366 18.0863 22.5603C16.6748 22.75 14.8658 22.75 12.5574 22.75H12.4426C10.1342 22.75 8.32519 22.75 6.91371 22.5603C5.46897 22.366 4.32895 21.9607 3.43414 21.0659C2.53933 20.1711 2.13399 19.031 1.93975 17.5863C1.74998 16.1748 1.74999 14.3658 1.75 12.0574V11.9426C1.74999 9.63423 1.74998 7.82519 1.93975 6.41371C2.13399 4.96897 2.53933 3.82895 3.43414 2.93414C4.32895 2.03933 5.46897 1.63399 6.91371 1.43975C8.32519 1.24998 10.1342 1.24999 12.4426 1.25Z" fill="#84DCC8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4041 3.5008C16.4041 3.50049 16.4041 3.50018 17.1528 3.45614C17.9015 3.4121 17.9015 3.4118 17.9014 3.41151L17.9013 3.40992L17.9012 3.40822C17.9015 3.41173 17.9023 3.41966 17.9038 3.43235C17.9067 3.45776 17.9123 3.50062 17.9227 3.55808C17.9433 3.67329 17.9823 3.84519 18.054 4.05174C18.1973 4.4648 18.4685 5.00879 18.9807 5.52107C19.493 6.03335 20.037 6.30454 20.4501 6.44785C20.6566 6.51951 20.8285 6.55847 20.9437 6.57915C21.0012 6.58946 21.044 6.59514 21.0695 6.59805C21.0821 6.59951 21.0904 6.60027 21.0939 6.60057C21.0957 6.60072 21.0962 6.60075 21.0956 6.6007L21.0939 6.60057L21.0919 6.60046L21.0909 6.6004C21.0906 6.60038 21.0897 6.60033 21.0457 7.34903C21.0016 8.09774 21.0013 8.09772 21.001 8.0977L20.9989 8.09758L20.9957 8.09737L20.9878 8.09683L20.9658 8.09509C20.9486 8.09361 20.926 8.09143 20.8986 8.08829C20.8439 8.08202 20.7695 8.07186 20.6787 8.05556C20.4974 8.02301 20.2486 7.96567 19.9584 7.86498C19.378 7.66361 18.6243 7.28598 17.9201 6.58173C17.2158 5.87748 16.8382 5.12384 16.6368 4.5434C16.5361 4.2532 16.4788 4.00439 16.4462 3.82307C16.4299 3.73227 16.4198 3.65791 16.4135 3.60317C16.4104 3.57578 16.4082 3.55325 16.4067 3.53597L16.405 3.51402L16.4044 3.50607L16.4042 3.50286L16.4041 3.5008ZM17.9011 3.40625L17.9012 3.40822L17.9011 3.40625ZM9.44347 13.3708L11.1311 15.0583L10.0704 16.119L8.38281 14.4314L9.44347 13.3708Z" fill="#84DCC8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2706 2.27591C18.6384 0.908028 20.8562 0.908028 22.2241 2.27591C23.592 3.6438 23.592 5.86158 22.2241 7.22947L15.576 13.8776C15.2047 14.2489 14.9721 14.4815 14.7126 14.684C14.4069 14.9224 14.0761 15.1268 13.7262 15.2936C13.429 15.4352 13.117 15.5392 12.6188 15.7052L9.71428 16.6734C9.17803 16.8521 8.58682 16.7126 8.18713 16.3129C7.78744 15.9132 7.64787 15.322 7.82662 14.7857L8.79479 11.8812C8.96081 11.3831 9.06481 11.071 9.20642 10.7739C9.37321 10.4239 9.57763 10.0931 9.81607 9.78742C10.0185 9.52787 10.2511 9.29529 10.6225 8.92401L17.2706 2.27591ZM21.1634 3.33657C20.3813 2.55448 19.1133 2.55448 18.3312 3.33657L17.6824 3.98539L11.7175 9.95026C11.301 10.3668 11.1377 10.532 10.9988 10.7099C10.8274 10.9297 10.6804 11.1676 10.5605 11.4192C10.4634 11.623 10.3887 11.8429 10.2024 12.4017L9.2545 15.2455L12.0983 14.2976C12.6571 14.1113 12.8771 14.0366 13.0808 13.9395C13.3325 13.8196 13.5703 13.6726 13.7901 13.5012C13.9681 13.3624 14.1332 13.199 14.5498 12.7825L21.1634 6.16881C21.9455 5.38671 21.9455 4.11867 21.1634 3.33657Z" fill="#1177BD" />
    </svg>
);

const INVESTMENT_TYPES = [{
    id: 1,
    label: "SIP"
}, {
    id: 2,
    label: "Lumpsum"
}];

const GoalSummary = React.memo((props) => {

    const INVESTMENT_STYLES = useSelector(state => state.generalData.INVESTMENT_STYLES);
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const KYC_STEP = useSelector(state => state.generalData.KYC_PROGRESS_DATA);

    const navigator = useNavigate();
    const location = useLocation();
    const formRef = useRef(null);

    const [sipAmount, setSipAmount] = useState('');
    const [lumpsum, setLumpsum] = useState('');
    const [sipTenure, setSipTenure] = useState(3);
    const [sipIncrement, setSipIncrement] = useState(0);
    const [showPopupFilter, setShowPopupFilter] = useState(false);
    const [showEditTooltip, setShowEditTooltip] = useState(false);
    const [investmentStyle, setInvestmentStyle] = useState({});
    const [corpusAmount, setCorpusAmount] = useState(null)
    const [endDate, setEndDate] = useState("");
    const [expectedReturn, setExpectedReturn] = useState(null);
    const [returnPercent, setReturnPercent] = useState(null);
    const [investmentAmount, setInvestmentAmount] = useState(null);
    const [fundDetail, setFundDetail] = useState({});
    const [loader, setLoader] = useState(true);
    const [goalRecalculationStatus, setGoalCalculationStatus] = useState(false); // re-calculate goal info if any changes found in the goal
    const [goalType, setGoalType] = useState(INVESTMENT_TYPES);
    const [selectedGoalType, setSelectedGoalType] = useState(INVESTMENT_TYPES[0].id);
    const [calculatedSip, setCalculatedSip] = useState(0);
    const [calculatedCorpus, setCalculatedCorpus] = useState(0);
    const [openInvestModal, setOpenInvestModal] = useState(false);
    const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
    const [additionalData, setAdditionalData] = useState({});
    const [sectionLoader, setSectionLoader] = useState(false);
    const [timeSlots, setTimeSlots] = useState([]);
    const [goalDetails, setGoalDetails] = useState({});
    const [apiLoader, setApiLoader] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [isNextMonth, setIsNextMonth] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);

    useEffect(()=>{
        function _handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    },[]);

    // API call:: get appointment time slots
    useEffect(() => {

        function _getAppointmentTimeSlots() {
            let url = 'general/get-appointment-slots';
            APIService(false, url).then((response) => {
                if (response.status_code === 200) {
                    setTimeSlots(response.data);
                }
            })
        }

        _getAppointmentTimeSlots();
    }, []);

    useEffect(() => {

        if (goalRecalculationStatus === true) {
            // API - set goalRecalculationStatus
            if (sipAmount === 0 && lumpsum === 0) {
                setCorpusAmount(0);
                setExpectedReturn(0);
                setReturnPercent(0);
                setInvestmentAmount(0);
                const updated_funds = fundDetail.fund_allocation.map(fund => {
                    // convert percentage to a floating-point number
                    return {
                        ...fund,
                        amount: 0 // update the amount based on the percentage
                    };
                });
                const updated_allocations = fundDetail.fund_distribution.map(fund => {
                    // convert percentage to a floating-point number
                    return {
                        ...fund,
                        allocation: 0,
                        amount: 0 // update the amount based on the percentage
                    };
                });

                setFundDetail({
                    ...fundDetail,
                    fund_distribution: updated_allocations,
                    fund_allocation: updated_funds
                });
            } else {
                additionalData.sip = sipAmount;
                additionalData.lumpsum = lumpsum;
                additionalData.step_sip = sipIncrement;
                additionalData.sip_tenure = sipTenure;
                additionalData.investment_style = investmentStyle;
                setSectionLoader(true);
                _setGoal(props.goalId, sipTenure, 0, lumpsum, sipAmount, sipIncrement, props.inflationRatio, props.availableCorpus, props.apiData.name, investmentStyle.id, additionalData)
                    .then((response) => {
                        setSectionLoader(false);
                        setCorpusAmount(response.corpus_amount);
                        setEndDate(response.end_date);
                        setExpectedReturn(response.expected_returns);
                        setReturnPercent(response.expected_returns_percentage);
                        setInvestmentAmount(response.invested_amount);
                        // Update the amount property for each item in the array
                        const updated_funds = response.fund_details.fund_allocation.map(fund => {
                            const percentage = parseFloat(fund.percentage); // convert percentage to a floating-point number
                            return {
                                ...fund,
                                amount: (percentage / 100) * (selectedGoalType === 1 ? sipAmount : lumpsum) // update the amount based on the percentage
                            };
                        });

                        setFundDetail({ ...response.fund_details, fund_allocation: updated_funds });
                        let goalDetail = goalDetails;
                        goalDetail.goal_data.calculated_sip = sipAmount;
                        goalDetail.goal_data.calculated_lumpsum = lumpsum;
                        setGoalDetails({ ...goalDetail });
                    })
                    .catch((response) => {
                        toast.dismiss();
                        toast.error(response, {
                            type: "error"
                        });
                    });
            }
            setGoalCalculationStatus(false);
        }
    }, [goalRecalculationStatus]);

    useEffect(() => {
        if (openInvestModal === true) {
            _openInvestmentModal();
        }
    }, [openInvestModal]);

    useEffect(() => {
        if (openAppointmentModal === true) {
            _openAppointmentModal();
        }
    }, [openAppointmentModal]);

    useEffect(() => {
        if (Object.keys(props.apiData).length !== 0) {
            // console.log('porps.apiData', props.apiData);
            setCalculatedSip(props.apiData.calculated_sip);
            setSipAmount(props.sip ? props.sip : props.apiData.calculated_sip);
            setLumpsum(props.apiData.calculated_lumpsum);
            setCalculatedCorpus(parseInt(props.apiData.corpus_amount.toFixed(0)));
            setCorpusAmount(parseInt(props.apiData.corpus_amount.toFixed(0)));
            setEndDate(props.apiData.end_date);
            setExpectedReturn(props.apiData.expected_returns);
            setReturnPercent(props.apiData.expected_returns_percentage);
            setInvestmentAmount(props.apiData.invested_amount);
            setFundDetail(props.apiData.fund_details);
            setSipIncrement(props.step_sip);
            setSipTenure(props.sip_tenure);
            setLumpsum(props.apiData.additional_data && props.apiData.additional_data.lumpsum ? props.apiData.additional_data.lumpsum : 0);
            setInvestmentStyle({
                id: props.apiData.additional_data.investment_style !== undefined && Object.keys(props.apiData.additional_data.investment_style).length > 0 ? props.apiData.additional_data.investment_style.id : props.apiData.investment_style.id,
                label: props.apiData.additional_data.investment_style !== undefined && Object.keys(props.apiData.additional_data.investment_style).length > 0 ? props.apiData.additional_data.investment_style.label : props.apiData.investment_style.name,
                image: props.apiData.additional_data.investment_style !== undefined && Object.keys(props.apiData.additional_data.investment_style).length > 0 ? props.apiData.additional_data.investment_style.image : props.apiData.investment_style.image,
                goal_returns_txt: props.apiData.additional_data.investment_style !== undefined && Object.keys(props.apiData.additional_data.investment_style).length > 0 ? props.apiData.additional_data.investment_style.goal_returns_txt : props.apiData.investment_style.goal_returns_txt
            });
            setAdditionalData(props.apiData.additional_data);
            setLoader(false);
        }
        else {
            //if no props data redirect to goal detail page, 
            // use case :: going to fund detail and coming back using browser back 
            navigator(`${location.pathname}#summary`, { state: { id: location.state.id } });
        }
    }, [props.apiData]);

    useEffect(() => {
        if (loader === false) {
            let form = document.getElementById('form-section');
            if (form) {
                form.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [loader])

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    useEffect(()=>{
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    },[showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }


    function _navigateBack() {
        if (location.hash !== '') {
            navigator(location.pathname, { state: { id: location.state.id } });
        }
    }
    const _handleShowTooltip = () => {
        setShowEditTooltip(!showEditTooltip);
    };

    function _navigateToProfile() {
        navigator('/profile', {
            state: { tab: 5, type: 2 },
        });
    }


    function _validateForm() {
        if ((sipAmount === "" || sipAmount <= 0) && (lumpsum === "" || lumpsum <= 0)) {
            toast.dismiss();
            toast.error('Please fill either SIP or lump sum', {
                type: "error"
            });
            if (formRef.current) {
                formRef.current.scrollIntoView({ behavior: 'smooth' });
            }

        } else {
            _setGoal(props.goalId, sipTenure, 0, lumpsum, sipAmount, sipIncrement, props.inflationRatio, props.availableCorpus, props.apiData.name, investmentStyle.id, additionalData)
                .then((response) => {
                    setSectionLoader(false);
                    setCorpusAmount(response.corpus_amount);
                    setEndDate(response.end_date);
                    setExpectedReturn(response.expected_returns);
                    setReturnPercent(response.expected_returns_percentage);
                    setInvestmentAmount(response.invested_amount);
                    // Update the amount property for each item in the array
                    const updated_funds = response.fund_details.fund_allocation.map(fund => {
                        const percentage = parseFloat(fund.percentage); // convert percentage to a floating-point number
                        return {
                            ...fund,
                            amount: (percentage / 100) * (selectedGoalType === 1 ? sipAmount : lumpsum) // update the amount based on the percentage
                        };
                    });

                    setFundDetail({ ...response.fund_details, fund_allocation: updated_funds });
                    _fetchGoalDetail();
                }).catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error"
                    });
                });

        }
    }

    const _openInvestmentModal = () => {
        var modal = new Modal(document.getElementById("invest-goal"), {})
        modal.show();
    }

    const _openAppointmentModal = () => {
        var modal = new Modal(document.getElementById("appointment-modal"), {})
        modal.show();
    }

    const _handleRadioInput = (e) => {
        setSelectedGoalType(e)
        const updated_funds = fundDetail.fund_allocation.map(fund => {
            const percentage = parseFloat(fund.percentage); // convert percentage to a floating-point number
            return {
                ...fund,
                amount: (percentage / 100) * (e === 1 ? sipAmount : lumpsum) // update the amount based on the percentage
            };
        });

        const updated_distribution = fundDetail.fund_distribution.map(fund => {
            const percentage = parseFloat(fund.percentage); // convert percentage to a floating-point number
            return {
                ...fund,
                amount: (percentage / 100) * (e === 1 ? sipAmount : lumpsum) // update the amount based on the percentage
            };
        })


        setFundDetail({
            ...fundDetail,
            fund_allocation: updated_funds,
            fund_distribution: updated_distribution
        });
    }

    // API - Get goal details
    function _fetchGoalDetail() {
        const {date} = _getDayOfMonth();
        _getGoalInvestmentSummary(props.apiData.goal_id, true, date)
            .then((response) => {

                setGoalDetails(response);
                setOpenInvestModal(true);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }

    // API:: get payment status after investment 
    function _getPaymentStatus(id, is_next_month) {
        if (id === null) {
            setOpenPaymentStatusModal(true);
            setIsNextMonth(is_next_month);
            setPaymentStatus(true);
            setPaymentStatusText('Success');
            return
        }
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                setPaymentStatus(response.data.payment_status);
                setPaymentStatusText(response.data.payment_status_txt);
            }
        })
    }

    function _redirectToKyc() {
        navigator('/kyc');
    }
    return (
        loader === true ?
            <CustomLoader />
            :
            <Fragment>
                <div className="row mt-sm-5 mt-3 pe-0 gx-sm-3 gx-0" ref={formRef} id='form-section'>
                    {/* Total gain when retire banner */}
                    <span className="color-black e-poppins-medium e-font-14 mb-sm-4 mb-2 cursor-pointer w-auto icon-link icon-link-hover"
                        onClick={_navigateBack}>
                        {ARROW} Back
                    </span>

                    {/* Banner */}
                    <div className="col-12 mb-2" >
                        <div className="bg-pale-mint-green border-radius-16px e-goal-banner position-relative">
                            <div className="position-absolute bottom-0 right-0 d-xl-block d-lg-none b-md-block">
                                <img src={require("../../Assets/Images/fund-suggestion/goal-banner.svg").default}
                                    className="e-goal-banner-img"
                                    alt="Goal banner" />
                            </div>
                            <div className="row">
                                <div className="col-xl-6">
                                    <p className="color-black e-poppins-medium e-font-18 line-height-32px mb-0 position-relative z-index-1">
                                        {props.title}
                                    </p>
                                </div>
                            </div>

                            <p className="color-black e-poppins-semi-bold e-font-32-sm-24 mb-sm-3 mb-0 position-relative z-index-1">
                                <span className="e-inter-regular">₹</span>{calculatedCorpus.toLocaleString("en-IN")}
                            </p>
                            <div className="row">
                                <div className="col-xl-6 pe-5">
                                    <p className="color-outer-space e-poppins-medium e-font-14 mb-0 position-relative z-index-1">
                                        We recommend the below amounts to achieve your desired corpus.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-3 bg-tea-green border-radius-4px px-2 py-2 d-flex gap-16px w-fit-content position-relative z-index-1">
                                <p className="color-outer-space e-poppins-medium e-font-14 line-height-22px mb-0">
                                    SIP amount: <span className="color-primary-color e-inter-medium e-font-16 ">₹&nbsp;</span>
                                    <span className="color-primary-color e-poppins-medium e-font-16">{calculatedSip.toLocaleString("en-IN")}</span>
                                </p>
                            </div>
                            <p className="color-outer-space e-poppins-medium e-font-12 line-height-22px mb-0 ">
                                *Approx. return percentage: 
                                <span className="color-outer-space e-poppins-medium e-font-12 ms-1">{investmentStyle.goal_returns_txt}</span>
                            </p>
                        </div>
                    </div>

                    {/* SIP amount */}
                    <div className='col-lg-6 col-md-6 col-12 mt-sm-4 mt-2 '>
                        <div className={`${windowWidth > 576 ? 'border-radius-16px border-all border-bright-gray p-4' : 'pt-2'}  min-height-144px-sm-8rem` }>
                            <CustomAmountInput value={sipAmount}
                                label="SIP amount"
                                inputClass="bg-transparent padding-28px-left"
                                labelClass="bg-mint-green"
                                showRupeePrefix={true}
                                postfix="*"
                                handleChange={(e) => {
                                    setGoalCalculationStatus(true);
                                    if (e.length === 0) {
                                        setSipAmount(0);
                                        setSelectedGoalType(INVESTMENT_TYPES[1].id)
                                    }
                                    else {
                                        setSipAmount(e)
                                        setSelectedGoalType(INVESTMENT_TYPES[0].id)
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {/* lumpsum amount */}
                    <div className='col-lg-6 col-md-6 col-12 mt-sm-4 mt-3 pe-0  '>
                        <div className={`${windowWidth > 576 ? 'border-radius-16px border-all border-bright-gray p-4' : ''} min-height-144px-sm-8rem`}>
                            <CustomAmountInput value={lumpsum}
                                label="Lumpsum amount"
                                inputClass="bg-transparent padding-28px-left"
                                labelClass="bg-mint-green"
                                showRupeePrefix={true}
                                handleChange={(e) => {
                                    setGoalCalculationStatus(true);
                                    if (e.length === 0) {
                                        setLumpsum(0)
                                        setSelectedGoalType(INVESTMENT_TYPES[0].id)
                                    } else {
                                        setLumpsum(e)
                                        setSelectedGoalType(INVESTMENT_TYPES[1].id)
                                    }
                                }} />
                        </div>
                    </div>

                    {/* SIP tenure */}
                    <div className='col-lg-6 col-md-6 col-12 mt-sm-4 mt-2'>
                        <div className='border-radius-16px border-all border-bright-gray p-sm-4 p-2 min-height-144px-sm-8rem'>
                            <div className='d-flex justify-content-between mb-3'>
                                <RepresentationLabel type={1}
                                    label={"SIP tenure"}
                                    mandatory={true} />
                                <RepresentationLabel type={2}
                                    value={sipTenure}
                                    postfix={sipTenure == 1 ? " Year" : " Years"} />
                            </div>

                            <CustomSlider min={1}
                                max={50}
                                value={sipTenure}
                                _handleChange={(e) => {
                                    setGoalCalculationStatus(true);
                                    setSipTenure(e)
                                }} />
                            <div className='d-flex justify-content-between'>
                                <RepresentationLabel type={3}
                                    value={1}
                                    postfix={" Year"}
                                    whiteSpace={true} />

                                <RepresentationLabel type={3}
                                    value={50}
                                    postfix={" Years"}
                                    whiteSpace={true} />
                            </div>
                        </div>
                    </div>

                    {/* SIP increment */}
                    <div className='col-lg-6 col-md-6 col-12 mt-sm-4 mt-3 pt-sm-0 pt-2 pe-0'>
                        <div className='border-radius-16px border-all border-bright-gray p-sm-4 p-2 min-height-144px-sm-8rem'>
                            <div className='d-flex justify-content-between mb-3'>
                                <RepresentationLabel type={1}
                                    label={"Increase my SIP annually"}
                                    mandatory={false} />
                                <RepresentationLabel type={2}
                                    value={sipIncrement}
                                    postfix={'%'} />
                            </div>

                            <CustomSlider min={0}
                                max={50}
                                value={sipIncrement}
                                _handleChange={(e) => {
                                    setGoalCalculationStatus(true);
                                    setSipIncrement(e)
                                }} />
                            <div className='d-flex justify-content-between'>
                                <RepresentationLabel type={3}
                                    value={0}
                                    postfix={" %"} />
                                <RepresentationLabel type={3}
                                    value={50}
                                    postfix={" %"} />
                            </div>
                        </div>
                    </div>

                    <Fragment>
                        {/* Total value banner */}
                        <div className="col-12 mt-sm-4 mt-3 pe-0">
                            <div className="bg-off-white border-radius-16px ps-4 py-4 position-relative">
                                <div className="row ">
                                    <div className="col-lg-8 col-md-8 col-12 ">
                                        <p className="color-black e-poppins-semi-bold e-font-16">
                                            Total value according to the information you just provided.
                                        </p>
                                        <p className="color-black e-poppins-bold e-font-24 line-height-32px">
                                            <span className="e-inter-bold">₹</span>{corpusAmount.toLocaleString("en-IN")}
                                        </p>
                                        <p className="color-outer-space e-poppins-regular e-font-14 margin-block-end-0">
                                            Invested amount: <span className="color-black e-inter-regular e-font-16">₹</span>
                                            <span className="color-black e-poppins-medium e-font-16">{investmentAmount.toLocaleString("en-IN")}</span>
                                        </p>
                                        <div className="d-flex align-items-center mt-1">
                                            <p className="color-outer-space e-poppins-regular e-font-14 me-1 mb-0">Est. returns:</p>
                                            <p className="color-black e-poppins-medium e-font-16 me-1 mb-0">
                                                <span className="e-inter-regular ">₹
                                                </span>{expectedReturn.toLocaleString("en-IN")}</p>
                                            <img src={require('../../Assets/Images/retirement/est-arrow-up.png')}
                                                alt="Arrow up"
                                                color={returnPercent < 0 && Color.red}
                                                className={returnPercent < 0 && "e-rotate-180"}
                                                width={16}
                                                height={16} />
                                            <p className={`${returnPercent < 0 ? "color-red" : "color-ufo-green"} e-poppins-semi-bold e-font-12 mb-0`}>{returnPercent.toLocaleString("en-IN")}%</p>

                                        </div>
                                        <p className="color-outer-space e-poppins-regular e-font-14  mt-1">
                                            At the date of: <span className="color-black e-poppins-medium e-font-16">{endDate}</span>
                                        </p>
                                    </div>

                                </div>
                                <img src={require('../../Assets/Images/fund-suggestion/total-value.svg').default}
                                    className=" position-absolute bottom-0 right-0 e-total-value-img"
                                    alt="" />
                            </div>
                        </div>

                        {/* Recommended investment banner */}
                        <div className="col-12 mt-4 pe-0">
                            <div className="bg-minty-cream  border-radius-16px p-4 border-all border-anti-flash-white w-100">
                                <div className="d-lg-flex d-md-flex d-sm-flex d-block justify-content-between align-items-center ">
                                    <div className="d-flex align-items-center gap-8px me-sm-4">
                                        <img src={require('../../Assets/Images/fund-suggestion/Pie-chart.svg').default}
                                            alt="" />
                                        <p className="color-black e-poppins-regular e-font-16 line-height-22px-sm-26px mb-0 ">
                                            Based on the risk analysis, it has been determined that your recommended investment style is:
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center gap-10px position-relative mt-lg-0 mt-md-0 mt-sm-0 mt-3 e-risk-analysis-wrapper">
                                        <div className="bg-white border-radius-16px px-4 py-3 d-flex align-items-center gap-16px">
                                            <img src={investmentStyle.image}
                                                alt={investmentStyle.label}
                                                width={48}
                                                height={24} />
                                            <span className="color-primary-color e-poppins-medium e-font-16"
                                                style={{ color: _getColorForInvestmentStyle(investmentStyle.label) }}>{investmentStyle.label}</span>
                                        </div>
                                        <p className="cursor-pointer mb-0 position-relative"
                                            onMouseEnter={_handleShowTooltip}
                                            onMouseLeave={_handleShowTooltip}
                                            onClick={() => setShowPopupFilter(true)}>
                                            {EDIT_FUND_DISTRIBUTION}
                                            {
                                                showEditTooltip === true &&
                                                <p className="color-outer-space e-poppins-regular e-font-12 line-height-12px position-absolute e-edit-tooltip bg-white border-radius-4px ">Edit</p>
                                            }
                                        </p>
                                        {
                                            showPopupFilter === true && (
                                                <InvestmentStylePopup popUpStatus={showPopupFilter}
                                                    selectedData={investmentStyle}
                                                    investmentStyles={INVESTMENT_STYLES}
                                                    filterChange={(e) => {
                                                        setGoalCalculationStatus(true);
                                                        setInvestmentStyle(e)
                                                    }}
                                                    closePopUp={() => setShowPopupFilter(false)} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* <RadioGroup data={goalType}
                            className={`gap-24px mt-3`}
                            type={2}
                            id={selectedGoalType}
                            radioHandler={_handleRadioInput} /> */}

                        {/* Portfolio break down */}
                        <div className="col-12 mt-4 pe-0">
                            <div className="p-4 border-radius-16px bg-baby-powder">
                                <div className="row">
                                    <div className="col-12 d-flex align-items-center gap-8px">
                                        <p className="color-black e-poppins-semi-bold e-font-16 mb-0">
                                            Fund distribution
                                            (<span className="color-primary-color e-poppins-regular e-font-14"
                                                style={{ color: _getColorForInvestmentStyle(investmentStyle.label) }}>
                                                {investmentStyle.label}
                                            </span>)
                                        </p>
                                        <RadioGroup data={goalType}
                                            radioItem=" mt-0"
                                            iconClassName="me-2"
                                            type={1}
                                            id={selectedGoalType}
                                            radioHandler={_handleRadioInput} />
                                    </div>
                                    <div className="row">
                                        {/* Chart  */}
                                        {
                                            fundDetail.fund_distribution.length > 0 ? (
                                                <div className="col-lg-6 col-md-6 col-12">
                                                    {console.log('fundDetail.fund_distribution.length ', fundDetail.fund_distribution.length)}
                                                    {/* Bar percentage */}
                                                    <div className="position-relative border-radius-8px h-24px w-100 mt-3"
                                                        style={{ backgroundColor: fundDetail.fund_distribution.length > 1 ? fundDetail.fund_distribution[1].color : fundDetail.fund_distribution[0].color }}>
                                                        <div className="position-absolute border-radius-8px h-24px top-0 left-0"
                                                            style={{ width: fundDetail.fund_distribution[0].percentage, backgroundColor: fundDetail.fund_distribution[0].color }}></div>
                                                    </div>
                                                    {/* Bar legend */}
                                                    <div className="d-flex justify-content-between align-items-center border-bottom mt-3 pb-2">
                                                        <div>
                                                            <div className=" border-radius-4px h-16px w-16px d-inline-block"
                                                                style={{ backgroundColor: fundDetail.fund_distribution[0].color }}></div>
                                                            <span className="color-outer-space e-poppins-medium e-font-16 line-height-22px align-text-bottom ms-1">
                                                                {fundDetail.fund_distribution[0].name} {fundDetail.fund_distribution[0].percentage}
                                                            </span>
                                                        </div>

                                                        <span className="color-outer-space e-poppins-medium e-font-16 ">
                                                            <span className=" e-inter-medium ">₹</span>{fundDetail.fund_distribution[0].amount.toLocaleString("en-IN")}
                                                        </span>

                                                    </div>
                                                    {
                                                        fundDetail.fund_distribution.length > 1 && (
                                                            <div className="d-flex justify-content-between align-items-center border-bottom mt-3 pb-2">
                                                                <div>
                                                                    <div className=" border-radius-4px h-16px w-16px d-inline-block"
                                                                        style={{ backgroundColor: fundDetail.fund_distribution[1].color }}></div>
                                                                    <span className="color-outer-space e-poppins-medium e-font-16 line-height-22px align-text-bottom ms-1">
                                                                        {fundDetail.fund_distribution[1].name} {fundDetail.fund_distribution[1].percentage}
                                                                    </span>
                                                                </div>

                                                                <span className="color-outer-space e-poppins-medium e-font-16 ">
                                                                    <span className=" e-inter-medium ">₹</span>{fundDetail.fund_distribution[1].amount.toLocaleString("en-IN")}
                                                                </span>

                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            ) : null
                                        }


                                        {/* Investment button */}
                                        <div className="col-6 text-end mt-4 e-btn-d-none">
                                            <GradientButton label="Invest now"
                                                className="padding-14px-tb padding-40px-lr mt-2"
                                                handleClick={_validateForm}
                                                loading={apiLoader} />
                                        </div>
                                    </div>

                                    {/* Table data */}
                                    <div className="row mt-4 e-fund-suggestion-table ps-3 pe-0">
                                        <FundSuggestionTable data={fundDetail.fund_allocation} />
                                    </div>
                                    {
                                        USER_DATA.is_kyc_done === false ? (
                                            <div className="row mt-4 pe-0">
                                                <div className="col-12 pe-0">
                                                    <CreateAccountBanner />
                                                </div>
                                            </div>
                                        ) : USER_DATA.is_assessment_completed === false ? (
                                            <div className="row mt-4 pe-0">
                                                <div className="col-12 pe-0">
                                                    <CompleteRiskScore />
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                </div>
                            </div>
                        </div>
                    </Fragment>

                    {/* Button */}
                    <div className="col-12 text-center mt-sm-5 mt-3">
                        {
                            KYC_STEP.kyc_step === 0 ?
                                <OutlineButton label="Create an account"
                                    className="padding-10px-tb padding-13px-lr  w-mobile-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3"
                                    iconClass="ms-2 align-bottom"
                                    handleClick={_redirectToKyc} />
                                :
                                USER_DATA.is_kyc_done === true ?
                                    <OutlineButton label="Need assistance to invest"
                                        className="padding-10px-tb padding-13px-lr  w-mobile-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3"
                                        icon="phone-rounded"
                                        iconSize={24}
                                        iconClass="ms-2 align-bottom"
                                        handleClick={() => {
                                            setOpenAppointmentModal(true)
                                        }} />
                                    :
                                    null
                        }

                        <GradientButton label="Invest now"
                            className="padding-12px-tb padding-40px-lr ms-lg-3 ms-md-3 ms-sm-3 ms-0 w-mobile-100"
                            handleClick={_validateForm} />
                    </div>
                </div>


                {/* goal investment modal */}
                {
                    openInvestModal === true && (
                        <InvestGoalModal investmentType={2}
                            goalId={goalDetails.goal_data.id}
                            goalSummary={goalDetails}
                            mfInvestmentData={goalDetails}
                            close={() => {
                                setOpenInvestModal(false)
                            }}
                            navigateToProfile={_navigateToProfile}
                            showInProcessModal={_openMfInProcessModal}
                            getPaymentStatus={_getPaymentStatus} />
                    )

                }

                {/* book a call with MT team */}
                {
                    openAppointmentModal === true &&
                    <AppointmentModal
                        slots={timeSlots}
                        close={() => {
                            setOpenAppointmentModal(false);
                        }} />
                }

                {
                    openPaymentStatusModal === true && (
                        <PaymentStatusModal status={paymentStatus}
                            close={() => setOpenPaymentStatusModal(false)}
                            paymentStatus={paymentStatusText}
                            type={isNextMonth === true ? 5 : 2}
                             />
                    )
                }
                {
                showMfInProcessModal === true &&
                    <MfInProcessStatus message={inProcessMessage} 
                        close={()=> setShowMfInProcessModal(false)}/>
            }
            </Fragment>
    )
})

export default GoalSummary;

GoalSummary.defaultProps = {
    title: ''
}
