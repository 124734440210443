/*
 *   File : create-mandate.js
 *   Author URI : https://evoqins.com
 *   Description : Screen to create bank mandate for an already existing bank account.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import Icon from "../Icon/icon";
import { GradientButton } from "../CTA";
import { CustomAmountInput, DatePicker } from "../Form-elements";
import { Amountchip } from "../Other";
// import { _authenticateMandate, _createMandate } from "../../Helper/api";

import APIService from "../../Service/api-service";
import { _mandateStatus, _authenticateMandate, _createMandate, _createPhysicalMandate } from "../../Helper/api";

import style from "../../Styles/Component/show-mandate.module.scss";

const AMOUNT_SUGGESTIONS = [
    100000,
    200000,
    300000,
    400000,
    500000
]

const today = new Date();
const min_date = new Date(today);
min_date.setFullYear(min_date.getFullYear() + 1);

const max_date = new Date(today);
max_date.setFullYear(max_date.getFullYear() + 40);
max_date.setMonth(max_date.getMonth() - 1); 

const default_date = new Date(today);
default_date.setFullYear(default_date.getFullYear() + 5);

const start_date = new Date(today); // Initialize with the provided 'today' date
start_date.setMonth(start_date.getMonth() + 1);

const end_date = new Date(start_date);
end_date.setMonth(end_date.getMonth() + 1);

const CreateMandateModal = (props) => {
    var timer; // global timer for payment url status check
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [selectedBank, setSelectedBank] = useState(props.bankData[0].bank_id);
    const [apiLoader, setApiLoader] = useState(false);
    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");
    const [expiryDate, setExpiryDate] = useState(format(max_date, "dd-MM-yyyy"));
    const [expiryDateError, setExpiryDateError] = useState("");
    const [startDate, setStartDate] = useState(format(start_date, "dd-MM-yyyy"));

    useEffect(() => {
        var my_modal = document.getElementById("show-mandate");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            clearInterval(timer);
        });
    }, [props.close]);

    const _handleCancel = () => {
        props.close();
    }

    function _handleDateChange(value) {
        setExpiryDate(value);
        setExpiryDateError("");
    }

    function _handleStartDate (value) {
        setStartDate(value);
    }

    function _handleAmountInput(value) {
        setAmount(value);
        setAmountError("");
    }

    function _handleChipSelect(index) {
        setAmount(AMOUNT_SUGGESTIONS[index]);
        setAmountError("");
    }

    function _validateMandateForm() {
        let error = false;
        if (amount === '') {
            setAmountError('Amount is required');
            error = true;
        }
        if (expiryDate === null) {
            setExpiryDateError("Expiry date in required");
            error = true;
            console.log('Expriy errror')
        }
        return error;
    };

    function _fileDownloader(url, file_name) {
        const link = document.createElement('a');
        link.href = url;
        link.download = file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const _handleCreateMandate = async () => {
        let valid_form = await _validateMandateForm();
        if (valid_form === true) {
            let id = document.getElementById('limit-input');
            id.scrollIntoView({ behavior: 'smooth' });
            return;
        }
        setApiLoader(true);

        let mandate_data = null;
        let response_ath_mandate = null;
        _createMandate(selectedBank, amount, expiryDate)
            .then((response) => {
                if (response.status_code === 200) {
                    mandate_data = response.data.mandate_id;
                    // Using a function to authenticate mandate recursively until success
                    const authenticateMandate = () => {
                        _authenticateMandate(response.data.mandate_id)
                            .then((response) => {
                                if (response.status_code === 200) {
                                    // If authentication successful, proceed to open payment URL
                                    _openPaymentUrl(response.authentication_url, mandate_data);
                                } else {
                                    // If authentication failed, retry after 1 second
                                    setTimeout(authenticateMandate, 1000);
                                }
                            })
                            .catch((error) => {
                                setTimeout(authenticateMandate, 1000);
                                // toast.dismiss();
                                // toast.error(error.message, {
                                //     type: 'error'
                                // });
                            });
                    };
                    // Start authenticating mandate
                    authenticateMandate();
                } else {
                    // If creating mandate fails, show error message
                    toast.error(response.message, {
                        type: 'error'
                    });
                }
            })
            .catch((error) => {
                // If an error occurs during mandate creation, handle it
                setApiLoader(false);
                toast.dismiss();
                toast.error(error.message, {
                    type: 'error'
                });
            });
    };

    // API create physical madnate
    const _handleCreatePhysicalMandate = async () => {
        let valid_form = await _validateMandateForm();
        if (valid_form === true) {
            let id = document.getElementById('limit-input');
            id.scrollIntoView({ behavior: 'smooth' });
            return;
        }
        setApiLoader(true);

        let mandate_data = null;
        let response_ath_mandate = null;
        _createPhysicalMandate(selectedBank, amount, startDate, expiryDate)
            .then((response) => {
                if (response.status_code === 200) {
                    mandate_data = response.data.mandate_id;
                    _fileDownloader(response.data.url, 'Mandate form');
                    let close_button = document.getElementById("close-mandate-modal");
                    if (close_button != null) {
                        close_button.click();
                    }
                    props.updateList(true);
                } else {
                    // If creating mandate fails, show error message
                    toast.error(response.message, {
                        type: 'error'
                    });
                }
            })
            .catch((error) => {
                // If an error occurs during mandate creation, handle it
                setApiLoader(false);
                toast.dismiss();
                toast.error(error.message, {
                    type: 'error'
                });
            });
    };


    // API - authenticate mandate
    function _mandateAuthentication(mandate_id) {
        let timer = setInterval(() => {
            _authenticateMandate(mandate_id).then((response) => {
                if (response.status_code === 200) {
                    // url is not null open url 
                    if (response.authentication_url !== null) {
                        _openPaymentUrl(response.authentication_url, mandate_id);
                        clearInterval(timer);
                    }


                }
            })
        }, 5000);
    };

    // API - check mandate status
    function _checkMandateStatus(mandate_id) {
        _mandateStatus(mandate_id).then((response) => {
            if (response.status_code === 200) {
                _paymentCallback(response.data.status);
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })
    }

    function _openPaymentUrl(url, mandate_id) {
        if (!url.startsWith("https://")) {
            url = "https://" + url;
        }
        var new_window;
        new_window = window.open(url, '_blank', 'width=800,height=800');
        if (new_window !== null) {
            new_window.opener = null;
            timer = setInterval(function () {
                if (new_window.closed) {
                    clearInterval(timer);
                    setApiLoader(false);
                    _checkMandateStatus(mandate_id);
                }
            }, 1000);
        } else {
            setApiLoader(false);
            toast.dismiss();
            toast.error('Popups are disabled in your browser. Please allow popups to open the payment gateway.', {
                type: 'error'
            });
        }
    }

    function _paymentCallback(status) {
        let close_button = document.getElementById("close-mandate-modal");
        if (close_button != null) {
            close_button.click();
        }
        props.getPaymentStatus(status);
    }

    const _handleSelectBank = (id) => {
        setSelectedBank(id)
    }

    function _handleDatePickerFocus() {
        let element = document.getElementById('footer-div');
        setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth' })
        }, 200);

    }

    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);


    return (
        <div className={`modal fade ${style.e_create_mandate}`}
            id="show-mandate"
            tabIndex="-1"
            aria-labelledby="show-mandate"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-all ${style.e_content} `}>
                    <div className="d-flex align-items-center justify-content-between mb-4">
                        <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">
                            {props.type === 1 ? "Configure mandate" : "Configure scan-based mandate"}
                        </p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className="cursor-pointer e-modal-close-btn"
                            onClick={_handleCancel} />
                        <button id="close-mandate-modal"
                            data-bs-dismiss="modal"
                            className="d-none"></button>
                    </div>
                    <div className={`${style.content_wrapper}`} id="modal-body">
                        <div className="border-all border-bright-gray p-3 border-radius-16px ">
                            <div className="d-flex gap-16px align-items-center margin-24px-bottom">
                                <img src={require("../../Assets/Images/profile/select-bank.png")}
                                    alt="Select bank"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                                <p className="color-jett-black e-poppins-medium e-font-16 mb-0">Select bank</p>
                            </div>
                            <div className={`d-flex flex-column gap-8px `}>
                                {
                                    props.bankData.map((item) =>
                                        <div className={`border-radius-8px p-sm-3 p-2 cursor-pointer ${selectedBank === item.id ? "bg-pale-mint" : "bg-grayish-white"}`}
                                            key={item.bank_id}
                                            onClick={() => _handleSelectBank(item.bank_id)}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex  align-items-center gap-12px-sm-8">
                                                    <img src={item.bank_image}
                                                        alt={item.bank_name}
                                                        width={36}
                                                        height={36}
                                                        className="border-radius-100 object-fit-contain" />
                                                    <div className="d-flex flex-wrap align-items-center gap-12px">
                                                        <p className="color-black e-poppins-semi-bold e-font-sm-16-14 line-height-22px mb-0">{item.bank_name}</p>
                                                        <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-16px mb-0">{item.masked_account_number}</p>
                                                    </div>
                                                </div>
                                                <Icon icon={selectedBank === item.bank_id ? "radio-selected" : "radio-unselected"}
                                                    size={25} />
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        {
                            props.type === 2 &&
                            <div className="row gx-0 mt-3">
                                <div className="col-12">
                                    <h5 className="color-jett-black e-poppins-medium e-font-16 ">Set date</h5>
                                </div>
                                <div className="col-md-6 col-12 pe-md-2">
                                    <DatePicker label="Start date"
                                        value={startDate}
                                        minDate={start_date}
                                        maxDate={end_date}
                                        dateFormat={'dd-MM-yyyy'}
                                        displayFormat={'dd MM yyyy'}
                                        removeLabelOnFocus={true}
                                        error={expiryDateError}
                                        calendarClass="position-relative top-0"
                                        openDateModal={_handleDatePickerFocus}
                                        dob={_handleStartDate} />
                                </div>
                                <div className="col-md-6 col-12 ps-md-2 mt-md-0 mt-4">
                                    <DatePicker label="End date"
                                        value={expiryDate}
                                        minDate={min_date}
                                        maxDate={max_date}
                                        dateFormat={'dd-MM-yyyy'}
                                        displayFormat={'dd MM yyyy'}
                                        removeLabelOnFocus={true}
                                        error={expiryDateError}
                                        calendarClass="position-relative top-0"
                                        openDateModal={_handleDatePickerFocus}
                                        dob={_handleDateChange} />
                                </div>
                                <div className="col-lg-11">
                                    <div className="d-flex gap-8px bg-mint-cream p-2  border-radius-8px mt-3">
                                        <div className="w-max-content">
                                            <Icon icon="info"
                                                size={24} />
                                        </div>
                                        <p className="e-font-14 e-poppins-regular line-height-24px color-jett-black mb-0">
                                            The start date for this process will be effective from T+29 days.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }


                        <div id="limit-input" className="mt-3">
                            <h5 className="color-jett-black e-poppins-medium e-font-16 mb-0">Set your mandate limit</h5>

                            <CustomAmountInput value={amount}
                                key={"SIP amount"}
                                label="Amount*"
                                className="mt-3"
                                error={amountError}
                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left  border-radius-12px"
                                labelClass=" e-font-16 line-height-26px bg-white"
                                handleChange={_handleAmountInput} />
                            <div className="d-flex flex-wrap gap-8px mt-4" id="footer-div">
                                {
                                    AMOUNT_SUGGESTIONS.map((item, key) => {
                                        return (
                                            <Amountchip value={item}
                                                key={key}
                                                amount={amount}
                                                index={key}
                                                handleChipSelect={() => _handleChipSelect(key)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            props.type === 1 &&
                            <div className="my-3">
                                <DatePicker label="Expiry date"
                                    value={expiryDate}
                                    minDate={min_date}
                                    maxDate={max_date}
                                    dateFormat={'dd-MM-yyyy'}
                                    displayFormat={'dd MM yyyy'}
                                    removeLabelOnFocus={true}
                                    error={expiryDateError}
                                    calendarClass="position-relative top-0"
                                    openDateModal={_handleDatePickerFocus}
                                    dob={_handleDateChange} />
                            </div>
                        }

                    </div>
                    {
                        props.type === 1 ?
                            <div className="mx-5 mt-3">
                                <GradientButton label="Create mandate"
                                    className="w-100 padding-12px-tb e-font-16"
                                    // disabled={USER_DATA.is_kyc_done === false ? true : false}
                                    loading={apiLoader}
                                    handleClick={_handleCreateMandate} />
                            </div>
                            :
                            <div className="mx-5 mt-3">
                                <GradientButton label="Submit"
                                    className="w-100 padding-12px-tb e-font-16"
                                    // disabled={USER_DATA.is_kyc_done === false ? true : false}
                                    loading={apiLoader}
                                    handleClick={_handleCreatePhysicalMandate} />
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default CreateMandateModal