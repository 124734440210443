/*
 *   File : switch.js
 *   Author : https://evoqins.com
 *   Description : Toggle component
 *   Integrations : null
 *   Version : 1.0.0
*/

// import style 
import { useState, useEffect } from 'react';

import Color from "../../Styles/color.module.scss"
import style from '../../Styles/Component/toggle.module.scss'

const Toggle = (props) => {
    const [leftPosition, setLeftPosition] = useState(props.check);
    
    useEffect(()=>{
        setLeftPosition(props.check)
    },[props.check])

    const _handleClick = () => {
        props.onChange(!leftPosition);
        setLeftPosition(!leftPosition);
    }

    return (
        <>
            {
                props.type === 1 ?
                    <div className={`border-radius-16px position-relative z-index-1 cursor-pointer ${style.e_switch}  ${props.className}`}
                        style={{
                            backgroundColor: leftPosition ?
                                props.leftColor ?
                                    props.leftColor :
                                    Color.light_gray
                                :
                                props.barColor ?
                                    props.barColor :
                                    Color.primary
                        }}
                        onClick={_handleClick}>
                        <div className={`bg-white border-radius-100 position-absolute cursor-pointer z-index-1 ${style.e_head} `}
                            style={{ left: leftPosition === true ? '0' : '50%' }}
                        // onClick={_handleClick}
                        ></div>
                    </div>
                    :
                    <div className={`border-radius-16px position-relative z-index-1 cursor-pointer ${style.e_switch}  ${props.className}`}
                        style={{
                            backgroundColor: leftPosition ?
                                    Color.primary
                                :
                                    Color.light_gray
                                    
                        }}
                        onClick={_handleClick}>
                        <div className={`bg-white border-radius-100 position-absolute cursor-pointer z-index-1 ${style.e_head} `}
                            style={{ left: leftPosition === true ? '50%' : '0' }}
                        // onClick={_handleClick}
                        ></div>
                    </div>
            }
        </>

    )
}

Toggle.defaultProps = {
    type: 1,
}

export default Toggle;
