
/*
 *   File :  manage-nominee.js
 *   Author URI : https://evoqins.com
 *   Description : Nominee page
 *   Integrations : null
 *   Version : v1.1
 */
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Color from '../../Styles/color.module.scss';

import { InvestmentHeader } from "../../Component/Header";
import { BreadCrumb } from "../../Component/BreadCrumb";
import Footer from "../../Component/Footer/footer";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import { OutlineButton } from "../../Component/CTA";
import { DataTable } from "../../Component/Form-elements";
import { ManageNomineeModal } from "../../Component/Modal";

import APIService from "../../Service/api-service";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage nominee', url: null }
];

const NOMINEE = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: false,
        // width: "150px",
    },
    {
        name: 'Relation',
        selector: row => row['relationship'],
        sortable: false,
        width: "120px",
    },

    {
        name: 'Date of Birth',
        selector: row => row['date_of_birth_str'],
        sortable: false,
        width: "120px",
    },
    {
        name: 'Allocation%',
        selector: row => row['allocation'],
        sortable: false,
        width: "120px",
    }

];

// nominee table style
const NOMINEE_STYLES = {
    table: {
        style: {
            paddingLeft: "0px",
            paddingRight: "0px"
        }
    },
    headRow: {
        style: {
            gap: "56px",
        }
    },
    headCells: {
        style: {
            color: Color.black,
            fontSize: "1rem",
            fontFamily: "Poppins-Medium",
            fontWeight: "500",
            lineHeight: "1.125rem",
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        }
    },
    rows: {
        style: {
            gap: "56px",
            '&:last-child': {
                marginBottom: '0',
            },
        },
    },
    cells: {
        style: {
            color: Color.black,
            fontFamily: "Poppins-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "20px",
            justifyContent: "center",
        }
    }
};

export default function ManageNominee(props) {
    const navigate = useNavigate();
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [nomineeData, setNomineeData] = useState(null);
    const [emptyNomineeList, setEmptyNomineeList] = useState(false);
    const [openNomineeModal, setOpenNomineeModal] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getNomineeData();
    }, []);

    // listener for open manage nominee modal
    useEffect(() => {
        if (openNomineeModal === true) {
            const modal = new Modal(document.getElementById("manage-nominee"), {});
            modal.show();
        }
    }, [openNomineeModal]);


    function _handleNoNominee() {
        if (USER_DATA.is_kyc_done == true) {
            _handleManageNominee();
        } else {
            navigate('/kyc');
        }
    }

    // open nominee modal
    const _handleManageNominee = () => {
        setOpenNomineeModal(true);
    }

    // close nominee modal
    const _handleCloseNomineeModal = () => {
        setOpenNomineeModal(false);
    }

    // API - get nominee data
    function _getNomineeData() {
        setApiLoader(true);
        let url = 'profile/nominee/list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setNomineeData(response.data);
            } else {
                if (response.status_code === 400) {
                    setEmptyNomineeList(true);
                }
            }
            setApiLoader(false);
        })
    }

    return (
        <Fragment>
            <div className="e-page-container margin-80px-bottom e-explore">
                {/* Header */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />
                {
                    apiLoader === true ? (
                        <div className="h-80vh">
                            <CustomLoader />
                        </div>
                    ) : (
                        <div className="border-radius-16px border-all border-bright-gray py-4 mt-4 padding-32px-left pe-4">
                            {
                                nomineeData == null || (nomineeData.nominees.length === 0 && nomineeData.skip_nominee === false) ?
                                    <EmptyScreen image={require('../../Assets/Images/nodata/general.svg').default}
                                        title={USER_DATA.is_kyc_done == true ? "Add nominee to your investment account" : USER_DATA.kyc_step === 0 ? "Create an account to add nominee" : "Resume with account creation to add nominee"}
                                        description={USER_DATA.is_kyc_done == true ? "Declare your nominees here" : USER_DATA.kyc_step === 0 ? "Declare your nominees after creating account" : "Declare your nominees in account account creation process"}
                                        buttonLabel={USER_DATA.is_kyc_done == true ? "Add new nominee" : USER_DATA.kyc_step === 0 ? "Create account" : "Resume KYC"}
                                        // disabled={USER_DATA.is_kyc_done === false ? true : false}
                                        onPress={_handleNoNominee} />

                                    :
                                    <>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <p className="color-black e-poppins-medium e-font-16 line-height-16 mb-3">Nominee type selected</p>
                                                <p className="color-black e-poppins-medium e-font-14 line-height-16px mb-0">{nomineeData.skip_nominee === false ? 'Opt-in' : 'Opt-out'}</p>
                                            </div>
                                            <OutlineButton label="Edit or add "
                                                icon="Pen-square"
                                                className="p-2 e-font-16"
                                                disabled={nomineeData.skip_nominee === true ? false : USER_DATA.is_kyc_done === false ? true : false}
                                                iconSize={24}
                                                handleClick={_handleManageNominee} />
                                        </div>

                                        {
                                            nomineeData.nominees.length !== 0 && (
                                                <>
                                                    <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-3 margin-50px-top">Your nominees ({nomineeData.total_nominees})</p>
                                                    <DataTable columns={NOMINEE}
                                                        data={nomineeData.nominees}
                                                        pagination={false}
                                                        customStyles={NOMINEE_STYLES} />
                                                </>
                                            )
                                        }
                                    </>
                            }
                        </div>
                    )
                }

            </div>

            <Footer />
            {
                openNomineeModal === true ?
                    <ManageNomineeModal data={nomineeData !== null ? nomineeData.nominees : []}
                        close={_handleCloseNomineeModal}
                        update={_getNomineeData} />
                    :
                    null
            }
        </Fragment>
    )
}