/*
 *   File : remove-goal.js
 *   Author URI : https://evoqins.com
 *   Description : Remove goal modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";

import { GradientButton, OutlineButton } from "../CTA";

import style from "../../Styles/Component/remove-goal.module.scss"

const RemoveGoalModal = (props) => {

    const { close } = props;
    useEffect(() => {
        var my_modal = document.getElementById("remove-goal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            close();
        });
    }, [close]);

    const _handleCancel = () => {
        close();
    }

    const _handleDelete = () => {
        props.confirmDelete();
    }

    return (
        <div className={`modal fade ${style.e_remove}`}
            id="remove-goal"
            tabIndex="-1"
            aria-labelledby="remove-goal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_remove_dialog}`}>
                <div className={`modal-content bg-white position-relative text-center d-flex align-items-center ${style.e_remove_content} `}>
                    <img src={require("../../Assets/Images/invest-in-goals/delete-modal-icon.png")}
                        alt="Risk assessment modal"
                        width={72}
                        height={72} />
                    <p className="color-eerie-black e-poppins-medium e-font-18 my-4">Deleting is a permanent action which will remove all your saved data.</p>
                    <div className="d-flex gap-8px">
                        <OutlineButton label="No, cancel"
                            cancel="modal"
                            className="padding-12px-tb padding-24px-lr e-font-16 e-poppins-regular"
                            handleClick={_handleCancel} />
                        <GradientButton label="Yes, delete"
                            className="padding-12px-tb padding-70px-lr e-font-16"
                            cancel="modal"
                            handleClick={_handleDelete} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoveGoalModal