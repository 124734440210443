

/*
 *   File : mandate.js
 *   Author URI : https://evoqins.com
 *   Description : Card for showing mandate 
 *   Integrations : null
 *   Version : v1.1
 */
import { useState } from "react";
import Icon from "../Icon/icon";

export default function MandateCard(props) {
    const [selectedMandate, setSelectedMandate] = useState(0);

    return (
        <div className="border-bright-gray border-all border-radius-24px p-4">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-end">
                    <img src={props.data.bank_icon}
                        alt='' 
                        width={40}
                        height={40}
                        className="border-radius-100"/>
                    <h5 className='color-black e-poppins-semi-bold e-font-15 white-space-nowrap ms-2'>{props.data.bank_name}</h5>
                </div>
                <p className='color-gray e-poppins-medium e-font-14 margin-block-end-0'>
                    Mandate ID: <span className='color-black e-poppins-medium e-font-14 ms-1'>{props.data.mandate_id}</span>
                </p>
                <p className='color-gray e-poppins-medium e-font-14 margin-block-end-0'>
                    Mandate amt./day: <span className='color-black e-inter-medium e-font-14 ms-1'>₹{props.data.mandate_limit.toLocaleString('en-IN')}</span>
                </p>
                {
                    props.type === 1 && (
                        <Icon icon={selectedMandate === props.data.id ? "delete-red" : "delete"}
                            size={24} 
                            onMouseEnter={()=> setSelectedMandate(props.data.id)}
                            onMouseLeave={()=> setSelectedMandate(null)}
                            className='cursor-pointer'/>
                    )
                }

            </div>
            {
                props.type === 2 && (
                    <div className="border-top-1px border-bright-gray d-flex mt-3 pt-3">
                        <p className="color-gray e-poppins-medium e-font-14 margin-block-end-0">
                            Cancelled on: <span className='color-orange-soda e-poppins-medium e-font-14 ms-1'>{props.data.cancelled_on}</span>
                        </p>
                        <p className="color-gray e-poppins-medium e-font-14 margin-block-end-0 ms-5">
                            Cancelled by:
                            <span className='color-black e-poppins-medium e-font-14 ms-1 text-capitalize'>
                                {props.data.cancelled_by}
                            </span>
                        </p>
                    </div>
                )
            }
        </div>
    )
}