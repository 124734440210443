/*
 *   File : remove-external-fund.js
 *   Author URI : https://evoqins.com
 *   Description : UI component which enables the customer to remove the external funds from the MT account. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Icon from "../Icon/icon";
import { GradientButton, OutlineButton } from "../CTA";

import APIService from "../../Service/api-service";

import style from "../../Styles/Component/external-fund-status.module.scss"

const RemoveExternalFundModal = (props) => {

    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("remove-external-fund-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const _handleDelete = () => {
        setButtonLoader(true);
        let url = 'mf-services/cas/delete';
        APIService(false, url).then((response)=> {
            if (response.status_code === 200) {
                toast.success('Successfully deleted external funds', {
                    type: 'success'
                });
                props.deleteSuccess();
            }else{
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setButtonLoader(false);
            let button = document.getElementById('cancel-modal');
            button.click();
        })
    }

    return (
        <div className={`modal fade ${style.e_external_fund_status_modal}`}
            id="remove-external-fund-modal"
            tabIndex="-1"
            aria-labelledby="remove-external-fund-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content text-center d-flex align-items-center border-radius-32px padding-32px-all ${style.e_content} `}>
                    <img src={require("../../Assets/Images/invest-in-goals/delete-modal-icon.png")}
                        width={72}
                        height={72}
                        draggable={false} />
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Stop tracking funds?</p>
                    <div className="mx-5">
                        <p className="color-jett-black e-poppins-regular e-font-16 margin-40px-bottom mx-3">Your external investments will no longer be tracked in your portfolio</p>
                    </div>
                    <div className="d-flex gap-8px ">
                        <OutlineButton label="Cancel"
                            id="cancel-modal"
                            cancel="modal"
                            className="e-font-16 px-4 padding-12px-tb" />
                        <GradientButton label="Yes, delete"
                            loading={buttonLoader}
                            className="e-font-16 padding-12px-tb padding-70px-lr"
                            handleClick={_handleDelete} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RemoveExternalFundModal