
/*
 *   File : notification.js
 *   Author : https://evoqins.com
 *   Description : Notification card
 *   Integrations : null
 *   Version : 1.0.0
 */

import { useNavigate } from 'react-router-dom';

import { GradientButton } from '../CTA';
import Icon from '../Icon/icon';

const NotificationBannerCard = (props) => {

    const navigator = useNavigate();

    function _navigateToDetail() {
        if (props.data.is_goal) {
            navigator("/sip/sip-goal-summary", { state: { sip_id: props.data.sip_id } });
        } else {
            navigator("/sip/sip-summary", { state: { sip_id: props.data.sip_id } });
        }
    }

    function _closeSlider() {
        let key = 'show_sip_reminder';
        let value = true;
        sessionStorage.setItem(key, value);
        const event = new CustomEvent('sessionStorageChange', {
            detail: { key, value },
        });
        window.dispatchEvent(event);
    }
    return (
        <div className="px-3 padding-18px-tb position-relative border-radius-16px bg-very-light-malachite-green e-notification-card">
            <img src={require("../../Assets/Images/dashboard/sip-bg.svg").default}
                alt="SIP"
                className='position-absolute left-0 bottom-0 h-100 border-radius-16px' />
            <Icon icon="close-without-circle"
                size={24}
                className="position-absolute e-close cursor-pointer"
                onClick={_closeSlider} />
            <div className='d-flex align-items-center gap-8px position-relative z-index-1'>
                <div className='e-sip-image border-radius-10px bg-white'>
                    <img src={props.data.image}
                        alt="SIP"
                        width={40}
                        height={40}
                        className='object-fit-contain' />
                </div>
                <div className='e-notification-sip-name'>
                    <p className='color-black e-poppins-medium e-font-16 line-height-20px mb-1 ' >{props.data.name}</p>
                    <p className='color-outer-space e-poppins-regular e-font-14 mb-0'>{`SIP next due: ${props.data.next_due}`}</p>
                </div>

                <GradientButton label="View"
                    className="py-2 px-3 e-font-12 mt-1"
                    handleClick={_navigateToDetail} />

            </div>

        </div>
    )
}

export default NotificationBannerCard