/*
 *   File : switch-funds.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for stp transaction
 *   Integrations : null
 *   Version : v1.1,
 */

import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { Tooltip } from "react-tooltip";

import Icon from "../Icon/icon";
import { CustomAmountInput, OTPInput, SearchSelectInput, RadioGroup } from "../Form-elements";
import { GradientButton } from "../CTA";

import { useClickOutside, _getDayOfMonth } from "../../Helper";
import { _consent2FA } from "../../Helper/api";
import APIService from "../../Service/api-service";

import style from "../../Styles/Component/invest-goal.module.scss"
import Color from "../../Styles/color.module.scss";

const FIRST_INSTALLMENT = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
];

export default function SwitchFundModal(props) {

    const modalRef = useRef(null);
    const userSearchRef = useRef(null);
    const fundRef = useRef(null);
    const FolioRef = useRef(null);
    const { date } = _getDayOfMonth();
    const [step, setStep] = useState(1);
    const [amount, setAmount] = useState(0);
    const [amountError, setAmountError] = useState("");
    const [pinString, setPinString] = useState("");
    const [pinStringError, setPinStringError] = useState("");
    const [apiLoader, setApiLoader] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [selectedFund, setSelectedFund] = useState('');
    const [selectedFundId, setSelectedFundId] = useState(null);
    const [fundSearchResult, setFundSearchResult] = useState([]);
    const [showUserDropDown, setShowUserDropDown] = useState(false);
    const [fundError, setFundError] = useState('');
    const [switchAll, setSwitchAll] = useState(1);
    const [selectedFolio, setSelectedFolio] = useState([]);
    const [folioError, setFolioError] = useState("");
    const [showFolioDropDown, setShowFolioDropDown] = useState(false);
    const [folioData, setFolioData] = useState([]);
    const [selectedBank, setSelectedBank] = useState({});
    const [minSwitchInAmount, setMinSwitchInAmount] = useState(null);
    const [showBackButton, setShowBackButton] = useState(false);
    const [mfData, setMfData] = useState(props.data);

    useEffect(() => {
        var my_modal = document.getElementById("switch-fund");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setStep(1);
            setPinString("");
        });
    }, []);

    useEffect(() => {

        const default_bank_index = props.data.banks.findIndex(bank => bank.is_default === true);
        if (default_bank_index != -1) {
            let default_bank = props.data.banks[default_bank_index];
            setSelectedBank(default_bank);
        }
        setFolioData(props.data.folios);

        // auto select only folio from the list if there is only one item in the list 
        if (props.data.folios.length > 0) {
            setSelectedFolio(props.data.folios[0]);

        }
    }, [props.data]);

    useClickOutside(fundRef, () => {
        setShowUserDropDown(false);
    });

    useEffect(() => {
        if (step === 2) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
        }
    }, [seconds, step]);

    useEffect(() => {

        const default_bank_index = props.data.banks.findIndex(bank => bank.is_default === true);
        if (default_bank_index != -1) {
            let default_bank = props.data.banks[default_bank_index];
            setSelectedBank(default_bank);
        }
        setFolioData(props.data.folios);

        // auto select only folio from the list if there is only one item in the list 
        if (props.data.folios.length > 0) {
            setSelectedFolio(props.data.folios[0]);

        }
    }, [props.data]);

    // calculating and updating amount to switch if select switch all 
    useEffect(() => {
        if (selectedFolio.length !== 0 && switchAll === 1) {
            setAmount(Math.floor(props.data.mf_data.nav.current_nav * selectedFolio.units));
        }
    }, [selectedFolio, switchAll]);

    useClickOutside(FolioRef, () => {
        setShowFolioDropDown(false);
    });

    // handle amount input change
    function _handleInputChange(value) {
        setAmount(value);
        setAmountError("");
    }

    function _closeModal() {
        setApiLoader(false);
    }

    // handle form validation 
    function _validateForm() {
        let valid_form = true;
        if ((amount === 0 || amount === '') && switchAll === 2) {
            setAmountError('Amount is required');
            valid_form = false;
        }
        //  else if (switchAll === 2 && amount > props.data.current_amount) {
        //     setAmountError(`Switch amount should be less than ${props.data.current_amount}`);
        //     valid_form = false;
        // }
        if (selectedFund === '') {
            setFundError('Fund is required');
            valid_form = false;
        }

        if (valid_form === true) {
            _confirmSwitch();
        }

    }

    function _validatePinAndSwitch() {
        if (pinString === "") {
            setPinStringError("OTP is required");
            return;
        }
        setApiLoader(true);
        let url = 'mf/switch';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            switch_in_mf_id: selectedFundId,
            amount,
            bank_id: selectedBank.bank_id,
            folio_number: selectedFolio.folio_no,
            redeem_all: switchAll === 1 ? true : false,
            otp: pinString
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.redeemStatus(1);
            } else {
                setShowBackButton(true);
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        })
    }

    const _getRedeemData = (folio) => {
        let url = 'mf/redeem-data';
        let payload = JSON.stringify({
            id: props.data.mf_data.id,
            units: folio.units,
            sip_date: date,
            transaction_type: 4,
            folio: folio.folio_no
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setMfData(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        });
    }

    // handle OTP input 
    function _handlePin(value) {
        setPinString(value);
        setPinStringError("");
    }

    function _handleShowMessage() {
        setShowMessage(true);
    }

    function _handleHideMessage() {
        setShowMessage(false);
    }

    // handle select user search
    function _handleSelectUserSearch(dropdown, id, label, data) {
        setShowUserDropDown(dropdown);
        setSelectedFundId(id);
        setSelectedFund(label);
        setMinSwitchInAmount(data.min_switch_in_amount);
    }

    function _handleInput(value) {
        setFundError('');
        if (value !== '') {
            _searchFund(value);
        } else {
            setShowUserDropDown(false);
        }
    }

    const _handleFolioClick = () => {
        setShowFolioDropDown(!showFolioDropDown);
    }

    const _handleFolioSelect = (data) => {
        setSelectedFolio(data);
        setShowFolioDropDown(false);
        _getRedeemData(data);
    }

    function _verifyPin() {
        if (pinString === "") {
            setPinStringError("OTP is required");
        }
    }

    function _handleResendOTP() {
        _consent2FA("MF")
            .then((response) => {
                toast.dismiss();
                toast.success("An OTP has been sent to your registered phone number", {
                    type: "success",
                });
                setSeconds(30);
                setPinString("");
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error"
                });
            });
    }

    function _handleRadioInput(instalment_id) {
        setSwitchAll(instalment_id);
        setAmountError("");
    }

    const _navigateToLanding = (path) => {
        // let url = process.env.REACT_APP_LANDING_PAGE_URL + path;
        window.open(path, "_blank", "noreferrer");
    }

    function _handleGoback() {
        setStep(1);
        setPinString("");
        setPinStringError("");
    }

    // API - confirm switch
    function _confirmSwitch() {
        setApiLoader(true);
        let url = 'mf/confirm-switch';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            switch_in_mf_id: selectedFundId,
            amount,
            bank_id: selectedBank.bank_id,
            folio_number: selectedFolio.folio_no,
            redeem_all: switchAll === 1 ? true : false
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setStep(2);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLoader(false);
        })
    }

    // API - get amc fund data
    function _searchFund(value) {
        let url = 'mf-data/search-mf';
        let payload = JSON.stringify({
            mf_id: props.data.mf_data.id,
            query: value,
            transaction_type: 4
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setFundSearchResult(response.data);
                setShowUserDropDown(true);
            } else {
                setFundSearchResult([]);
            }
        })
    }

    return (
        <div className={`modal fade  ${style.e_invest_modal} ${style.mt_transaction_modal}`}
            id="switch-fund"
            tabIndex="-1"
            aria-labelledby="switch-fund"
            aria-hidden="true"
            data-bs-keyboard="false"
            data-bs-backdrop="static" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_invest_dialog}`}>
                <div className={`modal-content p-4 ${style.e_invest_content} `}>
                    <span id="close-modal" data-bs-dismiss="modal" className='d-none'></span>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`}
                        onClick={_closeModal} />
                    {
                        step === 1 ? (
                            <>
                                <p className="color-eerie-black e-poppins-medium e-font-16 line-height-16px margin-12px-bottom">
                                    Switch from
                                </p>

                                {/* Modal body */}
                                <div className={`${style.e_modal_body} pt-2 `} >
                                    <div className="border-shade-light-blue border-radius-16px bg-light-sky-blue pt-3 px-4 ">
                                        <div className="d-flex flex-wrap justify-content-between border-bottom-1px border-shade-light-blue mb-3 pb-sm-0 pb-3">
                                            <div className={`d-flex gap-16px  align-items-start`}>

                                                {/* amc image */}
                                                <div className="border-all border-bright-gray p-1 border-radius-4px">
                                                    <img src={props.data.mf_data.image}
                                                        alt={props.data.mf_data.name}
                                                        width={48}
                                                        height={48}
                                                        className="object-fit-contain" />
                                                </div>

                                                <div className="row pb-2">
                                                    <div className="col-lg-12 mt-3">
                                                        {/* amc name */}
                                                        <p className="color-black e-poppins-medium e-font-14 line-height-20px mb-0">{props.data.mf_data.name}</p>
                                                        <p className="color-black e-poppins-black e-font-10 line-height-19px mb-0 border-all border-bright-gray py-1 padding-6px-lr border-radius-16px w-fit-content">
                                                            {props.data.mf_data.class_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="color-black e-poppins-semi-bold e-font-14 line-height-26px mb-0 text-end align-items-center">
                                                    <span className="e-inter-semi-bold">
                                                        ₹{' '}
                                                    </span>
                                                    {props.data.mf_data.nav.current_nav.toLocaleString('en-IN')}{' '}
                                                    <span className={`e-font-10 ${props.data.mf_data.nav.nav_change < 0 ? "color-red" : "color-sea-green"}`}>
                                                        ({props.data.mf_data.nav.nav_change} {props.data.mf_data.nav.nav_change !== '-' && '%'})
                                                    </span>
                                                </p>
                                                <p className="color-gray e-poppins-regular e-font-10 line-height-12px mb-0">(as on {props.data.mf_data.nav.nav_date})</p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            {/* <div className="d-flex justify-content-between">
                                                <p className="mb-3">
                                                    <span className="color-icon-black e-font-16 e-poppins-regular "> Available to switch:</span>
                                                    <span className="color-icon-black e-font-16 e-inter-semi-bold ">{` ₹ ${props.data.current_amount.toLocaleString('en-IN')}`}</span>
                                                    <span className="color-gray e-font-12 e-poppins-regular ms-1">{`${props.data.units.toFixed(4)} Units`}</span>
                                                </p>
                                            </div> */}
                                            <div className="col-12 d-flex flex-wrap justify-content-between mt-2">
                                                <p className="color-icon-black e-font-16 e-poppins-regular m">Available to switch:
                                                    {
                                                        selectedFolio.length !== 0 ?
                                                            <>
                                                                <span className="color-icon-black e-font-16 e-inter-semi-bold ms-2">
                                                                    ₹ {Math.floor(props.data.mf_data.nav.current_nav * selectedFolio.units)}
                                                                </span>
                                                            </>
                                                            :
                                                            '-'
                                                    }

                                                </p>
                                                <p className="color-icon-black e-font-16 e-poppins-regular m">Available units:
                                                    {
                                                        selectedFolio.length !== 0 ?
                                                            <>
                                                                <span className="color-icon-black e-font-16 e-inter-semi-bold ms-1">{selectedFolio.units.toFixed(3)}</span>
                                                            </>
                                                            :
                                                            '-'
                                                    }

                                                </p>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <p className="">
                                                    <span className="color-icon-black e-font-16 e-poppins-regular "> Min switch out amount:</span>
                                                    <span className="color-icon-black e-font-16 e-inter-semi-bold ">{props.data.mf_data.min_switch_out_amount ? ` ₹ ${props.data.mf_data.min_switch_out_amount.toLocaleString('en-IN')}` : '-'}</span>
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 mt-2">
                                            {
                                                folioData.length !== 0 &&
                                                <div className="position-relative margin-38px-bottom mt-2 mb-4">

                                                    <div ref={FolioRef}>
                                                        <div className={`py-3 padding-22px-lr cursor-pointer border-radius-12px border-all d-flex align-items-center position-relative 
                                                        ${selectedFolio.length !== 0 ? "justify-content-between" : "justify-content-end"}
                                                        ${folioError.length !== 0 ? "border-red" : "border-columbia-blue"}`}
                                                            // data-disabled={withdrawAll || PROFILE_DATA.demat}
                                                            onClick={_handleFolioClick}>

                                                            <p className={`e-poppins-regular e-font-16px line-height-16px mb-0 bg-white z-index-1 position-absolute
                                                            ${selectedFolio.length !== 0 ? 'e_transition_top' : 'e_transition_center'}
                                                            ${folioError ? "color-red" : "color-black"}`}>
                                                                Select folio<span className="color-red">*</span>
                                                            </p>
                                                            {
                                                                selectedFolio.length !== 0 &&
                                                                <div className="d-flex justify-content-between w-100">
                                                                    <p className="color-black e-poppins-semi-bold e-font-16 line-height-26px mb-0 w-max-content">{selectedFolio.folio_no}</p>
                                                                    <p className="color-outer-space e-poppins-regular e-font-12 line-height-26px mb-0">
                                                                        Available units: <span className="color-black e-poppins-medium">{selectedFolio.units}</span>
                                                                    </p>
                                                                </div>
                                                            }
                                                            <div className="d-flex align-items-center">
                                                                <Icon icon="calender-arrow-right"
                                                                    size={24}
                                                                    className={showFolioDropDown === true ? 'e-arrow-up' : 'e-arrow-down'} />
                                                            </div>
                                                        </div>
                                                        {
                                                            folioError.length !== 0 &&
                                                            <div className='position-absolute d-flex align-items-center mt-1 '>
                                                                <Icon icon="info-circle"
                                                                    size={16}
                                                                    className="me-1" />
                                                                <p className='color-red margin-block-end-0 e-font-12 '>
                                                                    {folioError}
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            showFolioDropDown === true &&
                                                            <div className={`border-radius-8px border-all border-bright-gray bg-white position-absolute w-100 z-index-1 mt-2 ${style.e_folio_dropdown}`}>
                                                                {
                                                                    folioData.map((item, key) => {
                                                                        // let contains_all_folio = selectedFolio.some(selectedBank => selectedBank.folio_no === item.folio_no);
                                                                        return (
                                                                            <div key={key}
                                                                                className={`d-flex justify-content-between align-items-center py-3 ps-2 pe-4 ${key !== folioData.length - 1 && "border-bottom-1px border-bright-gray"} cursor-pointer`}
                                                                                onClick={() => _handleFolioSelect(item)}>
                                                                                <div className="d-flex align-items-center gap-8px">
                                                                                    <Icon icon={selectedFolio.folio_no === item.folio_no ? "radio-selected" : "radio-unselected"}
                                                                                        size={16} />
                                                                                    <p className="color-outer-space e-poppins-medium e-font-12 line-height-10px mb-0">
                                                                                        {item.folio_no}
                                                                                    </p>
                                                                                </div>

                                                                                <div>
                                                                                    <p className="color-outer-space e-poppins-regular e-font-12 line-height-10px mb-0">
                                                                                        Available units: <span className="color-black e-poppins-medium">{item.units}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="col-12">
                                            <h6 className="color-eerie-black e-poppins-mediam e-font-16 mb-2">Switch to</h6>
                                        </div>

                                        <div className="col-12  pb-3" ref={fundRef}>
                                            <SearchSelectInput value={selectedFund}
                                                placeholder="Fund name"
                                                data={fundSearchResult}
                                                activeId={selectedFundId}
                                                type="text"
                                                postfix="*"
                                                id='user-input'
                                                name="search"
                                                error={fundError}
                                                showDropdown={showUserDropDown}
                                                customerList={true} // param to show mt_id 
                                                // onClick={() => _handleUserSearchClick(1)}
                                                select={(dropdown, id, label, data) => _handleSelectUserSearch(dropdown, id, label, data)}
                                                valueChange={(value) => _handleInput(value, 1)} />
                                        </div>

                                        <div className="col-12">
                                            <p className="my-3">
                                                <span className="color-icon-black e-font-16 e-poppins-regular ">Exit load</span>
                                                <span className="color-icon-black e-font-16 e-inter-semi-bold ms-2"> ₹ {mfData.mf_data.exit_load_amount.toLocaleString('en-IN')}</span>
                                            </p>
                                        </div>

                                        <div className="col-12 mt-2">
                                            <div className="d-flex align-items-center gap-8px ">
                                                <p className="color-black e-poppins-medium e-font-14 mb-0">Switch all units</p>
                                                <RadioGroup data={FIRST_INSTALLMENT}
                                                    radioItemClassName="py-2"
                                                    size={14}
                                                    fontSize="e-font-12"
                                                    type={2}
                                                    id={switchAll}
                                                    radioHandler={_handleRadioInput} />
                                            </div>
                                        </div>

                                        <div className="col-12 position-relative mt-4 mb-4 py-2" data-disabled={switchAll === 1 && 'true'}>
                                            <CustomAmountInput value={amount}
                                                key={"Switch amount"}
                                                label="Switch amount"
                                                error={amountError}
                                                description={`Min switch in amount: ${minSwitchInAmount ? minSwitchInAmount : '-'}, Min Switch Units: ${mfData.mf_data.min_units ? mfData.mf_data.min_units : '0'}`}
                                                inputClass=" bg-transparent e-poppins-semi-bold padding-28px-left padding-16px-tb border-radius-12px"
                                                labelClass="bg-white e-font-16 line-height-26px"
                                                handleChange={_handleInputChange} />

                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {
                                    showBackButton === true &&
                                    <div className="col-12 position-relative">
                                        <Icon icon="arrow-right1"
                                            size={24}
                                            color={Color.black}
                                            className={`position-absolute cursor-pointer top--7 left--7 ${style.e_back}`}
                                            onClick={_handleGoback} />
                                    </div>
                                }
                                <div className=" mt-5">
                                    <div className="row text-center">
                                        <div className="col-12">
                                            <img src={require("../../Assets/Images/explore/mt-pin-auth.png")}
                                                alt="Confirm payment"
                                                width={72}
                                                height={72} />
                                            <>
                                                <p className="color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3">Enter OTP you have received in registered email and phone number</p>

                                                <p className="color-jett-black e-poppins-regular e-font-16 mb-4">You are one step away from making a withdrawal</p>
                                                {
                                                    step === 2 ?
                                                        <OTPInput pinString={pinString}
                                                            id="invest"
                                                            autoFocus={true}
                                                            pinError={pinStringError}
                                                            disabled={apiLoader}
                                                            handleChange={_handlePin}
                                                            className="mb-4" />
                                                    :
                                                        null
                                                }

                                            </>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )
                    }
                    <div className="row pt-2">
                        {
                            step === 1 &&
                            <>
                                <div className="col-12 mt-1 mb-3">
                                    <p className="color-outer-space e-poppins-regular e-font-10 mb-1">a) Lock-in & exit load will be applicable as per scheme details.</p>
                                    <p className="color-outer-space e-poppins-regular e-font-10 mb-0">b) The current amount shown may vary as per NAV applicable to the successful processing of transaction.</p>
                                </div>
                                <div className="col-12 text-center">
                                    <p className="color-outer-space e-poppins-regular e-font-10 mb-0">By continuing, I agree with the <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding('https://www.mastertrust.co.in/disclaimer')}>Disclaimers</span> and <span className="color-primary-color cursor-pointer" onClick={() => _navigateToLanding(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`)}>T&C</span> of Mastertrust</p>
                                </div>
                            </>

                        }
                        <div className="col-lg-8 col-md-8 col-sm-8 col-12 offset-lg-2 offset-md-2 offset-sm-2 mt-2 ">
                            <GradientButton label={step === 1 ? "Confirm switch" : "Verify & switch"}
                                className=" e-font-16 padding-12px-tb w-100"
                                loading={apiLoader}
                                handleClick={step === 1 ? _validateForm : _validatePinAndSwitch} />
                            {
                                    step === 2 &&
                                    <div className='d-flex justify-content-center mt-4'>
                                    {
                                        seconds === 0 ?
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                onClick={_handleResendOTP}>Resend OTP</p>
                                            :
                                            <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                Resend OTP in <span className="color-black">{seconds}s</span>
                                            </p>
                                    }
                                </div>
                                }
                            {
                                step === 1 && (
                                    <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-0 d-flex justify-content-center align-items-center mt-2 position-relative">
                                        <div>OTP will be sent on registered mobile/email</div>
                                        {
                                            <div className={`ms-1 cursor-pointer  align-items-center w-max-content ${style.e_mail_icon}`}
                                                onMouseEnter={_handleShowMessage}
                                                onMouseLeave={_handleHideMessage}>
                                                <Icon icon="info"
                                                    size={16}
                                                    data-tooltip-id="email-tooltip" />
                                                <Tooltip id="email-tooltip"
                                                    arrowColor='transparent'
                                                    effect="float"
                                                    place="bottom"
                                                    className='e-tooltip-general'>
                                                    The OTP will be sent on mobile/email registered in the folio in case of additional purchase.
                                                </Tooltip>
                                            </div>
                                        }

                                    </p>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}