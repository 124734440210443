
/*
 *   File : retirement.js
 *   Author URI : https://evoqins.com
 *   Description : Page for retirement
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { InvestmentHeader } from "../../Component/Header";
import Footer from "../../Component/Footer/footer";
import { BreadCrumb } from '../../Component/BreadCrumb';
import CustomSlider from '../../Component/Other/slider';
import { CustomAmountInput, RadioGroup } from '../../Component/Form-elements';
import { GradientButton } from '../../Component/CTA';
import { GoalSummary } from '../../Component/Goal';
import { CustomLoader, RepresentationLabel } from '../../Component/Other';
import { GoalBanner } from '../../Component/Banner';
import { CompleteRiskScore } from "../../Component/Card";

import APIService from '../../Service/api-service';
import { _getGoalDetail } from '../../Helper/api';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Invest in Goals', url: '/goal' },
    { page: 'Retirement', url: null }
];

const INCLUDE_CORPUS = [
    { id: 1, label: 'Yes' },
    { id: 2, label: 'No' },
];

const STEP_PROGRESS = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" stroke="#1177BD" stroke-width="2" />
        <circle cx="16" cy="16" r="5" fill="#1177BD" />
    </svg>
);

const STEP_UPCOMING = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" stroke="#D1D5DB" stroke-width="2" />
    </svg>
);

const STEP_COMPLETED = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" fill="#209E49" stroke="#209E49" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4933 10.935C22.8053 11.2074 22.8374 11.6812 22.565 11.9933L14.7079 20.9933C14.5654 21.1564 14.3594 21.25 14.1429 21.25C13.9263 21.25 13.7203 21.1564 13.5779 20.9933L10.435 17.3933C10.1626 17.0812 10.1947 16.6074 10.5068 16.335C10.8188 16.0626 11.2926 16.0947 11.565 16.4068L14.1429 19.3596L21.435 11.0068C21.7074 10.6947 22.1812 10.6626 22.4933 10.935Z" fill="white" />
    </svg>
);

const ERROR_ICON = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M14.6693 8.0026C14.6693 11.6845 11.6845 14.6693 8.0026 14.6693C4.32071 14.6693 1.33594 11.6845 1.33594 8.0026C1.33594 4.32071 4.32071 1.33594 8.0026 1.33594C11.6845 1.33594 14.6693 4.32071 14.6693 8.0026Z" fill="#D13438" />
        <path d="M8.0026 11.8307C8.27875 11.8307 8.5026 11.6069 8.5026 11.3307V7.33073C8.5026 7.05459 8.27875 6.83073 8.0026 6.83073C7.72646 6.83073 7.5026 7.05459 7.5026 7.33073V11.3307C7.5026 11.6069 7.72646 11.8307 8.0026 11.8307Z" fill="white" />
        <path d="M8.0026 4.66406C8.37079 4.66406 8.66927 4.96254 8.66927 5.33073C8.66927 5.69892 8.37079 5.9974 8.0026 5.9974C7.63441 5.9974 7.33594 5.69892 7.33594 5.33073C7.33594 4.96254 7.63441 4.66406 8.0026 4.66406Z" fill="white" />
    </svg>
);

const GOAL_CREATION_STEPS = [
    {
        title: "Enter your details",
        step1: STEP_PROGRESS,
        step2: STEP_COMPLETED,
        step3: STEP_COMPLETED
    },
    {
        title: "Corpus & fund suggestion",
        step1: STEP_UPCOMING,
        step2: STEP_PROGRESS,
        step3: STEP_COMPLETED
    },
    {
        title: "Invest",
        step1: STEP_UPCOMING,
        step2: STEP_UPCOMING,
        step3: STEP_PROGRESS
    }
];

// Step progress component
function StepProgress(props) {
    // const icons = props.currentStep === 1 ? PROGRESS_ICONS_STEP_1 : PROGRESS_ICONS_STEP_2;
    return (

        // {/* Progress bar and indicator */}
        <Fragment>
            <div className='d-flex flex-column position-relative overflow-sm-hidden'>
                <div className='d-flex justify-content-between gap-100px mt-sm-4 mt-2 e-goal-progress'>
                    <div className='e-horizontal-line'></div>
                    {
                        GOAL_CREATION_STEPS.map((item, key) => {
                            return (
                                <div className='d-flex flex-column align-items-center' key={key}>
                                    <p className='bg-mint-green mb-2 z-index-1'> {props.currentStep === 1 ? item.step1 : props.currentStep === 2 ? item.step2 : item.step3}
                                    </p>
                                    <span className={`color-black e-poppins-medium e-font-14 text-center`} key={key}>{item.title}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
};



export default function Retirement(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const URL_PATH = useSelector(state => state.generalData.path);
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [currentGoalStep, setCurrentGoalStep] = useState(1);
    const [retirementAge, setRetirementAge] = useState(null);
    const [currentMonthlyExpense, setCurrentMonthlyExpense] = useState("");
    const [inflationRatio, setInflationRatio] = useState(null);
    const [lifeExpectancy, setLifeExpectancy] = useState(null);
    const [isCorpusIncluded, setIsCorpusIncluded] = useState(2);
    const [availableCorpus, setAvailableCorpus] = useState(null);
    const [currentMonthError, setCurrentMonthError] = useState(null);
    const [lifeExpectancyError, setLifeExpectancyError] = useState(null);
    const [availableCorpusError, setAvailableCorpusError] = useState("");
    const [corpusApiData, setCorpusApiData] = useState({});
    const [currentAge, setCurrentAge] = useState(null);
    const [description, setDescription] = useState("");
    const [detailData, setDetailData] = useState({});
    const [loader, setLoader] = useState(true);
    const [goalId, setGoalId] = useState(null);
    const [fromFundDetail,  setFromFundDetail] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(()=>{
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    },[]);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentGoalStep]);


    useEffect(() => {
       
        if (location.hash == '') {
            setCurrentGoalStep(1);
        }else{
            setLoader(true);
        }
    }, [location]);

    useEffect(()=>{
        if (fromFundDetail === true) {
            setLoader(true);
            _calculateCorpus();
        }
    },[fromFundDetail])

    useEffect(() => {
        if (location.state !== null) {
            _getGoalDetail(location.state.id)
                .then((response) => {
                    setGoalId(response.goal_id);
                    setAvailableCorpus(response.available_corpus);
                    setDescription(response.description);
                    setDetailData(response);
                    // setLoader(false);
                    if (response.is_set === true) {
                        setCurrentAge(response.current_age ? response.current_age : response.meta_data.current_age.default)
                        setRetirementAge(response.retiring_age ? response.retiring_age : response.meta_data.retiring_age.default);
                        setInflationRatio(response.inflation_rate ? response.inflation_rate : response.meta_data.inflation_rate.default);
                        setLifeExpectancy(response.life_expectancy ? response.life_expectancy : response.meta_data.life_expectancy.default);
                        setCurrentMonthlyExpense(response.additional_data.annual_expenses ? response.additional_data.annual_expenses / 12 : 0);
                        // _calculateCorpus();
                      
                        if ( location.hash && Object.keys(corpusApiData).length === 0) {
                            setFromFundDetail(true);
                            setLoader(true);
                        }else{
                            setLoader(false);
                        }
                    }
                    else {
                        setCurrentAge(response.meta_data.current_age.default)
                        setRetirementAge(response.meta_data.retiring_age.default);
                        setInflationRatio(response.meta_data.inflation_rate.default);
                        setLifeExpectancy(response.meta_data.life_expectancy.default);
                        setLoader(false);
                    }
                    if (response.available_corpus > 0) {
                        setIsCorpusIncluded(1);
                    } else {
                        // setLoader(false);
                        setIsCorpusIncluded(2);
                    }


                })
                .catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error",
                    });
                });
        }
        else {
            navigate("/goal");
        }
    }, [location])



    // calculate corpus form validation 
    function _validateInput() {
        let valid = true;
        var form_top = document.getElementById('form-top');
        if (currentMonthlyExpense < 100 || currentMonthlyExpense.length === 0) {
            setCurrentMonthError('Minimum monthly expense amount is ₹100');
            valid = false;
            form_top.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (lifeExpectancy < 60 || lifeExpectancy.length === 0) {
            setLifeExpectancyError("Minimum life expectancy required is 60");
            valid = false;
            form_top.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        if (isCorpusIncluded === 1) {
            if (availableCorpus < 100 || availableCorpus.length === 0) {
                setAvailableCorpusError("Minimum available corpus required is ₹100");
                valid = false;
                form_top.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
        if (valid === true) {
            _calculateCorpus();
        }
    }

    // API - calculate corpus
    const _calculateCorpus = () => {
        setButtonLoader(true);
        let url = 'goal/calculate-corpus';
        let payload = JSON.stringify({
            current_age: currentAge,
            retirement_age: retirementAge,
            life_expectancy: lifeExpectancy,
            annual_expenses: currentMonthlyExpense * 12,
            inflation_rate: inflationRatio,
            available_corpus: availableCorpus,
        });

        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                setCurrentGoalStep(2);
                if ( location.hash == '') {
                    navigate("/goal/retirement#summary", { state: { id: location.state.id } });
                }
                setCorpusApiData(response.data);  
                setLoader(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setButtonLoader(false);
        })

    }


    return (
        loader === true ?
            <CustomLoader />
            :
            <>
                <div className="e-page-container e-retirement pb-sm-0 pb-4 mb-sm-0 mb-5">
                    {/* Header */}
                    <InvestmentHeader />

                    {/* Breadcrumb */}
                    <BreadCrumb data={PAGE_NAVIGATION}
                        className="pb-2" />

                    {/* Banner  */}
                    <GoalBanner main_title={detailData.meta_data.title_1}
                        sub_title={detailData.meta_data.title_2}
                        banner_image={detailData.meta_data.banner_image}
                        description={detailData.meta_data.long_description} />

                    {/* Form */}
                    <div className='border-radius-16px border-bright-gray border-all bg-mint-green margin-40px-top-sm-16  padding-42px-tb  e-steps margin-72px-bottom-sm-16'>
                        <div className='row gx-3'>
                            <div className='col-12 text-center '>
                                <h4 className='color-black e-poppins-medium e-font-sm-24-20 line-height-28px'>
                                    You are few steps away from creating Retirement goal
                                </h4>
                            </div>

                            {/* Progress */}
                            <div className='col-12 d-flex justify-content-center' id='form-top'>
                                <StepProgress currentStep={currentGoalStep} />
                            </div>

                            {/* step 1 */}
                            {
                                currentGoalStep === 1 ? (
                                    <>
                                        {/* Current age */}
                                        <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                            <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <RepresentationLabel label="Current age"
                                                        type={1}
                                                        mandatory={true} />
                                                    <RepresentationLabel type={2}
                                                        value={currentAge}
                                                        postfix={currentAge == 1 ? "Year" : "Years"} />
                                                </div>
                                                <CustomSlider min={detailData.meta_data.current_age.min_age}
                                                    max={detailData.meta_data.current_age.max_age}
                                                    step={detailData.meta_data.current_age.step}
                                                    value={currentAge}
                                                    _handleChange={(e) => setCurrentAge(e)} />
                                                <div className='d-flex justify-content-between'>
                                                    <RepresentationLabel type={3}
                                                        value={detailData.meta_data.current_age.min_age}
                                                        postfix={detailData.meta_data.current_age.min_age == 1 ? "Year" : "Years"}
                                                        whiteSpace={false} />

                                                    <RepresentationLabel type={3}
                                                        value={detailData.meta_data.current_age.max_age}
                                                        postfix={detailData.meta_data.current_age.max_age == 1 ? "Year" : "Years"}
                                                        whiteSpace={false} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Retiring age */}
                                        <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                            <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                <div className='d-flex justify-content-between mb-3'>
                                                    <RepresentationLabel label="Age when you are retiring"
                                                        type={1}
                                                        mandatory={true} />
                                                    <RepresentationLabel type={2}
                                                        value={retirementAge}
                                                        postfix={retirementAge == 1 ? "Year" : "Years"} />
                                                </div>
                                                <CustomSlider min={detailData.meta_data.retiring_age.min_age}
                                                    max={detailData.meta_data.retiring_age.max_age}
                                                    step={detailData.meta_data.retiring_age.step}
                                                    value={retirementAge}
                                                    _handleChange={(e) => setRetirementAge(e)} />
                                                <div className='d-flex justify-content-between'>
                                                    <RepresentationLabel type={3}
                                                        value={detailData.meta_data.retiring_age.min_age}
                                                        postfix={detailData.meta_data.retiring_age.min_age == 1 ? "Year" : "Years"}
                                                        whiteSpace={false} />

                                                    <RepresentationLabel type={3}
                                                        value={detailData.meta_data.retiring_age.max_age}
                                                        postfix={detailData.meta_data.retiring_age.max_age == 1 ? "Year" : "Years"}
                                                        whiteSpace={false} />

                                                </div>
                                            </div>
                                        </div>

                                        {/* Monthly income */}
                                        <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                            <div className={`${screenWidth > 576 ? 'border-radius-16px border-all border-bright-gray p-4' : 'mt-2' }  min-height-144px-sm-8rem`}>
                                                <CustomAmountInput value={currentMonthlyExpense}
                                                    label="Current monthly expenses"
                                                    inputClass="bg-transparent padding-28px-left"
                                                    labelClass="bg-mint-green"
                                                    showRupeePrefix={true}
                                                    postfix="*"
                                                    error={currentMonthError}
                                                    handleChange={(e) => {
                                                        setCurrentMonthError("");
                                                        setCurrentMonthlyExpense(e);
                                                    }} />
                                            </div>
                                        </div>

                                        {/* Inflation ratio & life expectancy */}
                                        <div className='col-lg-6 col-md-6 mt-sm-5 mt-3'>
                                            <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                <div className='row gx-5 position-relative'>
                                                    <div className='col-12 '>
                                                        <div className='d-flex justify-content-between mb-3'>
                                                            <RepresentationLabel label="Inflation ratio"
                                                                type={1}
                                                                mandatory={false} />
                                                            <RepresentationLabel type={2}
                                                                value={inflationRatio}
                                                                postfix={"%"} />
                                                        </div>
                                                        <CustomSlider min={detailData.meta_data.inflation_rate.min}
                                                            max={detailData.meta_data.inflation_rate.max}
                                                            step={detailData.meta_data.inflation_rate.step}
                                                            value={inflationRatio}
                                                            _handleChange={(e) => setInflationRatio(e)} />
                                                        <div className='d-flex justify-content-between'>
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.inflation_rate.min}
                                                                postfix={"%"}
                                                                whiteSpace={false} />
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.inflation_rate.max}
                                                                postfix={"%"}
                                                                whiteSpace={false} />
                                                        </div>
                                                        {/* <div className='bg-silver w-1px h-64px position-absolute left-0 right-24 top-20px bottom-0 margin-auto'></div> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* Inflation ratio & life expectancy */}
                                        <div className='col-lg-6 col-md-6 mt-sm-5 mt-3'>
                                            <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                <div className='row gx-5 position-relative'>
                                                    {/* Life expectancy */}
                                                    <div className='col-12'>
                                                        <div className='d-flex justify-content-between mb-3'>
                                                            <RepresentationLabel label="Life expectancy"
                                                                type={1}
                                                                mandatory={true} />
                                                            <RepresentationLabel type={2}
                                                                value={lifeExpectancy}
                                                                postfix={lifeExpectancy == 1 ? "Year" : "Years"} />
                                                        </div>
                                                        <CustomSlider min={detailData.meta_data.life_expectancy.min_age}
                                                            max={detailData.meta_data.life_expectancy.max_age}
                                                            step={detailData.meta_data.life_expectancy.step}
                                                            value={lifeExpectancy}
                                                            _handleChange={(e) => setLifeExpectancy(e)} />
                                                        <div className='d-flex justify-content-between'>
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.life_expectancy.min_age}
                                                                postfix={"Years"}
                                                                whiteSpace={false} />
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.life_expectancy.max_age}
                                                                postfix={"Years"}
                                                                whiteSpace={false} />

                                                        </div>
                                                        {
                                                            lifeExpectancyError !== null && (
                                                                <p className='color-red margin-block-end-0 e-font-12 position-absolute'>
                                                                    {ERROR_ICON}{lifeExpectancyError}
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Include corpus */}
                                        <div className='col-12 mt-3'>
                                            <RepresentationLabel label="Do you want to include any available corpus?"
                                                type={1}
                                                mandatory={false} />
                                            <RadioGroup data={INCLUDE_CORPUS}
                                                className="gap-24px mt-3"
                                                type={2}
                                                id={isCorpusIncluded}
                                                radioHandler={(e) => {
                                                    setIsCorpusIncluded(e);
                                                    setAvailableCorpusError("");
                                                }} />
                                        </div>

                                        {/* Available corpus */}
                                        <div className='col-lg-5 col-md-6 col-12 mt-4 pt-2'>
                                            {
                                                isCorpusIncluded === 1 && (
                                                    <CustomAmountInput value={availableCorpus}
                                                        label="Enter available corpus"
                                                        inputClass="bg-transparent padding-28px-left"
                                                        labelClass="bg-mint-green"
                                                        className="mb-3"
                                                        postfix="*"
                                                        error={availableCorpusError}
                                                        showRupeePrefix={true}
                                                        handleChange={(e) => {
                                                            setAvailableCorpusError("");
                                                            setAvailableCorpus(e)
                                                        }
                                                        } />
                                                )
                                            }
                                        </div>
                                        
                                        {
                                            USER_DATA.is_assessment_completed === false &&
                                                <div className="row mt-4 gx-0">
                                                    <div className="col-12">
                                                        <CompleteRiskScore />
                                                    </div>
                                                </div>
                                        }

                                        {/* Button */}
                                        <div className='col-12 text-center margin-40px-top-sm-16'>
                                            <GradientButton label="Calculate your corpus"
                                                className="padding-12px-tb padding-24px-lr"
                                                loading={buttonLoader}
                                                handleClick={_validateInput} />
                                        </div>
                                    </>
                                ) : (
                                    <GoalSummary apiData={corpusApiData}
                                        title={'At the time of retirement, you will require the following sum.'}
                                        goalId={goalId}
                                        inflationRatio={inflationRatio}
                                        availableCorpus={availableCorpus}
                                        step_sip={detailData.meta_data.step_sip.default}
                                        sip_tenure={detailData.meta_data.sip_tenure.default}
                                        sip={0}
                                        lumpsum={0}
                                        target={detailData.meta_data.default_target}
                                        onBackButtonPress={() => {setCurrentGoalStep(1); console.log('not okay')}} />
                                )
                            }

                        </div>
                    </div>
                </div>
                <Footer />
            </>
    )
}