
/*
 *   File : retirement.js
 *   Author URI : https://evoqins.com
 *   Description : Page for retirement
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { InvestmentHeader } from "../../Component/Header";
import Footer from "../../Component/Footer/footer";
import { BreadCrumb } from '../../Component/BreadCrumb';
import CustomSlider from '../../Component/Other/slider';
import { CustomAmountInput, CustomSearchSelectBox, CustomTextInput } from '../../Component/Form-elements';
import { GradientButton } from '../../Component/CTA';
import { GoalSummary } from '../../Component/Goal';
import { CustomLoader, RepresentationLabel } from '../../Component/Other';
import { GoalBanner } from '../../Component/Banner';
import { CompleteRiskScore } from "../../Component/Card";

import { _getGoalDetail } from '../../Helper/api';
import APIService from '../../Service/api-service';

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Invest in Goals', url: '/goal' },
    { page: 'Custom goal', url: null }
];

const STEP_PROGRESS = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" stroke="#1177BD" stroke-width="2" />
        <circle cx="16" cy="16" r="5" fill="#1177BD" />
    </svg>
);

const STEP_UPCOMING = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" stroke="#D1D5DB" stroke-width="2" />
    </svg>
);

const STEP_COMPLETED = (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15" fill="#209E49" stroke="#209E49" stroke-width="2" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4933 10.935C22.8053 11.2074 22.8374 11.6812 22.565 11.9933L14.7079 20.9933C14.5654 21.1564 14.3594 21.25 14.1429 21.25C13.9263 21.25 13.7203 21.1564 13.5779 20.9933L10.435 17.3933C10.1626 17.0812 10.1947 16.6074 10.5068 16.335C10.8188 16.0626 11.2926 16.0947 11.565 16.4068L14.1429 19.3596L21.435 11.0068C21.7074 10.6947 22.1812 10.6626 22.4933 10.935Z" fill="white" />
    </svg>
);


const GOAL_CREATION_STEPS = [
    {
        title: "Enter your details",
        step1: STEP_PROGRESS,
        step2: STEP_COMPLETED,
        step3: STEP_COMPLETED
    },
    {
        title: "Corpus & fund suggestion",
        step1: STEP_UPCOMING,
        step2: STEP_PROGRESS,
        step3: STEP_COMPLETED
    },
    {
        title: "Invest",
        step1: STEP_UPCOMING,
        step2: STEP_UPCOMING,
        step3: STEP_PROGRESS
    }
];

// Step progress component
function StepProgress(props) {
    // const icons = props.currentStep === 1 ? PROGRESS_ICONS_STEP_1 : PROGRESS_ICONS_STEP_2;
    return (

        // {/* Progress bar and indicator */}
        <Fragment>
            <div className='d-flex flex-column position-relative overflow-sm-hidden'>
                <div className='d-flex justify-content-between gap-100px mt-4 e-goal-progress'>
                    <div className='e-horizontal-line'></div>
                    {
                        GOAL_CREATION_STEPS.map((item, key) => {
                            return (
                                <div className='d-flex flex-column align-items-center' key={key}>
                                    <p className='bg-mint-green mb-2 z-index-1'> {props.currentStep === 1 ? item.step1 : props.currentStep === 2 ? item.step2 : item.step3}
                                    </p>
                                    <span className={`color-black e-poppins-medium e-font-14 text-center`} key={key}>{item.title}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Fragment>
    )
};

export default function CustomGoal() {

    const location = useLocation();
    const navigate = useNavigate();
    const URL_PATH = useSelector(state => state.generalData.path);
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [currentGoalStep, setCurrentGoalStep] = useState(1);
    const [targetAmount, setTargetAmount] = useState("");
    const [inflationRatio, setInflationRatio] = useState(null);
    const [availableCorpus, setAvailableCorpus] = useState(null);
    const [currentMonthError, setCurrentMonthError] = useState(null);
    const [goalData, setGoalData] = useState({});
    const [tenure, setTenure] = useState(null);
    const [description, setDescription] = useState("");
    const [loader, setLoader] = useState(true);
    const [detailData, setDetailData] = useState({});
    const [goalId, setGoalId] = useState(null);
    const [otherGoalOptions, setOtherGoalOptions] = useState([]);
    const [goal, setGoal] = useState(null);
    const [goalName, setGoalName] = useState("");
    const [nameError, setNameError] = useState("");
    const [fromFundDetail, setFromFundDetail] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(()=>{
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    },[]);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [currentGoalStep]);

    useEffect(() => {
        if (location.hash == '') {
            setCurrentGoalStep(1);
        }else{
            setLoader(true);
        }
    }, [location]);

    useEffect(()=>{
        if (fromFundDetail === true) {
            setLoader(true);
            _calculateFutureValue();
        }
    },[fromFundDetail])

    useEffect(() => {
        if (location.state !== null) {
            _getGoalDetail(location.state.id)
                .then((response) => {
                    setLoader(false);
                    setGoalId(response.goal_id);
                    setAvailableCorpus(response.available_corpus !== "-" ? response.available_corpus : 0);
                    setDescription(response.description);
                    setDetailData(response);
                    let goal_options = []
                    response.meta_data.goals.map((item) => (
                        goal_options.push({
                            label: item.name,
                            value: item.id
                        })
                    ))
                    setOtherGoalOptions(goal_options);
                    if (response.is_set === true) {
                        setTargetAmount(response.additional_data.target ? response.additional_data.target : 0);
                        setTenure(response.additional_data.tenure ? response.additional_data.tenure : response.meta_data.tenure.default)
                        setInflationRatio(response.additional_data.inflation_rate ? response.additional_data.inflation_rate : response.meta_data.inflation_rate.default);
                        let goal_value = goal_options.filter((item) => item.label === response.additional_data.name);
                        if (goal_value.length > 0) {
                            setGoal(goal_value[0]);
                            setGoalName(goal_value[0].label);
                        } else {
                            setGoalName(response.additional_data.name);
                            setGoal({
                                label: response.additional_data.name,
                                value: 99
                            })
                        }
                        if (location.hash && Object.keys(goalData).length === 0) {
                            setFromFundDetail(true);
                            setLoader(true);
                        }
                    } else {
                        setTenure(response.meta_data.tenure.default)
                        setInflationRatio(response.meta_data.inflation_rate.default);
                        setGoal(goal_options[0]);
                        setGoalName(goal_options[0].label);
                    }

                })
                .catch((response) => {
                    toast.dismiss();
                    toast.error(response, {
                        type: "error",
                    });
                });
        }
        else {
            navigate("/goal")
        }
        //eslint-disable-next-line
    }, [location, currentGoalStep])


    // calculate corpus form validation 
    function _validateInput() {
        let valid = true;
        if (targetAmount.length === 0) {
            setCurrentMonthError('Specify your goal budget');
            valid = false;
        }
        if (goal !== null && goal.value === 99 && (goal.label.length === 0 || goalName.length === 0)) {
            setNameError('Enter goal name');
            valid = false;
        }

        console.log(goalName, goal)
        if (valid === true) {
            _calculateFutureValue();
        }
    }

    const _handleChangeGoal = (selected_obj) => {
        setNameError("");
        if (selected_obj.value !== 99) {
            setGoalName(selected_obj.label);
            setGoal(selected_obj);
        } else {
            setGoalName(selected_obj.label);
            setGoal({
                label: "",
                value: 99
            });
        }
    }
    // API - calculate corpus
    const _calculateFutureValue = () => {
        setButtonLoader(true);
        let url = 'goal/set';
        let payload = JSON.stringify({
            "goal_type_id": detailData.goal_id,
            "tenure": tenure,
            "target": targetAmount,
            "lumpsum": 0,
            "name": goalName,
            "sip": 0,
            "step_sip": 0,
            "inflation_rate": inflationRatio,
            "additional_data": {
                "tenure": tenure,
                "target": targetAmount,
                "inflation_rate": inflationRatio,
                "lumpsum": detailData.is_set ? detailData.additional_data.lumpsum : 0,
                "sip": detailData.is_set ? detailData.additional_data.sip : 0,
                "name": goalName,
                "step_sip": detailData.is_set ? detailData.additional_data.step_sip : detailData.meta_data.step_sip.default,
                "sip_tenure": detailData.is_set ? detailData.additional_data.sip_tenure : detailData.meta_data.sip_tenure.default,
                "investment_style": detailData.is_set ? detailData.additional_data.investment_style : {},
            }
        })
        APIService(true, url, payload, true).then((response) => {
            if (response.status_code === 200) {
                setCurrentGoalStep(2);
                if (location.hash === '') {
                    navigate("/goal/custom-goal#summary", { state: { id: location.state.id } });
                }
                setGoalData(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setButtonLoader(false);
        })

    }

    return (
        <>
            {
                loader ?
                    <CustomLoader />
                    :
                    <>
                        <div className="e-page-container e-retirement pb-sm-0 pb-4 mb-sm-0 mb-5">
                            {/* Header */}
                            <InvestmentHeader />

                            {/* Breadcrumb */}
                            <BreadCrumb data={PAGE_NAVIGATION}
                                className="pb-2" />

                            {/* Banner  */}
                            <GoalBanner main_title={detailData.meta_data.title_1}
                                sub_title={detailData.meta_data.title_2}
                                banner_image={detailData.meta_data.banner_image}
                                description={detailData.meta_data.long_description} />

                            {/* Form */}
                            <div className='border-radius-16px border-bright-gray border-all bg-mint-green margin-40px-top-sm-16  padding-42px-tb  e-steps margin-72px-bottom-sm-16'>
                                <div className='row gx-3'>
                                    <div className='col-12 text-center'>
                                        <h4 className='color-black e-poppins-medium e-font-sm-24-20 line-height-28px'>
                                            You are few steps away from creating your Custom goal
                                        </h4>
                                    </div>

                                    {/* Progress */}
                                    <div className='col-12 d-flex justify-content-center'>
                                        <StepProgress currentStep={currentGoalStep} />
                                    </div>

                                    {/* step 1 */}
                                    {
                                        currentGoalStep === 1 ? (
                                            <>
                                                {/* Monthly income */}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                                    <div className={`${screenWidth > 576 ? 'border-radius-16px border-all border-bright-gray p-4' : 'mt-2' }  min-height-144px-sm-8rem`}>
                                                        <CustomAmountInput value={targetAmount}
                                                            label="Budget"
                                                            inputClass="bg-transparent padding-28px-left"
                                                            labelClass="bg-mint-green"
                                                            showRupeePrefix={true}
                                                            amount={true}
                                                            postfix="*"
                                                            error={currentMonthError}
                                                            handleChange={(e) => {
                                                                setCurrentMonthError("");
                                                                setTargetAmount(e);
                                                            }} />
                                                    </div>
                                                </div>

                                                {/* other goals*/}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-4'>
                                                    <div className={`${screenWidth > 576 ? 'border-radius-16px border-all border-bright-gray p-4' : 'mt-2' }  min-height-144px-sm-8rem`}>

                                                        {
                                                            // goal !== null && goal.value === 99 ?
                                                            //     <CustomTextInput value={goalName}
                                                            //         label="Your goal"
                                                            //         inputClass="bg-transparent padding-20px-left"
                                                            //         labelClass="bg-mint-green"
                                                            //         showRupeePrefix={false}
                                                            //         error={nameError}
                                                            //         handleChange={(e) => {
                                                            //             setNameError("");
                                                            //             setGoalName(e);
                                                            //             if (e === "") {
                                                            //                 setGoal(otherGoalOptions[0]);
                                                            //                 setGoalName(otherGoalOptions[0].label)
                                                            //             }
                                                            //         }} />
                                                            //     :
                                                            <CustomSearchSelectBox options={otherGoalOptions}
                                                                background='transparent'
                                                                value={goal}
                                                                error={nameError}
                                                                creatable={goal.value === 99 ? true : false}
                                                                placeholder="Goal"
                                                                radius="8px"
                                                                postfix="*"
                                                                labelColor="#f4fef5"
                                                                padding="12px 12px 12px 12px"
                                                                onSelectChange={_handleChangeGoal} />
                                                        }

                                                    </div>
                                                </div>

                                                {/* Tenure */}
                                                <div className='col-lg-6 col-md-6 col-12 mt-sm-5 mt-3'>
                                                    <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                        <div className='d-flex justify-content-between mb-3'>
                                                            <RepresentationLabel label="By (Tenure)"
                                                                type={1}
                                                                mandatory={true} />
                                                            <RepresentationLabel type={2}
                                                                value={tenure}
                                                                postfix={tenure == 1 ? "Year" : "Years"} />
                                                        </div>
                                                        <CustomSlider min={1}
                                                            max={40}
                                                            step={1}
                                                            value={tenure}
                                                            _handleChange={(e) => setTenure(e)} />
                                                        <div className='d-flex justify-content-between'>
                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.tenure.min}
                                                                postfix={detailData.meta_data.tenure.min == 1 ? "Year" : "Years"}
                                                                whiteSpace={false} />

                                                            <RepresentationLabel type={3}
                                                                value={detailData.meta_data.tenure.max}
                                                                postfix={detailData.meta_data.tenure.max == 1 ? "Year" : "Years"}
                                                                whiteSpace={false} />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Inflation ratio & life expectancy */}
                                                <div className='col-lg-6 col-md-6 mt-sm-5 mt-4'>
                                                    <div className={`${screenWidth > 576 ? 'p-4' : 'py-2 px-3'} border-radius-16px border-all border-bright-gray min-height-144px-sm-8rem`}>
                                                        <div className='row gx-5 position-relative'>
                                                            <div className='col-lg-12 col-md-12 col-12'>
                                                                <div className='d-flex justify-content-between mb-3'>
                                                                    <RepresentationLabel label="Inflation ratio"
                                                                        type={1}
                                                                        mandatory={false} />
                                                                    <RepresentationLabel type={2}
                                                                        value={inflationRatio}
                                                                        postfix={"%"} />
                                                                </div>
                                                                <CustomSlider min={detailData.meta_data.inflation_rate.min}
                                                                    max={detailData.meta_data.inflation_rate.max}
                                                                    step={detailData.meta_data.inflation_rate.step}
                                                                    value={inflationRatio}
                                                                    _handleChange={(e) => setInflationRatio(e)} />
                                                                <div className='d-flex justify-content-between'>
                                                                    <RepresentationLabel type={3}
                                                                        value={detailData.meta_data.inflation_rate.min}
                                                                        postfix={"%"}
                                                                        whiteSpace={false} />
                                                                    <RepresentationLabel type={3}
                                                                        value={detailData.meta_data.inflation_rate.max}
                                                                        postfix={"%"}
                                                                        whiteSpace={false} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    USER_DATA.is_assessment_completed === false &&
                                                        <div className="row mt-4 gx-0">
                                                            <div className="col-12">
                                                                <CompleteRiskScore />
                                                            </div>
                                                        </div>
                                                }
                                                
                                                {/* Button */}
                                                <div className='col-12 text-center margin-40px-top-sm-16'>
                                                    <GradientButton label="View Investment suggestion"
                                                        className="padding-12px-tb padding-24px-lr"
                                                        loading={false}
                                                        handleClick={_validateInput} />
                                                </div>
                                            </>
                                        ) : (
                                            <GoalSummary goalId={goalId}
                                                title={`By ${tenure === 1 ? `${tenure} year` : `${tenure} years`}, you must require the following sum.`}
                                                apiData={goalData}
                                                inflationRatio={inflationRatio}
                                                sip={detailData.is_set === true ? detailData.additional_data.sip : 0}
                                                step_sip={detailData.is_set === true ? detailData.additional_data.step_sip : detailData.meta_data.step_sip.default}
                                                sip_tenure={detailData.is_set === true ? detailData.additional_data.sip_tenure : detailData.meta_data.sip_tenure.default}
                                                lumpsum={detailData.is_set === true ? detailData.additional_data.lumpsum : 0}
                                                availableCorpus={availableCorpus}
                                                target={detailData.meta_data.default_target}
                                                onBackButtonPress={() => setCurrentGoalStep(1)} />
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
            }
        </>
    )
}