/*
 *   File : fund-order.js
 *   Author URI : www.evoqins.com
 *   Description : Fund order data table
 *   Integrations : null
 *   Version : v1.0
 *   Created : 11-09-2023
 */
import { useState, useEffect } from "react";
import { DataTable } from "../Form-elements";

import Icon from "../Icon/icon";
import { useNavigate } from "react-router-dom";

import Colors from '../../Styles/color.module.scss';


const FundOrderTable = (props) => {

    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [showArrow, setShowArrow] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const _handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    useEffect(() => {
        setTableData(props.data)
    }, [props.data]);

    const MF_COLUMNS = [
        {
            name: 'Funds',
            selector: row => row['name'],
            left: true,
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <img src={row['image']} alt={row['name']}
                        width={46}
                        height={46}
                        className="padding-6px-all border-radius-8px border-all border-bright-gray object-fit-contain" />
                    <div>
                        <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px bg-white mb-2">{row['name']}</p>
                        {
                            row['category'] !== null && (
                                <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px ">
                                    {row['category']}
                                </span>
                            )
                        }

                    </div>

                </div>
        },
        {
            name: 'Type',
            selector: row => row['type'],
            sortable: false,
            left: true,
            width: "150px",
            style: {
                color: Colors.black,
                fontFamily: "Poppins-SemiBold",
                fontSize: windowWidth > 575 ? "16px" : "14px",
                lineHeight: "18px",
            }
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "100px",
            cell: row =>
                <p className="color-black e-poppins-medium e-font-14 line-height-18px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Status',
            selector: row => row['order_status'],
            sortable: false,
            left: true,
            width: "200px",
            cell: row =>
                row['order_status'] === 'Failed' ?
                    <div className="d-flex align-items-center justify-content-center"
                        onClick={() => _handleCardClick(row)}>
                        <p className="color-lust e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                            {row['order_status']}
                        </p>
                        <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                            width={24}
                            height={24}
                            draggable={false} />
                    </div>
                    :
                    row['order_status'] === "Success" ?
                        <div className="d-flex align-items-center justify-content-center "
                            onClick={() => _handleCardClick(row)}>
                            <p className="color-sea-green e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                {row['order_status']}
                            </p>
                            <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                width={24}
                                height={24}
                                draggable={false} />
                        </div>
                        :
                        row['order_status'] === "Payment pending" ?
                            <div className="d-flex align-items-center justify-content-center"
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-sunset-blaze e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                    {row['order_status']}
                                </p>
                                <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                            </div>
                            :
                            <div className="d-flex align-items-center justify-content-center"
                                onClick={() => _handleCardClick(row)}>
                                <p className="color-yellow-orange e-poppins-medium e-font-14 line-height-18px mb-0 me-1">
                                    {row['order_status']}
                                </p>
                                <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                    width={24}
                                    height={24}
                                    draggable={false} />
                            </div>

        },
        {
            name: 'Order date',
            selector: row => row['order_date'],
            sortable: false,
            left: true,
            width: windowWidth > 575 ? "100px" : "150px",
            center: true,
            style: {
                color: Colors.black,
                fontFamily: "Poppins-Medium",
                fontSize: "14px",
                lineHeight: " 18px"
            }

        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "24px",
            cell: row =>
                <div className={`e-fund-order-hover text-start ${showArrow === row.order_id ? 'show' : ''}`} data-tag="allowRowEvents"
                    onClick={() => _handleCardClick(row)}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const MF_COLUMNS_MOBILE = [
        {
            name: 'Funds',
            selector: row => row['name'],
            left: true,
            width: '80%',
            cell: row =>
                <div className="d-flex align-items-center gap-6px  e-fund-name "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={24}
                            height={24}
                            className="object-fit-contain" />
                    </div>
                    <div>
                        <p className="color-black e-poppins-medium e-font-12 line-height-22px bg-white mb-2 ">{row['name']}</p>
                        {
                            row['category'] !== null && (
                                <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px ">
                                    {row['category']}
                                </span>
                            )
                        }

                    </div>

                </div>
        },
        {
            name: 'Amount',
            selector: row => row['amount'],
            sortable: false,
            left: true,
            width: "20%",
            cell: row =>
                <div className="text-center">
                    <p className="color-black e-poppins-medium e-font-10 line-height-18px mb-0"
                        onClick={() => _handleCardClick(row)}>
                        <span className="e-inter-medium ">₹</span>{row['amount'].toLocaleString('en-IN')}</p>
                    <span className="border-radius-16px mt-2 d-block border-all border-bright-gray  padding-12px-lr color-black e-poppins-medium e-font-8 line-height-18px ">
                        {row['type']}
                    </span>
                </div>
        },
    ];

    const _handleCardClick = (row) => {
        navigate("/orders/fund-order-summary", { state: { order_id: row['order_id'] } });
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.order_id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const customStyles = {
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        rows: {
            style: {
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
            }
        }
    };

    const customStylesMobile = {
        headRow: {
            style: {
                paddingRight: "0px",
                fontSize: "14px",
                paddingBottom: "8px !important",
            }
        },
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        rows: {
            style: {
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
                '&:first-child': {
                    padding: "0px 0 0px 0px !important"
                },
                '&:last-child': {
                    padding: "0px 0px 0px 0 !important"
                },
            }
        }
    };

    return (
        <div className="e-mobile-table">
            <DataTable columns={windowWidth > 576 ? MF_COLUMNS : MF_COLUMNS_MOBILE}
                data={props.data}
                pagination={false}
                customStyles={windowWidth > 576 ? customStyles : customStylesMobile}
                onRowMouseEnter={_handleRowHover}
                onRowMouseLeave={_handleRowRemoveHover}
                rowClick={(row) => _handleCardClick(row)} />
        </div>

    )
}

export default FundOrderTable