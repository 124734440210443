/*
 *   File : form-select.js
 *   Author URI : www.evoqins.com
 *   Description : Select box will allow the customers to pick the desired option from a precreated list.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";

/* import components */
import Icon from "../Icon/icon";

import Colors from '../../Styles/color.module.scss'

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon="right-arrow"
                width="20px"
                height="20px"
                color={props.error ? Colors.red : Colors.space_cadet} />
        </components.DropdownIndicator>
    );
};


const CustomSelectBox = (props) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0"
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            borderRadius: "8px",
            border: `1px solid ${props.error ? Colors.red : Colors.columbia_blue}`,
            background: "transparent",
            padding: "0.75rem 0.9375rem 0.75rem 0.9375rem",
            boxShadow: "none",
            "&:hover": {
                border: `1px solid ${props.error ? Colors.red : Colors.columbia_blue}`,
                cursor: 'pointer',
            },
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),
        menu: (defaultStyles) => ({
            ...defaultStyles,
            // width: "247px",
            right: "0",
            border: `2px solid ${Colors.gainsboro}`,
            background: Colors.white,
            boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
            zIndex: 10
        }),

        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '8px 10px',
            display: "flex",
            flexDirection: "column",
            gap: "18px",
            maxHeight: "15.625rem",
            "&::-webkit-scrollbar": {
                width: "0.125rem"
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: Colors.argent,
                borderRadius: "0.25rem"
            },
            '&::-webkit-scrollbar-track': {
                marginTop: "0.313rem",
                marginBottom: "0.313rem"
            },
        }),

        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(-90deg)" : "rotate(90deg)"
        }),
        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            color: Colors.black,
            fontFamily: "Poppins-regular",
            fontSize: "14px",
            fontStyle: " normal",
            fontWeight: " 400",
            backgroundColor: Colors.white,
            borderRadius: "12px",
            cursor: "pointer",
            "&:active": {
                backgroundColor: Colors.white,
            },

        }),

        singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "14px",
            lineHeight: "18px",
            marginLeft: "0"
        }),
    };

    const _onSelectChange = (value) => {
        props.onSelectChange(value)
    }

    const CustomSingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <p className="e-poppins-regular color-black mb-0">{children}</p>
        </components.SingleValue>
    );

    // Custom Option component with the icon
    const CustomOption = ({ children, isSelected, ...props }) => (
        <components.Option {...props}>
            <div className="d-flex align-items-center gap-10px">
                {
                    isSelected ?
                        <Icon icon="radio-selected"
                            size={24} />
                        :
                        <Icon icon="radio-unselected"
                            size={24} />
                }
                <span className={isSelected ? "e-poppins-medium" : "e-poppins-regular"}>{children}</span>
            </div>
        </components.Option>
    );

    return (
        <Fragment>
            <div className={`${props.className} e-custom-select-box position-relative`}>
                {props.error &&
                    <div className='position-absolute d-flex align-items-center e-error left-0 mt-2'>
                        <Icon icon="info-circle"
                            size={16} />
                        <p className={`color-red e-font-12 mb-0 ms-1`}>{props.error}</p>
                    </div>}
                <p className={`e-poppins-regular e-font-14 line-height-14 position-absolute  bg-white h-fit-content margin-auto e-label 
                ${value ? "e-transition-top bg-white z-index-1" : "e-transition-center"}
                ${props.error ? "color-red" : "color-black"} ${props.labelClass} `}>
                    {props.label}<span className="color-red">{props.postfix}</span>
                </p>
                <Select value={value}
                    styles={customStyles}
                    isSearchable={false}
                    options={props.options}
                    placeholder=""
                    // menuIsOpen={true}
                    components={{
                        DropdownIndicator,
                        IndicatorSeparator: null,
                        Option: CustomOption,
                        SingleValue: CustomSingleValue,
                    }}
                    onChange={(value) => {
                        _onSelectChange(value)
                    }}
                />
            </div>

        </Fragment>
    );
}

export default CustomSelectBox;