/*
 *   File : goal.js
 *   Author URI : https://evoqins.com
 *   Description : Goal detail banner
 *   Integrations : null
 *   Version : v1.1
 */

import { memo } from "react";

/*
    Different types of label handled in this component 
    
    
   title1 , title 2 and description with image

  

*/

export default memo(GoalBanner);
function GoalBanner(props) {
    return (
        <div className='border-radius-20px w-100 e-retirement-banner mt-4 mb-4 padding-40px-all position-relative'>
            <p className='color-white e-inter-semi-bold e-font-32-sm-24 mb-2 position-relative z-index-1 d-sm-block d-none'>{props.main_title}</p>
            <p className='color-white e-inter-medium e-font-32-sm-24 mb-3 position-relative z-index-1 d-sm-block d-none'>{props.sub_title}</p>
            <p className='color-white e-inter-medium e-font-32-sm-24 mb-3 position-relative z-index-1 d-sm-none d-block'>
                {`${props.main_title} ${props.sub_title}`}
            </p>
            <div className="row">
                <div className="col-xl-9 col-md-8 pe-xl-5 position-relative z-index-1">
                    <p className='color-pale-white e-poppins-medium e-font-14 line-height-sm-20px-24px mb-0 pe-xl-5 le-xl-5'>
                        {
                            props.description.length !== 0 ?
                                props.description :
                                "Did you know that, an average Indians need 2.8cr rupees to cover their retirement expenses, inflation and small emergency expenses? So, start investing early for your retirement."
                        }</p>
                </div>
            </div>

            <img src={props.banner_image}
                className='h-100 right-0 bottom-0 e-goal-banner-image z-index-0 '
                alt="" />
        </div>
    )
};

GoalBanner.defaultProps = {
    main_title: '',
    sub_title: '',
    description: ''
}