
/*
 *   File : import-externalfund.js
 *   Author URI : https://evoqins.com
 *   Description : Success banner
 *   Integrations : null
 *   Version : v1.1
 */

import { GradientButton } from "../CTA";

export default function ImportExternalFund (props) {
    return(
        <div className="bg-faint-pink border-bright-gray border-all border-radius-24px px-4 pt-4">
            <div className="row">
                <div className='col-7'>
                    <h6 className="color-black e-inter-regular e-font-16 ">
                        Congratulations you have successfully imported all fund details
                    </h6>
                    <h3 className="color-black e-inter-bold e-font-24 mt-1">
                        Create your investment account today!
                    </h3>
                    <GradientButton label='Start investment'
                        className='padding-8px-tb padding-32px-lr e-font-12 mt-3'/>
                </div>
                <div className="col-4 text-end">
                    <img src={require('../../Assets/Images/dashboard/fund-imported.png')} 
                        alt=""
                        className="h-200px"/>
                </div>
            </div>
        </div>
    )
}