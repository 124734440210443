
/*
 *   File : view-mf-sector-allocation.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for list all the mf-sector-allocation
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect } from 'react';
import Icon from '../Icon/icon';

import style from '../../Styles/Component/view-mf-holding.module.scss';


const MfSectorAllocation = (props) => {

    // Modal close listener
    useEffect(() => {
        var my_modal = document.getElementById("view-allocations");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    return (
        <div className={`modal fade ${style.e_holdings_modal}`}
            id="view-allocations"
            tabIndex="-1"
            aria-labelledby="view-allocations"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white position-relative text-center d-flex align-items-center px-sm-4 py-4 px-2 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="container ">
                        {/* Modal title */}
                        <div className="row">
                            <div className="col-12 text-start">
                                <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content">
                                    Sector allocation
                                </h4>
                            </div>
                        </div>
                        {/* Modal body */}
                        <div className={`${style.e_table_wrapper} mt-3 border-radius-16px border-all border-bright-gray padding-14px-top`}>
                            <div className='row border-bottom gx-0 pb-2'>
                                <div className='col-sm-9 col-8 text-start ps-2'>
                                    <h5 className='color-outer-space e-font-16 e-poppins-medium'>Name</h5>
                                </div>
                                <div className='col-sm-3 col-4 text-start'>
                                    <h5 className='color-outer-space e-font-16 e-poppins-medium'>Percentage</h5>
                                </div>
                            </div>
                            <div className={style.e_table_body}>
                                {
                                    props.data.name.map((allocation, key) => {
                                        return (
                                            <div className={`row gx-0 pt-3 ${key !== props.data.name.length - 1 && 'border-bottom'}`}>
                                                <div className='col-9 text-start d-flex ps-2'>
                                                    <div style={{ backgroundColor: props.data.color[key], width: "10px", height: "10px" }}
                                                        className="border-radius-100 mt-2 pt-1 me-2"></div>
                                                    <p className="color-outer-space e-poppins-regular e-font-16 line-height-24px mb-0">{allocation}</p>
                                                </div>
                                                <div className='col-3 text-start'>
                                                    <p className='color-outer-space e-font-14 e-poppins-regular'>{props.data.percentage[key]} %</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MfSectorAllocation;