
/*
 *   File :  status.js
 *   Author URI : https://evoqins.com
 *   Description : Shows e-sign status.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Component/Icon/icon";
import { GradientButton } from "../../../Component/CTA";
import { CustomLoader } from "../../../Component/Other";
import Store from "../../../Store";
import { _getProfile } from "../../../Helper/api";

export default function KYCStatus(props) {

    const navigator = useNavigate();

    const KYC_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA);
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [eSignStatus, setEsignStatus] = useState(1); // 1 => progress , 2 => failed, 3 => success
    const [loader, setLoader] = useState(true);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const _handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', _handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('resize', _handleResize);
        };
    }, []);

    useEffect(() => {
        const _getStatus = async () => {
            if (KYC_DATA.kyc_step > 10) {
                setEsignStatus(3);
                await _fetchProfile();
            }
            else if (KYC_DATA.kyc_step === 9) {
                setEsignStatus(1)
            }
            else if (KYC_DATA.kyc_step === 10) {
                setEsignStatus(2);
            }
            setLoader(false);
        };
    
        _getStatus();
    
        async function _fetchProfile() {
            let profile = await _getProfile();
            // profile.is_kyc_done = true;
            Store.dispatch({
                type: 'UPDATE_PROFILE_DATA',
                payload: profile
            });
        }
    }, []);

    function _navigateToHome() {
        navigator('/');
    };

    function _navigateToPreview() {
        props.updateProgress(9);
    }

    function _navigateToExplore() {
        let profile = USER_DATA;
        profile.is_kyc_done = true;
        Store.dispatch({
            type: 'UPDATE_PROFILE_DATA',
            payload: profile
        });
        navigator('/explore');
    }




    const Progress = (props) => {
        return (
            <div className={`d-flex flex-column align-items-center text-center ${props.index !== 3 && "margin-34px-right"}`}>
                <Icon icon={`${props.status === 2 ?
                    'check-green'
                    : props.status === 1 ?
                        'pending-yellow'
                        : props.status === 3 ?
                            'cancelled'
                            : 'pending'}`}
                    size={24}
                    className="z-index-1" />
                <span className={`e-poppins-medium e-font-10 line-height-16px my-1 ${props.status === 2 ?
                    'color-sea-green'
                    : props.status === 1 ?
                        'color-yellow-orange'
                        : props.status === 3 ?
                            'color-red'
                            : 'color-black'}`}>{props.title}</span>
                <span className={`e-poppins-medium e-font-8 line-height-10px color-outer-space`}>{props.data}</span>
            </div>
        )
    }

    return (
        loader === true ?
            <CustomLoader />
            :
            <div className="row mt-sm-5 pb-sm-0 pb-5">
                {
                    eSignStatus === 1 ? (
                        <div className="col-lg-7 col-md-7 col-12 pe-lg-5 pe-md-5 pe-2">
                            <img src={require('../../../Assets/Images/kyc/e-sign-progress.svg').default}
                                alt=""
                                draggable={false}
                                width={screenWidth < 576 && '40'}
                                className="mb-2" />
                            <h4 className="color-black e-inter-bold e-font-24-sm-18 ">
                                Your application is under process
                            </h4>
                            <p className="color-outer-space e-poppins-regular pe-lg-5 pe-md-5 pe-0 e-font-sm-16-14">
                                It usually takes 1-2 days to verify and activate the account.
                                We’ll keep you updated via SMS on your mobile number.
                            </p>
                            <div className="row gx-0">
                                <div className={`position-relative margin-26px-top-sm-8 e-line-progress-status h-100 ps-sm-3 ps-0  pb-4 w-auto`}>
                                    <div className={`position-absolute bg-platinum e-progress-line w-80 ms-4`}></div>
                                    <div className="d-flex ">
                                        <Progress title="Application details submitted"
                                            data={KYC_DATA.kyc_submission_details.detail_submitted_at_date ? `${KYC_DATA.kyc_submission_details.detail_submitted_at_date} | ${KYC_DATA.kyc_submission_details.detail_submitted_at_time}` : null}
                                            index={0}
                                            status={KYC_DATA.kyc_submission_details.detail_submitted_step} />
                                        <Progress title="Under verification at mastertrust"
                                            data={KYC_DATA.kyc_submission_details.verification_at_date ? `${KYC_DATA.kyc_submission_details.verification_at_date} | ${KYC_DATA.kyc_submission_details.verification_at_time}` : null}
                                            index={1}
                                            status={KYC_DATA.kyc_submission_details.verification_step} />
                                        <Progress title="Submitted to KRA"
                                            data={KYC_DATA.kyc_submission_details.submitted_to_kra_at_date ? `${KYC_DATA.kyc_submission_details.submitted_to_kra_at_date} | ${KYC_DATA.kyc_submission_details.submitted_to_kra_at_time}` : null}
                                            index={2}
                                            status={KYC_DATA.kyc_submission_details.submitted_to_kra_step} />
                                        <Progress title="KRA under process"
                                            data={KYC_DATA.kyc_submission_details.kra_verfied_at_date ? `${KYC_DATA.kyc_submission_details.kra_verfied_at_date} | ${KYC_DATA.kyc_submission_details.kra_verfied_at_time}` : null}
                                            index={3}
                                            status={KYC_DATA.kyc_submission_details.kra_verified_status} />
                                    </div>
                                </div>
                            </div>
                            <p className="color-outer-space e-poppins-regular e-font-14 margin-block-end-0 mt-3">
                                *The 24-hour commitment excludes weekends and public holidays.
                            </p>
                            <p className="color-outer-space e-poppins-regular e-font-14-sm-12 margin-block-end-0 my-2 py-1">
                                *If your  KRA status is pending, do not worry as in some cases it may take a few days
                                and we suggest you to check your mail id if you have got any verification mail from CVL.
                            </p>
                            <p className="color-outer-space e-poppins-regular e-font-14-sm-12">
                                *Your KRA status is: New KYC Validated & last status check on: 11 Nov 2023 3:58 PM.
                            </p>
                            {
                                KYC_DATA.signup_progress_text === 'KYC_SUBMITTED' || KYC_DATA.signup_progress_text === 'ADMIN_VERIFICATION' ? (
                                    <GradientButton label="Back to home"
                                        className="padding-12px-tb padding-20px-lr mt-sm-4 mt-0 mob-w-100"
                                        handleClick={_navigateToHome} />
                                ) : (
                                    <GradientButton label="Re-submit application"
                                        className="padding-12px-tb padding-20px-lr mt-sm-4 mt-0 mob-w-100"
                                        handleClick={_navigateToPreview} />
                                )
                            }

                        </div>
                    ) : eSignStatus === 2 ? (
                        <div className="col-lg-7 col-md-7 col-12 pe-lg-5 pe-md-5 pe-2">
                            <img src={require('../../../Assets/Images/kyc/e-sign-fail.png')}
                                alt=""
                                draggable={false}
                                width={screenWidth < 576 && '40'}
                                className="mb-2" />
                            <h4 className="color-black e-inter-bold e-font-24-sm-18 ">
                                Failed to upload your details
                            </h4>
                            <p className="color-outer-space e-poppins-regular pe-lg-5 pe-md-5 pe-0 e-font-sm-16-14">
                                We regret to inform you that your KYC details were rejected. Please review and correct any errors, then resubmit your application.
                            </p>
                            <div className="row gx-0">
                                <div className={`position-relative margin-26px-top-sm-8 e-line-progress-status h-100 ps-sm-3 ps-0  pb-4 w-auto`}>
                                    <div className={`position-absolute bg-platinum e-progress-line w-80 ms-4`}></div>
                                    <div className="d-flex ">
                                        <Progress title="Application details submitted"
                                            data={KYC_DATA.kyc_submission_details.detail_submitted_at_date ? `${KYC_DATA.kyc_submission_details.detail_submitted_at_date} | ${KYC_DATA.kyc_submission_details.detail_submitted_at_time}` : null}
                                            index={0}
                                            status={KYC_DATA.kyc_submission_details.detail_submitted_step} />
                                        <Progress title="Under verification at mastertrust"
                                            data={KYC_DATA.kyc_submission_details.verification_at_date ? `${KYC_DATA.kyc_submission_details.verification_at_date} | ${KYC_DATA.kyc_submission_details.verification_at_time}` : null}
                                            index={1}
                                            status={KYC_DATA.kyc_submission_details.verification_step} />
                                        <Progress title="Submitted to KRA"
                                            data={KYC_DATA.kyc_submission_details.submitted_to_kra_at_date ? `${KYC_DATA.kyc_submission_details.submitted_to_kra_at_date} | ${KYC_DATA.kyc_submission_details.submitted_to_kra_at_time}` : null}
                                            index={2}
                                            status={KYC_DATA.kyc_submission_details.submitted_to_kra_step} />
                                        <Progress title="Rejected by KRA"
                                            data={KYC_DATA.kyc_submission_details.kra_verfied_at_date ? `${KYC_DATA.kyc_submission_details.kra_verfied_at_date} | ${KYC_DATA.kyc_submission_details.kra_verfied_at_time}` : null}
                                            index={3}
                                            status={KYC_DATA.kyc_submission_details.kra_verified_status} />
                                    </div>
                                </div>
                            </div>
                            <GradientButton label="Re-submit application"
                                className="padding-12px-tb padding-20px-lr mt-sm-4 mt-0 mob-w-100"
                                handleClick={_navigateToPreview} />
                        </div>
                    ) :
                        <div className="col-lg-7 col-md-7 col-12 pe-lg-5 pe-md-5 pe-2">
                            <img src={require('../../../Assets/Images/kyc/e-sign-success.png')}
                                alt=""
                                draggable={false}
                                width={screenWidth < 576 && '40'}
                                className="mb-2" />
                            <h4 className="color-black e-inter-bold e-font-24-sm-18 ">
                                Account successfully created
                            </h4>
                            <p className="color-outer-space e-poppins-regular pe-lg-5 pe-md-5 pe-0 e-font-sm-16-14">
                                Your account has been successfully created, and you’re now
                                ready to embark on your investment journey with us.
                            </p>
                            <GradientButton label="Start investing"
                                className="padding-12px-tb padding-20px-lr mt-4 mob-w-100"
                                handleClick={_navigateToExplore} />
                        </div>

                }

                <div className="col-5 mt-5 pt-5 d-none d-lg-block d-md-block">
                    <img src={require('../../../Assets/Images/kyc/e-sign.png')} alt=""
                        className="w-100"
                        draggable={false} />
                </div>
            </div>
    );
}