/*
 *   File : pause-sip.js
 *   Author URI : https://evoqins.com
 *   Description : Confirmation UI to be shown before Pause an active SIP. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect } from "react";

import { GradientButton, OutlineButton } from "../CTA";
import Icon from "../Icon/icon";

import style from "../../Styles/Component/cancel-sip.module.scss"

const PauseSIPModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("pause-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    const _handlePause = () => {
        props.pauseSip();
    }

    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="pause-sip"
            tabIndex="-1"
            aria-labelledby="pause-sip"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-lr pb-3 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="text-center">
                        <img src={require("../../Assets/Images/manage-sip/pause-sip.png")}
                            alt="Pause SIP"
                            width={72}
                            height={72} />
                    </div>
                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-0 mt-4 text-center">Are you sure you want to pause your running SIP?</p>

                    <div className="mt-4 d-flex justify-content-center gap-8px margin-34px-top">
                        <OutlineButton label="Cancel"
                            cancel="modal"
                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb" />
                        <GradientButton label="Yes, pause"
                            cancel="modal"
                            className="e-font-16 padding-70px-lr padding-12px-tb"
                            handleClick={_handlePause} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PauseSIPModal