import axios from "axios";
import Cookies from "js-cookie";

import { logOut } from "../Helper";

export default async function APIService(is_post, url, data, token_type = 'access') {
    let API_URL = process.env.REACT_APP_API_URL + url;
    let authToken = sessionStorage.getItem('access_token');
    if (token_type === 'refresh') {
        authToken = Cookies.get('refreshToken');
    }
  
    if (is_post === true) {
        return await axios
            .post(API_URL, data, {
                headers: {
                    method: "post",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                timeout: 60000
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        logOut();
                    }
                    return error.response.data;
                } else if (error.request) {
                    return { message: 'Network connection time out', status_code : 504}
                }
            });
    } else {
        return await axios
            .get(API_URL, {
                headers: {
                    method: "get",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                timeout: 60000
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        logOut();
                    }
                    return error.response.data;
                }
                else if (error.request) {
                    return { message: 'Network connection time out', status_code : 504}
                }
            });
    }
}
