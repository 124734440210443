/*
 *   File : cancel-sip.js
 *   Author URI : https://evoqins.com
 *   Description : Confirmation UI to be shown before Cancelling an active SIP.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";

import { GradientButton, OutlineButton } from "../CTA";
import { CustomSelectBox, CustomTextInput } from "../Form-elements";
import Icon from "../Icon/icon";

import APIService from "../../Service/api-service";

import style from "../../Styles/Component/cancel-sip.module.scss"

const CancelSIPModal = (props) => {

    const [reasons, setReasons] = useState([]);
    const [selectedReason, setSelectedReason] = useState(null);
    const [otherReason, setOtherReason] = useState("");
    const [selectReasonError, setSelectReasonError] = useState("");
    const [otherReasonError, setOtherReasonError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("cancel-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        _getReasons();
    }, []);

    function _handleReason(value) {
        setSelectedReason(value);
        setSelectReasonError("");
    }

    function _handleInput (value) {
        setOtherReason(value);
        setOtherReasonError("");
    }

    function _getReasons() {
        let url = 'sip/reason-list';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                let updated_reason = response.data.map((item, key) => {
                    return ({ value: item.id, label: item.name })
                });
                setReasons(updated_reason);
            }
        })
    }

    async function _handleSubmit () {
        if (selectedReason === null) {
            setSelectReasonError("Reason is required");
            return;
        }else if (selectedReason.value == 13 && otherReason === ''){
            setOtherReasonError("Please specify reason");
            return;
        }
        try {
            let response = await props.cancel(selectedReason.value, otherReason);
            const close_button = document.getElementById("close-button");
            close_button.click();
        }catch (error) {
            
        }
        
    }
    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="cancel-sip"
            tabIndex="-1"
            aria-labelledby="cancel-sip"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-lr pb-3 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="text-center">
                        <img src={require("../../Assets/Images/manage-sip/cancel-sip-delete.png")}
                            alt="Delete SIP"
                            width={72}
                            height={72} />
                    </div>
                    
                    <p className="color-eerie-black e-poppins-medium e-font-18 mb-3 mt-4">Are you sure you want to cancel your SIP?</p>

                    <div className="position-relative border-radius-8px bg-antique-white p-2 ps-3">
                        <div className={`bg-chinese-orange position-absolute top-0 left-0 h-100 ${style.e_warning}`}>

                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <img src={require("../../Assets/Images/manage-sip/danger-triangle.png")}
                                alt="Danger"
                                width={24}
                                height={24} />
                            <p className="color-kenyan-copper e-poppins-semi-bold e-font-12 mb-0 ms-1">Warning</p>
                        </div>
                        <p className="color-medium-carmine e-poppins-medium e-font-12 mb-0">Cancelling SIP will affect your portfolio.</p>
                    </div>

                    <div className="col-12 mt-4">
                        <CustomSelectBox value={selectedReason}
                            label="Reason "
                            error={selectReasonError}
                            postfix="*"
                            className="margin-32px-bottom "
                            options={reasons}
                            onSelectChange={_handleReason} />
                    </div>

                    {
                        selectedReason !== null && selectedReason.value == 13 &&
                        <div className="col-12 ">
                            <CustomTextInput label="Specify reason"
                                postfix="*"
                                type="text"
                                value={otherReason}
                                error={otherReasonError}
                                handleChange={_handleInput} />
                        </div>
                    }

                    <div className="mt-4 d-flex justify-content-end gap-8px">
                        <OutlineButton label="Cancel"
                            id="close-button"
                            cancel="modal"
                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb" />
                        <GradientButton label="Yes, Cancel SIP"
                            className="e-font-16 px-3 padding-12px-tb"
                            handleClick={_handleSubmit} />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelSIPModal