
/*
 *   File : faq.js
 *   Author URI : https://evoqins.com
 *   Description : FAQ types are going to be listed on left side widget. Upon selection of any item will show the questions and answers on the right side widget.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";

import { InvestmentHeader } from "../Header";
import { BreadCrumb } from "../BreadCrumb";
import Icon from "../Icon/icon";

import Color from "../../Styles/color.module.scss"
import Footer from "../Footer/footer";

const PAGE_NAVIGATION = [
    {page: 'Home', url: null},
    {page: 'FAQs/Knowledge repository', url: null},
];

const FAQ_HEADING = ["General terms", "Life goals", "Explore funds", "Bank accounts", "My orders", "Investments", "Transactions", "Others"]

const FAQ_DATA = [{
    question: "What is an exit load?",
    answer: "An exit load is a fee that you may have to pay if you withdraw your money from a mutual fund within a specified period. Exist loads don’t apply to liquid mutual funds. They are more likely to apply to duration debt funds and equity funds."
},
{
    question: "What is the turnover ratio?",
    answer: "An exit load is a fee that you may have to pay if you withdraw your money from a mutual fund within a specified period. Exist loads don’t apply to liquid mutual funds. They are more likely to apply to duration debt funds and equity funds."
},
{
    question: "What is risk grade?",
    answer: "An exit load is a fee that you may have to pay if you withdraw your money from a mutual fund within a specified period. Exist loads don’t apply to liquid mutual funds. They are more likely to apply to duration debt funds and equity funds."
},
{
    question: "What is an entry load?",
    answer: "An exit load is a fee that you may have to pay if you withdraw your money from a mutual fund within a specified period. Exist loads don’t apply to liquid mutual funds. They are more likely to apply to duration debt funds and equity funds."
},
{
    question: "What is indexation?",
    answer: "An exit load is a fee that you may have to pay if you withdraw your money from a mutual fund within a specified period. Exist loads don’t apply to liquid mutual funds. They are more likely to apply to duration debt funds and equity funds."
}]

const FAQ = () => {

    const [selectedFaq, setSelectedFaq] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);

    // scroll to top
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const _handleFaqChange = (index) => {
        setSelectedFaq(index)
    }

    function _handleAccordionClick(index) {
        // console.log("Key", index)
        setActiveIndex(activeIndex === index ? null : index);
    }

    return (
        <Fragment>
            <div className="e-faq e-page-container margin-80px-bottom">
                {/* Header */}
                <InvestmentHeader />

                <BreadCrumb data={PAGE_NAVIGATION} />

                <h5 className="color-black e-poppins-medium e-font-20 line-height-32px my-3">FAQs</h5>

                <div className="row">
                    <div className="col-3 d-flex flex-column gap-8px">
                        {
                            FAQ_HEADING.map((item, key) =>
                                <div key={key}
                                    className={`d-flex justify-content-between align-items-center cursor-pointer py-3 pe-3 ps-2 ${selectedFaq === key && "bg-bubbles"}`}
                                    onClick={() => _handleFaqChange(key)}>
                                    <p className={`e-poppins-medium e-font-16 line-height-22px mb-0 ${selectedFaq === key ? "color-primary-color" : "color-outer-space"}`}>{item}</p>
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={selectedFaq !== key && Color.outer_space} />
                                </div>)
                        }
                    </div>

                    <div className="col-8 ps-4 ms-3">
                        <div class={`accordion accordion-flush d-flex flex-column gap-8px e-faq-accordian`}
                            id="faqAccordian">
                            {
                                FAQ_DATA.map((item, key) =>
                                    <div key={key}
                                        className={`padding-32px-bottom e-accordian-item ${key !== 0 && "pt-4"} ${key !== FAQ_DATA.length - 1 && "border-bottom-1px border-bright-gray"}`}
                                        onClick={() => _handleAccordionClick(key)}>
                                        <h2 class={`d-flex justify-content-between align-items-center mb-2`}
                                            id={`heading-${key}`}>
                                            <button class={`e-poppins-medium e-font-16 line-height-28px accordion-button collapsed p-0 e-heading `}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse-${key}`}
                                                aria-expanded="false"
                                                aria-controls={`collapse-${key}`}>
                                                {item.question}
                                            </button>
                                            {
                                                activeIndex === key ?
                                                    <Icon icon="minus-circle"
                                                        size={24} /> :
                                                    <Icon icon="add-circle"
                                                        size={24} />
                                            }
                                        </h2>
                                        <div id={`collapse-${key}`}
                                            class="accordion-collapse collapse"
                                            aria-labelledby={`heading-${key}`}
                                            data-bs-parent="#faqAccordian">
                                            <div>
                                                <p className="color-outer-space e-poppins-medium e-font-16 line-height-28px mb-0">{item.answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
    )
}

export default FAQ;