/*
 *   File : primary.js
 *   Author URI : https://evoqins.com
 *   Description : Primary button for site
 *   Integrations : null
 *   Version : v1.1
 */

import style from "../../Styles/Component/primary-btn.module.scss";

const PrimaryButton = (props) => {

    return (
        // Primary button
        <button className={props.disabled === false ?
            `${style.e_active_btn} ${props.className} line-height-24px e-poppins-semi-bold padding-12px-tb padding-24px-lr bg-ocean-boat-blue color-white text-center border-radius-8px no-border cursor-pointer`
            : `${style.e_inactive_btn} ${props.className} line-height-24px e-poppins-semi-bold padding-12px-tb padding-24px-lr bg-ocean-boat-blue color-white text-center border-radius-8px no-border`}
            id={props.id}
            type={props.type ? props.type : "submit"}
            style={{ width: props.type === 1 ? "100%" : null }}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-dismiss={props.cancel}
            onClick={props.handleClick}>
        </button>
    );
};

PrimaryButton.defaultProps = {
    onPress: () => { },
    className: '',
    cancel: null,
    toggle: null,
    target: null,
    disabled: false,
    loading: false,
    type: '',
    id: null,
    label: "",
    handleClick: () => { }
}

export default PrimaryButton;
