/*
 *   File : import-fund.js
 *   Author URI : https://evoqins.com
 *   Description : import fund component
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";

import { GradientButton } from "../CTA";
import Icon from "../Icon/icon";
import { OTPInput } from "../Form-elements";

import style from "../../Styles/Component/import-funds.module.scss"

const ImportFundModal = (props) => {
    const { close } = props;
    const [continueImport, setContinueImport] = useState(false);
    const [otpString, setOtpString] = useState("");
    const [viewInvestment, setViewInvestment] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const my_modal = document.getElementById("import-fund");
        my_modal.addEventListener("hidden.bs.modal", () => {
            close(); // You can directly use the 'close' function from props
        });
    }, [close]);

    const _handleContinue = () => {
        setContinueImport(true);
    }

    const _handleOtpSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        setContinueImport(false);
        setViewInvestment(true);
    }

    const _handleOtp = (value) => {
        setOtpString(value);
    }

    const _handleViewInvestment = () => {
        setViewInvestment(false);
    }

    return (
        <div className={`modal fade ${style.e_import_fund}`}
            id="import-fund"
            tabIndex="-1"
            aria-labelledby="import-fund"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_import_dialogue}`}>
                <div className={`modal-content bg-white position-relative ${style.e_import_content} `}>
                    {
                        continueImport === false && viewInvestment === false &&
                        <div className='d-flex flex-column align-items-center text-center '>
                            <img src={require('../../Assets/Images/dashboard/import-funds.png')}
                                alt="Import funds"
                                draggable={false}
                                width={72}
                                height={72} />

                            <p className='color-eerie-black e-poppins-medium e-font-18 mb-3 mt-3'>Import external funds</p>
                            <p className='color-jett-black e-poppins-regular e-font-16 mb-4'>To retrieve your external portfolio, we need to share your
                                PAN card details with MF Central.</p>
                            <div className="w-100 px-5 mx-5">
                                <GradientButton label="Continue"
                                    className="w-100 padding-12px-tb"
                                    handleClick={_handleContinue} />
                            </div>
                            <p className="color-outer-space e-poppins-regular e-font-12 line-height-16px mb-0 mt-2">
                                By clicking continue, I agree to <span className=" color-black text-decoration-underline cursor-pointer"
                                    onClick={() => window.open(`${process.env.REACT_APP_LANDING_PAGE_URL}/terms`, "_blank", "noreferrer")}>
                                    T&C</span> and <span className=" color-black text-decoration-underline cursor-pointer">Privacy Policy</span>
                            </p>
                        </div>
                    }
                    {
                        continueImport === true && viewInvestment === false &&
                        <div className='d-flex flex-column align-items-center text-center '>
                            <img src={require('../../Assets/Images/dashboard/otp-import.png')}
                                alt="Import funds"
                                draggable={false}
                                width={72}
                                height={72} />

                            <p className='color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3'>Enter OTP you have received in your mobile</p>
                            <p className='color-jett-black e-poppins-regular e-font-16 mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <OTPInput pinString={otpString}
                                className="mb-4"
                                id="import-fund"
                                autoFocus={true}
                                handleChange={_handleOtp} />
                            <div className="w-100 px-5 mx-5">
                                <GradientButton label="Continue"
                                    className="w-100 padding-12px-tb"
                                    handleClick={_handleOtpSubmit} />
                            </div>

                        </div>
                    }
                    {
                        loading === true &&
                        <div className="d-flex justify-content-center align-items-center">
                            {/* <img src={require('../../Assets/Images/dashboard/loading.gif')} alt="Loading"
                        width={}
                        draggable={false} /> */}
                            <p></p><p className='color-eerie-black e-poppins-medium e-font-18 mb-0 mt-3'>Your external investment imported successfully</p>
                        </div>
                    }

                    {
                        viewInvestment === true && continueImport === false && loading === false &&
                        <div className='d-flex flex-column align-items-center text-center '>
                            <img src={require('../../Assets/Images/dashboard/view-investment.png')}
                                alt="Import funds"
                                draggable={false}
                                width={72}
                                height={72} />

                            <p className='color-eerie-black e-poppins-medium e-font-18 mb-2 mt-3'>Your external investment imported successfully</p>
                            <p className='color-jett-black e-poppins-regular e-font-16 margin-48px-bottom'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                            <div className="w-100 px-5 mx-5">
                                <GradientButton label="View my investment"
                                    className="w-100 margin-32px-bottom padding-12px-tb"
                                    cancel="modal"
                                    handleClick={_handleViewInvestment} />
                            </div>

                        </div>
                    }
                    {
                        viewInvestment === false &&
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_import_close}`} />
                    }



                </div>
            </div>
        </div>
    )
}

export default ImportFundModal;