/*
 *   File : fund-order.js
 *   Author URI : www.evoqins.com
 *   Description : Order details for Fund.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 19-09-2023
 */

import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

import { InvestmentHeader } from "../../Component/Header"
import { BreadCrumb } from "../../Component/BreadCrumb"
import Icon from "../../Component/Icon/icon";
import { GradientButton, OutlineButton } from "../../Component/CTA";
import Footer from "../../Component/Footer/footer";
import { CustomLoader } from "../../Component/Other";
import { _getMFInvestmentSummary, _getMFDetails } from '../../Helper/api';
import APIService from "../../Service/api-service";
import { InvestGoalModal, PaymentStatusModal, PendingPayments, MfInProcessStatus } from "../../Component/Modal";
import { _getDayOfMonth } from "../../Helper";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Orders', url: '/orders' },
    { page: 'Order summary', url: null }
];

const FundOrderSummary = (props) => {

    const location = useLocation();
    const navigator = useNavigate();

    const orderId = location.state && location.state.order_id;

    const [apiLoader, setApiLoader] = useState(true);
    const [orderDetail, setOrderDetail] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [showInvestModal, setInvestModalStatus] = useState(false);
    const [mfData, setMFData] = useState({});
    const [mfInvestmentData, setMfInvestmentData] = useState({});
    const [investmentType, setInvestmentType] = useState(1);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [openPaymentStatusModal, setOpenPaymentStatusModal] = useState(false);
    const [paymentStatusText, setPaymentStatusText] = useState("");
    const [isNextMonth, setIsNextMonth] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [inProcessMessage, setInProcessMessage] = useState('');
    const [showMfInProcessModal, setShowMfInProcessModal] = useState(false);

    useEffect(() => {
        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        return () => {
            window.removeEventListener('resize', _handleResize);
        }
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        _getOrderDetail();
    }, []);

    function _handleRepeatOrder() {
        _getFundData();
    }

    useEffect(() => {
        if (showInvestModal === true) {
            _openInvestGoalModal();
        }
    }, [showInvestModal]);

    useEffect(() => {
        if (openPaymentModal === true) {
            _openPendingPaymentModal();
        }
    }, [openPaymentModal]);

    useEffect(()=>{
        if (showMfInProcessModal === true) {
            const modal = new Modal(document.getElementById("mf-inprocess-status"), {});
            modal.show();
        }
    },[showMfInProcessModal]);

    const _openMfInProcessModal = (message) => {
        setInProcessMessage(message);
        setShowMfInProcessModal(true);
    }

    //to open invest goal modal
    const _openInvestGoalModal = () => {
        const modal = new Modal(document.getElementById("invest-goal"), {});
        modal.show();
    };

    const _openPendingPaymentModal = () => {
        const modal = new Modal(document.getElementById("payment-modal"), {});
        modal.show();
    }

    useEffect(() => {
        if (openPaymentStatusModal === true) {
            const modal = new Modal(document.getElementById("payment-status"), {});
            modal.show();
        }
    }, [openPaymentStatusModal]);

    function _navigateToProfile() {
        navigator('/profile', {
            state: { tab: 5, type: 2 },
        });
    }

    function _getPaymentStatus(id, is_next_month, mode_of_payment) {
        if (id === null) {
            setOpenPaymentStatusModal(true);
            setIsNextMonth(is_next_month);
            setPaymentStatus(true);
            setPaymentStatusText('Success');
            return
        }
        let url = 'payment/check-status';
        let payload = JSON.stringify({
            payment_id: id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentStatusModal(true);
                setIsNextMonth(is_next_month);
                // making payment success modal status as processing in case of netbanking and made instant payment
                if (mode_of_payment === 2 && is_next_month !== true) {
                    setPaymentStatus(false);
                    setPaymentStatusText('Processing');
                }
                else {
                    setPaymentStatus(response.data.payment_status);
                    setPaymentStatusText(response.data.payment_status_txt);
                }
            }
        })
    }

    // API - Get MF order details
    function _getOrderDetail() {
        let url = 'order/detail';
        let payload = JSON.stringify({
            order_id: orderId
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOrderDetail(response.data);
                setInvestmentType(response.data.summary.type === "Lumpsum" ? 2 : 1)
                setApiLoader(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
        });
    }

    // API - Fund detail
    function _getFundData() {
        setButtonLoader(true);
        let params = {
            'id': orderDetail.funds[0].mf_id
        };

        _getMFDetails(params)
            .then((response) => {
                setMFData(response);
                _getInvestmentData();
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
                setButtonLoader(false);
            });
    };

    // API - MF investment data
    const _getInvestmentData = () => {
        const { date } = _getDayOfMonth();
        _getMFInvestmentSummary(orderDetail.funds[0].mf_id, true, date)
            .then((response) => {
                console.log("So much", response)
                setMfInvestmentData(response);
                setInvestModalStatus(true);
            }).catch((response) => {
                toast.dismiss();
                toast.error(response, {
                    type: "error",
                });
            });
        setButtonLoader(false);
    }

    function _getOTP() {
        let url = '2FA/get-otp';
        let payload = JSON.stringify({
            type: 'ORDER'
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setOpenPaymentModal(true);
                toast.success('OTP send to your registered mobile number', {
                    type: 'success'
                });
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }

    function _unMountModal() {
        setOpenPaymentModal(false);
    }

    const Description = (props) => {
        return (
            <>
                {
                    props.status === true ?
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14-sm-12 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14-sm-12 line-height-24px ${props.description === 'Failed' ? 'color-red' : props.description === 'Success' ? 'color-sea-green' : props.description === 'Payment pending' ? 'color-sunset-blaze' : 'color-yellow-orange'}`}>
                                {props.description}
                                {
                                    props.description === 'Failed' ?
                                        <img src={require("../../Assets/Images/orders/failed.png")} alt="Failed"
                                            width={24}
                                            height={24}
                                            draggable={false}
                                            className="ms-1" />

                                        : props.description === 'Success' ?
                                            <img src={require("../../Assets/Images/orders/success.png")} alt="Success"
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="ms-1" />
                                            : props.description === 'Payment pending' ?
                                                <img src={require("../../Assets/Images/orders/payment-pending.svg").default} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />
                                                :
                                                <img src={require("../../Assets/Images/orders/processing.png")} alt="Processing"
                                                    width={24}
                                                    height={24}
                                                    draggable={false}
                                                    className="ms-1" />

                                }
                            </span>
                        </div>
                        :
                        <div className="d-flex">
                            <span className="color-gray e-poppins-medium e-font-14-sm-12 line-height-24px">{props.title}&nbsp;</span>
                            <span className={`e-poppins-medium e-font-14-sm-12 line-height-24px color-outer-space`}>
                                {props.symbol === true && <span className="e-inter-medium">₹</span>}
                                {props.description}
                            </span>
                        </div>
                }
            </>

        )
    }

    const Progress = (props) => {
        return (
            <div className={`d-flex flex-column align-items-center text-center ${props.index !== 3 && "margin-64px-right"}`}>
                <Icon icon={`${props.progress === 3 ? 'check-green' : props.progress === 2 ? 'pending-yellow' : props.progress === 0 ? 'info-circle' : 'pending'}`}
                    size={24}
                    className="z-index-1" />
                <span className={`e-poppins-medium e-font-12 line-height-12px my-1 w-max-content ${props.progress === 3 ? 'color-sea-green' : props.progress === 2 ? 'color-yellow-orange' : props.progress === 0 ? 'color-red' : 'color-gray'}`}>{props.title}</span>
                <span className={`e-poppins-medium e-font-10 line-height-10px ${props.data !== null ? 'color-outer-space' : 'color-gray'}`}>{props.data}</span>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="e-fund-order-summary e-page-container margin-48px-bottom">
                {/* Header */}
                <InvestmentHeader />

                {/* Breadcrumb */}
                <BreadCrumb data={PAGE_NAVIGATION} />

                {
                    apiLoader === true ?
                        <div className="row">
                            <div className="col-12 text-center h-80vh">
                                <CustomLoader />
                            </div>
                        </div>
                        :
                        <>
                            <h5 className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px margin-32px-bottom-sm-16 margin-32px-top-sm-16">Order Summary - {orderDetail.summary.is_purchase ? 'Purchase' : orderDetail.summary.type}</h5>
                            {
                                orderDetail.summary.type === 'STP' || orderDetail.summary.type === 'Switch' ? (
                                    <div className="row pb-sm-0 pb-5">
                                        <div className="col-xl-9 ">
                                            <div className="border-radius-16px border-all border-bright-gray p-sm-4 p-2" >

                                                <div className="d-flex gap-16px padding-18px-bottom border-bottom-1px border-bright-gray">
                                                    <div className="col-sm-1 col-2">
                                                        <div className="border-all border-bright-gray border-radius-8px p-2">
                                                            <img src={orderDetail.summary.image}
                                                                alt="mf-image"
                                                                width={screenWidth > 576 ? 60 : 40}
                                                                height={40}
                                                                className="object-fit-contain " />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-9 col-7">
                                                        {
                                                            orderDetail.summary.type === 'STP' ?
                                                                <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                                    Transfer Out
                                                                </p>
                                                                :
                                                                <p className="color-black e-poppins-regular e-font-14 line-height-20px mb-2">
                                                                    Switch Out
                                                                </p>
                                                        }

                                                        <div >
                                                            <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-2">
                                                                {orderDetail.funds[0].fund_name}
                                                            </p>
                                                            <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                                {orderDetail.funds[0].category}
                                                            </span>
                                                        </div>
                                                        <img src={require('../../Assets/Images/orders/stp-arrow.png')}
                                                            className="e-arrow-down my-3 w-35px" />
                                                        {
                                                            orderDetail.summary.type === 'STP' ?
                                                                <p className="color-black e-poppins-regular e-font-14-sm-12 line-height-20px mb-2">
                                                                    Transfer In
                                                                </p>
                                                                :
                                                                <p className="color-black e-poppins-regular e-font-14-sm-12 line-height-20px mb-2">
                                                                    Switch In
                                                                </p>
                                                        }
                                                        <div className="d-flex align-items-center gap-12px">
                                                            <div>
                                                                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-20px mb-2">
                                                                    {orderDetail.funds[0].switch_in_fund_name}
                                                                </p>
                                                                <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                                    {orderDetail.funds[0].switch_in_fund_category}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 col-3">
                                                        {/* invested amount */}
                                                        <p className="color-black e-poppins-medium e-font-24-sm-14 line-height-26px mb-0">
                                                            <span className="e-inter-medium">₹</span>{orderDetail.summary.amount.toLocaleString("en-IN")}
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    orderDetail.funds.map((fund, key) => {
                                                        return (
                                                            <div key={key} className="mt-4">
                                                                <div className="row my-sm-3 my-2">
                                                                    <div className="col-xl-5 col-6 pe-0">
                                                                        <Description title="Order id:" description={fund.mt_order_id} />
                                                                    </div>
                                                                    <div className="col-xl-7 col-6 ">
                                                                        <Description title="Order type:" description={orderDetail.summary.type} />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-xl-5 col-6 pe-0">
                                                                        <Description title="Folio:"
                                                                            description={'6292739379'} symbol={false} />
                                                                    </div>
                                                                    <div className="col-xl-7 col-6 d-flex ">
                                                                    <Description title={orderDetail.summary.type === 'STP' ? "Transfer out units:" : "Switch out units:"}
                                                                            description={fund.units} />
                                                                        {/* <span className="color-outer-space e-poppins-regular e-font-12 mt-1">&nbsp;(23 Aug 2023)</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-sm-3 mt-2">
                                                                    <div className="col-xl-5 col-6 pe-0">
                                                                    <Description title={orderDetail.summary.type === 'STP' ? "Transfer out NAV:" : "Switch out NAV:"}
                                                                            description={fund.purchase_nav} />
                                                                    </div>
                                                                    <div className="col-xl-7 col-6 ">
                                                                    <Description title={orderDetail.summary.type === 'STP' ? "Transfer in units:" : "Switch in units:"}
                                                                            description={fund.switch_in_units} />
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-sm-3 mt-2">
                                                                    <div className="col-xl-5 col-6 pe-0">
                                                                    <Description title={orderDetail.summary.type === 'STP' ? "Transfer in NAV:" : "Switch in NAV:"}
                                                                            description={fund.switch_in_nav} />
                                                                    </div>
                                                                    <div className="col-xl-7 col-sm-6 mt-sm-0 mt-2 ">
                                                                    <Description title="Placed on:" description={`${orderDetail.summary.order_date} ${fund.order_time}`} />
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-sm-3 mt-2">
                                                                    <div className="col-xl-5 col-lg-6 pe-0">
                                                                        <Description title="Order status:"
                                                                            description={orderDetail.summary.order_status}
                                                                            status={true} />
                                                                    </div>
                                                                </div>

                                                                <div className=" w-100 overflowX-auto overflowY-auto e-order-milestone-wrapper">
                                                                    <div className={`position-relative margin-26px-top e-line-progress-status ps-3 border-bottom pb-4 `}>
                                                                        <div className={`position-absolute bg-platinum e-progress-line ms-1 w-85`}></div>
                                                                        <div className="d-flex ">
                                                                            <Progress title={orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ? "Payment created" : "Order created"}
                                                                                data={fund.order_date}
                                                                                progress={3}
                                                                                index={0}
                                                                                status={fund.order_status} />
                                                                            <Progress title={orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ? "Payment confirmed" : orderDetail.summary.order_status === 'Payment pending' ? orderDetail.summary.order_status : "2FA completed"}
                                                                                data={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? fund.payment_date : fund.payment_received}
                                                                                progress={
                                                                                    orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ?
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.payment_received !== null ? 3 : (fund.order_date !== null && fund.payment_received === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                        :
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.payment_date !== null ? 3 : (fund.order_date !== null && fund.payment_date === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1

                                                                                }
                                                                                index={1}
                                                                                status={fund.order_status} />
                                                                            <Progress title="Accepted by Exchange/RTA"
                                                                                data={fund.order_accepted}
                                                                                progress={
                                                                                    orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ?
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.order_accepted !== null ? 3 : (fund.payment_received !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                        :
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.order_accepted !== null ? 3 : (fund.payment_date !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1

                                                                                }
                                                                                index={2}
                                                                                status={fund.order_status} />
                                                                            <Progress title={`${orderDetail.summary.type === 'STP' ? "Transfer" : orderDetail.summary.type} Out status`}
                                                                                data={fund.switch_out_status}
                                                                                progress={orderDetail.summary.order_status !== 'Failed' && fund.switch_out_status !== null ? 3 : (fund.order_accepted !== null && fund.switch_out_status === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1}
                                                                                index={2}
                                                                                status={fund.switch_out_status} />
                                                                            <Progress title={`${orderDetail.summary.type === 'STP' ? "Transfer" : orderDetail.summary.type} In status`}
                                                                                data={fund.switch_in_status}
                                                                                progress={orderDetail.summary.order_status !== 'Failed' && fund.switch_in_status !== null ? 3 : (fund.switch_out_status !== null && fund.switch_in_status === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1}
                                                                                index={3}
                                                                                status={fund.switch_in_status} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    orderDetail.summary.order_status === 'Failed' && fund.failed_reason &&
                                                                    <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink  mt-4 p-3 border-radius-24px ">
                                                                        <img src={require('../../Assets/Images/orders/clock-failed.png')}
                                                                            className="me-2" /> <span className="mt-1">{fund.failed_reason}</span>
                                                                    </div>
                                                                }
                                                            </div>

                                                        )
                                                    })
                                                }


                                                {
                                                    orderDetail.summary.order_status === "Payment pending" && (orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP') &&
                                                    <div className="d-flex gap-8px bg-antique-white border-radius-8px p-2 mt-3">
                                                        <div className="w-max-content">
                                                            <Icon icon="info"
                                                                size={30} />
                                                        </div>
                                                        <p className="e-font-16 e-poppins-regular line-height-24px color-jett-black mb-0 ">In case of fund transfer via Net Banking, payment confirmation may take some time due to Clearing Corporation fund received. Fund transfer via UPI is instant</p>
                                                    </div>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row pb-sm-0 pb-5">
                                        <div className="col-xl-9 ">
                                            <div className="border-radius-16px border-all border-bright-gray p-sm-4 p-2" >
                                                <div className="d-flex justify-content-between align-items-center  gap-16px padding-18px-bottom border-bottom-1px border-bright-gray">
                                                    <div className="d-flex align-items-center gap-sm-12px-8px">
                                                        {/* mf image */}
                                                        <div className="border-all border-bright-gray border-radius-8px p-2">
                                                            <img src={orderDetail.summary.image}
                                                                alt="mf-image"
                                                                width={screenWidth > 576 ? 60 : 40}
                                                                height={40}
                                                                className="object-fit-contain " />
                                                        </div>


                                                        {/* fund name and category */}
                                                        <div>
                                                            <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-24px mb-2">
                                                                {orderDetail.funds[0].fund_name}
                                                            </p>
                                                            <span className="border-all border-bright-gray border-radius-16px color-black e-poppins-medium e-font-10 line-height-18px e-fund-chip padding-6px-tb ">
                                                                {orderDetail.funds[0].category}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    {/* invested amount */}
                                                    <p className="color-black e-poppins-medium e-font-24-sm-14 line-height-26px mb-0">
                                                        <span className="e-inter-medium">₹</span>{orderDetail.summary.amount.toLocaleString("en-IN")}
                                                    </p>
                                                </div>
                                                {
                                                    orderDetail.funds.map((fund, key) => {
                                                        return (
                                                            <div key={key} className="mt-4">
                                                                <div className="row my-sm-3 my-2">
                                                                    <div className="col-xl-5 col-6 pe-0">
                                                                        <Description title="Order id:" description={fund.mt_order_id} />
                                                                    </div>
                                                                    <div className="col-xl-7 col-6 ">
                                                                        <Description title="Order type:" description={orderDetail.summary.type} />
                                                                    </div>
                                                                </div>
                                                                <div className="d-sm-none d-block">
                                                                    <div className="row mt-sm-3 mt-2">
                                                                        <div className="col-xl-5 col-6 pe-0">
                                                                            <Description title="Units:" description={fund.units} />
                                                                        </div>
                                                                        <div className="col-xl-7 col-6 ">
                                                                            <Description title="Folio:" description={fund.folio_no} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-sm-0 mt-2">
                                                                    <div className="col-xl-5 col-sm-6 pe-0">
                                                                        <Description title="Placed on:" description={`${orderDetail.summary.order_date} ${fund.order_time}`} />
                                                                    </div>
                                                                    <div className="col-xl-7 col-sm-6 d-flex mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                                                                        <Description title={orderDetail.summary.type === 'Withdrawal' ? "Redemption NAV: " : "Purchased NAV: "}
                                                                            description={fund.purchase_nav} symbol={false} />
                                                                        {/* <span className="color-outer-space e-poppins-regular e-font-12 mt-1">&nbsp;(23 Aug 2023)</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className="d-sm-block d-none">
                                                                    <div className="row mt-sm-3 mt-2">
                                                                        <div className="col-xl-5 col-6 pe-0">
                                                                            <Description title="Units:" description={fund.units} />
                                                                        </div>
                                                                        <div className="col-xl-7 col-6 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                                                                            <Description title="Folio:" description={fund.folio_no} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-sm-3 mt-2">
                                                                    <div className="col-xl-5 col-lg-6 pe-0">
                                                                        <Description title="Order status:"
                                                                            description={orderDetail.summary.order_status}
                                                                            status={true} />
                                                                    </div>
                                                                </div>

                                                                <div className=" w-100 overflowX-auto overflowY-auto e-order-milestone-wrapper">
                                                                    <div className={`position-relative  e-line-progress-status ps-3 border-bottom pb-4 ${screenWidth > 576 ? 'margin-26px-top' : 'mt-2'}`}>
                                                                        <div className={`position-absolute bg-platinum e-progress-line `}></div>
                                                                        <div className="d-flex ">
                                                                            <Progress title={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? "Order created" : orderDetail.summary.type === 'Lumpsum' ? "Payment created" : orderDetail.summary.generate_today === true ? "Payment created" : "Order created"}
                                                                                data={fund.order_date}
                                                                                progress={3}
                                                                                index={0}
                                                                                status={fund.order_status} />
                                                                            <Progress title={orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ? "Payment confirmed" : orderDetail.summary.order_status === 'Payment pending' ? orderDetail.summary.order_status : "2FA completed"}
                                                                                data={orderDetail.summary.type === 'Withdrawal' || orderDetail.summary.type === 'SWP' ? fund.payment_date : fund.payment_received}
                                                                                progress={
                                                                                    orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ?
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.payment_received !== null ? 3 : (fund.order_date !== null && fund.payment_received === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                        :
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.payment_date !== null ? 3 : (fund.order_date !== null && fund.payment_date === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1

                                                                                }
                                                                                index={1}
                                                                                status={fund.order_status} />
                                                                            <Progress title="Accepted by Exchange/RTA"
                                                                                data={fund.order_accepted}
                                                                                progress={
                                                                                    orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ?
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.order_accepted !== null ? 3 : (fund.payment_received !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1
                                                                                        :
                                                                                        orderDetail.summary.order_status !== 'Failed' && fund.order_accepted !== null ? 3 : (fund.payment_date !== null && fund.order_accepted === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1

                                                                                }
                                                                                index={2}
                                                                                status={fund.order_status} />

                                                                            <Progress title={orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP' ? "Unit(s) allocated " : "Amount transferred"}
                                                                                data={fund.portfolio_updated}
                                                                                progress={fund.portfolio_updated !== null ? 3 : (fund.order_accepted !== null && fund.portfolio_updated === null && orderDetail.summary.order_status !== 'Failed') ? 2 : orderDetail.summary.order_status === 'Failed' ? 0 : 1}
                                                                                index={3}
                                                                                status={fund.order_status} />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {
                                                                    orderDetail.summary.order_status === 'Failed' && fund.failed_reason &&
                                                                    <div className="color-red-orange e-poppins-medium e-font-14 bg-soft-pink  mt-4 p-3 border-radius-24px ">
                                                                        <img src={require('../../Assets/Images/orders/clock-failed.png')}
                                                                            className="me-2" /> <span className="mt-1">{fund.failed_reason}</span>
                                                                    </div>
                                                                }
                                                            </div>

                                                        )
                                                    })
                                                }


                                                {
                                                    orderDetail.summary.order_status === "Payment pending" && (orderDetail.summary.type === 'Lumpsum' || orderDetail.summary.type === 'SIP') &&
                                                    <div className="d-flex gap-8px bg-antique-white border-radius-8px p-2 mt-3">
                                                        <div className="w-max-content">
                                                            <Icon icon="info"
                                                                size={30} />
                                                        </div>
                                                        <p className="e-font-16 e-poppins-regular line-height-24px color-jett-black mb-0 ">In case of fund transfer via Net Banking, payment confirmation may take some time due to Clearing Corporation fund received. Fund transfer via UPI is instant</p>
                                                    </div>
                                                }

                                                {/* invest more */}
                                                <div className="d-flex justify-content-end margin-32px-top">
                                                    {
                                                        orderDetail.summary.order_status === 'Payment pending' &&
                                                        <OutlineButton label="Pay now"
                                                            className="px-3 padding-12px-tb e-font-16 me-2"
                                                            handleClick={_getOTP} />
                                                    }
                                                    {
                                                        orderDetail.summary.is_purchase === true &&

                                                        <GradientButton label="Repeat order"
                                                            className="px-3 padding-12px-tb e-font-16"
                                                            loading={buttonLoader}
                                                            handleClick={_handleRepeatOrder} />

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </>
                }
            </div>


            {/* Investment modal */}
            {
                showInvestModal === true &&
                <InvestGoalModal data={mfData}
                    mfInvestmentData={mfInvestmentData}
                    amount={orderDetail.funds.amount}
                    fundType={orderDetail.summary.type}
                    selectedTab={investmentType}
                    close={() => {
                        setMfInvestmentData({})
                        setMFData({})
                        setInvestModalStatus(false);
                    }}
                    navigateToProfile={_navigateToProfile}
                    showInProcessModal={_openMfInProcessModal}
                    getPaymentStatus={_getPaymentStatus} />
            }

            {
                openPaymentStatusModal === true && (
                    <PaymentStatusModal status={paymentStatus}
                        paymentStatus={paymentStatusText}
                        type={isNextMonth === true ? 5 : 1}
                        close={() => setOpenPaymentStatusModal(false)} />
                )
            }

            {
                openPaymentModal === true && (
                    <PendingPayments data={orderDetail}
                        id={[orderId]}
                        redeem={false}
                        validUser={true}
                        close={_unMountModal}
                        successCallBack={_getOrderDetail}
                        handleInvalidToken={() => console.log('')} />
                )
            }
            {
                showMfInProcessModal === true &&
                    <MfInProcessStatus message={inProcessMessage} 
                        close={()=> setShowMfInProcessModal(false)}/>
            }

            <Footer />
        </Fragment >
    )
}

export default FundOrderSummary