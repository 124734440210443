/*
 *   File : sidebarModal.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for resposnive sidebar item
 *   Integrations : null
 *   Version : v1.1
 */

import React, { useRef, useEffect } from 'react';

import { NavigationItem } from "../../Container/Navigation/index";

const MenuModal = ({ onClose, showOverlay }) => {

    const modalRef = useRef();

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    return (
        <>
            {
                showOverlay &&
                <div className="overlay" onClick={onClose}></div>
            }
            <div className="left-slide-modal">
                <div className="modal-content e-sidebar-modal">
                    <img draggable={false}
                        src={require("../../Assets/Images/close.svg").default}
                        width={25}
                        height={25}
                        className="close-icon position-absolute top-20px right-20px cursor-pointer"
                        alt="Mastertrust"
                        onClick={onClose} />
                    <NavigationItem />
                    <p className='color-silver-sand e-poppins-medium e-font-8 mt-3 mb-0'>
                        AMFI Registered Mutual Fund Distributor:  ARN-58711 
                    </p>
                    <p className='color-silver-sand e-poppins-medium e-font-8 mt-1 mb-0'>
                        Date of initial Registration: 18/03/2008 
                    </p>
                    <p className='color-silver-sand e-poppins-medium e-font-8 mt-1 mb-0'>
                        Current validity of ARN: 17/03/2025
                    </p>

                </div>
            </div>
        </>
    );
};

export default MenuModal;
