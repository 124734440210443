/*
 *   File : unlock.js
 *   Author URI : https://evoqins.com
 *   Description : Unlock modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";

import { GradientButton, OutlineButton } from "../CTA";
import Icon from "../Icon/icon";

import style from "../../Styles/Component/unlock.module.scss"

const DeclarationErrorModal = (props) => {
    // modal close listener
    useEffect(() => {
        const my_modal = document.getElementById("declaration-error");
        my_modal.addEventListener("hidden.bs.modal", () => {
        });
    }, []);


    return (
        <div id="declaration-error"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="declaration-error"
            aria-hidden="true"
            className={`modal fade ${style.e_unlock}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-end align-items-center ">
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className={` cursor-pointer e-modal-close-btn`} />
                    </div>
                    <div className="col-12 text-center">
                        <img src={require('../../Assets/Images/error-modal.png')}
                        />
                    </div>

                    <div className="d-flex justify-content-center">
                        <p className="color-outer-space e-poppins-medium e-font-18 line-height-27px mb-4 margin-20px-top">
                            {props.message}
                        </p>
                    </div>

                    <div className="mt-4 d-flex justify-content-center gap-8px">
                        <OutlineButton label="Cancel"
                            cancel="modal"
                            className="e-font-16 e-poppins-regular px-4 padding-12px-tb " />
                        <GradientButton label="Yes, continue"
                            cancel="modal"
                            className="e-font-16 px-3 padding-12px-tb w-80" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeclarationErrorModal;
