
/*
 *   File : landing.js
 *   Author URI : https://evoqins.com
 *   Description : KYC verification landing page.
 *   Integrations : null
 *   Version : v1.1
 */

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import Store from "../../../Store";

import { GradientButton } from "../../../Component/CTA";
import { CustomTextInput, CustomPhoneInput, CustomSelectBox } from "../../../Component/Form-elements";
import Icon from "../../../Component/Icon/icon";

import APIService from "../../../Service/api-service";
import { _getProfile } from "../../../Helper/api";

export default function LandingPage(props) {
    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);
    const [otpScreen, setOtpScreen] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(null);
    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState('');
    const [apiLoader, setApiLoader] = useState(false);
    const [maskedMail, setMaskedMail] = useState('');
    const [maskedMobile, setMaskedMobile] = useState('');
    const [showInfo, setShowInfo] = useState(false);
    const [selectedUserType, setSelectedUserType] = useState(1);
    const [signedUpUsingMobile, setSignedUpUsingMobile] = useState(true);
    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [seconds, setSeconds] = useState(30);
    const [selectedRelation, setSelectedRelation] = useState("");
    const [relationOptions, setRelationOptions] = useState([]);
    const [relationError, setRelationError] = useState("");

    useEffect(() => {
        if (USER_DATA.mobile === '-') {
            setSignedUpUsingMobile(false);
        }
    }, [USER_DATA]);

    useEffect(() => {
        if (otpScreen === true) {
            const countdown = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    clearInterval(countdown);
                    // Handle expiration logic here (e.g., show a resend button)
                }
            }, 1000);

            return () => {
                clearInterval(countdown);
            };
        } else {
            setSeconds(30);
            setOtp("");
        }
    }, [seconds, otpScreen]);

    useEffect(() => {
        _getRelationOptions();
    }, []);

    // input handler
    function _handleInput(value, type) {
        switch (type) {
            case 1: setOtp(value);
                setOtpError(null);
                break;
            case 2: setMail(value);
                setMailError(null);
                break;
            case 3: setMobile(value);
                setMobileError('');
                break;
            default: return;
        }
    }

    // API - get mobile|email relation
    function _getRelationOptions() {
        let url = 'auth/signup-relation';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const relation_options = response.data.map(({ id, name }) => ({
                    value: id,
                    label: name
                }));
                setRelationOptions(relation_options);
                setSelectedRelation(relation_options[0]);
            }
        })
    }

    // email validation and render otp screen;
    function _getOTP(resend = false) {
        if (signedUpUsingMobile === true) {
            if (mail === '') {
                setMailError('Email is required');
                return;
            } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail)) {
                setMailError('Invalid email');
                return;
            }
        } else {
            if (mobile.length < 10) {
                setMobileError('Mobile number is required');
                return;
            }
        }
        _addMobileOrMail(resend);
        if (signedUpUsingMobile === true) {
            _maskMail();
        } else {
            _maskMobile();
        }
    }

    // masking mail
    function _maskMail() {
        let user_name = mail.split('@')[0];
        let domain = mail.split('@')[1];
        let masking_length = user_name.length <= 4 ? Math.floor(user_name.length / 2) : 3;
        let masked_name = user_name.substring(0, masking_length) + '*'.repeat(user_name.length - masking_length);
        setMaskedMail(`${masked_name}@${domain}`);
    }

    function _maskMobile() {
        const masked_number = mobile.substring(0, 3) + "*****" + mobile.substring(8);
        setMaskedMobile(masked_number);
    }


    function _verifyOTP() {
        if (otp === '') {
            setOtpError('OTP required');
            return;
        } else if (!/^\d{4}$/.test(otp)) {
            setOtpError('Invalid otp must be 4 digits');
            return;
        } else {
            _verifyMobileOrEmail();
        }
    }

    function _handleEditClick() {
        setOtpError('');
        setOtpScreen(false);
        setMaskedMail('');
    }

    function _handleHover() {
        setShowInfo(!showInfo);
    }

    function _selectAccountType(e) {
        setSelectedUserType(e);
    }

    function _handleSelectBox(e) {
        setSelectedRelation(e);
        setRelationError("");
    }
    // Account type card
    function AccountType(props) {
        return (
            <div className={`bg-icy-blue border-radius-16px  border-all position-relative e-kyc-user-type-card ${props.enabled === false && 'no-events'} ${selectedUserType === props.type ? 'border-primary-color' : 'border-transparent'} `}
                data-coming-soon={`${!props.enabled}`}
                onClick={() => _selectAccountType(props.type)}>
                <img src={props.icon}
                    draggable={false}
                    alt="" />
                {
                    selectedUserType === props.type && (
                        <Icon icon='cart-selected'
                            size={24}
                            className="position-absolute top-14px right-14px" />
                    )
                }
                {
                    props.enabled === false && (
                        <div className="e-kyc-type-card-ribbon position-absolute bg-ocean-boat-blue color-white e-poppins-regular e-font-10 text-center">
                            Coming soon
                        </div>
                    )
                }
                <h4 className="color-black e-poppins-bold mt-3 margin-block-end-0 e-kyc-investment-type">{props.title}</h4>
                <p className="color-outer-space e-poppins-medium e-font-12 mt-1">{props.desc}</p>
            </div>
        )
    };

    // API :: add phone number 
    const _addMobileOrMail = (resend) => {
        if (resend === false) {
            setApiLoader(true);
        }
        let url = 'profile/add-mobile';
        let data = {
            "mobile": mobile,
            "relation": selectedRelation.value
        }
        if (signedUpUsingMobile === true) {
            url = 'profile/add-email';
            data = {
                "email": mail.toLowerCase(),
                "relation": selectedRelation.value
            }
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success('OTP has been sent successfully', {
                    type: "success"
                });
                if (resend === true) {
                    setSeconds(30);
                    setOtp("");
                }
                setOtpScreen(true);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API :: verification
    const _verifyMobileOrEmail = () => {
        setApiLoader(true);
        let url = 'profile/verify-mobile';
        let data = {
            "mobile": mobile,
            "otp": otp,
            "relation": selectedRelation.value
        }
        if (signedUpUsingMobile === true) {
            url = 'profile/verify-email';
            data = {
                "email": mail.toLowerCase(),
                "otp": otp,
                "relation": selectedRelation.value
            }
        }

        APIService(true, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(signedUpUsingMobile === true ? 'Email updated successfully' : 'Mobile updated successfully', {
                    type: "success"
                });
                _fetchProfile();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }

    // API - Get profile details 
    const _fetchProfile = async () => {
        try {
            const profile_data = await _getProfile();
            Store.dispatch({
                type: 'UPDATE_PROFILE_DATA',
                payload: profile_data
            });
            props.updateProgress(2);
        } catch (error) {
            toast.dismiss();
            toast.error(error, {
                type: "error"
            });
        }
    };

    return (
        <div className="row gx-0">
            <div className="col-lg-8 col-12 pe-lg-5 pe-md-5 pe-1">
                <h2 className="color-black e-inter-bold e-font-24 mt-5">Let's get started</h2>
                <p className="color-outer-space e-poppins-regular e-font-16px mt-3">
                    Open your investment account in just a few clicks. It will take few minutes*.
                </p>

                {/* Info card */}
                {/* <div className="mt-4">
                    <div className="d-flex border-pearl-aqua border-all border-radius-16px p-2 justify-content-space-around ">
                        <img src={require('../../../Assets/Images/kyc/sand-glass.svg').default}
                            alt="" />
                        <div className="ms-2">
                            <p className="color-outer-space e-poppins-regular e-font-16px margin-block-end-0 pe-5">
                                For making <span className="color-primary-color">faster account opening</span> process we are using
                                <img src={require('../../../Assets/Images/kyc/digiLocker.svg').default}
                                    className="ms-1" />
                            </p>
                        </div>
                        <div className="position-relative">
                            <Icon icon="info"
                                size={30}
                                className="cursor-pointer"
                                onMouseEnter={_handleHover}
                                onMouseLeave={_handleHover} />
                            {
                                showInfo === true &&
                                <p className="color-outer-space e-poppins-regular e-font-12 line-height-14px e-kyc-landing-info p-2 position-absolute bg-white border-radius-8px">
                                   Lorem ipsum dolor sit amet, see consectetur adipiscing elit. Nunc vulputate libero et velit nunc om interdum, ac aliquet odio mattis.
                                </p>
                            }
                        </div>
                    </div>
                </div> */}

                {/* Card section */}
                <div className="row margin-30px-top">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="p-1 overflow-hidden">
                            <AccountType title="Individual"
                                desc="For individual investors who want to invest in mutual funds."
                                icon={require('../../../Assets/Images/kyc/user-individual.svg').default}
                                type={1}
                                enabled={true} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
                        <div className="p-1 overflow-hidden">
                            <AccountType title="Non-Individual"
                                desc="For non-individual entities such as companies, partnerships, etc."
                                icon={require('../../../Assets/Images/kyc/user-trust.svg').default}
                                type={2}
                                enabled={false} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-0 mt-3">
                        <div className="p-1 overflow-hidden">
                            <AccountType title="NRI"
                                desc="For non-resident Indians who want to invest in Indian rupees or foreign currency."
                                icon={require('../../../Assets/Images/kyc/user-NRI.svg').default}
                                type={3}
                                enabled={false} />
                        </div>
                    </div>
                </div>
                {/* Form */}
                <div className="pt-2">
                    <div className="bg-azure-mist border-radius-24px p-4 mt-4">
                        <div className="row">
                            <div className="col-lg-10 col-md-10 col-12">
                                <h6 className="color-black e-poppins-medium e-font-16px">{`Create an account using ${signedUpUsingMobile === true ? 'mail' : 'phone number'}`}</h6>

                                <p className="color-outer-space e-poppins-regular e-font-16px ">
                                    {
                                        otpScreen === true ?
                                            `Enter the OTP received on ${signedUpUsingMobile === true ? maskedMail : maskedMobile}`
                                            :
                                            `We’ll send you an OTP to verify your ${signedUpUsingMobile === true ? 'mail  Id' : 'phone number'}.`
                                    }
                                    {
                                        otpScreen === true && (
                                            <span className="cursor-pointer"
                                                onClick={_handleEditClick}>
                                                <span className="color-primary-color e-poppins-medium e-font-14 line-height-16px mx-1 d-inline-block">Edit</span>
                                                <Icon icon="Pen-square"
                                                    size={18} />
                                            </span>
                                        )
                                    }
                                </p>
                                {
                                    otpScreen === true ? (
                                        <>
                                            <CustomTextInput value={otp}
                                                label="Enter OTP"
                                                type="otp"
                                                name="otp"
                                                inputClass="bg-transparent"
                                                labelClass="bg-azure-mist"
                                                className="mt-4 mb-3"
                                                maxLength={4}
                                                postfix="*"
                                                error={otpError}
                                                autoComplete={false}
                                                isAuthInput={true}
                                                handleChange={(e) => _handleInput(e, 1)}
                                                require />
                                            <div className="d-flex justify-content-start mt-4">
                                                {
                                                    seconds === 0 ?
                                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                            onClick={() => _getOTP(true)}>Resend OTP</p>
                                                        :
                                                        <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                            Resend OTP in <span className="color-black">{seconds}s</span>
                                                        </p>
                                                }

                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                signedUpUsingMobile === false ? (
                                                    <CustomPhoneInput
                                                        label="Mobile number"
                                                        postfix="*"
                                                        error={mobileError}
                                                        value={mobile}
                                                        type='number'
                                                        isMobileInput={true}
                                                        className="mt-4 mb-3"
                                                        inputClass="bg-transparent"
                                                        labelClass="bg-azure-mist"
                                                        handleChange={(e) => _handleInput(e, 3)} />
                                                ) : (
                                                    <CustomTextInput value={mail}
                                                        label="Mail"
                                                        type="text"
                                                        className="mt-4 mb-3"
                                                        inputClass="bg-transparent"
                                                        labelClass="bg-azure-mist"
                                                        error={mailError}
                                                        postfix="*"
                                                        handleChange={(e) => _handleInput(e, 2)}
                                                        require />
                                                )
                                            }
                                            <div className="mt-4 pt-1">
                                                <CustomSelectBox value={selectedRelation}
                                                    label={signedUpUsingMobile === false ? "Mobile relation" : "Email relation"}
                                                    labelClass="bg-azure-mist"
                                                    error={relationError}
                                                    postfix="*"
                                                    className="mt-3"
                                                    options={relationOptions}
                                                    onSelectChange={(value) => _handleSelectBox(value)} />
                                            </div>
                                            <p className="e-poppins-regular d-flex align-items-center e-font-10 mb-0 color-outer-space mt-2">
                                                <Icon icon="checked"
                                                    size={18} />
                                                <div className="ms-1">
                                                    {`By submitting the data, you are confirming the ${signedUpUsingMobile === false ? 'mobile' : 'email'} relation`}
                                                </div>
                                            </p>
                                        </>

                                    )
                                }
                                <GradientButton label={otpScreen === true ? 'Continue' : 'Get OTP'}
                                    loading={apiLoader}
                                    className='mt-4 padding-14px-tb padding-16px-lr e-font-16'
                                    handleClick={otpScreen === true ? _verifyOTP : _getOTP} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Note */}
                <p className="color-outer-space e-poppins-regular e-font-12 mt-2">
                    *Account opening may take 3-5 minutes based on your KYC status, DigiLocker account,
                    IPV video and documents upload.
                </p>
            </div>
            <div className="col-lg-4 d-lg-block d-md-block d-none col-12">
                <img src={require('../../../Assets/Images/kyc/kyc-landing.png')}
                    alt=""
                    draggable={false}
                    className="w-100" />
            </div>
        </div >
    )
}