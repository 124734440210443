/*
 *   File : search-select-input.js
 *   Author URI : www.evoqins.com
 *   Description : Select box with search functionality
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState, forwardRef, useRef } from "react";

/* import components */
import { Icon } from "../Icon";

/* import styles */
import styles from "../../Styles/Component/search.module.scss";

const SearchSelectInput = (props, dropdownRef) => {

    const inputRef = useRef(null);
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    // useClickOutside(dropdownRef, () => {
    //     props.closeDropDown();
    // });

    // onchange value
    const _handleChange = (event) => {
        const input_value = event.target.value;
        setValue(input_value);
        props.valueChange(input_value);
    }

    //on press of enter
    // const _handleSearch = (event) => {
    //     const value = event.target.value;
    //     if (event.keyCode === 13) {
    //         props.handleSearch(value);
    //     }
    // }

    // clear suggestion or focus on text input
    const _iconClick = () => {
        if (value.length == 0) {
            inputRef.current.focus(); // focus on search input
        } else {
            setValue(''); // clear the input
            props.valueChange(''); // sending props to clear search input info
        }
    }

    // enable search when data is there
    const _focusSearchInput = () => {
        props.valueChange(value);
    }

    return (
        <Fragment>
            <div className={
                props.error && props.error.length !== 0 ?
                    `${styles.e_search_input}  ${styles.e_error_input}  w-100 position-relative`
                    :
                    `${styles.e_search_input} w-100 position-relative`}
                ref={dropdownRef}>

                <div className={`${styles.e_input_wrapper} position-relative`}>
                    <input type="text"
                        id={props.id ? props.id : props.placeholder}
                        spellCheck="false"
                        ref={inputRef}
                        name={props.name}
                        required
                        // placeholder={props.placeholder}
                        autoComplete="off"
                        value={value}
                        className={`border-columbia-blue ${props.className} ${styles.e_input}`}
                        onFocus={_focusSearchInput}
                        onChange={_handleChange}
                    // onKeyDown={(e) => _handleSearch(e)} 
                    />

                    {/* label */}
                    <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                        htmlFor={props.id ? props.id : props.placeholder}>
                        {props.placeholder}
                        <span className='color-red'>
                            {props.postfix}
                        </span>
                    </label>

                    {/* right side icon - close and search */}
                    <Icon icon={(value.length == 0) ? "search" : "close"}
                        size={24}
                        className={`position-absolute top-0 right-10px bottom-0 ${value.length != 0 && 'cursor-pointer'}`}
                        onClick={_iconClick} />
                </div>

                {
                    props.showDropdown ?
                        <div className="position-absolute border-all border-anti-flash-white border-radius-8px box-shadow-dropdown top-56px bg-white w-100 z-index-9 h-290px overflow-auto e-search-suggestion-dropdown" >
                            {
                                props.data.length !== 0 ?
                                    props.data.map((item, index) => {
                                        return (
                                            <p className={`${item.id === props.activeId ? 'e-poppins-medium' : 'e-poppins-regular'} color-outer-space e-font-12 line-height-14px cursor-pointer line-height-10px text-start px-3 padding-18px-tb mb-0 ${index === props.data.length - 1 ? '' : "border-bottom border-bright-gray"}`}
                                                onClick={() => { props.select(false, item.id, item.name, item) }}>
                                                {item.name}</p>
                                        )
                                    })
                                    :
                                    <p className="e-poppins-regular color-outer-space e-font-12 line-height-10px px-3 padding-18px-tb mb-0">No options found</p>
                            }
                        </div>
                        :
                        null
                }

                {
                    props.hide_error_text ?
                        null
                        :
                        props.error &&
                        <div className='position-absolute d-flex align-items-center mt-1 '>
                            <Icon icon="info-circle"
                                size={16}
                                className="me-1" />
                            <p className='color-red mb-0 e-font-12 my-0 '>
                                {props.error}
                            </p>
                        </div>
                }
            </div>
        </Fragment>
    );
};

SearchSelectInput.defaultProps = {
    valueChange: () => { },
    id: 1,
    error: "",
    value: "",
    placeholder: "Search",
    className: '',
    customerList: true,
    onClick: () => { },
    handleSearch: () => { },
    closeDropDown: () => { },
    select: () => { }
}

export default forwardRef(SearchSelectInput);