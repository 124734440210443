/*
 *   File : unlock.js
 *   Author URI : https://evoqins.com
 *   Description : Unlock modal
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState, useRef } from "react";

import { GradientButton } from "../CTA";
import Icon from "../Icon/icon";
import { OTPInput } from "../Form-elements";

import style from "../../Styles/Component/unlock.module.scss"

const ActivateSIPModal = (props) => {

    const firstPINref = useRef(null);

    const [pinString, setPinString] = useState("");

    const [errorMessage, setErrorMessage] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);

    // Focus the first input 
    // useEffect(() => {
    //     const my_modal = document.getElementById("active-sip");
    //     my_modal.addEventListener("shown.bs.modal", () => {
    //         firstPINref.current.click();
    //     });
    // }, []);

    // modal close listener
    useEffect(() => {
        const my_modal = document.getElementById("active-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
            setPinString('');
        });
    }, []);

    // update button loading status 
    useEffect(() => {
        setButtonLoader(props.loader);
    }, [props.loader]);

    useEffect(() => {
        const _handleKeyPress = (event) => {
            // Check if Ctrl key (or Command key on Mac) is pressed and 'a' key is pressed
            if (event.ctrlKey && event.key === 'a') {
                event.preventDefault(); // Prevent the default behavior (text selection)
            }
        };

        // Attach the event listener to the modal content
        const modalContent = document.getElementById('active-sip');

        modalContent.addEventListener('keydown', _handleKeyPress);

        // Clean up the event listener when the component is unmounted
        return () => {
            modalContent.removeEventListener('keydown', _handleKeyPress);
        };
    }, []);

    // handle function pin input
    const _handlePin = (value) => {
        setPinString(value);
        setErrorMessage("");
    }

    // submit button click 
    async function _verifyPin() {
        if (pinString.length !== 4) {
            setErrorMessage("Please enter OTP");
            return;
        }
        try {
            const result = await props.handleOTPSubmission(pinString);
            // closing modal after api call;
            if (result === true) {
                const close_button = document.getElementById("close-modal");
                close_button.click();
                props.updateList();
            }

        } catch (error) {

        }
    }

    return (
        <div id="active-sip"
            // data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="active-sip"
            aria-hidden="true"
            className={`modal fade ${style.e_unlock}`}>
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content p-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <p className="color-eerie-black e-poppins-medium e-font-18 mb-0">
                            {props.type === 1 ? 'Enable SIP' : 'Resume SIP'}
                        </p>
                        <Icon icon="close"
                            size={24}
                            data-bs-dismiss="modal"
                            className={` cursor-pointer e-modal-close-btn`} />
                    </div>

                    <p className="color-outer-space e-poppins-regular e-font-14 mb-4 margin-20px-top">
                        Enter OTP you have received in registered email and phone number to resume SIP
                    </p>

                    <button className="d-none" data-bs-dismiss="modal" id="close-modal"></button>
                    <div className="d-flex flex-column align-items-center text-start">
                        <OTPInput pinString={pinString}
                            id="sip-otp"
                            autoFocus={true}
                            pinError={errorMessage}
                            disabled={buttonLoader}
                            handleChange={_handlePin} />


                        <GradientButton label="Resume SIP"
                            loading={buttonLoader}
                            className="w-100 padding-14px-tb margin-32px-top"
                            handleClick={_verifyPin} />
                    </div>
                </div>
            </div>
        </div>
    )
}

ActivateSIPModal.defaultProps = {
    updateList: () => { },
    handleOTPSubmission: () => { }
}

export default ActivateSIPModal;