/*
 *   File : custom-textarea.js
 *   Author : https://evoqins.com
 *   Description : Textarea component
 *   Integrations : null
 *   Version : 1.0.0
*/

import style from '../../Styles/Component/custom-textbox.module.scss';
import Icon from '../Icon/icon';

const CustomTextarea = (props) => {

    return (
        <div className={props.error && props.error.length !== 0 ? `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` : `${props.className} ${style.e_input_wrapper} position-relative w-100`}>
            {/* Text area */}
            <textarea value={props.value}
                id={props.id}
                type='text'
                className={`${style.e_textarea} color-charleston-green e-poppins-regular e-font-14 w-100 no-outline`}
                onChange={(e) => props.handleChange(e.target.value)}
                required
                rows={6}>
            </textarea>
            {/* Label */}
            <label className={`position-absolute left-15px e-poppins-regular e-font-14 bg-white ${style.e_textarea_label}`}
                for={props.id}>
                {props.label}
                <span className='color-red'>
                    {props.postfix}
                </span>
            </label>
            {/* Error text */}
            {
                props.hide_error_text === true ?
                    null
                    : props.error &&
                    <div className='position-absolute d-flex align-items-center mt-1 '>
                        <Icon icon="info-circle"
                            size={16}
                            className="me-1" />
                        <p className='color-red margin-block-end-0 e-font-12 '>
                            {props.error}
                        </p>
                    </div>}
        </div>
    )
}

CustomTextarea.defaultProps = {
    value: null,
    handleChange: () => { },
    className: '',
    error: ''
};

export default CustomTextarea;