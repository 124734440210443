import { all, fork } from 'redux-saga/effects'
import { addToCart, updateProfile, updateSearchSuggestions, updateSearchSuggestionStatus, getInvestmentStyles, getNotificationCount, getQueryTypes, getKycTypes, getKYCProgress, getAppGeneralData, getToolTip } from './saga'


export default function* rootSaga() {
    yield all([
        fork(
            updateProfile,
            updateSearchSuggestions,
            updateSearchSuggestionStatus,
            addToCart,
            getInvestmentStyles,
            getNotificationCount,
            getQueryTypes,
            getKycTypes,
            getKycTypes,
            getToolTip,
            getKYCProgress,
            getAppGeneralData
        )
    ]);
}