
/*
 *   File : loader.js
 *   Author URI : https://evoqins.com
 *   Description : UI element for api loading
 *   Integrations : null
 *   Version : v1.1
 */

import LoaderGif from '../../Assets/Images/loader.gif';

export default function CustomLoader (props) {
    return(
        <div className={`${window.innerWidth > 575 ? 'h-100' : 'h-50vh'}  w-100  d-flex justify-content-center align-items-center ${props.className}`}>
            <img src={LoaderGif} 
                alt="data loader"
                width={80}/>
        </div>
    );
}

CustomLoader.defaultProps={
    className: 'bg-white'
};