/*
 *   File : redeem-text-input.js
 *   Author : https://evoqins.com
 *   Description : Input component for redeem modal
 *   Integrations : null
 *   Version : 1.0.0
*/
import React, { useState, useCallback } from 'react';

import style from '../../Styles/Component/custom-textbox.module.scss';
import Icon from '../Icon/icon';

const CustomRedeemTextInput = React.memo((props) => {
    const [isInputFocused, setInputFocus] = useState(false);
    // const [value, setValue] = useState("");

    const _handleChange = (event) => {
        const input_value = event.target.value;
        const regex = /^\d*(\.\d{0,4})?$/;
        if (!regex.test(input_value)) {
            return; 
        }
        props.handleChange(input_value);
    };


    // Preventing non numeric characters if type is number 
    // Validating because Firefox and Safari don't support type number 
    const _validateNumber = (e) => {
        const keyCode = e.keyCode || e.which;

        if (e.ctrlKey && keyCode === 86) {
            // Allow Ctrl+V (paste)
            return;
        }

        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
         }

        if (e.ctrlKey) {
            return null;
        }
        // Check for Ctrl+Shift
        if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
            return null; // Allow Ctrl+Shift
        }

        // Check for Ctrl+C (copy) and Ctrl+V (paste)
        if ((e.ctrlKey || e.metaKey) && (keyCode === 67 || keyCode === 86)) {
            return null; // Allow Ctrl+C and Ctrl+V
        }

        // tab key 
        if (keyCode === 9) {
            return null;
        }
        if (
            (keyCode >= 48 && keyCode <= 57) || // 0-9
            (keyCode >= 96 && keyCode <= 105) || // Numpad 0-9
            keyCode === 8 || // Backspace
            keyCode === 190 || // Decimal point
            (keyCode >= 37 && keyCode <= 40) // Arrow keys
        ) {
            // Allow the keypress
        } else {
            e.preventDefault();
        }
    }

    const _handleFocus = () => {
        setInputFocus(true);
    };

    const _handleBlur = () => {
        setInputFocus(false);
    };

    return (
        <div className={
            props.error && props.error.length !== 0 ?
                `${props.className} ${style.e_input_wrapper} ${style.e_error_input} position-relative w-100` :
                `${props.className} ${style.e_input_wrapper} position-relative w-100`}
            data-disabled= {props.disabled}>
            {/* Input box */}
            <input
                value={props.value}
                id={props.label}
                type={props.type ? props.type : 'text'}
                className={`${style.e_input} ${props.inputClass} color-charleston-green e-poppins-regular e-font-16 w-100 no-outline`}
                onKeyDown={(e) => {
                    if (props.type == 'number') {
                        _validateNumber(e);
                    }
                }}
                onWheel={(e) => e.target.blur()}
                onChange={_handleChange}
                onFocus={_handleFocus}
                onBlur={_handleBlur}
                required />
            {/* <p className={`color-steel-gray e-poppins-regular e-font-10 line-height-12px mb-0 position-absolute ${style.e_postLabel}`}>Approx. redeem value: <span className='e-inter-regular color-very-dark-slate-blue e-font-12'>
                ₹</span>
                <span className='color-very-dark-slate-blue e-font-12 '>{props.approxValue}</span></p> */}

            {/* label */}
            <label className={`${props.labelClass} position-absolute left-15px e-poppins-regular e-font-14 cursor-text p-0`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-red'>
                    {props.postfix}
                </span>
            </label>
            {/* Error text */}
            {props.error &&
                <div className='position-absolute d-flex align-items-center mt-1 '>
                    <Icon icon="info-circle"
                        size={16}
                        className="me-1" />
                    <p className='color-red margin-block-end-0 e-font-12 '>
                        {props.error}
                    </p>
                </div>}
        </div>
    )
})

CustomRedeemTextInput.defaultProps = {
    value: null,
    handleChange: () => { },
    className: '',
    error: null,
    labelClass: 'bg-white',
    disabled: false
};

export default CustomRedeemTextInput;