/*
 *   File :  portfolio-options.js
 *   Author : https://evoqins.com
 *   Description : Popup component which allows the user to make any transactions from the invested funds or goals.
 *   Integrations : null
 *   Version : 1.0.0
*/
import { useEffect, useState } from "react";

const OPTIONS = [{
    id: 1,
    image: require("../../Assets/Images/portfolio/invest-more.png"),
    label: "Invest more"
},
{
    id: 2,
    image: require("../../Assets/Images/portfolio/sip.png"),
    label: "SIP"
},
{
    id: 3,
    image: require("../../Assets/Images/portfolio/switch.png"),
    label: "Switch"
},
{
    id: 4,
    image: require("../../Assets/Images/portfolio/swp.png"),
    label: "SWP"
},
{
    id: 5,
    image: require("../../Assets/Images/portfolio/stp.png"),
    label: "STP"
},
{
    id: 6,
    image: require("../../Assets/Images/portfolio/redeem.png"),
    label: "Redeem"
}];

const PortfolioOptions = (props) => {
    const { lumpsum_enabled, sip_enabled, swp_enabled, switch_enabled, stp_enabled } = props.data;
    const [menuOptions, setMenuOptions] = useState([]);

    useEffect(() => {
        let updated_options = [...OPTIONS];
        if (lumpsum_enabled === false || props.fromDetail === true) {
            let index = updated_options.findIndex(item => item.id === 1);
            updated_options.splice(index, 1);
        }
        if (sip_enabled === false || props.fromDetail === true) {
            let sip_index = updated_options.findIndex(item => item.id === 2);
            updated_options.splice(sip_index, 1);
        }
        if(swp_enabled === false) {
            let swp_index = updated_options.findIndex(item => item.id === 4);
            updated_options.splice(swp_index, 1);
        }
        if(switch_enabled === false) {
            let switch_index = updated_options.findIndex(item => item.id === 3);
            updated_options.splice(switch_index, 1);
        }
        if(stp_enabled === false) {
            let stp_index = updated_options.findIndex(item => item.id === 5);
            updated_options.splice(stp_index, 1);
        }
        if (props.fromDetail === true) {
            let redeem_index = updated_options.findIndex(item => item.id === 6);
            updated_options.splice(redeem_index, 1);
        }
        setMenuOptions([...updated_options]);
    }, [props.data]);

    // button click handler 
    const _handleClick = (id) => {
        if (id == 1) {
            props.invest(2); // Lumpsum investment
        } else if (id == 2) {
            props.invest(1); // SIP investment 
        } else if (id === 6) {
            props.redeem();
        } else if (id === 4) {
            props.swp();
        }else if (id === 3) {
            props.switch();
        }else{
            props.stp();
        }
    }

    return (
        <div className="border-radius-12px border-width-2px border-solid border-bright-gray padding-10px-lr padding-6px-tb d-flex flex-column gap-12px position-absolute white-space-nowrap bg-white z-index-1 e-portfolio-options cursor-pointer"
            id={props.id}>
            {menuOptions.map((item, key) => {
                return (
                    <div className="d-flex align-items-center gap-8px" key={key}
                        onClick={() => _handleClick(item.id)}>
                        <img src={item.image} alt={item.label}
                            width={24}
                            height={24} />
                        <p className="color-black e-poppins-regular e-font-14 mb-0">{item.label}</p>
                    </div>
                )
            }
            )}
        </div>

    )
}

export default PortfolioOptions