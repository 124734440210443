/*
 *   File : watchlist.js
 *   Author URI : https://evoqins.com
 *   Description : The watchlist table consists of MFs which the user saved from the MF listing and MF detail pages. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { DataTable } from "../Form-elements";
import Icon from "../Icon/icon";
import { GradientButton } from "../CTA";
import { _addToWatchList } from "../../Helper/api";
import { DeleteConfirmation } from "../Modal";

import Colors from "../../Styles/color.module.scss";


const WatchlistTable = (props) => {

    const navigator = useNavigate();
    const [showArrow, setShowArrow] = useState(0);
    const [showUnitsHeld, setShowUnitsHeld] = useState(null);
    const [showSocial, setShowSocial] = useState(null);
    const [showDeleteHover, setShowDeleteHover] = useState(null);
    const [showShareToolTip, setShowShareToolTip] = useState(-1);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {

        function _handleScroll() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('scroll', _handleScroll);

        return () => {
            window.removeEventListener('scroll', _handleScroll);
        }
    }, []);

    const _sortReturns = (rowA, rowB, key) => {
        const return_percentage_a = parseFloat(rowA[key]);
        const return_percentage_b = parseFloat(rowB[key]);

        if (return_percentage_a > return_percentage_b) {
            return 1;
        }

        if (return_percentage_b > return_percentage_a) {
            return -1;
        }

        return 0;
    };


    const WATCHLIST_COLUMN = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            // width: "300px",
            cell: row =>
                <div className="d-flex  gap-12px e-fund-name"
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset h-100">
                        <img src={row['image']} alt={row['name']}
                            width={36}
                            height={36}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-12px">
                        <p className="color-black e-poppins-medium e-font-sm-16-14 mb-0 bg-white line-height-24px ">{row['name']}</p>
                        <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr  color-black e-poppins-medium e-font-10 line-height-18px w-fit-content">
                            {row['type']}
                        </span>
                    </div>

                </div>
        },
        {
            id: 2,
            name: "NAV",
            width: "150px",
            selector: row => row['nav'].current_nav,
            sortable: false,
            left: true,
            cell: row =>
                <p className="color-black e-poppins-semi-bold e-font-12 line-height-20px mb-0"
                    onClick={() => _handleCardClick(row)}>
                    <span className="e-inter-semi-bold">₹</span>
                    {row['nav'].current_nav.toLocaleString("en-IN")} <span className={`${row['nav'].nav_change < 0 ? 'color-red' : 'color-sea-green'}`}>({row['nav'].nav_change}%)</span>
                </p>
        },
        {
            id: 3,
            name: '1Y',
            selector: row => row['one_year_return'],
            sortable: true,
            left: true,
            width: "50px",
            sortFunction: (a, b) => _sortReturns(a, b, 'one_year_return'),
            cell: row =>
                <p className={`${parseFloat(row['one_year_return']) < 0 ? 'color-red' : parseFloat(row['one_year_return']) > 0 ? 'color-sea-green' : 'color-black'}  e-poppins-semi-bold e-font-12 line-height-20px mb-0`}
                    onClick={() => _handleCardClick(row)}>
                    {row['one_year_return']}
                </p>
        },
        {
            id: 4,
            name: '3Y',
            selector: row => row['three_year_return'],
            sortable: true,
            left: true,
            width: "50px",
            sortFunction: (a, b) => _sortReturns(a, b, 'three_year_return'),
            cell: row => <p className={`${parseFloat(row['three_year_return']) < 0 ? 'color-red' : parseFloat(row['three_year_return']) > 0 ? 'color-sea-green' : 'color-black'} e-poppins-semi-bold e-font-12 line-height-20px mb-0`}
                onClick={() => _handleCardClick(row)}>
                {row['three_year_return']}
            </p>


        },
        {
            id: 5,
            name: '5Y',
            selector: row => row['five_year_return'],
            sortable: true,
            left: true,
            width: "50px",
            sortFunction: (a, b) => _sortReturns(a, b, 'five_year_return'),
            cell: row => <p className={`${parseFloat(row['five_year_return']) < 0 ? 'color-red' : parseFloat(row['five_year_return']) > 0 ? 'color-sea-green' : 'color-black'} e-poppins-semi-bold e-font-12 line-height-20px mb-0`}
                onClick={() => _handleCardClick(row)}>
                {row['five_year_return']}
            </p>


        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "300px",
            cell: row =>
                <div className="d-flex align-items-center gap-22px">
                    <GradientButton label="Invest now"
                        className="py-2 px-3 e-font-16"
                        handleClick={() => props.openInvestmentModal(row)} />
                    <div className="d-flex align-items-center gap-10px">
                        <div className="position-relative">
                            <img src={require("../../Assets/Images/watchlist/invested-item.png")}
                                alt="Invested-item"
                                width={32}
                                height={32}
                                onMouseEnter={() => _showUnitsHeld(row.id)}
                                onMouseLeave={_hideUnitsHeld} />
                            {
                                showUnitsHeld === row.id &&
                                <p className="position-absolute bg-white e-units-held border-radius-8px border-all border-bright-gray color-jett-black e-poppins-regular e-font-14 line-height-12px mb-0 p-2">
                                    {
                                        row['has_holding'] === false ?
                                            "You don't have any holdings."
                                            :
                                            `You are holding ${row['units']} units`
                                    }

                                </p>
                            }
                        </div>

                        <div className="position-relative">
                            <div className='e-share-icon'>
                                <Icon icon={showSocial === row.id ? "close" : "share"}
                                    size={24}
                                    onMouseEnter={() => _showShareToolTip(row.id)}
                                    onMouseLeave={() => _showShareToolTip(row.id)}
                                    onClick={() => _showSocialLinks(row.id)} />
                                {
                                    showShareToolTip === row.id &&
                                    <p className="position-absolute bg-white e-units-held border-radius-8px border-all border-bright-gray color-jett-black e-poppins-regular e-font-14 line-height-12px mb-0 p-2">
                                        Share scheme
                                    </p>
                                }
                            </div>
                            {
                                showSocial === row.id &&
                                <div className="d-flex gap-8px position-absolute e-watchlist-social-media">
                                    <img src={require("../../Assets/Images/explore/facebook.png")}
                                        alt="Facebook"
                                        width={32}
                                        height={32}
                                        onClick={() => _shareFund(1)} />
                                    <img src={require("../../Assets/Images/explore/whatsapp.png")}
                                        alt="whatsapp"
                                        width={32}
                                        height={32}
                                        onClick={() => _shareFund(2)} />
                                    <img src={require("../../Assets/Images/explore/messenger.png")}
                                        alt="messenger"
                                        width={32}
                                        height={32}
                                        onClick={() => _shareFund(3)} />
                                </div>
                            }
                        </div>
                        <div className="position-relative">
                            <Icon icon={showDeleteHover === row.id ? "delete-red" : "delete"}
                                size={24}
                                onMouseEnter={() => _showDeleteHover(row.id)}
                                onMouseLeave={_hideDeleteHover}
                                onClick={() => _handleRemoveFromWatchlist(row)} />
                            {
                                showDeleteHover === row.id &&
                                <p className="position-absolute bg-white e-units-held border-radius-8px border-all border-bright-gray color-jett-black e-poppins-regular e-font-14 line-height-12px mb-0 p-2">
                                    Delete
                                </p>
                            }
                        </div>
                    </div>
                </div>
        },
        {
            name: '',
            sortable: false,
            left: true,
            width: "24px",
            cell: row =>
                <div className={`e-watchlist-hover  ${showArrow === row.id ? 'show' : ''}`}>
                    <Icon icon="arrow-right1"
                        size={24}
                        color={Colors.black} />
                </div>

        }

    ];

    const WATCHLIST_COLUMN_MOBILE = [
        {
            id: 1,
            name: 'Funds',
            selector: row => row['name'],
            sortable: true,
            left: true,
            width: '80%',
            cell: row =>
                <div className="d-flex align-items-center gap-12px "
                    onClick={() => _handleCardClick(row)}>
                    <div className="p-2 border-radius-8px border-all border-bright-gray w-unset h-100">
                        <img src={row['image']} alt={row['name']}
                            width={24}
                            height={24}
                            className="object-fit-contain" />
                    </div>
                    <div className="d-flex flex-column gap-6px">
                        <p className="color-black e-poppins-medium mb-0 bg-white e-font-12 line-height-22px ">{row['name']}</p>
                        <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr  color-black e-poppins-medium e-font-8 line-height-10px w-fit-content">
                            {row['type']}
                        </span>
                        {/* <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block text-decoration-underline"
                        onClick={(e) => { e.stopPropagation(); props.openInvestmentModal(row)}}>Invest now</p> */}
                    </div>

                </div>
        },
        {
            id: 4,
            name: '3Y',
            selector: row => row['three_year_return'],
            sortable: true,
            left: false,
            width: "20%",
            cell: row =>
                <div className="d-block text-center">
                    <p className={`${parseFloat(row['three_year_return']) < 0 ? 'color-red' : parseFloat(row['three_year_return']) > 0 ? 'color-sea-green' : 'color-black'} e-poppins-semi-bold e-font-12 line-height-20px mb-0`}
                        onClick={() => _handleCardClick(row)}>
                        {row['three_year_return']}
                    </p>
                    {/* <p className="e-primary-font-medium e-font-12 cursor-pointer color-ocean-blue mb-0 pb-0 line-height-12px d-block text-decoration-underline"
                        onClick={(e) => { e.stopPropagation(); props.openInvestmentModal(row)}}>Invest now</p> */}
                        <GradientButton label="Invest"
                                className="py-2 px-3  e-font-10"
                                handleClick={(e) =>{e.stopPropagation(); props.openInvestmentModal(row)}} />
                </div>

        }
    ]

    function _showShareToolTip(id) {
        let c_id = showShareToolTip;
        if (c_id === -1) {
            setShowShareToolTip(id)
        } else {
            setShowShareToolTip(-1);
        }
    }

    const _handleRemoveFromWatchlist = (row) => {
        props.removeItem(row);
    }

    const _showDeleteHover = (id) => {
        setShowDeleteHover(id)
    }

    const _hideDeleteHover = () => {
        setShowDeleteHover(null);
    }

    const _showSocialLinks = (id) => {
        if (id === showSocial) {
            setShowSocial(null);
        }
        else {
            setShowSocial(id);
        }
    }
    const _handleCardClick = (row) => {
        navigator("/explore/fund", {
            state: {
                fund_id: row.mf_id
            }
        });
    }

    const _handleRowHover = (row) => {
        setShowArrow(row.id)
    }

    const _handleRowRemoveHover = () => {
        setShowArrow(0);
    }

    const _showUnitsHeld = (id) => {
        setShowUnitsHeld(id)
    }

    const _hideUnitsHeld = () => {
        setShowUnitsHeld(null);
    }

    function _shareFund(platform) {
        switch (platform) {
            case 1: window.open(process.env.REACT_APP_FB_SHARE_LINK + window.location, '_blank');
                break;
            case 2: window.open(process.env.REACT_APP_WHATSAPP_LINK + window.location, '_blank');
                break;
            case 3: window.open(process.env.REACT_APP_MESSENGER_LINK + window.location, '_blank');
                break;
            default: return;
        }
    }

    const customStyles = {
        headRow: {
            style: {
                paddingRight: "0px",
                fontSize: "16px",
                paddingBottom: "8px !important",
            }
        },
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'center',
                },
            },
        },
        rows: {
            style: {
                padding: "0px",
                '&:hover': {
                    boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "center",
                '&:first-child': {
                    padding: "16px 0 16px 16px !important"
                },
                '&:last-child': {
                    padding: "16px 16px 16px 0 !important"
                },
            }
        }
    };

    const customStylesMobile = {
        headRow: {
            style: {
                paddingRight: "0px",
                fontSize: "14px",
                paddingBottom: "8px !important",
            }
        },
        headCells: {
            style: {
                '&:not(:first-child)': {
                    justifyContent: 'end',
                },
            },
        },
        cells: {
            style: {
                justifyContent: "end",
                '&:first-child': {
                    padding: "0px 0 0px 0px !important"
                },
                '&:last-child': {
                    padding: "0px 0px 0px 0 !important"
                },
            }
        }
    };

    return (
        <div className="e-mobile-table">

            <DataTable columns={screenWidth > 576 ? WATCHLIST_COLUMN : WATCHLIST_COLUMN_MOBILE}
                data={props.data}
                pagination={false}
                // defaultSortFieldId={3}
                customStyles={screenWidth > 576 ? customStyles : customStylesMobile}
                onRowMouseEnter={_handleRowHover}
                onRowMouseLeave={_handleRowRemoveHover}
                rowClick={_handleCardClick} />
        </div>
    )
}

export default WatchlistTable;