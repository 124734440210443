/*
 *   File : ipv.js
 *   Author URI : https://evoqins.com
 *   Description :  In-person verification and video need to be implemented for customers who are not compliant with KYC
 *   Integrations : null
 *   Version : v1.1
 */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { Tooltip } from 'react-tooltip';
import Cookies from 'js-cookie';

import { GradientButton } from "../../../Component/CTA";
import Icon from '../../../Component/Icon/icon';
import { CustomToolTip } from '../../../Component/Popups';

import APIService from "../../../Service/api-service";
import { _getKycProfile } from '../../../Helper/api';

const IPVPage = (props) => {

    const IPV_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA.selfie_captured);
    const TOOL_TIP = useSelector(state => state.generalData.TOOL_TIP_DATA);

    const [apiLoader, setApiLoader] = useState(false);
    const [showToolTip, setShowTooltip] = useState(false);
    const [selfieImage, setSelfieImage] = useState("");
    const [digioAccessToken, setDigioAccessToken] = useState("");
    

    useEffect(() => {
        if (IPV_DATA !== null) {
            setSelfieImage(IPV_DATA);
        }
    }, [])

    const _handleProceed = () => {
        // props.updateProgress(9);
        setApiLoader(true);
        let url = 'kyc/ipv/initiate';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _initiateDigiLocker(response.data.id, response.data.customer_identifier, response.data.access_token.id);
                Cookies.set('digio_token', response.data.access_token.id);
            } else {
                setApiLoader(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        })
    };

    async function _initiateDigiLocker(digiLockerId, mail, token_id) {
        var Digio = await window.Digio;
        var options = {
            // "environment" : "production", 
            "environment": process.env.REACT_APP_DIGILOCKER_ENVIRONMENT,
            "callback": _digiLockerCallback,
            "logo": process.env.REACT_APP_LOGO
        };

        var digio = new Digio(options);

        try {
            digio.init();  // Call init on user action (eg.button press) and then proceed to asynchronous upload
            digio.submit(digiLockerId, mail, token_id);
        } catch (err) {
            // console.log(err)
            this.notify("Allow pop-ups and reload the page to fetch from digilocker. ")
        }

    }

    function _digiLockerCallback(e) {
        let url = 'kyc/ipv/callback';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _nextStep();
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                setApiLoader(false);
            }
        })
    }

    function _generatePdf () {
        let url = 'kyc/generate-pdf';
        return APIService(false, url).then((response) => {
            return response.status;
        }); 
    }

    const _nextStep = async () => {
        setApiLoader(true);
        const pdf = await _generatePdf();
        const kyc_data = await _getKycProfile();
        setApiLoader(false);
        props.updateProgress(9, digioAccessToken);
    }

    return (
        selfieImage.length !== 0
            ?
            <div className='pb-sm-0 pb-4'>
                <p className='color-black e-poppins-bold e-font-24-sm-18 mb-2'>Capture your selfie</p>
                <p className='color-outer-space e-poppins-regular line-height-24px margin-32px-bottom e-font-sm-16-14  '>Your selfie has been successfully captured.</p>
                <img src={selfieImage} alt="Selfie"
                    width={226}
                    draggable={false}
                    height={208}
                    className='object-fit-contain mb-4' />
                <div className='d-flex align-items-center gap-8px margin-40px-bottom cursor-pointer w-max-content'
                    onClick={_handleProceed}>
                    <Icon icon="re-capture"
                        size={24} />
                    <p className='color-primary-color e-poppins-regular e-font-sm-16-14 line-height-24px mb-0'>Re-capture</p>
                </div>
                <GradientButton label="Save & proceed"
                    loading={apiLoader}
                    className="padding-12px-tb px-4"
                    handleClick={_nextStep} />

            </div>
            :
            <div className="row pb-sm-0 pb-4">
                <div className="col-sm-7 pe-5 order-sm-1 order-2 mt-sm-0 mt-3 mb-sm-0 mb-3">
                    <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-2 position-relative w-max-content">
                        Capture your selfie
                        {
                            Object.keys(TOOL_TIP).length !== 0 && (
                                <>
                                    <Icon icon="info"
                                        size={24}
                                        className="ms-2"
                                        data-tooltip-id="ipv-tooltip" />
                                    <Tooltip id="ipv-tooltip"
                                        arrowColor='transparent'
                                        effect="float"
                                        place="bottom"
                                        className='e-tooltip-general'>
                                        {parse(TOOL_TIP.kyc_ipv_step)}
                                    </Tooltip>
                                </>
                            )
                        }

                    </p>
                    <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0 pe-5">
                        For clear selfie, follow the below instructions.
                    </p>

                    {/* <p className="color-black e-poppins-bold e-font-16 line-height-24px mb-0 px-4 py-3 margin-40px-bottom w-fit-content e-dashed-otp mt-3">
                    2406
                </p> */}

                    <div className="border-radius-16px bg-beige p-3 margin-40px-top pe-4">
                        <p className="color-black e-poppins-medium e-font-14-sm-12 line-height-14px mb-2">*Note</p>
                        <ol className="ps-3">
                            <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                Make sure the place where you are taking a selfie is well lit and not dark.
                            </li>
                            <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                Do not wear eyeglasses.
                            </li>
                            <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                Ensure your face is within the frame.
                            </li>
                            <li className="color-outer-space e-poppins-regular e-font-14-sm-12 line-height-20px mb-0">
                                Position your face in the frame and move your head in a circle.
                            </li>
                        </ol>

                    </div>
                    <GradientButton label="Proceed"
                        className="margin-40px-top padding-12px-tb px-4 e-font-16"
                        loading={apiLoader}
                        handleClick={_handleProceed} />
                </div>
                <div className="col-sm-5 order-sm-2 order-1">
                    {/* <p className="color-black e-poppins-bold e-font-16 mb-3">Example</p> */}
                    <img src={require("../../../Assets/Images/kyc/ipv.png")}
                        alt="IPV"
                        draggable={false}
                        height={312}
                        className="object-fit-contain" />
                </div>

            </div>

    )
}

export default IPVPage;