/*
 *   File : settings.js
 *   Author URI : https://evoqins.com
 *   Description : Container file to change the configurations such as Application PIN, Notification Settings and  Change Password.
 *   Integrations : NA
 *   Version : v1.1
 */

import { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import OneSignal from 'react-onesignal';

import { BreadCrumb } from "../../Component/BreadCrumb"
import { InvestmentHeader } from "../../Component/Header"
import Icon from "../../Component/Icon/icon";
import { CustomTextInput, Toggle } from "../../Component/Form-elements";
import { GradientButton } from "../../Component/CTA";
import { CustomTab } from "../../Component/Tab";
import Footer from "../../Component/Footer/footer";

import APIService from "../../Service/api-service";

import Colors from "../../Styles/color.module.scss";

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Settings', url: null },
];

const SETTINGS_TAB = ["PIN settings", "Notification settings", "Change password"]

const Settings = () => {

    const [selectTab, setSelectTab] = useState(0);
    const [currentPin, setCurrentPin] = useState("");
    const [newPin, setNewPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        // _pushNotification();
        OneSignal.Notifications.addEventListener("permissionChange", permissionChangeListener);
    }, []);

    useEffect(() => {
        const handleSubscriptionChange = async () => {
            try {
                const permission = await OneSignal.User.PushSubscription.optedIn;
                console.log('permission', permission);
                setIsNotificationEnabled(permission);
            } catch (error) {
                console.error('Error fetching notification permission:', error);
            }
        };
        if (selectTab === 1) {
            handleSubscriptionChange();
        }
       
    }, [selectTab]);

    useEffect(()=>{
        console.log('notification status', isNotificationEnabled);
    },[isNotificationEnabled])
    // const _pushNotification = async () => {
    //     try {
    //         console.log(process.env.REACT_APP_ONESIGNAL_ID);

    //         // Initialize OneSignal with your app ID
    //         await OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_ID });

    //         // Check if push notifications are enabled
    //         let isPushEnabled = await OneSignal.isPushNotificationsEnabled();
    //         console.log('Is push notifications enabled:', isPushEnabled);

    //         // Show the slidedown prompt
    //         OneSignal.showSlidedownPrompt();
    //     } catch (error) {
    //         console.error('Error in push notification:', error);
    //     }
    // }

    function permissionChangeListener(permission) {
        setIsNotificationEnabled(permission);
        toast.dismiss();
        if (permission == false) {
            toast.error('Please enable push notifications for transactional updates and notices from the admin', {
                type: "error"
            });
        } else {
            toast.error('Thank you for subscribing to the mt wealth push notifications. We will keep you updated', {
                type: "success"
            });
        }

    }

    const _updatePushNotificationStatus = async (e) => {
        console.log('e',e);
        if (e == true) {
            await OneSignal.User.PushSubscription.optIn();
            setIsNotificationEnabled(true);

        } else {
            await OneSignal.User.PushSubscription.optOut();
            setIsNotificationEnabled(false);
        }
        permissionChangeListener(e)
    }

    // API - Update pin number
    async function _updatePin() {
        let valid_form = await _validateForm();
        if (valid_form === false) {
            return;
        }
        setApiLoader(true);
        let url = 'auth/change-pin';
        let payload = JSON.stringify({
            current_pin: currentPin,
            new_pin: confirmPin
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setCurrentPin('');
                setNewPin('');
                setConfirmPin('');
                toast.success("PIN changed successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    };

    // API - Update password
    async function _updatePassword() {
        let valid_form = await _validateForm(2);
        if (valid_form === false) {
            return;
        }
        setApiLoader(true);
        let url = 'auth/change-password';
        let payload = JSON.stringify({
            current_password: currentPassword,
            new_password: confirmPassword
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                toast.success("Password changed successfully", {
                    type: "success"
                });
            } else {
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        })
    }

    const _handleTabSelect = (index) => {
        setSelectTab(index);
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setCurrentPin('');
        setNewPin('');
        setConfirmPin('');
        setValidationErrors([]);
    }

    const _handleInputFields = (type, value, field) => {

        if (validationErrors.length !== 0) {
            _clearValidationError(field);
        }

        switch (type) {
            case 1:
                setCurrentPin(value);
                break;
            case 2:
                setNewPin(value);
                break;
            case 3:
                setConfirmPin(value)
                break;
            case 4:
                setCurrentPassword(value);
                break;
            case 5:
                setNewPassword(value);
                break;
            case 6:
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    // Form validation function, type = 1 for PIN form, 2 for password form
    function _validateForm(type = 1) {
        let valid = true;
        let error_messages = [];

        // Function validating form
        function validateField(field, value, message) {
            // checking mandatory
            if (value === '') {
                valid = false;
                const words = field.split(/(?=[A-Z])/);
                const capitalized_words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
                const result_string = capitalized_words.join(' ');
                error_messages.push({ [field]: `${result_string} is required` });
            }
            // checking for pin length
            else if (value.length < 4) {
                valid = false;
                error_messages.push({ [field]: 'Minimum 4 digits' });
            }
            // checking for number only
            else if (!/^\d+$/.test(value)) {
                if (type === 2) {
                    return;
                }
                valid = false;
                error_messages.push({ [field]: message });
            }
        }

        // for pin number form validation
        if (type === 1) {
            validateField('currentPin', currentPin, 'Only numbers are allowed');
            validateField('newPin', newPin, 'Only numbers are allowed');
            validateField('confirmPin', confirmPin, 'Only numbers are allowed');

            if (valid && newPin !== confirmPin) {
                valid = false;
                error_messages.push({ 'confirmPin': 'Please ensure that the new PIN and the confirmed PIN are identical' });
            }
        }

        // for password form validation
        else {
            validateField('currentPassword', currentPassword);
            validateField('newPassword', newPassword);
            validateField('confirmPassword', confirmPassword);

            if (valid && newPassword !== confirmPassword) {
                valid = false;
                error_messages.push({ 'confirmPassword': 'New & Confirm passwords must match' });
            }
        }

        setValidationErrors(error_messages);
        return valid;
    };

    // Onchange remove error from field
    function _clearValidationError(fieldName) {
        let validation_error = validationErrors.filter(item => !item.hasOwnProperty(fieldName));
        setValidationErrors(validation_error);
    };

    // get form field error message
    function _getFormError(key) {
        let error_obj = validationErrors.find(item => item.hasOwnProperty(key));
        if (error_obj) {
            return error_obj[key]
        }
        return null;
    };



    return (
        <Fragment>
            <div className="e-notification e-page-container margin-72px-bottom">
                {/* Header */}
                <InvestmentHeader
                />

                <BreadCrumb data={PAGE_NAVIGATION} />
                <p className="color-black e-poppins-medium e-font-20-sm-18 line-height-32px my-sm-4 my-3">Settings</p>
                <div className="row mb-sm-0 mb-5 pb-sm-0 pb-5">
                    <div className="col-lg-3 col-md-3 col-12 d-lg-block d-md-block d-none d-flex gap-8px flex-column">
                        {
                            SETTINGS_TAB.map((item, key) =>
                                <div key={key}
                                    className={`d-flex justify-content-between w-100 py-3 pe-3 padding-12px-left cursor-pointer ${selectTab === key && "bg-bubbles"}`}
                                    onClick={() => _handleTabSelect(key)}>
                                    <p className={`e-poppins-medium e-font-16 line-height-22px mb-0 ${selectTab === key ? "color-primary-color" : "color-outer-space"}`}>{item}
                                    </p>
                                    <Icon icon="arrow-right1"
                                        size={24}
                                        color={selectTab !== key && Colors.black} />
                                </div>)
                        }
                    </div>
                    <div className="d-lg-none d-md-none d-block col-12">
                        <div className="e-tap-wrapper">
                            <CustomTab data={SETTINGS_TAB}
                                wrapperClass={'gap-16px'}
                                className="px-0 padding-10px-tb e-order-tab mb-4"
                                onSelectTab={(value) => _handleTabSelect(value - 1)} />
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-7 col-12">
                        <div className="border-radius-24px border-all border-bright-gray p-sm-4 p-3">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    {
                                        selectTab === 0 ?
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                <p className="color-black e-poppins-semi-bold e-font-16 margin-20px-bottom-sm-10">PIN change</p>
                                                <CustomTextInput label="Current PIN"
                                                    value={currentPin}
                                                    type="otp"
                                                    name="Current pin"
                                                    isPin={true}
                                                    maxLength={4}
                                                    postfix="*"
                                                    autoComplete="off"
                                                    error={_getFormError('currentPin')}
                                                    handleChange={(value) => _handleInputFields(1, value, 'currentPin')} />
                                                <CustomTextInput label="New PIN"
                                                    value={newPin}
                                                    type="otp"
                                                    name="New pin"
                                                    isPin={true}
                                                    maxLength={4}
                                                    postfix="*"
                                                    className="margin-38px-top-sm-24"
                                                    autoComplete="off"
                                                    error={_getFormError('newPin')}
                                                    handleChange={(value) => _handleInputFields(2, value, 'newPin')} />
                                                <CustomTextInput label="Confirm new PIN"
                                                    value={confirmPin}
                                                    type="otp"
                                                    name="Confirm pin"
                                                    isPin={true}
                                                    maxLength={4}
                                                    postfix="*"
                                                    className="margin-38px-top-sm-24 mb-2"
                                                    autoComplete="off"
                                                    error={_getFormError('confirmPin')}
                                                    handleChange={(value) => _handleInputFields(3, value, 'confirmPin')} />
                                                <GradientButton label="Update PIN"
                                                    className="px-3 padding-12px-tb e-font-16 mt-sm-4 mt-3"
                                                    loading={apiLoader}
                                                    handleClick={_updatePin} />
                                            </form>
                                            :
                                            selectTab === 1 ?
                                                <Fragment>
                                                    <p className="color-black e-poppins-semi-bold e-font-16 margin-20px-bottom">Notification preference</p>
                                                    <p className="color-black e-poppins-regular e-font-16 mb-2">Push notification</p>
                                                    <div className="d-flex align-items-center gap-8px">

                                                        <span className="color-jett-black e-poppins-regular e-font-16">
                                                            No</span>
                                                        <Toggle check={isNotificationEnabled}
                                                            type={2}
                                                            barColor={Colors.pearl_aqua}
                                                            className="e-notification-toggle"
                                                            onChange={(e) => {
                                                                setIsNotificationEnabled(e);
                                                                _updatePushNotificationStatus(e)
                                                            }} />
                                                        <span className="color-jett-black e-poppins-regular e-font-16">Yes</span>
                                                    </div>
                                                    {/* <GradientButton label="Update settings"
                                                    className="e-font-16 padding-12px-tb px-3 mt-4" /> */}
                                                </Fragment>
                                                :
                                                <form onSubmit={(e) => e.preventDefault()}>
                                                    <p className="color-black e-poppins-semi-bold e-font-16 margin-20px-bottom-sm-10">Change password</p>
                                                    <CustomTextInput label="Current password"
                                                        value={currentPassword}
                                                        type={showCurrentPassword ? "text" : "otp"}
                                                        name="Current password"
                                                        password_icon={true}
                                                        autoComplete="off"
                                                        postfix="*"
                                                        error={_getFormError('currentPassword')}
                                                        showPassword={() => {
                                                            setShowCurrentPassword(!showCurrentPassword)
                                                        }}
                                                        handleChange={(value) => _handleInputFields(4, value, 'currentPassword')} />
                                                    <CustomTextInput label="New password"
                                                        value={newPassword}
                                                        autoComplete="off"
                                                        type={showNewPassword ? "text" : "otp"}
                                                        name="New password"
                                                        password_icon={true}
                                                        postfix="*"
                                                        className="margin-38px-top-sm-24"
                                                        error={_getFormError('newPassword')}
                                                        handleChange={(value) => _handleInputFields(5, value, 'newPassword')}
                                                        showPassword={() => {
                                                            setShowNewPassword(!showNewPassword)
                                                        }} />
                                                    <CustomTextInput label="Confirm new password"
                                                        value={confirmPassword}
                                                        type={showConfirmPassword ? "text" : "otp"}
                                                        password_icon={true}
                                                        name="Confirm new password"
                                                        autoComplete="off"
                                                        postfix="*"
                                                        className="margin-38px-top-sm-24 mb-2"
                                                        error={_getFormError('confirmPassword')}
                                                        handleChange={(value) => _handleInputFields(6, value, 'confirmPassword')}
                                                        showPassword={() => {
                                                            setShowConfirmPassword(!showConfirmPassword)
                                                        }} />
                                                    <GradientButton label="Update password"
                                                        className="px-3 padding-12px-tb e-font-16 mt-sm-4 mt-3"
                                                        loading={apiLoader}
                                                        handleClick={_updatePassword} />
                                                </form>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Settings