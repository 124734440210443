
/*
 *   File : view-mf-holdings.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for list all the mf-holdings
 *   Integrations : null
 *   Version : v1.1
 */
import { useEffect } from 'react';
import Icon from '../Icon/icon';

import style from '../../Styles/Component/view-mf-holding.module.scss';


const MFHoldingsModal = (props) => {

     // Modal close listener
     useEffect(() => {
        var my_modal = document.getElementById("view-holdings");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    return (
        <div className={`modal fade ${style.e_holdings_modal}`}
            id="view-holdings"
            tabIndex="-1"
            aria-labelledby="view-holdings"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content bg-white position-relative text-center d-flex align-items-center px-ms-4 px-2 py-4 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="container ">
                        {/* Modal title */}
                        <div className="row">
                            <div className="col-12 text-start">
                                <h4 className="color-black e-poppins-medium e-font-16 cursor-pointer w-max-content">
                                    Holdings({props.data.length})
                                </h4>
                            </div>
                        </div>
                        {/* Modal body */}
                        <div className={`${style.e_table_wrapper} mt-3 border-radius-16px border-all border-bright-gray padding-14px-top `}>
                            <div className='row border-bottom gx-0 pb-2'>
                                <div className='col-sm-4 col-5 text-start ps-2'>
                                    <h5 className='color-outer-space e-font-16 e-poppins-medium'>Top holdings</h5>
                                </div>
                                <div className='col-4 text-start'>
                                    <h5 className='color-outer-space e-font-16 e-poppins-medium'>Sector</h5>
                                </div>
                                <div className='col-3 text-sm-start text-end pe-sm-0 pe-4'>
                                    <h5 className='color-outer-space e-font-16 e-poppins-medium'>Assets</h5>
                                </div>
                            </div>
                            <div className={`${style.e_table_body} `}>
                                {
                                    props.data.map((holding, key) => {
                                        return (
                                            <div className={`row gx-0 pt-3 ${key !== props.data.length - 1 && 'border-bottom'}`}>
                                                <div className='col-sm-4 col-5 text-start ps-2'>
                                                    <p className='color-outer-space e-font-14 e-poppins-regular word-break-all'>{holding.name}</p>
                                                </div>
                                                <div className='col-4 text-start'>
                                                    <p className='color-outer-space e-font-14 e-poppins-regular word-break-all'>{holding.sector ? holding.sector : '--'}</p>
                                                </div>
                                                <div className='col-3 text-sm-start text-end pe-4'>
                                                    <p className='color-outer-space e-font-14 e-poppins-regular'>{holding.percentage}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MFHoldingsModal;