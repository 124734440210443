/*
 *   File :  preview.js
 *   Author URI : https://evoqins.com
 *   Description : Shows information about what the user added during KYC.
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import Icon from "../../../Component/Icon/icon";
import Cookies from 'js-cookie';

import { GradientButton } from "../../../Component/CTA";
import { toast } from "react-toastify";
import APIService from "../../../Service/api-service";
import { FilePreview } from "../../../Component/Form-elements";
import { SignaturePreviewModal } from "../../../Component/Modal";
import { Modal } from "bootstrap";

import { _getKycProfile } from "../../../Helper/api";

const PreviewPage = (props) => {

    const PREVIEW_DATA = useSelector(state => state.generalData.KYC_PROGRESS_DATA);
    const [showEdit, setShowEdit] = useState(0);
    const [apiLoader, setApiLoader] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        if (showPreview === true) {
            const modal = new Modal(document.getElementById("signature-preview"), {});
            modal.show();
        }
    }, [showPreview])

    const _handleNavigate = () => {
        switch (showEdit) {
            case 1:
                props.updateProgress(4)
                break;
            case 2:
                props.updateProgress(5)
                break;
            case 3:
                props.updateProgress(6)
                break;
            case 4:
                props.updateProgress(7)
                break;
            case 5:
                props.updateProgress(8)
                break;
        }
    }

    const _handlePreview = (type) => {
        switch (type) {
            case 1:
                setShowPreview(true);
                setFileUrl(PREVIEW_DATA.additional_info.signature_status)
                break;
            case 2:
                setShowPreview(true);
                setFileUrl(PREVIEW_DATA.selfie_captured)
                break;
        }
    }

    // API - initiate digilocker to complete kyc
    const _handleProceed = () => {
        setApiLoader(true);
        let url = 'kyc/esign/initiate';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _initiateDigiLocker(response.data.id, response.data.signing_parties[0].identifier, response.data.access_token.id);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
            }
        })
    };

    // open digilocker UI to enter aadhar number
    async function _initiateDigiLocker(digiLockerId, mail, token_id = Cookies.get("digio_token")) {
        var Digio = await window.Digio;
        var options = {
            // "environment" : "production", 
            "environment": process.env.REACT_APP_DIGILOCKER_ENVIRONMENT,
            "callback": _digiLockerCallback,
            "logo": process.env.REACT_APP_LOGO
        };

        var digio = new Digio(options);

        try {
            digio.init();  // Call init on user action (eg.button press) and then proceed to asynchronous upload
            digio.submit(digiLockerId, mail, token_id);
        } catch (err) {
            // console.log(err)
            this.notify("Allow pop-ups and reload the page to fetch from digilocker. ")
        }

    }

    // API - to complete kyc
    function _digiLockerCallback(e) {
        let url = 'kyc/esign/callback';
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                _nextStep();
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                });
                _getKycProfile();
                setApiLoader(false);
            }
        })
    }

    const _nextStep = async () => {
        const kyc_data = await _getKycProfile();
        setApiLoader(false);
        props.updateProgress(10);
    }

    const LabelAnswer = (props) => {
        return (
            <Fragment>
                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-16px mb-2">{props.label}</p>
                <p className="color-black e-poppins-medium e-font-sm-16-14 line-height-22px mb-0 word-break-all">{props.answer}</p>
            </Fragment>
        )
    }

    const HeadingEdit = (props) => {
        return (
            <div className="d-flex align-items-center justify-content-between mb-4">
                <p className="color-jett-black e-poppins-medium e-font-sm-16-14 mb-0">{props.heading}</p>
                <div className="d-flex align-items-center cursor-pointer position-relative"
                    onMouseEnter={() => setShowEdit(props.type)}
                    onMouseLeave={() => setShowEdit(0)}
                    onClick={_handleNavigate}>
                    <span className="color-primary-color e-poppins-medium e-font-sm-16-14 line-height-16px me-1">Edit</span>
                    <Icon icon="Pen-square"
                        size={24} />
                    {
                        showEdit === props.type &&
                        <span className="color-outer-space e-poppins-regular e-font-12-sm-10 line-height-12px bg-white position-absolute px-2 e-preview-edit border-radius-4px">Edit</span>
                    }
                </div>
            </div>
        )
    }
    return (
        <Fragment>
            <div className="mb-sm-0 mb-3">
                <p className="color-black e-poppins e-poppins-bold e-font-24-sm-18 mb-2">Review your details</p>
                <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-24px mb-0">
                    Review all your given detail and continue to e-sign process.
                </p>
                <div className="row margin-32px-top">
                    <div className="col-lg-7 col-md-7 col-12">

                        {/* Pan detail */}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3">
                            <p className="color-jett-black e-poppins-medium e-font-sm-16-14 mb-4">PAN details</p>
                            <div className="row">
                                <div className="col-6">
                                    <LabelAnswer label="PAN number"
                                        answer={PREVIEW_DATA.pan_details.pan} />
                                </div>
                                <div className="col-6">
                                    <LabelAnswer label="Date of birth"
                                        answer={PREVIEW_DATA.pan_details.date_of_birth_str} />
                                </div>
                            </div>

                        </div>

                        {/* KRA information */}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-sm-3 mt-2">
                            <p className="color-jett-black e-poppins-medium e-font-sm-16-14 mb-4">KRA information</p>
                            <div className="row">
                                <div className="col-6">
                                    <LabelAnswer label="Name as on PAN"
                                        answer={PREVIEW_DATA.kra_info.name} />
                                </div>
                                <div className="col-6">
                                    <LabelAnswer label="Nationality"
                                        answer={PREVIEW_DATA.kra_info.nationality === true ? "Indian" : null} />
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="Aadhar number"
                                        answer={PREVIEW_DATA.kra_info.aadhaar} />
                                </div>
                                <div className="col-12 mt-3">
                                    <LabelAnswer label="Address"
                                        answer={PREVIEW_DATA.kra_info.address} />
                                </div>
                            </div>

                        </div>

                        {/* Additional information */}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-sm-3 mt-2">
                            <HeadingEdit heading="Additional information" type={1} />
                            <div className="row">
                                <div className="col-6">
                                    <LabelAnswer label="Fathers name"
                                        answer={PREVIEW_DATA.additional_info.father_name} />
                                </div>
                                <div className="col-6">
                                    <LabelAnswer label="Gender"
                                        answer={PREVIEW_DATA.additional_info.gender_text} />
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="Marital status"
                                        answer={PREVIEW_DATA.additional_info.marital_status_text} />
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="Occupation"
                                        answer={PREVIEW_DATA.additional_info.occupation_text} />
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="Annual income"
                                        answer={PREVIEW_DATA.additional_info.income_text} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12 mt-3">
                                    <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-16px mb-2">Signature status</p>
                                    {
                                        PREVIEW_DATA.additional_info.signature_status &&
                                        <FilePreview fileName={
                                            PREVIEW_DATA.additional_info.signature_status.substring(PREVIEW_DATA.additional_info.signature_status.lastIndexOf('/') + 1, PREVIEW_DATA.additional_info.signature_status.indexOf('?'))}
                                            fileUrl={PREVIEW_DATA.additional_info.signature_status}
                                            fileSize={PREVIEW_DATA.additional_info.file_size}
                                            showBinIcon={false}
                                            preview={true}
                                            previewFile={() => _handlePreview(1)} />
                                    }
                                    
                                </div>
                            </div>

                        </div>

                        {/* Declaration */}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-sm-3 mt-2">
                            <HeadingEdit heading="Declarations" type={2} />
                            <div className="row">
                                <div className="col-6">
                                    <LabelAnswer label="Tax payee in India"
                                        answer={PREVIEW_DATA.declaration.tax_payer_in_india === true ? "Yes" : "No"} />
                                </div>
                                
                                <div className="col-6">
                                    <LabelAnswer label="Politically exposed"
                                        answer={!PREVIEW_DATA.declaration.politically_exposed === true ? "Yes" : "No"} /> {/* negation needed since backed team is already performing it  */}
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="Terms & conditions"
                                        answer={PREVIEW_DATA.declaration.terms_and_condition === true ? "Checked" : "Unchecked"} />
                                </div>
                            </div>

                        </div>

                        {/* Bank details*/}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-sm-3 mt-2">
                            <HeadingEdit heading="Bank details" type={3} />
                            <div className="row">
                                <div className="col-6">
                                    <LabelAnswer label="Bank name"
                                        answer={PREVIEW_DATA.bank_details.bank_name} />
                                </div>
                                <div className="col-6">
                                    <LabelAnswer label="Account number"
                                        answer={PREVIEW_DATA.bank_details.account_number} />
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="Account type"
                                        answer={PREVIEW_DATA.bank_details.account_type_text} />
                                </div>
                                <div className="col-6 mt-3">
                                    <LabelAnswer label="IFSC code"
                                        answer={PREVIEW_DATA.bank_details.ifsc_code} />
                                </div>
                            </div>

                        </div>

                        {/* Nominee*/}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-sm-3 mt-2">
                            <HeadingEdit heading="Nominee"
                                type={4} />

                            {
                                PREVIEW_DATA.skip_nominee ?
                                    <LabelAnswer label="Status"
                                        answer={'Opt Out'} />
                                    :
                                    PREVIEW_DATA.nominees.map((item, key) =>
                                        <div className="row mt-3" key={key}>
                                            <div className="col-6">
                                                <LabelAnswer label="Nominee name"
                                                    answer={item.name} />
                                            </div>
                                            <div className="col-6">
                                                <LabelAnswer label="Relation"
                                                    answer={item.relationship} />
                                            </div>
                                            <div className="col-6 mt-3">
                                                <LabelAnswer label="Allocation percentage"
                                                    answer={item.allocation_text} />
                                            </div>
                                            {
                                                item.guardian_name !== null &&
                                                <div className="col-6 mt-3">
                                                    <LabelAnswer label="Guardian name"
                                                        answer={item.guardian_name} />
                                                </div>
                                            }
                                            {
                                                item.guardian_relationship.length !== 0 &&
                                                <div className="col-6 mt-3">
                                                    <LabelAnswer label="Guardian relation"
                                                        answer={item.guardian_relationship} />
                                                </div>
                                            }
                                            {
                                                item.guardian_pan !== null &&
                                                <div className="col-6 mt-3">
                                                    <LabelAnswer label="Guardian PAN"
                                                        answer={item.guardian_pan} />
                                                </div>
                                            }
                                        </div>
                                    )
                            }

                        </div>

                        {/* In person verification*/}
                        <div className="border-radius-8px bg-very-light-alice-blue p-3 mt-sm-3 mt-2">
                            <HeadingEdit heading="In person verification" type={5} />
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <p className="color-outer-space e-poppins-regular e-font-sm-16-14 line-height-16px mb-2">Status</p>
                                    <FilePreview fileName={PREVIEW_DATA.selfie_captured?.substring(PREVIEW_DATA.selfie_captured.lastIndexOf('/') + 1, PREVIEW_DATA.selfie_captured.indexOf('?'))}
                                        fileUrl={PREVIEW_DATA.selfie_captured}
                                        fileSize={PREVIEW_DATA.selfie_file_size}
                                        showBinIcon={false}
                                        preview={true}
                                        previewFile={() => _handlePreview(2)} />
                                </div>
                            </div>

                        </div>

                        <div className="margin-56px-top-sm-16 text-center">
                            <GradientButton label="Save & proceed to e-sign"
                                loading={apiLoader}
                                className="px-3 padding-12px-tb e-font-16 mob-w-100"
                                handleClick={_handleProceed} />
                        </div>
                    </div>
                </div>
            </div>
            {
                showPreview === true &&
                <SignaturePreviewModal file={fileUrl}
                    close={() => setShowPreview(false)} />
            }
        </Fragment>
    )
}

export default PreviewPage
