/*
 *   File : representation-label.js
 *   Author URI : https://evoqins.com
 *   Description : Label component
 *   Integrations : null
 *   Version : v1.1
 */

import { memo } from "react";

/*
    Different types of label handled in this component 
    
    Type : 1
    ---------
    Mandatory label 
    
    Type : 2
    --------
    Value and post fix
    Eg: Selected value from a slider component in Goal configuration page 

    Type : 3
    --------
    Value, prefix, white space and postfix
    Eg: Min and Max value to be shown in the slider component 

*/

export default memo(RepresentationLabel);
function RepresentationLabel(props) {
    return (
        <>
            {
                props.type == 1 ?
                    <label className='color-black e-poppins-regular e-font-sm-16-14 '>
                        {props.label}
                        {
                            props.mandatory === true && (
                                <span className='color-red'>*</span>
                            )
                        }
                    </label>
                    :
                    props.type == 2 ?
                        <p className='color-black e-poppins-regular e-font-sm-16-14 text-capitalized mb-0'>
                            <span className='e-poppins-medium me-1'>{props.value}</span>
                            {props.postfix}
                        </p>
                        :
                        <span className='color-old-silver e-poppins-regular e-font-12 mt-2'>
                            {props.prefix}{/* text to be shown before value */}
                            {props.value}{/* value to be displayed */}
                            {props.whiteSpace == false && " "}{/* include additional space between the content */}
                            {props.postfix}{/* text to be shown after value */}
                        </span>
            }
        </>
    )
}